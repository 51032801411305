import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { EnvironmentService } from 'src/app/snatch/services';
import { FetchSuggestions } from "../../actions/suggestion.actions";
const ɵ0 = (state) => state.suggestions.courseSuggestions, ɵ1 = (state) => state.suggestions.courseEventSuggestions, ɵ2 = (state) => state.suggestions.instructorSuggestions, ɵ3 = (state) => state.suggestions.fetchingSuggestions;
export class SuggestionListComponent {
    constructor(envService, store) {
        this.envService = envService;
        this.store = store;
        this.ngUnsubscribe = new Subject();
        this.domain = this.envService.serviceMarketUrl;
    }
    set allowDay(value) {
        this._term = value;
        this.goSearchSuggestions();
    }
    ngOnInit() {
        this.goSearchSuggestions();
    }
    goSearchSuggestions() {
        const context = this.store.snapshot().core.globalFilters.context;
        this.store.dispatch(new FetchSuggestions(this._term, context));
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], SuggestionListComponent.prototype, "courseSuggestions$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], SuggestionListComponent.prototype, "courseEventSuggestions$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], SuggestionListComponent.prototype, "instructorSuggestions$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], SuggestionListComponent.prototype, "fetchingSuggestions$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
