<ng-container>
    <div class="menu-wrapper" [class.open]="menuState">
        <div class="menu">
            <div class="column catalogs">
                <span class="title">CATALOGS</span>
                <ng-container *ngIf="!items || !items.catalogs.length">
                    <ul class="catalog-items">
                        <li class="menu-item" *ngFor="let number of [0,1,2,3,4,5,6,7,8]">
                            <nz-skeleton [nzActive]="true"
                                         [nzLoading]="true"
                                         [nzAvatar]="false"
                                         [nzParagraph]="{ rows: 1, width: '100%' }"
                                         [nzTitle]="false">
                            </nz-skeleton>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="items && items.catalogs.length">
                    <ul class="catalog-items" >
                        <li class="item"
                            [class.active]="selectedCatalog.code === item.code"
                            *ngFor="let item of items.catalogs"
                            (click)="toggleCatalog(item)">
                        <span>
                           <span class="menu-icon" *ngIf="item && item.catalogSvg" [innerHTML]="item.catalogSvg | sanitizeHtml"></span>
                           <span class="menu-icon" *ngIf="!item || !item.catalogSvg">
                               <ng-container *ngTemplateOutlet="defaultMenuIcon"></ng-container>
                           </span>
                            <span class="menu-text">{{ item.name | ellipsis:['32']}}</span>
                        </span>
                            <ng-container *ngTemplateOutlet="arrowLeft"></ng-container>
                        </li>
                    </ul>
                </ng-container>

            </div>
            <div class="separator-menu">
                <span></span>
            </div>
            <ng-container *ngIf="!items || !items.catalogs.length">
                <div class="column topics">
                    <div class="topics-and-subtopics">
                        <div class="topics-wrapper">
                            <span class="title">TOPICS</span>
                            <ng-container [ngTemplateOutlet]="topicSkeletonTemplate"
                                          [ngTemplateOutletContext]="{ items: [0,1,2,3,4,5,6,7,8]}"
                            ></ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="items && items.catalogs.length">
                <div class="column topics">
                    <div class="topics-and-subtopics">
                        <div class="box" [class.sub-topics-visible]="subTopicsVisible">
                            <div class="topics-wrapper"
                                 *ngIf="selectedCatalog"
                                 [ngStyle]="{width: selectedTopic ? '100%' : '50%'}">
                                <span class="title">TOPICS</span>
                                <ng-container [ngTemplateOutlet]="topicsTemplate"
                                              [ngTemplateOutletContext]="{ topics: items.topics}"
                                ></ng-container>
                                <div class="select-catalog" (click)="selectCatalog(selectedCatalog.code)">
                                    Show me all products within {{selectedCatalog?.name}}
                                </div>
                            </div>

                            <div class="sub-topics" *ngIf="selectedTopic">
                            <span class="title" (click)="toggleSubTopics()">
                                <ng-container [ngTemplateOutlet]="arrowRight"></ng-container>
                                {{items.topics[selectedTopicIndex].title}}</span>
                                <ng-container  [ngTemplateOutlet]="subTopicsTemplate"
                                               [ngTemplateOutletContext]="{ subTopics: items.subTopics}">
                                    <div class="select-topic" (click)="selectTopic(items.topics[selectedTopicIndex].code)">
                                        Show me all products within {{items.topics[selectedTopicIndex].title}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="close">
            <ng-container *ngTemplateOutlet="close"></ng-container>
        </div>
    </div>
</ng-container>
<ng-template #defaultMenuIcon>
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" width="17" height="20"><g transform="translate(1, 1)"><path d="M356 160H188c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zm12 52v-8c0-6.6-5.4-12-12-12H188c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12zm64.7 268h3.3c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h344c13.3 0 24 10.7 24 24v368c0 10-6.2 18.6-14.9 22.2-3.6 16.1-4.4 45.6-.4 65.8zM128 384h288V32H128v352zm-96 16c13.4-10 30-16 48-16h16V32H80c-26.5 0-48 21.5-48 48v320zm372.3 80c-3.1-20.4-2.9-45.2 0-64H80c-64 0-64 64 0 64h324.3z" vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);"/></g></svg>
</ng-template>

<ng-template #arrowLeft>
<span class="icon-arrow-left">
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible"
         preserveAspectRatio="none" viewBox="0 0 194 514" width="7.173333333333333" height="20"><g
            transform="translate(1, 1)"><path
            d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"
            vector-effect="non-scaling-stroke" /></g></svg>
</span>
</ng-template>

<ng-template #arrowRight>
    <span class="icon-arrow-right">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 194 514" width="7.173333333333333" height="20.000000000000007"><g transform="translate(1, 1)"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" vector-effect="non-scaling-stroke"/></g></svg>
    </span>
</ng-template>

<ng-template #close>
<span (click)="closeMenu()">
<svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 322 514" xml:space="preserve" y="0px" x="0px" id="Layer_1_1587373087825" width="14" height="22"><g transform="translate(1, 1)"><style type="text/css">
	.st0_1587373087825 {
        fill: #394042;
    }
</style><path d="M193.9,256l102.6-102.6l21.1-21.1c3.1-3.1,3.1-8.2,0-11.3L295,98.4c-3.1-3.1-8.2-3.1-11.3,0L160,222.1  L36.3,98.3c-3.1-3.1-8.2-3.1-11.3,0L2.3,121c-3.1,3.1-3.1,8.2,0,11.3L126.1,256L2.3,379.7c-3.1,3.1-3.1,8.2,0,11.3L25,413.6  c3.1,3.1,8.2,3.1,11.3,0L160,289.9l102.6,102.6l21.1,21.1c3.1,3.1,8.2,3.1,11.3,0l22.6-22.6c3.1-3.1,3.1-8.2,0-11.3L193.9,256z" class="st0_1587373087825" vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);"/></g></svg>
</span>
</ng-template>

<ng-template #topicsTemplate let-topics="topics">
    <div class="items">
        <div class="item"
             *ngFor="let item of topics | menuFilter:{parentCatalog: selectedCatalog.code}; let index = index"
             (click)="toggleSubTopics(item)">
            <div class="topic-icon" *ngIf="item && item.imageSvg" [innerHTML]="item.imageSvg | sanitizeHtml"></div>
            <div class="topic-icon" *ngIf="!item || !item.imageSvg"><ng-container *ngTemplateOutlet="defaultMenuIcon"></ng-container> </div>
            <div class="topic-title">{{ item.title | ellipsis:['25']}}</div>
        </div>
    </div>
</ng-template>

<ng-template #topicSkeletonTemplate let-items="items">
    <div class="items">
        <div class="item skeleton-item" *ngFor="let item of items; let index = index">
            <nz-skeleton [nzActive]="true"
                         [nzLoading]="true"
                         [nzAvatar]="{shape: 'square'}"
                         [nzParagraph]="{ rows: 1, width: '100%' }"
                         [nzTitle]="false">
            </nz-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #subTopicsTemplate let-subTopics="subTopics">
    <div class="items">
        <nz-empty *ngIf="checkIfNoSubTopicsExist()"></nz-empty>
        <ng-container *ngFor="let item of subTopics">
            <p *ngIf="item.parentTopics.includes(selectedTopic?.code) && item.parentCatalogs.includes(selectedCatalog.code)"
               class="sub-topic"
               (click)="selectSubtopic(item.code)">
                {{ item.title | ellipsis:['25'] }}
            </p>
        </ng-container>
    </div>
</ng-template>
