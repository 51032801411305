import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { EnvironmentService } from "src/app/snatch/services";
import { ClearGlobalSearch } from "../../../../actions/global-filters.actions";
import { SetContext } from "../../../../actions/global-filters.actions";
const ɵ0 = (state) => state.core.globalFilters.globalSearchPending;
export class GlobalSearchBarComponent {
    constructor(store, envService) {
        this.store = store;
        this.envService = envService;
        this.goToProductDetails = new EventEmitter();
        this.filterProducts = new EventEmitter();
        this.filterProduct = new EventEmitter();
        this.filterSeller = new EventEmitter();
        this.filterSubtopic = new EventEmitter();
        this.filter = new EventEmitter();
        this.typeSelected = new EventEmitter();
        this.ngUnsubscribe = new Subject();
    }
    ngOnInit() {
        this.defaultProfilePhotoUrl = this.envService.defaultProfilePhotoUrl;
        this.store.select((state) => state.core.globalFilters.searchForm.model)
            .pipe(skip(1), filter(_ => !_), takeUntil(this.ngUnsubscribe))
            .subscribe(_ => {
            this.form.reset();
        });
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    onKeyEnterSet(ev) {
        const type = this.form.get('type').value;
        const term = ev.target.value;
        this.dispatchRedirect(term);
    }
    goTo(type, term = '') {
        this.store.dispatch(new ClearGlobalSearch());
        switch (type) {
            case 'courses':
                window.location.href = `${this.envService.serviceMarketUrl}/courses?filter=${term}`;
                break;
            case 'instructors':
                window.location.href = `${this.envService.serviceMarketUrl}/instructors?filter=${term}`;
                break;
            default:
                this.filterProducts.emit(term);
                break;
        }
        this.filterProducts.emit(term);
        return;
    }
    selectType(type) {
        this.typeSelected.emit(type);
        this.store.dispatch(new SetContext(type));
    }
    onEnterProduct(hit) {
        this.filterProduct.emit({ hit, context: 'products' });
    }
    onEnterTopic(hit) {
        this.filterSubtopic.emit({ hit, context: 'products' });
    }
    onEnterSeller(hit) {
        this.filterSeller.emit({ hit, context: 'products' });
    }
    dispatchRedirect(term) {
        if (this.searchHits) {
            if (this.searchHits.products && this.searchHits.products.length) {
                const exactMatch = this.searchHits.products.find(el => el.key === term);
                if (exactMatch) {
                    this.onEnterProduct(exactMatch);
                    return;
                }
            }
            if (this.searchHits.sellers && this.searchHits.sellers.length) {
                const exactMatch = this.searchHits.sellers.find(el => el.key === term);
                if (exactMatch) {
                    this.onEnterSeller(exactMatch);
                    return;
                }
            }
            if (this.searchHits.topics && this.searchHits.topics.length) {
                const exactMatch = this.searchHits.topics.find(el => el.key === term);
                if (exactMatch) {
                    this.onEnterTopic(exactMatch);
                    return;
                }
            }
            if (this.searchHits.courses && this.searchHits.courses.length) {
                const exactMatch = this.searchHits.courses.find(el => el.course.code === term);
                if (exactMatch) {
                    window.location.href = `${this.envService.serviceMarketUrl}/courses/details/${term}?leapest-force-login=true`;
                    return;
                }
            }
            if (this.searchHits.instructors && this.searchHits.instructors.length) {
                const exactMatch = this.searchHits.instructors.find(el => el.instructor.id === term);
                if (exactMatch) {
                    window.location.href = `${this.envService.serviceMarketUrl}/instructors/profile/${term}?leapest-force-login=true`;
                    return;
                }
            }
        }
        const type = this.form.get('type').value;
        this.goTo(type, term);
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], GlobalSearchBarComponent.prototype, "globalSearchPending$", void 0);
export { ɵ0 };
