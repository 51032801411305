import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { Router } from "@angular/router";
import { map, catchError } from 'rxjs/operators';
import { NotificationsState } from './notifications.state';
import { GlobalFiltersState } from './global-filters.state';
import { DictionariesState } from './dictionaries.state';
import { Forbidden, NotFound } from "../constants/error-messages.config";
import { GetProfile, GetProfileSuccess, GetProfileFailure, GetEmployee, GetTranslations, RedirectOnError, GetVmsFlag } from "../actions/core.actions";
import { MiscService } from '../services/misc.service';
export class CoreStateModel {
}
let CoreState = class CoreState {
    constructor(miscService, router) {
        this.miscService = miscService;
        this.router = router;
    }
    static isPermittedToPublishToLxp(state) {
        return state.profile
            && state.profile.employee
            && state.profile.employee.isAccountAdmin
            && state.profile.employee.account.lxpConfigured
            && state.profile.employee.account.buyer;
    }
    getEmployee({ patchState, dispatch, getState }) {
        patchState({ getProfilePending: true, getProfileSuccess: false, getProfileError: undefined });
        return this.miscService.getEmployee().pipe(map(_ => _.data.employee), map((employee) => {
            return dispatch(new GetProfileSuccess({ employee }));
        }), catchError((httpError) => {
            const error = httpError.error.error;
            return dispatch(new GetProfileFailure(error));
        }));
    }
    getProfile({ dispatch }) {
        return dispatch(new GetEmployee());
    }
    getTranslations({ patchState, dispatch, getState }) {
        patchState(Object.assign({}, getState(), { translations: {} }));
        this.miscService.getProductMarketplaceTranslations().subscribe((response) => {
            window['translations'] = response.data;
            patchState(Object.assign({}, getState(), { translations: response.data }));
        });
    }
    getProfileSuccess({ patchState, getState }, action) {
        patchState(Object.assign({}, getState(), { getProfilePending: false, getProfileSuccess: true, getProfileError: undefined, profile: action.payload, currency: action.payload.employee && action.payload.employee.account ? action.payload.employee.account.currency : "EUR" //Default value EUR for itpx domain
         }));
    }
    getProfileFailure({ patchState, getState }, action) {
        patchState(Object.assign({}, getState(), { getProfilePending: false, getProfileSuccess: false, getProfileError: action.payload, profile: undefined }));
    }
    redirectTo404({ patchState, getState }, action) {
        switch (action.errorKey) {
            case NotFound:
                this.router.navigate(['404']);
                break;
            case Forbidden:
                this.router.navigate(['403']);
                break;
        }
    }
    getUserViewPermissions({ patchState, getState, dispatch }, action) {
        patchState({ getProfilePending: true });
        return this.miscService.getUserViewPermissionSettings(action.domain).pipe(map(body => body['data']), map((data) => {
            let vmsEnabled = data.features && data.features.vmsEnabled;
            let integrationEnabled = data.features && data.features.integration.enabled;
            patchState(Object.assign({}, getState(), { getProfilePending: false, vmsEnabled: vmsEnabled, integrationEnabled: integrationEnabled }));
            return dispatch([]);
        }), catchError((httpError) => {
            const error = httpError.error.error;
            return dispatch(new GetProfileFailure(error));
        }));
    }
};
tslib_1.__decorate([
    Action(GetEmployee),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "getEmployee", null);
tslib_1.__decorate([
    Action(GetProfile),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "getProfile", null);
tslib_1.__decorate([
    Action(GetTranslations),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "getTranslations", null);
tslib_1.__decorate([
    Action(GetProfileSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, GetProfileSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "getProfileSuccess", null);
tslib_1.__decorate([
    Action(GetProfileFailure),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, GetProfileFailure]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "getProfileFailure", null);
tslib_1.__decorate([
    Action(RedirectOnError),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RedirectOnError]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "redirectTo404", null);
tslib_1.__decorate([
    Action(GetVmsFlag),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, GetVmsFlag]),
    tslib_1.__metadata("design:returntype", void 0)
], CoreState.prototype, "getUserViewPermissions", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [CoreStateModel]),
    tslib_1.__metadata("design:returntype", Boolean)
], CoreState, "isPermittedToPublishToLxp", null);
CoreState = tslib_1.__decorate([
    State({
        name: 'core',
        defaults: {
            profile: undefined,
            currency: undefined,
            translations: {},
            getProfilePending: false,
            getProfileSuccess: false,
            getProfileError: undefined,
            vmsEnabled: false,
            integrationEnabled: false
        },
        children: [GlobalFiltersState, DictionariesState, NotificationsState]
    }),
    tslib_1.__metadata("design:paramtypes", [MiscService, Router])
], CoreState);
export { CoreState };
