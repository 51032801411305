export class CallbackResultModel {
  event: string;
  [key: string]: any;

  static toJSON(obj: CallbackResultModel): string {
     return JSON.stringify(obj);
  }

  static fromJSON(str: string): CallbackResultModel {
    try {
      const obj = JSON.parse(str);
      return Object.assign(new CallbackResultModel(), obj);
    } catch (err) {
      console.log(`Error decoded state as json: ${str}`);
      return null;
    }
  }
}
