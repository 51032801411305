import { Action, State, StateContext, Store } from '@ngxs/store';
import { AmberError, AmberResponse } from 'src/app/snatch/models/amber-response.model';
import { Sort } from 'src/app/snatch/models/sort.model';
import { UrlStorageService } from '../services/url-storage.service';
import { ProductsService } from '../services/products.service';
import { IPageable } from 'src/app/snatch/models/page.model';
import { ProductShort } from 'src/app/common/common-product/models/product-short.model';
import {
    FetchProducts,
    FetchProductsSuccess,
    FetchProductsFailure,
    RestoreProductsState,
    ChangePage,
    ToggleSort,
    ClearProducts,
    SelectSubtopic,
    FetchCategories,
    GlobalFilterProducts,
    FetchFavoriteSuppliers,
    FetchMySuppliers,
    SelectTopic,
    SelectCatalog
} from '../actions/products.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, map, tap } from "rxjs/operators";
import { of, zip } from 'rxjs';
import { ICountAggregate } from 'src/app/snatch/models/products-search-params.model';
import {Router} from "@angular/router";

export interface ISearchParams {
    deliveryFormats?: Array<string>;
    productTypes?: Array<string>;
    languages?: Array<string>;
    category?: Array<string>;
    level?: Array<string>;
    sellers?: Array<string>;
    topic?: string;
    subtopic?: string;
    filter?: string;
    deliveryCourses?: Array<string>;
    courseDeliveryFormats?: Array<string>;
    lxpProductStatus?: Array<string>;
    catalogs?: Array<string>;
}

export interface ISearchMetadataResponse {
    aggregations: Array<{ key: string, items: Array<{ key: string, count: number, displayValue: string }> }>;
    totalCount: number;
}

export interface ISearchMetadata {
    deliveryFormats: Array<ICountAggregate>;
    productTypes: Array<ICountAggregate>;
    languages: Array<ICountAggregate>;
    sellers: Array<ICountAggregate>;
    category: Array<ICountAggregate>;
    level: Array<ICountAggregate>;
    deliveryCourses: Array<ICountAggregate>;
    courseDeliveryFormats: Array<ICountAggregate>;
    lxpProductStatus: Array<ICountAggregate>;
    count: number;
}

export interface ProductsStateModel {
    products: ProductShort[];
    searchMetadata?: ISearchMetadata;
    searchForm: { model: ISearchParams };

    customSuppliers: Array<any>,
    // mySuppliers: Array<any>,

    getCustomSellersPending: boolean,
    getCustomSellersSuccess: boolean,
    getCustomSellersError: AmberError,

    getMySuppliersPending: boolean,
    getMySuppliersSuccess: boolean,
    getMySuppliersError: AmberError,

    sort: Sort;
    pagination: IPageable;

    categoriesMap: Map<string, string>;

    fetchProductsPending: boolean;
    fetchProductsSuccess: boolean;
    fetchProductsError: AmberError;
}

@State<ProductsStateModel>({
    name: 'products',
    defaults: {
        products: [],
        searchForm: {
            model: {
                topic: undefined,
                subtopic: undefined,
                filter: undefined,
                deliveryFormats: [],
                courseDeliveryFormats: [],
                productTypes: [],
                languages: [],
                level: [],
                category: [],
                sellers: [],
                catalogs: []
                // publishedToLXP: false,
                // unpublishedToLXP: false,
            }
        },

        customSuppliers: undefined,

        getCustomSellersPending: false,
        getCustomSellersSuccess: false,
        getCustomSellersError: undefined,

        getMySuppliersPending: false,
        getMySuppliersSuccess: false,
        getMySuppliersError: undefined,

        sort: {},
        pagination: { limit: 12, page: 1 },

        categoriesMap: new Map(),

        fetchProductsPending: false,
        fetchProductsSuccess: false,
        fetchProductsError: undefined
    }
})
export class ProductsState {
    constructor(
        private store: Store,
        private router: Router,
        private service: ProductsService,
        private urlStorageService: UrlStorageService
    ) {
    }

    @Action(RestoreProductsState)
    restoreState({ patchState }: StateContext<ProductsStateModel>) {
        const { filters, pageable, sort } = this.urlStorageService.restore();

        patchState({
            searchForm: {
                model: filters,
            },
            sort: sort
        });

        if (pageable.page > 1)
            setTimeout(_ => {
                patchState({
                    pagination: pageable
                })
            }, 500);
    }

    @Action(FetchCategories)
    fetchCategories({ patchState, dispatch }: StateContext<ProductsStateModel>, action: FetchCategories) {
        return this.service.listCategories(action.code, action.isCatalog)
            .pipe(
                map(_ => _.data.results),
                tap((results: any) => {
                    patchState({ categoriesMap: this.convertCategoriesArrayToMap(results) })
                    return dispatch([]);
                }),
                catchError((httpError: HttpErrorResponse) => {
                    const error = (<AmberResponse<any>>httpError.error).error;
                    return dispatch(new FetchProductsFailure(error));
                })
            );
    }

    private convertCategoriesArrayToMap(arr: { code: string, name: string }[]): Map<string, string> {
        return new Map(arr.map<any>(i => [i.code, i.name]));
    }

    @Action(FetchProducts)
    fetchProducts({ patchState, dispatch, getState }: StateContext<ProductsStateModel>) {
        patchState({
            ...getState(),
            fetchProductsPending: true,
            fetchProductsSuccess: false,
            fetchProductsError: undefined,
            products: []
        });

        {
            const { searchForm, sort, pagination } = getState();
            this.urlStorageService.save(searchForm.model, sort, pagination);
        }

        const {
            searchForm,
            sort,
            pagination,
            searchMetadata: currentMetadata
        } = getState();

        return zip(
            this.service.list(searchForm.model, sort, pagination).pipe(
                map(_ => _.data.results)
            ),
            currentMetadata ? of({
                data: {
                    results: null,
                    metadata: null
                }
            }) : this.service.list({}, sort, pagination).pipe(map(_ => _.data.results))
        ).pipe(
            tap(([
                { products, metadata },
                { metadata: fullMetadata }
            ]) => {
                const searchMetadata = getState().searchMetadata;
                if (metadata.aggregations.find(_ => _.key === 'seller'))
                metadata.aggregations.find(_ => _.key === 'seller').key = 'sellers';
                metadata = this.mergeSearchMetadata(
                    this.convertToISearchMetadata(metadata),
                    fullMetadata ? this.convertToISearchMetadata(fullMetadata) : searchMetadata);
                dispatch(new FetchProductsSuccess({ products, metadata }));
            }),
            catchError((httpError: HttpErrorResponse) => {
                console.log(httpError)
                if (httpError.error) {
                    const error = (<AmberResponse<any>>httpError.error).error;
                    return dispatch(new FetchProductsFailure(error));
                }
            })
        );
    }

    private convertToISearchMetadata(
        input: ISearchMetadataResponse
    ): ISearchMetadata {
        let result: any = {};

        input.aggregations.forEach(_ => {
            result[_.key] = this.convertToICountAggregate(_.items);
        })
        result.count = input.totalCount;
        return result
    }

    private mergeCollections(c1: Array<ICountAggregate>, c2: Array<ICountAggregate>): Array<ICountAggregate> {
        const second = c2.filter(i => c1.findIndex(ii => ii.id === i.id) === -1)
            .map(({ id, displayValue }) => ({ id, displayValue, count: 0 }));
        return c1.concat(second);
    }

    private mergeSearchMetadata(
        m1: ISearchMetadata,
        m2: ISearchMetadata): ISearchMetadata {
        return {
            ...m1,
            deliveryFormats: this.mergeCollections(m1.deliveryFormats, m2.deliveryFormats),
            courseDeliveryFormats: this.mergeCollections(m1.courseDeliveryFormats, m2.courseDeliveryFormats),
            productTypes: this.mergeCollections(m1['productType'], m2['productType']),
            languages: this.mergeCollections(m1.languages, m2.languages),
            level: this.mergeCollections(m1.level, m2.level),
            category: this.mergeCollections(m1.category || [], m2.category || []),
            sellers: this.mergeCollections(m1.sellers || [], m2.sellers || [])
        };
    }

    private convertToICountAggregate(list: Array<{ key: string, count: number, displayValue: string }>): Array<ICountAggregate> {
        return list.map(_ => {
            return <ICountAggregate>{
                id: _.key,
                displayValue: _.displayValue,
                count: _.count
            }
        });
    }

    @Action(FetchProductsSuccess)
    fetchProductsSuccess(
        { patchState }: StateContext<ProductsStateModel>, action: FetchProductsSuccess
    ) {
        patchState({
            fetchProductsPending: false,
            fetchProductsSuccess: true,
            fetchProductsError: undefined,
            products: action.payload.products,
            searchMetadata: action.payload.metadata || <ISearchMetadata>{}
        });
    }

    @Action(FetchProductsFailure)
    fetchProductsFailure({ patchState, getState }: StateContext<any>, action: FetchProductsFailure) {
        patchState({
            ...getState(),
            fetchProductsPending: false,
            fetchProductsSuccess: false,
            fetchProductsError: action.payload
        });
    }

    @Action(ToggleSort)
    toggleSort({ patchState, dispatch, getState }: StateContext<ProductsStateModel>, { key: newKey }: ToggleSort) {
        const { key, order } = getState().sort;
        if (key && key === newKey) {
            patchState({ ...getState(), sort: { key, order: order * -1 } });
        } else {
            patchState({ ...getState(), sort: { key: newKey, order: 1 } });
        }
    }

    @Action(ChangePage)
    changePage({ patchState, getState }: StateContext<ProductsStateModel>, action: ChangePage) {
        patchState({ pagination: { ...getState().pagination, page: action.payload } });
    }

    @Action(ClearProducts)
    clearProducts({ patchState, getState }: StateContext<ProductsStateModel>) {
        patchState({
            fetchProductsPending: false,
            fetchProductsSuccess: false,
            fetchProductsError: undefined,
            products: [],

            searchForm: {
                model: {
                    topic: null,
                    subtopic: null,
                    filter: null,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                }
            },
            sort: {},
            pagination: { limit: 12, page: 1 }
        });
    }

    @Action(SelectTopic)
    selectTopic({ getState, patchState, dispatch }: StateContext<ProductsStateModel>, action: SelectTopic) {
        patchState({
            searchForm: {
                model: {
                    topic: action.topic,
                    subtopic: undefined,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                }
            }
        });
        this.router.navigate(['products'], {queryParams: { topic: action.topic }});

        return dispatch([new FetchCategories(action.topic)])
    }

    @Action(SelectSubtopic)
    selectSubtopic({ getState, patchState, dispatch }: StateContext<ProductsStateModel>, action: SelectSubtopic) {
        patchState({
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: action.subTopic,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                }
            }
        });
        this.router.navigate(["products"], { queryParams: { subTopic: action.subTopic } });

        return dispatch([new FetchCategories(action.subTopic)])
    }

    @Action(SelectCatalog)
    selectCatalog({ getState, patchState, dispatch }: StateContext<ProductsStateModel>, action: SelectCatalog) {
        patchState({
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: undefined,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: action.catalogs
                }
            }
        });
        this.router.navigate(["products"], { queryParams: { catalog: action.catalogs } });

        return dispatch([new FetchCategories(action.catalogs[0], true)])
    }

    @Action(GlobalFilterProducts)
    globalFilterProducts({ patchState, dispatch }: StateContext<ProductsStateModel>, action: GlobalFilterProducts) {
        patchState({
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: action.subtopic,
                    filter: action.term,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: action.sellers,
                    catalogs: []
                }
            }
        });

        return dispatch([]);
    }

    @Action(FetchFavoriteSuppliers)
    fetchFavoriteSuppliers({ getState, patchState, dispatch }: StateContext<ProductsStateModel>, action: FetchFavoriteSuppliers) {
        patchState({
            customSuppliers: undefined,
            getCustomSellersPending: true,
            getCustomSellersSuccess: false,
            getCustomSellersError: undefined,
        });
        this.service.fetchFavoriteSuppliers().pipe(
            map(response => response.data)
        ).subscribe(response => {
            patchState({
                customSuppliers: this.getCustomSellersFromFavorites(response),
                getCustomSellersPending: false,
                getCustomSellersSuccess: true,
                getCustomSellersError: undefined,
            });
        },
            (httpError: HttpErrorResponse) => {

                patchState({
                    customSuppliers: undefined,
                    getCustomSellersPending: false,
                    getCustomSellersSuccess: false,
                    getCustomSellersError: <any>httpError
                });

                return dispatch([]);
            });
    }

    private getCustomSellersFromFavorites(supplierList: Array<any>) {
        return supplierList.map(_ => {
            return {
                displayValue: _.supplierName,
                id: _.sellerAccount.domain,
                count: _.count
            }
        })
    }

    @Action(FetchMySuppliers)
    fetchMySuppliers({ getState, patchState, dispatch }: StateContext<ProductsStateModel>, action: FetchMySuppliers) {

        patchState({
            customSuppliers: undefined,
            getCustomSellersPending: true,
            getCustomSellersSuccess: false,
            getCustomSellersError: undefined,
        });

        this.service.fetchMySuppliers().pipe(
            map((response: any) => response.data.results),
        ).subscribe((response: any) => {
            patchState({
                customSuppliers: this.getCustomSellersFromMySuppliers(response),
                getCustomSellersPending: false,
                getCustomSellersSuccess: true,
                getCustomSellersError: undefined,
            });
        },
            ((httpError: HttpErrorResponse) => {
                const error = (<AmberResponse<any>>httpError.error).error;
                patchState({
                    customSuppliers: undefined,
                    getCustomSellersPending: false,
                    getCustomSellersSuccess: false,
                    getCustomSellersError: <any>httpError
                });
            })
        );
    }

    private getCustomSellersFromMySuppliers(supplierList: Array<any>) {
        return supplierList.map(_ => {
            return {
                displayValue: _.displayName,
                id: _.name,
                count: _.count
            }
        })
    }
}
