export class DoGlobalSearch {
  static readonly type = '[Global Filters] Do Global Search';
  constructor(public context:string) {}
}

export class ClearGlobalSearch {
  static readonly type = '[Global Filters] Clear Global Search';
  constructor() {}
}
export class SetContext {
	static readonly type = '[Global Filters] Set Context Search';
	constructor(public context: string) {}
}
