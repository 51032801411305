import { Injectable } from '@angular/core';
import {Store} from "@ngxs/store";
import {ErrorMessages} from "../constants/error-messages.config";

@Injectable()
export class TranslateService {
    
    public translations: any = null;

    constructor(private store: Store) {
        this.store.select((state) => state.core.translations).subscribe((translations) => {
            this.translations = translations;
        });
    }

    public translate(key: string): string {
        return key && this.translations && this.translations.hasOwnProperty(key) ? this.translations[key] : '';
    }

    public static translateErrorMessage(key) {
        if (ErrorMessages.hasOwnProperty(key)) {
            return ErrorMessages[key];
        }
        return key;
    }
}
