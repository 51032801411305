import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'leap-product-filter-pill',
  templateUrl: './product-filter-pill.component.html',
  styleUrls: ['./product-filter-pill.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductFilterPillComponent implements OnInit {

  @Output()
  removed = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
