<form [formGroup]="form">
  <ul nz-menu nzMode="inline" class="filter-menu">
    <li>
      <div class="filters-title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.2" overflow="visible" preserveAspectRatio="none"
             viewBox="0 0 24 24" width="18" height="18">
          <g>
            <path xmlns:default="http://www.w3.org/2000/svg" d="M10,18h4v-2h-4V18z M3,6v2h18V6H3z M6,13h12v-2H6V13z"
                  style="fill: rgb(156, 164, 166);" vector-effect="non-scaling-stroke"/>
          </g>
        </svg>
        <span style="cursor: default;">Filters</span>
      </div>
      <nz-divider></nz-divider>
    </li>

    <li *ngIf="isPermittedToPublishToLxp && searchMetadata?.lxpProductStatus?.length">
      <p class="section-header">Products in LXP</p>
      <leap-searchable-multiselect
        [items]="searchMetadata?.lxpProductStatus"
        [showSearchInput]="false"
        [selectedItems]="searchForm?.model?.lxpProductStatus"
        (itemsStateChanged)="publishedToLXPSelected($event)">
      </leap-searchable-multiselect>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.courseDeliveryFormats?.length">
      <p class="section-header">Course Delivery</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!courseDeliveryPanelActive" (click)="courseDeliveryPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="courseDeliveryPanelActive" (click)="courseDeliveryPanelActive = false" nz-icon type="down"></i>
      </div>
      <p class="filter-description">Filter products suitable for different course deliveries.</p>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="courseDeliveryPanelActive">
          <leap-searchable-multiselect [items]="searchMetadata?.courseDeliveryFormats"
                                       [selectedItems]="searchForm?.model?.courseDeliveryFormats"
                                       (itemsStateChanged)="courseDeliveryFormatsSelected($event)">
          </leap-searchable-multiselect>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.productTypes?.length">
      <p class="section-header">Product Types</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!productTypesPanelActive" (click)="productTypesPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="productTypesPanelActive" (click)="productTypesPanelActive = false" nz-icon type="down"></i>
      </div>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="productTypesPanelActive">
          <leap-searchable-multiselect [items]="searchMetadata?.productTypes"
                                       [selectedItems]="searchForm?.model?.productTypes"
                                       (itemsStateChanged)="productTypesSelected($event)">
          </leap-searchable-multiselect>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.deliveryFormats?.length">
      <p class="section-header">Delivery Formats</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!deliveryFormatsPanelActive" (click)="deliveryFormatsPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="deliveryFormatsPanelActive" (click)="deliveryFormatsPanelActive = false" nz-icon type="down"></i>
      </div>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="deliveryFormatsPanelActive">
          <leap-searchable-multiselect [items]="searchMetadata?.deliveryFormats"
                                       [selectedItems]="searchForm?.model?.deliveryFormats"
                                       (itemsStateChanged)="deliveryFormatsSelected($event)">
          </leap-searchable-multiselect>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.languages?.length">
      <p class="section-header">Languages</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!languagesPanelActive" (click)="languagesPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="languagesPanelActive" (click)="languagesPanelActive = false" nz-icon type="down"></i>
      </div>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="languagesPanelActive">
          <leap-searchable-multiselect [items]="searchMetadata?.languages"
                                       [selectedItems]="searchForm?.model?.languages"
                                       (itemsStateChanged)="languagesSelected($event)">
          </leap-searchable-multiselect>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.category?.length">
      <p class="section-header">Categories</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!categoriesPanelActive" (click)="categoriesPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="categoriesPanelActive" (click)="categoriesPanelActive = false" nz-icon type="down"></i>
      </div>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="categoriesPanelActive">
          <leap-searchable-multiselect [items]="searchMetadata?.category"
                                       [selectedItems]="searchForm?.model?.category"
                                       (itemsStateChanged)="categoriesSelected($event)">
          </leap-searchable-multiselect>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.level?.length">
      <p class="section-header">Levels</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!levelPanelActive" (click)="levelPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="levelPanelActive" (click)="levelPanelActive = false" nz-icon type="down"></i>
      </div>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="levelPanelActive">
          <leap-searchable-multiselect [items]="searchMetadata?.level"
                                       [selectedItems]="searchForm?.model?.level"
                                       (itemsStateChanged)="levelSelected($event)">
          </leap-searchable-multiselect>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
    <li *ngIf="searchMetadata?.sellers?.length && searchMetadata.sellers.length > 1">
      <p class="section-header">Sellers</p>
      <div class="collapse-buttons-container">
        <i *ngIf="!sellersPanelActive" (click)="sellersPanelActive = true" nz-icon type="left"></i>
        <i *ngIf="sellersPanelActive" (click)="sellersPanelActive = false" nz-icon type="down"></i>
      </div>
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzActive]="sellersPanelActive">
<!--              FIXME when BE refactored for my suppliers enable myFavourites and mySuppliers-->
<!--          <div *ngIf="isAuthorized" class="custom-seller-filters">-->
<!--            <div><label nz-checkbox [(ngModel)]="showFavorites" [ngModelOptions]="{standalone: true}" (ngModelChange)="customSellerSelection()">Show Favorites</label></div>-->
<!--            <div><label nz-checkbox [(ngModel)]="showMySuppliers" [ngModelOptions]="{standalone: true}" (ngModelChange)="customSellerSelection()">My Suppliers</label></div>-->
<!--          </div>-->
<!--            <div *ngIf="showFavorites || showMySuppliers" class="custom-odyssey-sellers">-->
<!--&lt;!&ndash;                <nz-radio-group [(ngModel)]="selectedSellerOdyssey" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectionChanged()">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div *ngFor="let item of odysseySellers; trackBy: trackById" class="control-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <label nz-radio [ngStyle]="nzRadioSelectionStyles" [nzValue]="item.domain">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span class="display-value"><span class="token-wrapper" >{{item.displayName}}</span></span>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            <span class="option-count">({{item.count}})</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                        </label>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </nz-radio-group>&ndash;&gt;-->

<!--              <leap-searchable-multiselect [items]="odysseySellers"-->
<!--                                           [selectedItems]="searchForm?.model?.sellers"-->
<!--                                           [enableRadioSelection]="true"-->
<!--                                           (itemsStateChanged)="sellersSelected($event)">-->
<!--              </leap-searchable-multiselect>-->
<!--            </div>-->
            <div *ngIf="!showFavorites && !showMySuppliers">
                <leap-searchable-multiselect [items]="searchMetadata?.sellers"
                                             [selectedItems]="searchForm?.model?.sellers"
                                             (itemsStateChanged)="sellersSelected($event)">
                </leap-searchable-multiselect>
            </div>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-divider></nz-divider>
    </li>
  </ul>
</form>
