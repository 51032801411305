import { Component, Input, OnChanges, OnInit } from "@angular/core";

@Component({
  selector: "leap-sort-caret",
  templateUrl: "./sort-caret.component.html",
  styleUrls: ["./sort-caret.component.less"]
})
export class SortCaretComponent implements OnInit, OnChanges {

  @Input()
  direction?: number;
  @Input()
  active?: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }
}
