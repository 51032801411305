import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./environment.service";
export class DictionaryService {
    constructor(http, envService) {
        this.http = http;
        this.envService = envService;
    }
    getBulk(types) {
        const url = `${this.envService.amberUrl}/virgo/configuration/bulk/read`;
        const params = types.reduce((p, type) => p.append('types', type), new HttpParams());
        return this.http.get(url, { params });
    }
}
DictionaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DictionaryService_Factory() { return new DictionaryService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvironmentService)); }, token: DictionaryService, providedIn: "root" });
