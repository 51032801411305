import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {IFilter, ISelectedFilters, SortTypes} from "../../models/filters.model";
import {IPageable} from "../../models/page.model";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {FilterService} from "../../services/filter.service";
import {Subscription} from "rxjs";

@Component({
    selector: "leap-multi-table",
    templateUrl: "./multi-table.component.html",
    styleUrls: ["./multi-table.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FilterService]
})
export class MultiTableComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() fetchingPending: boolean;
    @Input() sortFields: IFilter[];
    @Input() displayingColumns: string[];
    @Input() columnTemplate: any;
    @Input() pagination: IPageable;
    @Input() totalCount: number;
    @Input() searchBar: boolean;
    @Input() searchPlaceholder: string;
    @Input() searchFilter: IFilter;
    @Input() externalFilter: IFilter;
    @Input() resetExternalFilter: IFilter;
    @Output() changedPage = new EventEmitter<{ page: string, filters: ISelectedFilters }>();
    @Output() filtersApplied = new EventEmitter<ISelectedFilters>();
    protected appliedFilters: IFilter[] = [];

    protected form: FormGroup = this.fb.group({
        filter: [null]
    });

    private search = new FormControl(null);
    private subscription: Subscription;

    constructor(private fb: FormBuilder, private filterService: FilterService) {
        this.subscription = this.filterService.listenSelectedFilters().subscribe(
            res => {
                let filters = this.filterService.getSelectedFiltersParamsAsObject();
                this.filtersApplied.emit(filters);
            }
        );
    }

    ngOnInit() {
        this.listenSearch();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ("externalFilter" in changes) {
            if (this.externalFilter) {
                this.pushFilter(this.externalFilter);
            }
        }
        if ("resetExternalFilter" in changes) {
            if (this.resetExternalFilter) {
                this.resetFilterExternal(this.resetExternalFilter);
            }
        }
    }

    changePage(page: any) {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        let filters = this.filterService.getSelectedFiltersParamsAsObject();
        this.changedPage.emit({page, filters});
    }

    listenSearch() {
        this.search.valueChanges
            .pipe(debounceTime(100))
            .subscribe(
                world => {
                    const findIndex = this.appliedFilters.findIndex(f => f.value === "filter");
                    const filterObj = this.searchFilter;
                    filterObj.order = `&filter=${world}`;

                    if (findIndex >= 0) {
                        this.appliedFilters.splice(findIndex, 1, filterObj);
                    } else this.appliedFilters.push(filterObj);

                    this.filterService.setFilter(filterObj);
                    this.callFilters(filterObj);
                }
            );
    }

    public pushFilter(filter) {
        const sort = filter.type === SortTypes.SORT;
        const findTheSameIndex = this.appliedFilters.findIndex(s => s.value == filter.value);
        if (findTheSameIndex >= 0) {
            this.appliedFilters.splice(findTheSameIndex, 1);
            if (sort) {
                this.appliedFilters.unshift(filter);
            } else this.appliedFilters.push(filter);
        } else {
            if (sort) {
                this.appliedFilters.unshift(filter);
            } else this.appliedFilters.push(filter);
        }
        this.filterService.setFilter(filter, sort);
        this.callFilters(filter);
    }

    private callFilters(filter: IFilter) {
        let filters = this.filterService.getSelectedFiltersParamsAsObject();
        const applyFilter = {...filter};
        applyFilter.order = filters;
        applyFilter.action(applyFilter);
    }

    private resetFilterExternal(filter: IFilter) {
        const findIndex = this.appliedFilters.findIndex(a => a.external && a.value === filter.value);
        this.appliedFilters.splice(findIndex, 1);
        this.filterService.removeFilter(filter);
        this.callFilters(filter);
    }

    toggleSort(event: any) {

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
