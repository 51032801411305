import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'leap-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent implements OnInit {

  @Input() typeFilter: string;
  
  @Output() changeTypeFilter: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
