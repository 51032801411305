import { PublishToLxpFormValueDto } from "../models/publish-to-lxp-form-dto.interface";
import { ProductShort } from "../models/product-short.model";
import {
  PublishBundleToLxpPayload,
  PublishToLxpPayload,
} from "../models/publish-to-lxp-payload.interface";
import { isMonthlyConsumptionInvoice } from "./transaction-type.utils";

export function buildPublishToLxpPayload(
  dto: PublishToLxpFormValueDto,
  productDetails: ProductShort,
  lxpProductId?: string
): PublishToLxpPayload {
  const isConsumption: boolean =
    dto.transactionType && dto.transactionType.value
      ? isMonthlyConsumptionInvoice(dto.transactionType.value)
      : false;

  const restrictedDeliveryFormat = dto.restrictedDeliveryFormats.find(
    (df) => df.sku === productDetails.sku
  );
  const res: PublishToLxpPayload = {
    approval: dto.approvalRequired,
    approvalPolicy: dto.approvalRequired ? { id: dto.approvalRule.id } : null,
    channels: dto.channels,
    consumption: isConsumption,
    groups: dto.groups,
    name: productDetails.name,
    online: true,
    prepayment: !isConsumption,
    product: { sku: productDetails.sku },
    sku: productDetails.sku,
    restrictedDeliveryFormat:
      restrictedDeliveryFormat &&
      restrictedDeliveryFormat.restrictedDeliveryFormat,
    transactionType: {
      id: dto.transactionType ? dto.transactionType.key : null,
    },
  };

  if (lxpProductId) {
    res.id = lxpProductId;
  }

  return res;
}

export function buildPublishBundleToLxpPayload(
  dto: PublishToLxpFormValueDto,
  productDetails: ProductShort
): PublishBundleToLxpPayload {
  return {
    channels: dto.channels,
    bundleSku: productDetails.bundle.sku,
  };
}
