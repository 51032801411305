import { Action, State, StateContext, Store } from "@ngxs/store";
import { MiscService } from '../services/misc.service';
import { DictionaryItem, ICatalog } from 'src/app/snatch/models/dictionary.model';
import { map } from "rxjs/operators";
import { GetCatalogTree } from "../actions/dictionaries.actions";

export class DictionariesStateModel {
  topicsTree: any[];
  catalogs: ICatalog;
  currencies: DictionaryItem[];
  countries: DictionaryItem[];
  currenciesMap: Map<string, string>;
  currencySignsMap: Map<string, string>;
  topicsMap: Map<string, string>;
  countriesMap: Map<string, string>;
  statesMap: Map<string, Array<StateCodeName>>;
  subTopicsMap: Map<string, string>;
  lxpProductStatusMap: Map<string, string>;
  dictionaryFetchingPending: boolean;

  catalogTreeFetchingPending: boolean;
  catalogTreeFetchingSuccess: boolean;
  catalogTreeFetchingError: any;
  catalogTreeFetchingFailed: boolean;
  catalogTree: ICatalog;
  catalogsMap: Map<string, string>;
}

export interface Topic {
  code: string;
  title: string;
  children: { code: string, title: string }[];
}

export interface StateCodeName {
  code: string;
  name: string;
}

@State<DictionariesStateModel>({
  name: 'dictionaries',
  defaults: {
    catalogs: {
      catalogs: [],
      topics: [],
      subTopics: []
    },
    currencies: [],
    countries: [],
    topicsTree: [],
    catalogsMap: undefined,
    currenciesMap: undefined,
    currencySignsMap: undefined,
    topicsMap: undefined,
    countriesMap: undefined,
    statesMap: undefined,
    subTopicsMap: undefined,
    lxpProductStatusMap: undefined,
    dictionaryFetchingPending: false,

    catalogTreeFetchingPending: false,
    catalogTreeFetchingSuccess: true,
    catalogTreeFetchingFailed: false,
    catalogTreeFetchingError: undefined,
    catalogTree: {
      catalogs: [],
      topics: [],
      subTopics: []
    }
  }
})
export class DictionariesState {
  constructor(private miscService: MiscService, private store: Store) { }

  ngxsOnInit({ patchState, getState }: StateContext<DictionariesStateModel>) {

    let currencySignsMap = new Map()
      .set('USD', '$')
      .set('EUR', '€')
      .set('INR', '₹')
      .set('GBP', '£')
      .set('DKK', 'kr')
      .set('AED', 'AED');

    let lxpProductStatusMap = new Map()
      .set('published', 'Published')
      .set('notPublished', 'Not Published');

    patchState({ currencySignsMap, dictionaryFetchingPending: true, lxpProductStatusMap });

    this.miscService.getDictionaries()
      .pipe(
        map(response => response.data)
      )
      .subscribe(({
        currencies,
        countries,
      }) => {

        patchState({

          currencies,
          currenciesMap: this.convertArrayToMap(currencies),

          countries,
          countriesMap: this.convertArrayToMap(countries),

          dictionaryFetchingPending: false
        })
      });

    this.miscService.getStates()
      .pipe(
        map(response => response.data.states)
      )
      .subscribe((states) => {
        patchState({
          ...getState(),
          statesMap: this.convertStatesToMap(states)
        });
      });
  }

  @Action(GetCatalogTree)
  fetchCatalogTree({ patchState, dispatch, getState }: StateContext<DictionariesStateModel>, action: GetCatalogTree) {
    patchState({catalogTreeFetchingPending: true});
    this.miscService.getCatalogs()
      .pipe(
        map(_ => _.data)
      )
      .subscribe((data: any) => {
        const catalogs = data.catalogs ? data.catalogs : [];
        patchState({
          catalogTree: this.mapCatalogs(catalogs).menu,
          topicsMap: this.mapCatalogs(catalogs).topicsMap,
          subTopicsMap: this.mapCatalogs(catalogs).subTopicsMap,
          catalogsMap: this.convertCategoriesArrayToMap(catalogs),
          catalogTreeFetchingSuccess: true,
          catalogTreeFetchingPending: false
        });
        return dispatch([]);
      }, error => {
        patchState({
          catalogTreeFetchingPending: false,
          catalogTreeFetchingError: error,
          catalogTreeFetchingFailed: true
        });
        return dispatch([]);
      })
  }

  convertArrayToMap(arr: DictionaryItem[]): Map<string, string> {
    return new Map(arr.map<any>(i => [i.key, i.value]));
  }

  convertCategoriesArrayToMap(arr: { code: string, name: string }[]): Map<string, string> {
    return new Map(arr.map<any>(i => [i.code, i.name]));
  }

  convertSubTopics(arr: { code: string, title: string }[]): Map<string, string> {
    return new Map(arr.map<any>(i => [i.code, i.title]));
  }

  convertStatesToMap(arr: { countryCode: string, code: string, name: string }[]): Map<string, Array<StateCodeName>> {
    let result = new Map<string, Array<StateCodeName>>();
    arr.forEach((_) => {
      const cCode = _.countryCode;
      const sCode = _.code;
      const name = _.name;
      if (!result.has(cCode)) result.set(cCode, []);
      result.set(cCode, [...result.get(cCode), { code: sCode, name }]);
    });
    return result;
  }

  mapCatalogs(arr: any): { menu: ICatalog, topicsMap: Map<string, string>, subTopicsMap: Map<string, string> } {
    const menu = {
      catalogs: [],
      topics: [],
      subTopics: []
    };
    const uniqueSubTopics = {};
    arr.forEach(item => {
      const { categories, ...restData } = item;
      menu.catalogs.push(restData);

      const mapCategory = categories ? this.mapTopics(categories, item.code) : null;
      const { mTopics, mSubTopics } = mapCategory;
      // FIXME move check if topic if empty to Backend

      mSubTopics.forEach(s => {
        const findTheSame = Object.keys(uniqueSubTopics).find(k => k === s.code);
        if (findTheSame) {
          uniqueSubTopics[findTheSame].parentTopics.push(...s.parentTopics);
          uniqueSubTopics[findTheSame].parentCatalogs.push(item.code);
        } else {
          uniqueSubTopics[s.code] = s;
        }
      });
      menu.topics.push(...mTopics);
    });
    menu.subTopics.push(...Object.values(uniqueSubTopics));
    const topicsMap = this.convertSubTopics(menu.topics);
    const subTopicsMap = this.convertSubTopics(menu.subTopics);
    return { menu, topicsMap, subTopicsMap };
  }

  mapTopics(arr, parentCatalog: string) {
    const mTopics = [];
    const mSubTopics = [];

    arr.forEach(item => {
      const topics = item.topics;
      topics.forEach(e => {
        const { children, ...restData } = e;
        const findEl = mTopics.find(el => el.code === e.code && el.parentCatalog === parentCatalog);
        if (!findEl) {
          mTopics.push({ ...restData, parentCatalog });
          children.forEach(c => {
            const findSubTopic = mSubTopics.find(s => s.code === c.code);
            if (!findSubTopic) {
              mSubTopics.push({ ...c, parentTopics: [e.code], parentCatalogs: [parentCatalog] });
            }

          })
        }
      })
    });
    return { mTopics: mTopics.sort((a, b) => (a.title > b.title) ? 1 : -1), mSubTopics: mSubTopics.sort((a, b) => (a.title > b.title) ? 1 : -1) };
  }
}

