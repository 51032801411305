import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from "@angular/core";
import { NavigateToLogout } from "../../../auth/actions/auth.actions";
import { Select, Store } from "@ngxs/store";
import { AuthTokenState } from "../../../auth/state/auth-token.state";
import { Observable, Subject } from "rxjs";
import { Profile } from "src/app/common/common-profile/models/profile.model";
import { OpenShoppingCartDrawer } from "src/app/shopping-cart/actions/shopping-cart.actions";
import { map, filter } from "rxjs/operators";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { SelectSubtopic, GlobalFilterProducts } from "src/app/products/actions/products.actions";
import { DoGlobalSearch, ClearGlobalSearch } from "../../actions/global-filters.actions";
import { EnvironmentService } from "src/app/snatch/services";
import {ICatalogItem} from "src/app/snatch/models/dictionary.model";
import { GetVmsFlag } from "../../actions/core.actions";
import { GetCatalogTree } from "../../actions/dictionaries.actions";
import {GenericSearchHit} from "../../state/global-filters.state";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
  selector: "leap-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.less"]
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Select(AuthTokenState.isLogged)
  isLogged$: Observable<boolean>;
  @Select((state: any) => state.core.getProfilePending)
  getProfilePending$: Observable<boolean>;
  @Select((state: any) => state.core.profile)
  profile$: Observable<Profile>;
  @Select((state: any) => state.core.vmsEnabled)
  vmsEnabled$: Observable<boolean>;
  @Select((state: any) => state.session.groups)
  groups$: Observable<string[]>;

  @Select((state: any) => state.session.userDetails)
  userDetails$: Observable<any>;

  @Select((state: any) => state.core.dictionaries.topicsTree)
  topicsTree$: Observable<any[]>;

  @Select((state: any) => state.shoppingCart.lineItems)
  shoppingCartlineItems$: Observable<any[]>;

  shoppingCartLineItemsCount$: Observable<number>;

  @Select((state: any) => state.core.dictionaries.catalogTree)
  catalogs$: Observable<ICatalogItem[]>;
  menuState =  false;
  @Select((state: any) => state.core.integrationEnabled)
  integrationEnabled$: Observable<boolean>;
  @Select((state: any) => state.domain.domainConfiguration)
  domainConfiguration$: Observable<any>;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  innerWidth: number;

  showSignInBanner: boolean = true;
  isWelcomePage: boolean;

  isLogged: boolean;
  navLogo:string;

  // Use for takeUntil to unsubscribe onDestroy
  private ngUnsubscribe = new Subject();

  constructor(private store: Store, private route: ActivatedRoute, private router: Router, public environment: EnvironmentService) {
    this.innerWidth = window.innerWidth;

    this.shoppingCartLineItemsCount$ = this.shoppingCartlineItems$
      .pipe(map(_ => _.length));
  }

  ngOnInit() {
    this.domainConfiguration$.subscribe(data =>{
      if(data && data.logo){
      this.navLogo = `${this.environment.s3Url}/${this.environment.cdnBucket}${data.logo.marketplaceHeader.url}`;
      }
      })
    this.isLogged$
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.isLogged = _;
      })
    this.isWelcomePage = this.route.snapshot["_routerState"].url.startsWith("/welcome");
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((_: NavigationEnd) => _.urlAfterRedirects.startsWith("/welcome"))
      )
      .subscribe((_: boolean) => {
        this.isWelcomePage = _;
      });
    this.store.dispatch(new GetCatalogTree());
    this.userDetails$
      .subscribe( data => data && data.domain ? this.store.dispatch(new GetVmsFlag(data.domain)): null);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  login() {
    this.router.navigate(['login'])
  }

  register() {
    const url = `${this.environment.saharaUrl}/register`;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  switchAccount() {
    this.router.navigate(['login'])
  }

  logout() {
    this.store.dispatch(new NavigateToLogout());
  }

  openShoppingCartDrawer() {
    this.store.dispatch(new OpenShoppingCartDrawer());
  }

  topicSelect(subTopic: string) {
    this.store.dispatch([
      new SelectSubtopic(subTopic)
    ]);
    this.router.navigate(["products"], { queryParams: { subtopic: subTopic } });
  }

  filterProducts(filter: string) {
    this.store.dispatch([
      new GlobalFilterProducts(filter, null, null),
      new ClearGlobalSearch()
    ]);
    this.router.navigate(['products'], { queryParams: { filter }});
  }

  filterProduct({hit}: {hit: GenericSearchHit}) {
    this.store.dispatch([
      new ClearGlobalSearch()
    ]);
    this.router.navigate(['products', 'details', hit.key]);
    if (this.menuState) {
      this.menuState = false;
    }
  }

  filterSeller({hit}: {hit: GenericSearchHit}) {
    this.store.dispatch([
      new GlobalFilterProducts(null, [hit.key], null),
      new ClearGlobalSearch()
    ]);
    this.router.navigate(["products"], { queryParams: { sellers: hit.key } });
  }

  filterSubtopic({hit}: {hit: GenericSearchHit}) {
    this.store.dispatch([
      new GlobalFilterProducts(null, [], hit.key),
      new ClearGlobalSearch()
    ]);
    this.router.navigate(["products"], { queryParams: { subtopic: hit.key } });
  }

  doGlobalSearch(ev) {
    this.store.dispatch([
      new DoGlobalSearch(ev.context)
    ]);
  }
   navigateToServiceMarket(){
    window.location.href= `${this.environment.serviceMarketUrl}${this.isLogged ? '?leapest-force-login=true' : ''}`;
   }

  toggleMenu(state) {
    this.menuState = state;
  }
}
