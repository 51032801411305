import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';

export interface NavItem {
  label: string;
  path: string;
  queryParams?: Params;
}

export interface NavGroup {
  label?: string;
  navItems: NavItem[];
}

@Component({
  selector: 'leap-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class SideNavigationMenuComponent {

  @Input() navGroups: NavGroup[] = [];

  constructor() { }
}