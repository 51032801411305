import * as tslib_1 from "tslib";
import { Action, State, Store } from '@ngxs/store';
import { UrlStorageService } from '../services/url-storage.service';
import { ProductsService } from '../services/products.service';
import { FetchProducts, FetchProductsSuccess, FetchProductsFailure, RestoreProductsState, ChangePage, ToggleSort, ClearProducts, SelectSubtopic, FetchCategories, GlobalFilterProducts, FetchFavoriteSuppliers, FetchMySuppliers, SelectTopic, SelectCatalog } from '../actions/products.actions';
import { catchError, map, tap } from "rxjs/operators";
import { of, zip } from 'rxjs';
import { Router } from "@angular/router";
let ProductsState = class ProductsState {
    constructor(store, router, service, urlStorageService) {
        this.store = store;
        this.router = router;
        this.service = service;
        this.urlStorageService = urlStorageService;
    }
    restoreState({ patchState }) {
        const { filters, pageable, sort } = this.urlStorageService.restore();
        patchState({
            searchForm: {
                model: filters,
            },
            sort: sort
        });
        if (pageable.page > 1)
            setTimeout(_ => {
                patchState({
                    pagination: pageable
                });
            }, 500);
    }
    fetchCategories({ patchState, dispatch }, action) {
        return this.service.listCategories(action.code, action.isCatalog)
            .pipe(map(_ => _.data.results), tap((results) => {
            patchState({ categoriesMap: this.convertCategoriesArrayToMap(results) });
            return dispatch([]);
        }), catchError((httpError) => {
            const error = httpError.error.error;
            return dispatch(new FetchProductsFailure(error));
        }));
    }
    convertCategoriesArrayToMap(arr) {
        return new Map(arr.map(i => [i.code, i.name]));
    }
    fetchProducts({ patchState, dispatch, getState }) {
        patchState(Object.assign({}, getState(), { fetchProductsPending: true, fetchProductsSuccess: false, fetchProductsError: undefined, products: [] }));
        {
            const { searchForm, sort, pagination } = getState();
            this.urlStorageService.save(searchForm.model, sort, pagination);
        }
        const { searchForm, sort, pagination, searchMetadata: currentMetadata } = getState();
        return zip(this.service.list(searchForm.model, sort, pagination).pipe(map(_ => _.data.results)), currentMetadata ? of({
            data: {
                results: null,
                metadata: null
            }
        }) : this.service.list({}, sort, pagination).pipe(map(_ => _.data.results))).pipe(tap(([{ products, metadata }, { metadata: fullMetadata }]) => {
            const searchMetadata = getState().searchMetadata;
            if (metadata.aggregations.find(_ => _.key === 'seller'))
                metadata.aggregations.find(_ => _.key === 'seller').key = 'sellers';
            metadata = this.mergeSearchMetadata(this.convertToISearchMetadata(metadata), fullMetadata ? this.convertToISearchMetadata(fullMetadata) : searchMetadata);
            dispatch(new FetchProductsSuccess({ products, metadata }));
        }), catchError((httpError) => {
            console.log(httpError);
            if (httpError.error) {
                const error = httpError.error.error;
                return dispatch(new FetchProductsFailure(error));
            }
        }));
    }
    convertToISearchMetadata(input) {
        let result = {};
        input.aggregations.forEach(_ => {
            result[_.key] = this.convertToICountAggregate(_.items);
        });
        result.count = input.totalCount;
        return result;
    }
    mergeCollections(c1, c2) {
        const second = c2.filter(i => c1.findIndex(ii => ii.id === i.id) === -1)
            .map(({ id, displayValue }) => ({ id, displayValue, count: 0 }));
        return c1.concat(second);
    }
    mergeSearchMetadata(m1, m2) {
        return Object.assign({}, m1, { deliveryFormats: this.mergeCollections(m1.deliveryFormats, m2.deliveryFormats), courseDeliveryFormats: this.mergeCollections(m1.courseDeliveryFormats, m2.courseDeliveryFormats), productTypes: this.mergeCollections(m1['productType'], m2['productType']), languages: this.mergeCollections(m1.languages, m2.languages), level: this.mergeCollections(m1.level, m2.level), category: this.mergeCollections(m1.category || [], m2.category || []), sellers: this.mergeCollections(m1.sellers || [], m2.sellers || []) });
    }
    convertToICountAggregate(list) {
        return list.map(_ => {
            return {
                id: _.key,
                displayValue: _.displayValue,
                count: _.count
            };
        });
    }
    fetchProductsSuccess({ patchState }, action) {
        patchState({
            fetchProductsPending: false,
            fetchProductsSuccess: true,
            fetchProductsError: undefined,
            products: action.payload.products,
            searchMetadata: action.payload.metadata || {}
        });
    }
    fetchProductsFailure({ patchState, getState }, action) {
        patchState(Object.assign({}, getState(), { fetchProductsPending: false, fetchProductsSuccess: false, fetchProductsError: action.payload }));
    }
    toggleSort({ patchState, dispatch, getState }, { key: newKey }) {
        const { key, order } = getState().sort;
        if (key && key === newKey) {
            patchState(Object.assign({}, getState(), { sort: { key, order: order * -1 } }));
        }
        else {
            patchState(Object.assign({}, getState(), { sort: { key: newKey, order: 1 } }));
        }
    }
    changePage({ patchState, getState }, action) {
        patchState({ pagination: Object.assign({}, getState().pagination, { page: action.payload }) });
    }
    clearProducts({ patchState, getState }) {
        patchState({
            fetchProductsPending: false,
            fetchProductsSuccess: false,
            fetchProductsError: undefined,
            products: [],
            searchForm: {
                model: {
                    topic: null,
                    subtopic: null,
                    filter: null,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                }
            },
            sort: {},
            pagination: { limit: 12, page: 1 }
        });
    }
    selectTopic({ getState, patchState, dispatch }, action) {
        patchState({
            searchForm: {
                model: {
                    topic: action.topic,
                    subtopic: undefined,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                }
            }
        });
        this.router.navigate(['products'], { queryParams: { topic: action.topic } });
        return dispatch([new FetchCategories(action.topic)]);
    }
    selectSubtopic({ getState, patchState, dispatch }, action) {
        patchState({
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: action.subTopic,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                }
            }
        });
        this.router.navigate(["products"], { queryParams: { subTopic: action.subTopic } });
        return dispatch([new FetchCategories(action.subTopic)]);
    }
    selectCatalog({ getState, patchState, dispatch }, action) {
        patchState({
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: undefined,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: action.catalogs
                }
            }
        });
        this.router.navigate(["products"], { queryParams: { catalog: action.catalogs } });
        return dispatch([new FetchCategories(action.catalogs[0], true)]);
    }
    globalFilterProducts({ patchState, dispatch }, action) {
        patchState({
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: action.subtopic,
                    filter: action.term,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: action.sellers,
                    catalogs: []
                }
            }
        });
        return dispatch([]);
    }
    fetchFavoriteSuppliers({ getState, patchState, dispatch }, action) {
        patchState({
            customSuppliers: undefined,
            getCustomSellersPending: true,
            getCustomSellersSuccess: false,
            getCustomSellersError: undefined,
        });
        this.service.fetchFavoriteSuppliers().pipe(map(response => response.data)).subscribe(response => {
            patchState({
                customSuppliers: this.getCustomSellersFromFavorites(response),
                getCustomSellersPending: false,
                getCustomSellersSuccess: true,
                getCustomSellersError: undefined,
            });
        }, (httpError) => {
            patchState({
                customSuppliers: undefined,
                getCustomSellersPending: false,
                getCustomSellersSuccess: false,
                getCustomSellersError: httpError
            });
            return dispatch([]);
        });
    }
    getCustomSellersFromFavorites(supplierList) {
        return supplierList.map(_ => {
            return {
                displayValue: _.supplierName,
                id: _.sellerAccount.domain,
                count: _.count
            };
        });
    }
    fetchMySuppliers({ getState, patchState, dispatch }, action) {
        patchState({
            customSuppliers: undefined,
            getCustomSellersPending: true,
            getCustomSellersSuccess: false,
            getCustomSellersError: undefined,
        });
        this.service.fetchMySuppliers().pipe(map((response) => response.data.results)).subscribe((response) => {
            patchState({
                customSuppliers: this.getCustomSellersFromMySuppliers(response),
                getCustomSellersPending: false,
                getCustomSellersSuccess: true,
                getCustomSellersError: undefined,
            });
        }, ((httpError) => {
            const error = httpError.error.error;
            patchState({
                customSuppliers: undefined,
                getCustomSellersPending: false,
                getCustomSellersSuccess: false,
                getCustomSellersError: httpError
            });
        }));
    }
    getCustomSellersFromMySuppliers(supplierList) {
        return supplierList.map(_ => {
            return {
                displayValue: _.displayName,
                id: _.name,
                count: _.count
            };
        });
    }
};
tslib_1.__decorate([
    Action(RestoreProductsState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "restoreState", null);
tslib_1.__decorate([
    Action(FetchCategories),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchCategories]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "fetchCategories", null);
tslib_1.__decorate([
    Action(FetchProducts),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "fetchProducts", null);
tslib_1.__decorate([
    Action(FetchProductsSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchProductsSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "fetchProductsSuccess", null);
tslib_1.__decorate([
    Action(FetchProductsFailure),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchProductsFailure]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "fetchProductsFailure", null);
tslib_1.__decorate([
    Action(ToggleSort),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ToggleSort]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "toggleSort", null);
tslib_1.__decorate([
    Action(ChangePage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangePage]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "changePage", null);
tslib_1.__decorate([
    Action(ClearProducts),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "clearProducts", null);
tslib_1.__decorate([
    Action(SelectTopic),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectTopic]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "selectTopic", null);
tslib_1.__decorate([
    Action(SelectSubtopic),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectSubtopic]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "selectSubtopic", null);
tslib_1.__decorate([
    Action(SelectCatalog),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectCatalog]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "selectCatalog", null);
tslib_1.__decorate([
    Action(GlobalFilterProducts),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, GlobalFilterProducts]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "globalFilterProducts", null);
tslib_1.__decorate([
    Action(FetchFavoriteSuppliers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchFavoriteSuppliers]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "fetchFavoriteSuppliers", null);
tslib_1.__decorate([
    Action(FetchMySuppliers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchMySuppliers]),
    tslib_1.__metadata("design:returntype", void 0)
], ProductsState.prototype, "fetchMySuppliers", null);
ProductsState = tslib_1.__decorate([
    State({
        name: 'products',
        defaults: {
            products: [],
            searchForm: {
                model: {
                    topic: undefined,
                    subtopic: undefined,
                    filter: undefined,
                    deliveryFormats: [],
                    courseDeliveryFormats: [],
                    productTypes: [],
                    languages: [],
                    level: [],
                    category: [],
                    sellers: [],
                    catalogs: []
                    // publishedToLXP: false,
                    // unpublishedToLXP: false,
                }
            },
            customSuppliers: undefined,
            getCustomSellersPending: false,
            getCustomSellersSuccess: false,
            getCustomSellersError: undefined,
            getMySuppliersPending: false,
            getMySuppliersSuccess: false,
            getMySuppliersError: undefined,
            sort: {},
            pagination: { limit: 12, page: 1 },
            categoriesMap: new Map(),
            fetchProductsPending: false,
            fetchProductsSuccess: false,
            fetchProductsError: undefined
        }
    }),
    tslib_1.__metadata("design:paramtypes", [Store,
        Router,
        ProductsService,
        UrlStorageService])
], ProductsState);
export { ProductsState };
