import * as io from 'socket.io-client';
import { fromEvent, Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/snatch/services';
import { SocketEvents } from '../constants/headwig-ws-events.const';
import * as i0 from "@angular/core";
import * as i1 from "../../snatch/services/environment.service";
export class HedwigService {
    constructor(environment) {
        this.environment = environment;
        this.socketUrl = this.environment.hedwigUrl;
        this.fetchNotifications = (page, limit, archived) => {
            return new Observable((observer) => {
                this.socket.emit(SocketEvents.FETCH_NOTIFICATIONS, { page, limit, archived }, (data) => {
                    observer.next(data);
                    observer.complete();
                });
            });
        };
        this.newMessage = () => {
            return fromEvent(this.socket, SocketEvents.NEW_MESSAGE);
        };
        this.fetchMessages = (channelId, cursor, size) => {
            return new Observable((observer) => {
                this.socket.emit(SocketEvents.FETCH_MESSAGES, { channelId, cursor, size }, (data) => {
                    observer.next(data);
                    observer.complete();
                });
            });
        };
        this.fetchChannels = () => {
            return new Observable((observer) => {
                this.socket.emit(SocketEvents.FETCH_CHANNELS, {}, (data) => {
                    observer.next(data);
                    observer.complete();
                });
            });
        };
        this.newChannel = () => {
            return fromEvent(this.socket, SocketEvents.NEW_CHANNEL);
        };
        this.updateChannel = () => {
            return fromEvent(this.socket, SocketEvents.UPDATE_CHANNEL);
        };
        this.createDMChannel = (domain) => {
            return new Observable((observer) => {
                this.socket.emit(SocketEvents.CREATE_DM_CHANNEL, { domain }, (data) => {
                    observer.next(data);
                    observer.complete();
                });
            });
        };
        this.checkDMChannel = (domain) => {
            return new Observable((observer) => {
                this.socket.emit(SocketEvents.CHECK_DM_CHANNEL, { domain }, (data) => {
                    observer.next(data);
                    observer.complete();
                });
            });
        };
    }
    init(accessToken) {
        this.socket = io(`${this.socketUrl}?accessToken=${accessToken}`, {
            transports: ['websocket'],
            upgrade: false
        });
    }
    getSummary() {
        return fromEvent(this.socket, SocketEvents.WELCOME);
    }
    newNotification() {
        return fromEvent(this.socket, SocketEvents.NEW_NOTIFICATION);
    }
    notificationsUpdated() {
        return fromEvent(this.socket, SocketEvents.CHANGE_NOTIFICATION_STATUS);
    }
    changeNotificationStatus(payload) {
        return new Observable((observer) => {
            this.socket.emit(SocketEvents.CHANGE_NOTIFICATION_STATUS, {
                notifications: payload.notificationsIds,
                isNew: payload.isNew,
                isArchived: payload.isArchived,
            }, (data) => {
                observer.next(data);
                observer.complete();
            });
        });
    }
    sendMessage(channelId, message) {
        return new Observable((observer) => {
            this.socket.emit(SocketEvents.SEND_MESSAGE, { channelId, message }, (data) => {
                observer.next(data);
                observer.complete();
            });
        });
    }
    markReadMessage(channelId, messageId) {
        return new Observable((observer) => {
            this.socket.emit(SocketEvents.MARK_READ, { channelId, messageId }, (data) => {
                observer.next(data);
                observer.complete();
            });
        });
    }
}
HedwigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HedwigService_Factory() { return new HedwigService(i0.ɵɵinject(i1.EnvironmentService)); }, token: HedwigService, providedIn: "root" });
