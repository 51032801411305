import * as tslib_1 from "tslib";
import { State, Action, Store } from '@ngxs/store';
import { DoGlobalSearch, ClearGlobalSearch, SetContext } from '../actions/global-filters.actions';
import { MiscService } from '../services/misc.service';
import { map, catchError, tap } from 'rxjs/operators';
export class GlobalFiltersStateModel {
}
const defaults = {
    searchForm: {
        model: {
            term: undefined,
            type: 'products'
        }
    },
    searchHits: undefined,
    lastQuery: undefined,
    context: 'products',
    globalSearchPending: false
};
let GlobalFiltersState = class GlobalFiltersState {
    constructor(miscService, store) {
        this.miscService = miscService;
        this.store = store;
    }
    doGlobalSearch({ patchState, dispatch, getState }, action) {
        let termExists = getState().searchForm.model && getState().searchForm.model.term;
        if (!termExists) {
            patchState({ searchHits: undefined });
            return undefined;
        }
        patchState({ lastQuery: getState().searchForm.model.term, globalSearchPending: true });
        switch (action.context) {
            case 'products':
                return this.miscService.doGlobalSearch(getState().searchForm.model.term)
                    .pipe(catchError((httpError) => {
                    patchState({ globalSearchPending: false });
                    return dispatch([]);
                }), map(response => response.data.results), tap((results) => {
                    patchState({ searchHits: Object.assign({}, getState().searchHits, { products: results.products, sellers: results.sellers, topics: results.subtopics }), globalSearchPending: false });
                    return dispatch([]);
                }));
            case 'courses':
                return this.miscService.doGlobalSearchCourses(getState().searchForm.model.term)
                    .pipe(catchError((httpError) => {
                    patchState({ globalSearchPending: false });
                    return dispatch([]);
                }), map(response => response.data.results), tap((results) => {
                    patchState({ searchHits: Object.assign({}, getState().searchHits, { courses: results.courses }), globalSearchPending: false });
                    return dispatch([]);
                }));
            case 'instructors':
                return this.miscService.doGlobalSearchInstructors(getState().searchForm.model.term)
                    .pipe(catchError((httpError) => {
                    patchState({ globalSearchPending: false });
                    return dispatch([]);
                }), map(response => response.data.results), tap((results) => {
                    patchState({ searchHits: Object.assign({}, getState().searchHits, { instructors: results.instructors }), globalSearchPending: false });
                    return dispatch([]);
                }));
            default:
                return this.miscService.doGlobalSearch(getState().searchForm.model.term)
                    .pipe(catchError((httpError) => {
                    patchState({ globalSearchPending: false });
                    return dispatch([]);
                }), map(response => response.data.results), tap((results) => {
                    patchState({ searchHits: Object.assign({}, getState().searchHits, { products: results.productHits }), globalSearchPending: false });
                    return dispatch([]);
                }));
        }
    }
    clearGlobalSearch({ patchState, dispatch, getState }, action) {
        patchState(defaults);
    }
    SetContext({ patchState, dispatch, getState }, action) {
        patchState({ context: action.context });
    }
};
tslib_1.__decorate([
    Action(DoGlobalSearch),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DoGlobalSearch]),
    tslib_1.__metadata("design:returntype", void 0)
], GlobalFiltersState.prototype, "doGlobalSearch", null);
tslib_1.__decorate([
    Action(ClearGlobalSearch),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ClearGlobalSearch]),
    tslib_1.__metadata("design:returntype", void 0)
], GlobalFiltersState.prototype, "clearGlobalSearch", null);
tslib_1.__decorate([
    Action(SetContext),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetContext]),
    tslib_1.__metadata("design:returntype", void 0)
], GlobalFiltersState.prototype, "SetContext", null);
GlobalFiltersState = tslib_1.__decorate([
    State({
        name: 'globalFilters',
        defaults
    }),
    tslib_1.__metadata("design:paramtypes", [MiscService, Store])
], GlobalFiltersState);
export { GlobalFiltersState };
