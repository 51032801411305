import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Surcharge } from 'src/app/common/common-product/models/product-variant-pricing.model';
import {IProductPricingTiers} from "../../models/promotion-code.model";

@Component({
  selector: 'leap-line-item-price-and-surcharge',
  templateUrl: './line-item-price-and-surcharge.component.html',
  styleUrls: ['./line-item-price-and-surcharge.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemPriceAndSurchargeComponent implements OnInit {

  @Input() currency: string;
  @Input() netAmountWithSurcharges: number;
  @Input() netAmount: number;
  @Input() surcharges: Surcharge[];
  @Input() surchargeTotal: number;
  @Input() discountTiers?: IProductPricingTiers[];
  @Input() currencySignsMap: Map<string, string>;

  constructor() { }

  ngOnInit() {
  }
}
