import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CloseShoppingCartDrawer, QuantityChangeLineItemInShoppingCart, RemoveLineItemFromShoppingCart } from '../../actions/shopping-cart.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { GoToDetails } from "../../../checkout/actions/checkout.actions";
const ɵ0 = state => state.shoppingCart.drawerVisible, ɵ1 = state => state.shoppingCart.lineItems, ɵ2 = state => state.shoppingCart.grossTotal, ɵ3 = state => state.shoppingCart.taxTotal, ɵ4 = state => state.shoppingCart.discountTotal, ɵ5 = state => state.shoppingCart.currency, ɵ6 = (state) => state.core.dictionaries.languagesMap, ɵ7 = (state) => state.core.dictionaries.deliveryFormatsMap, ɵ8 = (state) => state.core.dictionaries.certificateTypesMap, ɵ9 = (state) => state.core.dictionaries.productTypesMap, ɵ10 = (state) => state.core.dictionaries.currencySignsMap, ɵ11 = (state) => state.shoppingCart.addLineItemPending, ɵ12 = (state) => state.shoppingCart.addLineItemError, ɵ13 = (state) => state.shoppingCart.removeLineItemPending, ɵ14 = (state) => state.shoppingCart.removeLineItemError, ɵ15 = (state) => state.shoppingCart.quantityChangeLineItemPending, ɵ16 = (state) => state.shoppingCart.quantityChangeLineItemError, ɵ17 = (state) => state.shoppingCart.addPromotionCodePending, ɵ18 = (state) => state.shoppingCart.addPromotionCodeError, ɵ19 = (state) => state.shoppingCart.removePromotionCodePending, ɵ20 = (state) => state.shoppingCart.removePromotionCodeError;
export class ShoppingCartDrawerComponent {
    constructor(store, router, activatedRoute) {
        this.store = store;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    ngOnInit() {
        this.calculating$ = combineLatest(this.addLineItemPending$, this.removeLineItemPending$, this.quantityChangeLineItemPending$, this.addPromotionCodePending$, this.removePromotionCodePending$)
            .pipe(map(([a, b, c, d, e]) => a || b || c || d || e));
        this.error$ = merge(this.addLineItemError$, this.removeLineItemError$, this.quantityChangeLineItemError$, this.addPromotionCodeError$, this.removePromotionCodeError$).pipe(map(_ => {
            return _;
        }));
    }
    closeShoppingCartDrawer() {
        this.store.dispatch(new CloseShoppingCartDrawer());
    }
    removeLineItem(lineItem, index) {
        this.store.dispatch(new RemoveLineItemFromShoppingCart(lineItem));
    }
    quantityChangeLineItem(lineItem, index) {
        this.store.dispatch(new QuantityChangeLineItemInShoppingCart(lineItem));
    }
    trackByFn(index, item) {
        return item.id;
    }
    viewCart() {
        this.closeShoppingCartDrawer();
        this.router.navigate(['shopping-cart']);
    }
    proceedToCheckout() {
        this.closeShoppingCartDrawer();
        this.router.navigate(['checkout']).then(_ => {
            this.store.dispatch(new GoToDetails());
        });
    }
    goBackToProducts() {
        this.closeShoppingCartDrawer();
        this.router.navigate(['products']);
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "drawerVisible$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "lineItems$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "grossTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "taxTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ4),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "discountTotal$", void 0);
tslib_1.__decorate([
    Select(ɵ5),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "currency$", void 0);
tslib_1.__decorate([
    Select(ɵ6),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "languagesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ7),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "deliveryFormatsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ8),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "certificateTypesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ9),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "productTypesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ10),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "currencySignsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ11),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "addLineItemPending$", void 0);
tslib_1.__decorate([
    Select(ɵ12),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "addLineItemError$", void 0);
tslib_1.__decorate([
    Select(ɵ13),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "removeLineItemPending$", void 0);
tslib_1.__decorate([
    Select(ɵ14),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "removeLineItemError$", void 0);
tslib_1.__decorate([
    Select(ɵ15),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "quantityChangeLineItemPending$", void 0);
tslib_1.__decorate([
    Select(ɵ16),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "quantityChangeLineItemError$", void 0);
tslib_1.__decorate([
    Select(ɵ17),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "addPromotionCodePending$", void 0);
tslib_1.__decorate([
    Select(ɵ18),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "addPromotionCodeError$", void 0);
tslib_1.__decorate([
    Select(ɵ19),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "removePromotionCodePending$", void 0);
tslib_1.__decorate([
    Select(ɵ20),
    tslib_1.__metadata("design:type", Observable)
], ShoppingCartDrawerComponent.prototype, "removePromotionCodeError$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20 };
