<div class="conversation">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <div class="conversation-container" #conversationContainer infiniteScroll leapScrollContainer
        [infiniteScrollUpDistance]="1.5"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScrollDown()"
        (scrolledUp)="onScrollUp()">
        <nz-spin *ngIf="fetchingOlderMessages$ | async" nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
        <ng-container *ngIf="(messages$ | async).length" >
            <ng-container *ngFor="let message of messages$ | async; trackBy: trackById; let i = index;">
                <ng-container [ngSwitch]="(conversation$ | async)?.channel?.type">
                    <ng-container *ngSwitchCase="'dm'">
                        <leap-message-with-context *ngIf="(conversation$ | async)?.channel as channel"
                            [orientation]="message.sender?.domain === myDomain ? 'right' : 'left'"
                            [imageUrl]="getSenderLogoUrl(message.sender.domain, channel.accounts)"
                            [timestamp]="message.createdAt"
                            [sender]="getSenderDisplayName(message.sender.domain, channel.accounts)">
                            <div message>
                                <div>{{ message.context.message }}</div>
                            </div>
                        </leap-message-with-context>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="bottom-container">
        <ng-container *ngIf="(conversation$ | async) as conversation">
            <leap-compose-message
                *ngIf="!conversation.channel.closed"
                [recipient]="getRecipientDisplayName(conversation)"
                (send)="sendMessage($event)"></leap-compose-message>
        </ng-container>
    </div>
</div>

<ng-template #indicatorTemplate><i nz-icon nzType="loading" style="font-size: 24px;"></i> </ng-template>

<ng-template #headerTemplate>
    <div class="header-container" *ngIf="conversation$ | async as conversation">
        <div style="display: flex;">
            <ng-container *ngIf="getRecipientLogoUrl(conversation); else imagePlaceholder">
                <div class="image-container">
                    <img class="image" alt="Image"
                        [src]="getRecipientLogoUrl(conversation)">
                </div>
            </ng-container>
            <ng-template #imagePlaceholder>
              <div class="image-placeholder"></div>  
            </ng-template>
            <h5>{{ getRecipientDisplayName(conversation) }}</h5>
        </div>
    </div>
</ng-template>