import * as tslib_1 from "tslib";
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
// In app.module we need to init the OKTA SDK
export function getOktaFactory(router) {
    const oktaAuth = createOktaClient({ issuer: environment.okta.issuer, clientId: environment.okta.clientId });
    const factoryOut = { oktaAuth };
    factoryOut['onAuthRequired'] = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
        const activeSession = yield checkForActiveSession();
        if (activeSession) {
            yield oktaSignInWithRedirect(oktaAuth);
        }
        else {
            router.navigate(['/login']);
        }
    });
    return factoryOut;
}
export function oktaSignInWithRedirect(oktaClient) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        try {
            yield oktaClient.signInWithRedirect();
        }
        catch (e) { }
    });
}
export function checkForActiveSession() {
    try {
        const cookie = getCookie('mkp-session');
        const cookieClaims = cookie && cookie.length && JSON.parse(cookie);
        if (cookieClaims && cookieClaims.username) {
            return true;
        }
    }
    catch (e) { }
    return false;
}
export function checkForSessionMismatch(oktaClient, storageService, sessionService) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        try {
            const cookie = getCookie('mkp-session');
            const cookieClaims = cookie && cookie.length && JSON.parse(cookie);
            if (cookieClaims && cookieClaims.username) {
                const oktaTokenStorage = storageService.get('okta-token-storage');
                const accessTokenData = oktaTokenStorage && oktaTokenStorage['accessToken'];
                const accessToken = accessTokenData && accessTokenData['accessToken'];
                const authTime = sessionService.decodeToken(accessToken).auth_time;
                if (cookieClaims.authAt > authTime) { // stale session, must sign in with redirect
                    yield oktaClient.signInWithRedirect();
                }
            }
        }
        catch (e) { }
        return false;
    });
}
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
// Config OKTA Client with session storage for `lxp` session
// and local storage for `platform` session
function createOktaClient(config) {
    const oktaParams = {
        issuer: config.issuer,
        clientId: config.clientId,
        redirectUri: getRedirectUri(),
        scopes: ['openid', 'profile', 'offline_access'],
        useInteractionCodeFlow: false,
    };
    const oktaAuth = new OktaAuth(oktaParams);
    return oktaAuth;
}
function getRedirectUri() {
    return `${window.location.origin}/login/callback`;
}
