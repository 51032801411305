import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

// Icons Static Load
import { IconDefinition } from '@ant-design/icons-angular';
import {
  StopOutline,
  PlusOutline,
  BellOutline,
  AppstoreOutline,
  FileOutline,
  CloudOutline,
  PictureOutline,
  DownloadOutline,
  DeleteOutline,
  LaptopOutline,
  StarOutline,
  NotificationOutline,
  UserOutline,
  CheckOutline,
  EditOutline,
  DownOutline,
  LeftOutline,
  MailOutline
} from '@ant-design/icons-angular/icons';
import { NZ_ICON_DEFAULT_TWOTONE_COLOR, NZ_ICONS, NZ_NOTIFICATION_CONFIG } from 'ng-zorro-antd';
// Icons Static End

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import bg from '@angular/common/locales/bg';

import { NgxsModule, Store } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { SnatchModule } from './snatch/snatch.module';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthService } from './auth/auth.service';
import { AuthRequestInterceptor } from './core/services/auth-request-interceptor.service';
import { GraphQLModule } from './graphql.module';
import { DomainConfigurationService } from './auth/domain-configuration.service';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { Router } from '@angular/router';
import * as oktaHelpers from './snatch/utils/okta-helpers';
import { of } from 'rxjs';
import { ListenForSessionChange } from './auth/actions/auth.actions';

registerLocaleData(en);

// Every Icon you use should be imported individually to keep the bundle size low
const icons: IconDefinition[] = [
  StopOutline,
  PlusOutline,
  BellOutline,
  AppstoreOutline,
  FileOutline,
  CloudOutline,
  PictureOutline,

  DownloadOutline,
  EditOutline,
  DeleteOutline,

  LaptopOutline,
  StarOutline,
  NotificationOutline,
  UserOutline,

  CheckOutline,

  DownOutline,
  LeftOutline,

  MailOutline
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    GraphQLModule,
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsStoragePluginModule.forRoot({ key: ['session'] }),

    CoreModule,
    SnatchModule.forRoot(),
    OktaAuthModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },

    // Icons Static Load
    { provide: NZ_ICON_DEFAULT_TWOTONE_COLOR, useValue: '#00ff00' }, // If not provided, Ant Design's official blue would be used
    { provide: NZ_ICONS, useValue: icons },
    // Icons Static End
    {
      provide: OKTA_CONFIG,
      useFactory: (router: Router) => oktaHelpers.getOktaFactory(router),
      deps: [Router],
    },
    {
      provide: NZ_NOTIFICATION_CONFIG,
      useValue: {
        nzDuration: 30 * 1000,
        nzPauseOnHover: true,
        nzAnimate: true
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService, store: Store, domainConfigurationService: DomainConfigurationService) => {
        return () => of().toPromise().then(async _ => {
          const result = await authService.initSession()
          store.dispatch([result, new ListenForSessionChange()])
          return domainConfigurationService.getDomainConfiguration()
        })
      },
      deps: [AuthService, Store, DomainConfigurationService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthRequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
