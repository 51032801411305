import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from 'src/app/snatch/services';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ChangeTypeFilter } from '../../state/inbox.state';
const ɵ0 = (state) => state.inbox.typeFilter, ɵ1 = (state) => state.inbox.selectedConversation;
export class InboxComponent {
    constructor(environment, store) {
        this.environment = environment;
        this.store = store;
    }
    ngOnInit() {
        const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
        this.myEmail = userDetails.username;
        this.myDomain = userDetails.domain;
    }
    ngOnDestroy() { }
    onChangeTypeFilter(type) {
        this.store.dispatch(new ChangeTypeFilter(type));
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], InboxComponent.prototype, "typeFilter$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], InboxComponent.prototype, "conversation$", void 0);
export { ɵ0, ɵ1 };
