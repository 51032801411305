import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";

import { ProductVariantShort } from "../../models/product-short.model";
import { Params, Router } from "@angular/router";
import { Configuration } from "../../../../snatch/models/configuration.model";
import { EnvironmentService } from "src/app/snatch/services";
import {
  BundleQueryModel,
  CommonPricingQueryModel,
} from "../../../../product-details/models/bundle.model";

@Component({
  selector: "leap-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() sku: string;
  @Input() name: string;
  @Input() suffix: string;
  @Input() type: string;
  @Input() price: number;
  @Input() currency: string;
  @Input() shortDescription: string;
  @Input() hasAccess: boolean;
  @Input() isNotContractedSupplier: boolean;
  @Input() restricted: boolean;
  @Input() variants: ProductVariantShort[];
  @Input() isBundleType: boolean;
  @Input() bundle: BundleQueryModel;

  @Input() addLineItemSku: string;
  @Input() addLineItemPending: boolean;
  @Input() searchedDeliveryFormats: Array<string>;
  @Input() searchedLanguageTypes: Array<string>;

  @Input() currencySignsMap: Map<string, string>;
  @Input() sellerImageUrl: string;
  @Input() sellerName: string;
  @Input() sellerDomain: string;
  @Input() isLogged: boolean;
  @Input() checked: boolean;
  @Input() publishedToLxp: boolean;
  @Input() enabledForLxp: boolean;
  @Input() isPermittedToPublishToLxp: boolean;
  @Input() isPromotionPricingApplied: boolean;
  @Input() actualAmount: number;
  @Input() promotionPricingDiscountEndDate: string;

  @Output() addToCart: EventEmitter<string> = new EventEmitter();
  @Output() publishToLxp: EventEmitter<string> = new EventEmitter();
  @Output() editLxpProduct: EventEmitter<string> = new EventEmitter();
  @Output() selectCard: EventEmitter<boolean> = new EventEmitter();

  languages: Configuration[];
  deliveryFormats: Configuration[];
  certificateTypes: Configuration[];
  licenses: CommonPricingQueryModel[];
  selectedVariant: ProductVariantShort;
  addToBasketDisabled: boolean = false;
  form: FormGroup = this.fb.group({
    productSku: [null],
    sku: [null],
    language: [null],
    deliveryFormat: [null],
    certificateType: [null],
    license: [null],
  });
  imageUrl: string;

  get canBePublishedToLxp(): boolean {
    return (
      (!this.restricted || this.hasAccess) &&
      !this.isNotContractedSupplier &&
      this.enabledForLxp &&
      this.isPermittedToPublishToLxp &&
      !this.isBundleType
    );
  }

  get isProductBundle(): boolean {
    return;
  }

  get lxpLabelTooltip(): string {
    return !this.canBePublishedToLxp
      ? `This product can't be published to your EdCast LXP.`
      : this.publishedToLxp
      ? this.isBundleType
        ? "The bundle is already published"
        : `Click here to view the publish details`
      : "This item is not available in your EdCast LXP. Click the Publish to LXP button to make it available.";
  }

  constructor(
    private fb: FormBuilder,
    private chgRef: ChangeDetectorRef,
    private router: Router,
    private envService: EnvironmentService
  ) {}

  ngOnInit() {
    this.addToBasketDisabled =
      (this.restricted && !this.hasAccess) || this.isNotContractedSupplier;
    this.languages = this.variants
      .map((_: ProductVariantShort) => _.languageConfig)
      .filter(this.uniqueFromArray);
    this.deliveryFormats = this.variants
      .map((_: ProductVariantShort) => _.deliveryFormatConfig)
      .filter(this.uniqueFromArray);
    this.certificateTypes = this.variants
      .map((_: ProductVariantShort) => _.certificateTypeConfig)
      .filter(this.uniqueFromArray);
    this.licenses = (this.bundle && this.bundle.licensePricing) || [];

    let firstLanguage = this.languages[0].configKey;
    let firstDeliveryFormat = this.deliveryFormats.length
      ? this.deliveryFormats[0].configKey
      : undefined;
    let firstCertificateType = this.certificateTypes.length
      ? this.certificateTypes[0].configKey
      : undefined;

    const pvBySearchTerms =
      this.searchedDeliveryFormats && this.searchedDeliveryFormats.length
        ? this.variants.find((variant) => {
            return (
              this.searchedDeliveryFormats.indexOf(variant.deliveryFormat) >=
                0 || this.searchedLanguageTypes.indexOf(variant.language) >= 0
            );
          })
        : null;
    const defaultVariant = this.variants.filter((_) => !!_.defaultVariant)[0];

    const firstVariant = this.variants.find((_: ProductVariantShort) => {
      return (
        _.language === firstLanguage &&
        _.deliveryFormat === firstDeliveryFormat &&
        (firstCertificateType
          ? _.certificateType === firstCertificateType
          : true)
      );
    });

    this.selectedVariant = pvBySearchTerms || defaultVariant || firstVariant;

    this.form.patchValue({
      productSku: this.sku,
      sku: this.selectedVariant.sku,
      language: this.selectedVariant.language,
      deliveryFormat: this.selectedVariant.deliveryFormat,
      certificateType: this.selectedVariant.certificateType,
      license: this.licenses.find(
        (license) =>
          license &&
          license.sku === this.selectedVariant.bundleLicensePricingSKU
      ),
    });

    this.imageUrl =
      this.selectedVariant.renders.length &&
      this.selectedVariant.renders[0].mediumImageUri
        ? this.selectedVariant.renders[0].mediumImageUri
        : null;

    this.chgRef.detectChanges();

    this.form.valueChanges.subscribe((formValues) => {
      const selectedVariant = this.variants.find((pv: ProductVariantShort) => {
        return (
          pv.language === formValues.language &&
          pv.deliveryFormat === formValues.deliveryFormat &&
          (formValues.certificateType
            ? pv.certificateType === formValues.certificateType
            : true) &&
          (formValues.license
            ? pv.bundleLicensePricingSKU === formValues.license.sku
            : true)
        );
      });
      if (selectedVariant) {
        const newImageUrl =
          selectedVariant.renders.length &&
          selectedVariant.renders[0].mediumImageUri
            ? selectedVariant.renders[0].mediumImageUri
            : null;
        if (this.imageUrl !== newImageUrl) {
          this.imageUrl = newImageUrl;
        }
        this.form.patchValue(
          { sku: selectedVariant.sku },
          { emitEvent: false }
        );
      }
    });
    if (
      this.envService.isQA ||
      this.envService.isPreview ||
      this.envService.isProdin
    ) {
      this.setProductType(this.selectedVariant.deliveryFormat);
    }
  }

  ngAfterViewInit(): void {}

  getQueryParams(): Params {
    const queryParams = {
      language: this.form.value.language,
      deliveryFormat: this.form.value.deliveryFormat,
    };
    if (this.form.value.certificateType) {
      queryParams["certificateType"] = this.form.value.certificateType;
    }
    if (this.form.value.license) {
      queryParams["license"] = this.form.value.license.sku;
    }
    return queryParams;
  }

  getRouterLink(): string {
    const detailsUrl = this.router.serializeUrl(
      this.router.createUrlTree([
        "products",
        "details",
        this.form.value.productSku,
      ])
    );
    return detailsUrl;
  }

  private uniqueFromArray(value, index, self) {
    if (!value || !self) {
      return null;
    }
    return (
      index === self.findIndex((v) => v && v.configKey === value.configKey)
    );
  }

  addToCartFn() {
    this.addToCart.emit(this.form.value.sku);
  }

  getTooltipTemplate() {
    return this.isNotContractedSupplier
      ? "Your account is set to only allow procurement from contracted suppliers. " +
          this.sellerName +
          " does not have an agreement with your organization. Please contact your account administrator if you are interested in this product or service"
      : "This product requires you to sign an agreement with " +
          this.sellerName +
          ". Please visit the product's page for more information.";
  }

  onLxpLabelClick() {
    if (this.publishedToLxp) {
      this.editLxpProduct.emit(this.id);
    } else {
      this.publishToLxp.emit(this.id);
    }
  }

  emitSelectCard(value: boolean) {
    this.selectCard.emit(value);
  }
  setProductType(deliveryFormat) {
    if (deliveryFormat === "diagnostics") {
      this.type = "Diagnostic";
    } else if (deliveryFormat === "product-assessment") {
      this.type = "Assessment";
    } else if (deliveryFormat === "lab") {
      this.type = "vLab";
    }
  }
}
