import { Injectable, ElementRef } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { DictionaryItem } from '../models/dictionary.model';
import { TranslateService } from 'src/app/core/services/translate.service';
import { AmberError } from '../models/amber-response.model';
import { GraphQLError } from '../models/graphql-error.model';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private environment: EnvironmentService) {
  }

  buildSphinxUrl(bucket: string, key: string): string {
    return `https://downloads.mkpqa.com/proxy/service/jwt/${bucket}/${key}`;
  }

  extractVideoIdFromUrl(url: string) {
    // let regex: any = '/^https?://(?:www\.)?youtu(?:\.be|be\.com)/(?:\S+/)?(?:[^\s/]*(?:\?|&)vi?=)?([^#?&]+)/'
    const regex = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
    const parsed = url.match(regex);

    return parsed ? parsed[7] : null;
  }

  getFileUrl(key: string, bucket: string): string {
    const result = this.environment.sphinxUrl + `/proxy/system/jwt/${bucket}/${key}`;
    return result;
  }

  convertObjectToDictionary(obj: any): DictionaryItem[] {
    const result = [];
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result.push({ key: prop, value: obj[prop] });
      }
    }
    return result;
  }

  formatLineItems(items: any[]) {
    return items
      .sort((a, b) => {
        if (a.createdAt < b.createdAt) return 1; 
        else if (a.createdAt > b.createdAt) return -1; 
        else return 0;
      })
  }

  scrollModalTop(modalRef: ElementRef): void {
    modalRef['elementRef'].nativeElement.children[0].children[1].scrollTo({top: 0, behavior: 'smooth'})
  }

  scrollToElementID(elementId: string): void {
    const element = document.querySelector('#' + elementId);
    const top = element.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top,
      behavior: 'smooth'
    })
  }

  formatGraphqlError(graphqlError: GraphQLError): AmberError {
    let message = graphqlError.message.replace('GraphQL error: ', '');
    if(message.indexOf('Network error') === 0) message = 'network.error';
    return { message: TranslateService.translateErrorMessage(message), errorKey: message };
  }
}
