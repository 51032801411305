import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SelectConversation, InboxState, SelectLatestConversation, ClearInboxState, CheckDMConversation } from '../../state/inbox.state';
import { filter, first } from 'rxjs/operators';
import { InboxConversation } from '../models/inbox-message.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ActivatedRoute } from '@angular/router';
import { TypedParamMap } from "../../services/url-storage.service";

@Component({
  selector: 'leap-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationListComponent implements OnInit, OnDestroy {

  @Select(InboxState.filteredConversations)
  conversations$: Observable<InboxConversation[]>;

  @Select((state: any) => state.inbox.selectedConversationId)
  selectedConversationId$: Observable<number>;

  @Select((state: any) => state.inbox.searchForm)
  searchForm$: Observable<any>;

  form: FormGroup;

  myEmail: string;
  myDomain: string;

  constructor(private store: Store, private fb: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit() {

    const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
    const queryParams = new TypedParamMap<any>(this.route.snapshot.queryParamMap);
    this.myEmail = userDetails.username;
    this.myDomain = userDetails.domain

    this.form = this.fb.group({
      term: null
    });

    this.conversations$
      .pipe(
        filter(
          _ => {
            return !!(_ && _.length)
          }
        ),
        first(),
        untilDestroyed(this)
      )
      .subscribe(_ => {
        const actions = [];
        
        if(queryParams.get('dmDomain')) {
          actions.push(new CheckDMConversation(queryParams.get('dmDomain')))
        } else {
          actions.push(new SelectLatestConversation())
        }
        
        this.store.dispatch(actions);
      })

  }

  ngOnDestroy() {
    this.store.dispatch(new ClearInboxState());
  }

  selectConversation(conversationId: string) {
    this.store.dispatch(new SelectConversation(conversationId))
  }

  trackByFunction(index, item) {
    if (!item) return null;
    return item.channelId;
  }

  compareMyDomain(domain: string) {
    return this.myDomain === domain;
  }

}
