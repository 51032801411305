import { ComponentFactoryResolver, ComponentRef, InjectionToken, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormSubmitDirective } from './form-submit.directive';
import { EMPTY, merge } from 'rxjs';
import { ControlErrorComponent } from '../components/control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
const ɵ0 = error => `Field is required`, ɵ1 = ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`, ɵ2 = ({ min }) => `Select at least ${min} elements`, ɵ3 = () => `Invalid format`;
export const defaultErrors = {
    required: ɵ0,
    minlength: ɵ1,
    minSize: ɵ2,
    pattern: ɵ3
};
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});
export class ControlErrorDirective {
    constructor(control, parentForm, form, errors, resolver, vcr, controlErrorContainer) {
        this.control = control;
        this.parentForm = parentForm;
        this.form = form;
        this.errors = errors;
        this.resolver = resolver;
        this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
        this.submit$ = this.form ? this.form.submit$ : EMPTY;
    }
    ngOnInit() {
        if (!this.form) {
            return;
        }
        const control = this.control ? this.control : this.parentForm.form.get(this.leapFormControlProxy);
        merge(this.submit$, control.valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
            const controlErrors = control.errors;
            if (controlErrors) {
                const firstKey = Object.keys(controlErrors)[0];
                const formatErrorFn = this.errors[firstKey];
                const text = formatErrorFn ? formatErrorFn(controlErrors[firstKey]) : null;
                this.setError(text, controlErrors);
            }
            else {
                this.setError(null, null);
            }
        });
    }
    setError(text, errors) {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.container.createComponent(factory);
        }
        this.ref.instance.text = text;
        if (this.errorTemplateRef) {
            this.ref.instance.errors = errors;
            this.ref.instance.templateRef = this.errorTemplateRef;
        }
    }
    ngOnDestroy() { }
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
