
<div class="footer-wrapper">
    <div class="footer-conteiner">
        <div nz-row class="logo-container-small show-md">
            <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
        </div>
        <div nz-row style="margin-bottom: 30px;">
            <ng-container *ngIf="!(domainConfiguration$ | async)?.hideFooter">
            <div nz-col nzXs="12" nzMd="12" nzLg="4">
                <h1 i18n="Title for about section|@@core.footer.aboutSectionTitle">About</h1>

                <h2><a href="https://www.edcast.com/corp/about-us/"
                        i18n="Link for About Leapest by Edcast|@@core.footer.aboutLeapestByEdcast">About EdCast</a></h2>
                <h2><a href="https://www.edcast.com/corp/careers/"
                        i18n="Link for Leapest careers|@@core.footer.leapestCareerLinks">Working at EdCast</a></h2>
                <h2><a href="https://blog.leapest.com/"
                        i18n="Link for Leapest blog|@@core.footer.leapestBlogLink">EdCast Marketplace Blog</a></h2>
            </div>
            <div nz-col nzXs="12" nzMd="12" nzLg="4">
                <h1 i18n="Title for Footer support section|@@core.footer.leapestSupportSection">Support</h1>

                <h2><a href="{{saharaUrl}}/register" target="_blank" rel="noopener noreferrer"
                        i18n="Link to register section for buyers|@@core.footer.becomeBuyerText">Become a Buyer</a></h2>
                <h2><a [href]="instructorMarketUrl" target="_blank" rel="noopener noreferrer"
                        i18n="Link to register section for instructors|@@core.footer.becomeInstructorSection">Become an
                        Instructor</a></h2>
                <h2><a href="{{saharaUrl}}/support" target="_blank" rel="noopener noreferrer"
                        i18n="Link to FAQ section|@@core.footer.faqLinkText">FAQ & Knowledge Base</a></h2>
                <h2><a href="{{saharaUrl}}/support?articleId=reach-customer-support-team-by-phone" target="_blank" rel="noopener noreferrer"
                        i18n="Link to Customer Service section|@@core.footer.customerServiceLink">Customer Service</a>
                </h2>
            </div>
            <div nz-col nzXs="12" nzMd="12" nzLg="4">
                <h1 i18n="Title for explore section|@@core.footer.exploreSectionTitle">Explore</h1>
                <h2><a [routerLink]="['/products']" i18n="Link to products feed|@@core.footer.findProducts">Find
                        Products</a></h2>
                <h2><a *leapIsAuth="true" [routerLink]="['/orders']"
                        i18n="Link to orders|@@core.footer.findProducts">Orders & Invoices</a></h2>
                <h2><a *leapIsAuth="false" (click)="login()" i18n="Link to sign in|@@core.footer.signIn">Sign In</a>
                </h2>
            </div>
        
            <div class="social-media-links social-media-links-small show-md" nz-col nzXs="12" nzMd="12" nzLg="4">
                <ng-container *ngTemplateOutlet="socialMediaLinksTemplate"></ng-container>
            </div>
        </ng-container>
            <div class="hide-md">
                <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
            </div>
        </div>
        <ng-container *ngIf="!(domainConfiguration$ | async)?.hideFooter">
        <div nz-row nzType="flex" nzJustify="flex-end" class="logo-caption"
            i18n="Logo caption for Leapest by Edcast|@@core.footer.logoCaption">Leapest Corporate Learning Marketplace
            is owned by EdCast.</div>
        <div nz-row class="links-container">
            <div nz-col nzXs="8" nzMd="8" nzLg="4"><a class="text-link" href="/" target="_blank" rel="noopener noreferrer"
                    i18n="Link to product catalog|@@core.footer.productCatalogLinkText">Product Catalog</a></div>
            <div nz-col nzXs="8" nzMd="8" nzLg="4"><a class="text-link" href="{{instructorMarketUrl}}" target="_blank" rel="noopener noreferrer"
                    i18n="Link to instructor services|@@core.footer.instructorServiceLinkText">Instructor Services</a>
            </div>
            <div nz-col nzXs="8" nzMd="8" nzLg="4"><a *leapIsAuth="true" class="text-link" href="{{saharaUrl}}"
                    target="_blank" rel="noopener noreferrer" i18n="Link to myLeapest|@@core.footer.myLeapestLinkText">Dashboard
                    <span>(myLeapest)</span></a></div>
            <div nz-col nzLg="12" class="social-media-links hide-md">
                <ng-container *ngTemplateOutlet="socialMediaLinksTemplate"></ng-container>
            </div>
        </div>
        <span style="color: #8e8fa6; display: block; margin-top: 10px; font-size: 14px;">© {{ year }} - Leapest - <span
                i18n="All rights reserved text|@@core.footer.allRightReservedText">All Rights Reserved</span>  -  <span
                i18n="copyright and trademark|@@core.footer.copyrightTrademarkText">Copyright & Trademark</span>  - <a
                href="{{saharaUrl}}/support?articleId=leapest-privacy-policy" target="_blank" rel="noopener noreferrer"
                i18n="Link to privacy policy|@@core.footer.privacyPolicyLinkText">Privacy Policy</a>  -  <a
                href="{{saharaUrl}}/legal/general-terms-and-conditions" target="_blank" rel="noopener noreferrer"
                i18n="Link to terms and conditions|@@core.footer.termsAndConditionsLinkText">Terms and
                Conditions</a></span>
            </ng-container>
    </div>
</div>

<ng-template #logoTemplate>
    <div nz-col  style="display: flex; justify-content: flex-end;">
        <img style="float: right; margin-top: 7px; margin-right: 12px;"
            [src]="footerLogo"
            [style.width]="(domainConfiguration$ | async)?.logo?.light?.width"
            [style.height]="(domainConfiguration$ | async)?.logo?.light?.height "
            [style.maxWidth]="(domainConfiguration$ | async)?.logo?.light?.width "
            [style.maxHeight]="(domainConfiguration$ | async)?.logo?.light?.height || '100px'"
            class="login-logo"
            alt="Leapest Logo">
    </div>
</ng-template>

<ng-template #socialMediaLinksTemplate>
    <a class="sm-link" href="https://www.facebook.com/EdCast" target="_blank" rel="noopener noreferrer">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" width="26" height="30"
            class="facebook-svg">
            <g transform="translate(1, 1)">
                <path
                    d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"
                    vector-effect="non-scaling-stroke" />
            </g>
        </svg>
    </a>
    <a class="sm-link" href="https://www.linkedin.com/company/edcast/" target="_blank" rel="noopener noreferrer">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" width="26.25" height="30"
            class="linkedin-svg">
            <g transform="translate(1, 1)">
                <path
                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                    vector-effect="non-scaling-stroke" />
            </g>
        </svg>
    </a>
    <a class="sm-link" href="https://www.instagram.com/edcast_inc/" target="_blank" rel="noopener noreferrer">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" width="26.25" height="30"
            class="insta-svg">
            <g transform="translate(1, 1)">
                <path
                    d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.34,53.34,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33s1.92-73.26-6.43-94.33ZM224,338a81.9,81.9,0,1,1,.2,0Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.15h0a19.1,19.1,0,0,1-19,19.18h-.07ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85S66.4,347.58,65.18,322c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
                    vector-effect="non-scaling-stroke" />
            </g>
        </svg>
    </a>
    <a class="sm-link" href="https://twitter.com/edcast" target="_blank" rel="noopener noreferrer">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" width="26.25" height="30"
            class="twitter-svg">
            <g transform="translate(1, 1)">
                <path
                    d="M400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM351.1,190.8c.2,2.8.2,5.7.2,8.5,0,86.7-66,186.6-186.6,186.6A185.74,185.74,0,0,1,64,356.5a138.17,138.17,0,0,0,15.8.8,131.49,131.49,0,0,0,81.4-28,65.67,65.67,0,0,1-61.3-45.5c10.1,1.5,19.2,1.5,29.6-1.2A65.59,65.59,0,0,1,77,218.2v-.8a65.54,65.54,0,0,0,29.6,8.3,65.44,65.44,0,0,1-29.2-54.6A64.77,64.77,0,0,1,86.3,138a186.22,186.22,0,0,0,135.2,68.6c-9.3-44.5,24-80.6,64-80.6a65.35,65.35,0,0,1,47.9,20.7A128.52,128.52,0,0,0,375,130.9,65.41,65.41,0,0,1,346.2,167,130.52,130.52,0,0,0,384,156.8,138,138,0,0,1,351.1,190.8Z"
                    vector-effect="non-scaling-stroke" />
            </g>
        </svg>
    </a>
</ng-template>
