<nz-drawer
        [nzVisible]="drawerVisible$ | async"
        nzWidth="570px"
        [nzMaskClosable]="true"
        nzPlacement="right"
        (nzOnClose)="closeShoppingCartDrawer()">

  <nz-spin [nzSpinning]="calculating$ | async" [nzIndicator]="loadingIndicatorTemplate">
    <leap-error-alert [source]="(error$ | async)?.message"></leap-error-alert>
    <ng-container *ngTemplateOutlet="lineItemListTemplate"></ng-container>
  </nz-spin>
  <ng-container *ngTemplateOutlet="bottomTemplate"></ng-container>

</nz-drawer>

<ng-template #bottomTemplate>
  <nz-spin class="bottom-container" [nzSpinning]="calculating$ | async" [nzIndicator]="loadingIndicatorTemplate">
    <div class="total">
      <span class="label" i18n="@@shopping-cart-drawer.labels.taxes">Taxes:</span>
      <span class="value">{{ (currencySignsMap$ | async)?.get(currency$ | async) }} {{ (taxTotal$ | async) | number:'1.2-2' }}</span>
    </div>
    <div class="total" style="margin-top: 0px;" *ngIf="(discountTotal$ | async)">
      <span class="label" i18n="@@shopping-cart-drawer.labels.promotions">Promotions:</span>
      <span class="value">-{{ (currencySignsMap$ | async)?.get(currency$ | async) }} {{ (discountTotal$ | async) | number:'1.2-2' }}</span>
    </div>
    <div class="total" style="margin-top: -3px;">
      <hr style="display: inline-block; width: 200px; margin: 0px;">
    </div>
    <div class="total">
      <span class="label" i18n="@@shopping-cart-drawer.labels.estimated-total">Estimated Total:</span>
      <span class="value">{{ (currencySignsMap$ | async)?.get(currency$ | async) }} {{ (grossTotal$ | async) | number:'1.2-2' }}</span>
    </div>
    <div class="btn-container">
      <button i18n="@@shopping-cart-drawer.buttons.view-cart"
              [disabled]="!(lineItems$ | async)?.length"
              nz-button nzType="default" (click)="viewCart()">View Cart</button>
      <button i18n="@@shopping-cart-drawer.buttons.proceed-to-checkout"
              [disabled]="!(lineItems$ | async)?.length" nz-button nzType="primary"
              (click)="proceedToCheckout()">
        Proceed to Checkout
      </button>
    </div>
  </nz-spin>
</ng-template>

<ng-template #lineItemListTemplate>

  <div class="list-item-drawer-container">
    <ng-container *ngIf="!(lineItems$ | async)?.length">
      <ng-container *ngTemplateOutlet="emptyCartTemplate"></ng-container>
    </ng-container>
    <div nz-row *ngFor="let item of lineItems$ | async; let i = index;let trackBy: trackByFn" >
      <leap-line-item
              [id]="item.id"
              [sku]="item.productVariantSnapshot.sku"
              [masterProductSku]="item.productVariant.product.sku"
              [name]="item.productVariantSnapshot.name"
              [bundle]="item.bundleSnapshot"
              [language]="item.productVariantSnapshot?.language"
              [deliveryFormat]="item.productVariantSnapshot?.deliveryFormat"
              [certificateType]="item.productVariantSnapshot?.certificateType"
              [netTotalWithSurcharges]="item.netTotalWithSurcharges"
              [currency]="currency$ | async"
              [quantity]="item.quantity"
              [imageUrl]="item.productVariantSnapshot.logoUrl"
              [currencySignsMap]="currencySignsMap$ | async"
              (remove)="removeLineItem($event, i)"
              (quantityChange)="quantityChangeLineItem($event, i)">
      </leap-line-item>
    </div>
  </div>
</ng-template>

<ng-template #loadingIndicatorTemplate>
  <div class="loading-wrapper">
    <i nz-icon nzType="loading" style="font-size: 24px;"></i>
  </div>
</ng-template>

<ng-template #emptyCartTemplate>
  <div class="empty-cart-container">
    <h2>Your shopping cart is empty!</h2>
    <p class="empty-cart-description">It appears that you have not added any items to your shopping cart yet. <a (click)="goBackToProducts()">Continue Shopping</a></p>
  </div>
</ng-template>
