import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

// Reference for the tutorial:
// https://juristr.com/blog/2018/02/angular-permission-directive/

@Directive({
  selector: '[leapHasGroup]'
})
export class HasGroupDirective {
  @Select((state: any) => state.session.groups)
  groups$: Observable<string[]>;
  
  private currentUserGroups;
  private groups = [];
  private logicalOp = 'AND';
  private isHidden = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    this.groups$.subscribe(_ => {
      this.currentUserGroups = _;
      this.updateView();
    });
  }

  @Input()
  set leapHasGroup(val) {
    this.groups = val;
    this.updateView();
  }

  @Input()
  set leapHasGroupOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkGroup()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkGroup() {
    let hasGroup = false;

    if (!this.groups || !this.groups.length) {
      return true; //means public
    }

    if (this.currentUserGroups) {
      for (const checkGroup of this.groups) {
        const groupFound = this.currentUserGroups.find(x => x.toUpperCase() === checkGroup.toUpperCase());

        if (groupFound) {
          hasGroup = true;

          if (this.logicalOp === 'OR') {
            break;
          }

          if (this.logicalOp === 'NOR') {
            break;
          }
        } else {
          hasGroup = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    if (this.logicalOp === 'NOR') {
      hasGroup = !hasGroup;
    }

    return hasGroup;
  }

}
