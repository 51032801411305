/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seller-image-and-name.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-zorro-antd/tooltip";
import * as i4 from "@angular/router";
import * as i5 from "./seller-image-and-name.component";
var styles_SellerImageAndNameComponent = [i0.styles];
var RenderType_SellerImageAndNameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SellerImageAndNameComponent, data: {} });
export { RenderType_SellerImageAndNameComponent as RenderType_SellerImageAndNameComponent };
function View_SellerImageAndNameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "seller-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "seller-image"]], [[8, "alt", 0], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_1 = _co.imageUrl; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_SellerImageAndNameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "seller-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, ":svg:svg", [["data-name", "Layer 1"], ["height", "30"], ["id", "Layer_1"], ["viewBox", "0 0 40 30"], ["width", "40"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [".cls-1{fill:#737394;}"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["class", "cls-1"], ["d", "M29.75,9H24.5V5.63A1.12,1.12,0,0,0,23.38,4.5H16.62A1.12,1.12,0,0,0,15.5,5.63V9H10.25A2.25,2.25,0,0,0,8,11.25v12a2.25,2.25,0,0,0,2.25,2.25h19.5A2.25,2.25,0,0,0,32,23.25v-12A2.25,2.25,0,0,0,29.75,9ZM17,6h6V9H17ZM30.5,23.25a.76.76,0,0,1-.75.75H10.25a.76.76,0,0,1-.75-.75V16.5H17v1.88a1.12,1.12,0,0,0,1.13,1.12h3.75A1.12,1.12,0,0,0,23,18.38V16.5h7.5ZM18.5,18V16.5h3V18Zm12-3H9.5V11.25a.76.76,0,0,1,.75-.75h19.5a.76.76,0,0,1,.75.75Z"]], null, null, null, null, null))], null, null); }
export function View_SellerImageAndNameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "seller-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SellerImageAndNameComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 3, "a", [["class", "seller-name"], ["nz-tooltip", ""], ["nzTooltipPlacement", "bottom"]], [[2, "ant-tooltip-open", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 4931584, null, 0, i3.NzTooltipDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, [2, i3.NzTooltipBaseComponentLegacy], [8, null]], { specificTitle: [0, "specificTitle"], directiveNameTitle: [1, "directiveNameTitle"], specificPlacement: [2, "specificPlacement"] }, null), i1.ɵdid(7, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(0, [["imagePlaceholder", 2]], null, 0, null, View_SellerImageAndNameComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "seller-container"; var currVal_1 = _co.size; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.imageUrl; var currVal_3 = i1.ɵnov(_v, 9); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_7 = ((_co.name.length > 26) ? _co.name : ""); var currVal_8 = ""; var currVal_9 = "bottom"; _ck(_v, 6, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵinlineInterpolate(1, "/seller/page/", _co.domain, ""); _ck(_v, 7, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵnov(_v, 6).isTooltipComponentVisible; var currVal_5 = i1.ɵnov(_v, 7).target; var currVal_6 = i1.ɵnov(_v, 7).href; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); var currVal_11 = _co.name; _ck(_v, 8, 0, currVal_11); }); }
export function View_SellerImageAndNameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-seller-image-and-name", [], null, null, null, View_SellerImageAndNameComponent_0, RenderType_SellerImageAndNameComponent)), i1.ɵdid(1, 114688, null, 0, i5.SellerImageAndNameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SellerImageAndNameComponentNgFactory = i1.ɵccf("leap-seller-image-and-name", i5.SellerImageAndNameComponent, View_SellerImageAndNameComponent_Host_0, { size: "size", imageUrl: "imageUrl", name: "name", domain: "domain" }, {}, []);
export { SellerImageAndNameComponentNgFactory as SellerImageAndNameComponentNgFactory };
