<div class="suggestions-wrapper upcoming">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'courses'">
            <p class="title">COURSE SERVICES YOU MIGHT BE INTERESTED IN <a class="view-more" *ngIf="!fetching" [href]="domain + moreLink">view more</a></p>
        </ng-container>
        <ng-container *ngSwitchCase="'course_events'">
            <p class="title">UPCOMING COURSE EVENTS YOU MIGHT BE INTERESTED IN <a class="view-more" *ngIf="!fetching" [href]="domain + moreLink">view more</a></p>
        </ng-container>
        <ng-container *ngSwitchCase="'instructors'">
            <p class="title">CERTIFIED INSTRUCTOR PROFILES YOU MIGHT BE INTERESTED IN  <a class="view-more" *ngIf="!fetching" [href]="domain + moreLink">view more</a></p>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <p class="title">UPCOMING COURSE EVENTS YOU MIGHT BE INTERESTED IN <a class="view-more" *ngIf="!fetching" [href]="domain + moreLink">view more</a></p>
        </ng-container>
    </ng-container>

    <div class="suggestions-cards-wrapper">
        <nz-carousel *ngIf="!fetching" [nzDots]="false" [nzEnableSwipe]="false" [ngStyle]="{height: type === 'course_events' ? '120px': '100px'}">
            <div nz-carousel-content *ngIf="suggestions.length > 4" class="carousel-content">
                <div class="content-wrapper" [class.isEnd]="amountOfElements > 4 && amountOfElements < 8">
                    <ng-container  *ngFor="let item of suggestions; let index = index">
                            <div class="card" *ngIf="index > (amountOfElements - 1) && index <= (amountOfElements- 1) + amountOfElements" [ngClass]="{
                    'events': type === 'course_events',
                    'instructors': type === 'instructors'
                    }" (click)="goToDetails(item)">
                                <div class="logo-wrapper">
                                    <p class="logo" *ngIf="item.details.image">
                                        <img [src]="item.details.image  | urlFromBucket:item.details.image | safeUrl" alt="">
                                    </p>
                                    <p class="no-logo" *ngIf="!item.details.image"></p>
                                </div>
                                <div class="separator"></div>
                                <div class="details">
                                    <div class="up-info">
                                        <p class="title-card">{{item.details.name | ellipsis:[20]}}</p>
                                        <p class="title-description">{{item.details.sellerName | ellipsis:[20]}}</p>
                                    </div>
                                    <p class="date" *ngIf="type == 'course_events'"><ng-container *ngTemplateOutlet="dateIcon"></ng-container>
                                        {{ item.details.startDate | date: 'dd MMM yyyy'}}
                                    </p>
                                </div>

                            </div>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="leftArrow"></ng-container>
                </div>
            </div>
            <div nz-carousel-content  class="carousel-content">
                <div class="content-wrapper">
                    <ng-container  *ngFor="let item of suggestions; let index = index">

                        <div class="card" *ngIf="index <= (amountOfElements - 1)" [ngClass]="{
                    'events': type === 'course_events',
                    'instructors': type === 'instructors'
                    }" (click)="goToDetails(item)">
                            <div class="logo-wrapper">
                                <p class="logo" *ngIf="item.details.image">
                                    <img [src]="item.details.image  | urlFromBucket:item.details.image | safeUrl" alt="">
                                </p>
                                <p class="no-logo" *ngIf="!item.details.image"></p>
                            </div>
                            <div class="separator"></div>
                            <div class="details">
                                <div class="up-info">
                                    <p class="title-card">{{item.details.name | ellipsis:[20]}}</p>
                                    <p class="title-description">{{item.details.sellerName | ellipsis:[20]}}</p>
                                </div>
                                <p class="date" *ngIf="type == 'course_events'"><ng-container *ngTemplateOutlet="dateIcon"></ng-container>
                                    {{ item.details.startDate | date: 'dd MMM yyyy'}}
                                </p>
                            </div>

                        </div>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="rightArrow" ></ng-container>

                </div>

            </div>
        </nz-carousel>
        <div class="skeletons" *ngIf="fetching">
            <div class="item">
                <nz-skeleton  [nzParagraph]="{ rows: 1 }"></nz-skeleton>
            </div>
            <div class="item">
                <nz-skeleton  [nzParagraph]="{ rows: 1 }"></nz-skeleton>
            </div>
            <div class="item">
                <nz-skeleton  [nzParagraph]="{ rows: 1 }"></nz-skeleton>
            </div>
            <div class="item">
                <nz-skeleton  [nzParagraph]="{ rows: 1 }"></nz-skeleton>
            </div>

        </div>
    </div>
</div>
<ng-template #dateIcon>
    <span class="icon">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" xml:space="preserve" y="0px" x="0px" id="Layer_1_1587718117016" width="14" height="16"><g transform="translate(1, 1)"><style type="text/css">
	.st0_1587718117016{fill:#394042;}
</style><path d="M400,64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6,0-12,5.4-12,12v52H128V12c0-6.6-5.4-12-12-12h-8  c-6.6,0-12,5.4-12,12v52H48C21.5,64,0,85.5,0,112v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V112C448,85.5,426.5,64,400,64z   M48,96h352c8.8,0,16,7.2,16,16v48H32v-48C32,103.2,39.2,96,48,96z M400,480H48c-8.8,0-16-7.2-16-16V192h384v272  C416,472.8,408.8,480,400,480z" class="st0_1587718117016" vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);"/></g></svg>
    </span>
</ng-template>
<ng-template #rightArrow>
    <p class="icon" (click)="switchPage()">
        <ng-container *ngIf="amountOfElements < 5 || amountOfElements != 8">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 194 514" width="9" height="24"><g transform="translate(1, 1)"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z" vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);"/></g></svg>
        </ng-container>
    </p>
</ng-template>
<ng-template #leftArrow>
    <p class="icon" (click)="switchPage()">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 194 514" width="9" height="24"><g transform="translate(1, 1)"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);"/></g></svg>
    </p>
</ng-template>
