import { Pipe, PipeTransform } from '@angular/core';
import {ProductVideo} from "../../product-details/models/product-media.model";

@Pipe({
  name: 'youTubePreview'
})
export class YouTubePreviewPipe implements PipeTransform {

  transform(video: ProductVideo): string {
	  let urlParams = (new URL(video.url)).searchParams;
	  let videoId = urlParams.get('v');
    return 'http://img.youtube.com/vi/' + videoId + '/mqdefault.jpg';
  }

}
