import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallbackResultModel } from '../models/callback-result.model';
@Component({
  selector: 'leap-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.less']
})
export class CallbackComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    const originUrl = window.location.origin;
    const params = this.route.snapshot.queryParams;
    if (window.opener === null) {
      console.error('No opener - no one to emit callback result to');
      return;
    }

    const stateBase64 = params['state'];
    if (!stateBase64) {
      console.error('Missing state');
      return;
    }

    const jsonString = atob(stateBase64);
    const state = CallbackResultModel.fromJSON(jsonString);
    if (state) {
      const event = state.event;
      window.opener.postMessage({ event, state, params }, originUrl);
    }
  }
}
