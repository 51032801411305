import * as tslib_1 from "tslib";
import { StopOutline, PlusOutline, BellOutline, AppstoreOutline, FileOutline, CloudOutline, PictureOutline, DownloadOutline, DeleteOutline, LaptopOutline, StarOutline, NotificationOutline, UserOutline, CheckOutline, EditOutline, DownOutline, LeftOutline, MailOutline } from '@ant-design/icons-angular/icons';
import { en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { Store } from '@ngxs/store';
import { AuthService } from './auth/auth.service';
import { DomainConfigurationService } from './auth/domain-configuration.service';
import { Router } from '@angular/router';
import * as oktaHelpers from './snatch/utils/okta-helpers';
import { of } from 'rxjs';
import { ListenForSessionChange } from './auth/actions/auth.actions';
registerLocaleData(en);
// Every Icon you use should be imported individually to keep the bundle size low
const icons = [
    StopOutline,
    PlusOutline,
    BellOutline,
    AppstoreOutline,
    FileOutline,
    CloudOutline,
    PictureOutline,
    DownloadOutline,
    EditOutline,
    DeleteOutline,
    LaptopOutline,
    StarOutline,
    NotificationOutline,
    UserOutline,
    CheckOutline,
    DownOutline,
    LeftOutline,
    MailOutline
];
const ɵ0 = en_US, ɵ1 = icons, ɵ2 = (router) => oktaHelpers.getOktaFactory(router), ɵ3 = {
    nzDuration: 30 * 1000,
    nzPauseOnHover: true,
    nzAnimate: true
}, ɵ4 = (authService, store, domainConfigurationService) => {
    return () => of().toPromise().then((_) => tslib_1.__awaiter(this, void 0, void 0, function* () {
        const result = yield authService.initSession();
        store.dispatch([result, new ListenForSessionChange()]);
        return domainConfigurationService.getDomainConfiguration();
    }));
};
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
