/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-alert.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/alert/ng-zorro-antd-alert.ngfactory";
import * as i3 from "ng-zorro-antd/alert";
import * as i4 from "ng-zorro-antd/core";
import * as i5 from "@angular/common";
import * as i6 from "./error-alert.component";
var styles_ErrorAlertComponent = [i0.styles];
var RenderType_ErrorAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorAlertComponent, data: {} });
export { RenderType_ErrorAlertComponent as RenderType_ErrorAlertComponent };
function View_ErrorAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nz-alert", [["nzType", "error"]], null, null, null, i2.View_NzAlertComponent_0, i2.RenderType_NzAlertComponent)), i1.ɵdid(1, 573440, null, 0, i3.NzAlertComponent, [i4.NzConfigService], { nzMessage: [0, "nzMessage"], nzType: [1, "nzType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; var currVal_1 = "error"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ErrorAlertComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorAlertComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-error-alert", [], null, null, null, View_ErrorAlertComponent_0, RenderType_ErrorAlertComponent)), i1.ɵdid(1, 114688, null, 0, i6.ErrorAlertComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorAlertComponentNgFactory = i1.ɵccf("leap-error-alert", i6.ErrorAlertComponent, View_ErrorAlertComponent_Host_0, { source: "source" }, {}, []);
export { ErrorAlertComponentNgFactory as ErrorAlertComponentNgFactory };
