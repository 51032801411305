import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectConversation, MarkAsSeen } from 'src/app/inbox/state/inbox.state';
import { EnvironmentService } from "../../../../../snatch/services";
const ɵ0 = (state) => state.inbox.conversations, ɵ1 = (state) => state.inbox.latestSeenMessage, ɵ2 = (state) => state.inbox.latestReceivedMessage, ɵ3 = (state) => state.inbox.unseenMessages;
export class MessagesComponent {
    constructor(store, environment) {
        this.store = store;
        this.environment = environment;
    }
    ngOnInit() {
        const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
        this.myEmail = userDetails.username;
        this.myDomain = userDetails.domain;
        this.conversationsLimited$ = this.conversations$.pipe(map(_ => _.slice(0, 3)));
    }
    redirectToInbox() {
        this.store.dispatch(new Navigate(['inbox']));
    }
    redirectToConversation(conversation) {
        if (conversation.channel.type != 'dm') {
            window.location.href = `${this.environment.serviceMarketUrl}/inbox?quoteCycleCode=${conversation.channel.context.data.quoteCycle.code}&leapest-force-login=true`;
        }
        else {
            this.store.dispatch([new SelectConversation(conversation.channelId), new Navigate(['inbox'])]);
        }
    }
    markAsSeen() {
        this.store.dispatch([new MarkAsSeen()]);
    }
    compareMyDomain(domain) {
        return this.myDomain === domain;
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], MessagesComponent.prototype, "conversations$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], MessagesComponent.prototype, "latestSeenMessage$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], MessagesComponent.prototype, "latestReceivedMessage$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], MessagesComponent.prototype, "unseenMessages$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
