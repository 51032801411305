<div class="filters-container">
  <leap-product-filter-pill *ngFor="let status of filters?.lxpProductStatus" (removed)="removeArrayItem('lxpProductStatus', status.id)">
    <span>{{ status.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let productType of filters?.productTypes" (removed)="removeArrayItem('productTypes', productType.id)">
    <span>{{ productType.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let deliveryFormat of filters?.deliveryFormats" (removed)="removeArrayItem('deliveryFormats', deliveryFormat.id)">
    <span>{{ deliveryFormat.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let courseDeliveryFormat of filters?.courseDeliveryFormats" (removed)="removeArrayItem('courseDeliveryFormats', courseDeliveryFormat.id)">
    <span>{{ courseDeliveryFormat.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let language of filters?.languages" (removed)="removeArrayItem('languages', language.id)">
    <span>{{ language.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngIf="filters?.topic as topicItem" (removed)="removeFilter('topic')">
    <span> {{ topicItem['value']}} </span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngIf="filters?.subtopic as subtopicItem" (removed)="removeFilter('subtopic')">
    <span>{{ subtopicItem['value'] }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let categoryItem of filters?.category" (removed)="removeArrayItem('category', categoryItem.id)">
    <span>{{ categoryItem.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let levelItem of filters?.level" (removed)="removeArrayItem('level', levelItem.id)">
    <span>{{ levelItem.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let seller of filters?.sellers" (removed)="removeArrayItem('sellers', seller.id)">
    <span>{{ seller.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngFor="let catalog of filters?.catalogs" (removed)="removeArrayItem('catalogs', catalog.id)">
    <span>{{ catalog.value }}</span>
  </leap-product-filter-pill>
  <leap-product-filter-pill *ngIf="searchForm?.filter" (removed)="removeFilter('filter')">
    <span>"<em>{{searchForm.filter}}</em>"</span>
  </leap-product-filter-pill>

  <div *ngIf="searchMetadata?.count" class="result-count-container" [ngPlural]="searchMetadata.count">
    <ng-template ngPluralCase="=0">No results match your search criteria</ng-template>
    <ng-template ngPluralCase="=1">1 result</ng-template>
    <ng-template ngPluralCase="other">{{searchMetadata.count}} results</ng-template>
  </div>

  <span *ngIf="filterPills?.length > 0" class="clear-filters-btn" (click)="clearFilters()">
   <svg version="1.2" fill="currentColor" overflow="visible" preserveAspectRatio="none" viewBox="0 0 514 514" xml:space="preserve" y="0px" x="0px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" id="Layer_1_1547729343501" width="26" height="26"><g transform="translate(1, 1)"><g>
	<path d="M385.5,230.5c-24,0-46.4,6.7-65.5,18.3v-11.5L502.6,54.6C522.7,34.5,508.5,0,480,0H32&#10;&#9;&#9;C3.6,0-10.7,34.5,9.4,54.6L192,237.3V424c0,9.2,4,18,10.9,24.1l64,56c20.4,17.9,53.1,3.7,53.1-24.1v-14.7&#10;&#9;&#9;c19.1,11.6,41.5,18.3,65.5,18.3c69.9,0,126.5-56.6,126.5-126.5S455.4,230.5,385.5,230.5z M224,424V224L32,32h448L288,224v52.4&#10;&#9;&#9;c-18.1,21.9-29,50-29,80.6c0,30.6,10.9,58.7,29,80.6V480L224,424z M385.5,454.5c-53.8,0-97.5-43.7-97.5-97.5&#10;&#9;&#9;c0-53.8,43.7-97.5,97.5-97.5S483,303.2,483,357C483,410.8,439.3,454.5,385.5,454.5z" class="st0_1547729343501" vector-effect="non-scaling-stroke"/>
	<path d="M436.2,312.8l-6.6-6.6c-3.6-3.6-9.5-3.6-13.2,0l-31,31l-31-31c-3.6-3.6-9.5-3.6-13.2,0l-6.6,6.6&#10;&#9;&#9;c-3.6,3.6-3.6,9.5,0,13.2l31,31l-31,31c-3.6,3.6-3.6,9.5,0,13.2l6.6,6.6c3.6,3.6,9.5,3.6,13.2,0l31-31l31,31&#10;&#9;&#9;c1.8,1.8,4.2,2.7,6.6,2.7s4.8-0.9,6.6-2.7l6.6-6.6c3.6-3.6,3.6-9.5,0-13.2l-31-31l31-31C439.9,322.4,439.9,316.5,436.2,312.8z" class="st0_1547729343501" vector-effect="non-scaling-stroke"/>
</g></g></svg>
  </span>
</div>
