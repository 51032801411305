import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AmberResponse } from '../models/amber-response.model';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import {ApolloError, ApolloQueryResult} from "apollo-client";
import {FetchResult} from "apollo-link";

@Injectable({
  providedIn: 'root'
})
export class AmberRestService {
  constructor(protected http: HttpClient, protected environment: EnvironmentService) {}

  static from<T>(observable: Observable<AmberResponse<T>>): Observable<DeferredResource<T>> {
    return AmberRestService.applyOperators(observable);
  }

  private static applyOperators<T>(observable: Observable<AmberResponse<T>>): Observable<DeferredResource<T>> {
    return observable.pipe(
      map(_body => DeferredResource.success(_body.data)),
      catchError(err => of(DeferredResource.error<T>(err))),
      startWith(DeferredResource.pending<T>())
    );
  }

  get<T>(endpoint: string, opts?: IGetOpts): Observable<DeferredResource<T>> {
    const url = this.getEndpoint(endpoint);
    return AmberRestService.applyOperators(this.http.get<AmberResponse<T>>(url, opts));
  }

  post<T>(endpoint: string, body: any, opts?: IGetOpts): Observable<DeferredResource<T>> {
    const url = this.getEndpoint(endpoint);
    return AmberRestService.applyOperators(this.http.post<AmberResponse<T>>(url, body, opts));
  }

  protected getEndpoint(endpoint: string) {
    if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
      return endpoint;
    } else {
      return `${this.environment.amberUrl}${endpoint}`;
    }
  }
}

export class DeferredResource<T> {
  constructor(public readonly pending: boolean, public readonly error: any, public readonly response: T) {}

  static pending<T>(): DeferredResource<T> {
    return new DeferredResource(true, undefined, undefined);
  }

  static error<T>(err): DeferredResource<T> {
    return new DeferredResource(false, err, undefined);
  }

  static success<T>(result: T): DeferredResource<T> {
    return new DeferredResource(false, undefined, result);
  }

  get hasData(): boolean {
    return !!this.response;
  }

  get isSuccess(): boolean {
    return !this.pending && !this.error && !!this.response;
  }

  get isPending() {
    return this.pending && !this.response;
  }

  get isReFetching() {
    return this.pending && this.response;
  }

  merge(other: DeferredResource<T> | null): DeferredResource<T> {
    if (!other) {
      return this;
    }
    return new DeferredResource<T>(this.pending, this.error, this.response || other.response);
  }

  unwrap<V>(selector: (response: T) => V): DeferredResource<V> {
    if (this.response) {
      return new DeferredResource<V>(this.pending, this.error, selector(this.response));
    }
    return ((this as unknown) as DeferredResource<V>);
  }
}

export interface IGetOpts {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export namespace DeferredResourceUtils {
  export const wrapObservable = <T>(observable: Observable<ApolloQueryResult<T> | FetchResult<T>>): Observable<DeferredResource<T>> => {
    return observable.pipe(
        map(body => {
          if (body.errors) {
            throw new ApolloError({ graphQLErrors: body.errors });
          }
          return DeferredResource.success(body.data);
        }),
        catchError(err => of(DeferredResource.error<T>(err))),
        startWith(DeferredResource.pending<T>())
    );
  };

  export const wrapAmberObservable = <T>(observable: Observable<AmberResponse<T>>): Observable<DeferredResource<T>> => {
    return observable.pipe(
        map(body => DeferredResource.success(body.data)),
        catchError(err => of(DeferredResource.error<T>(err))),
        startWith(DeferredResource.pending<T>())
    );
  };

  export const wrapAmberObservableWithFlags = <T>(observable: Observable<AmberResponse<T>>): Observable<DeferredResource<T>> => {
    return observable.pipe(
        map(body => DeferredResource.success(<any>body)),
        catchError(err => of(DeferredResource.error<T>(err))),
        startWith(DeferredResource.pending<T>())
    );
  };
}
