import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressSectionComponent } from './components/address-section/address-section.component';
import { OrderReviewSectionComponent } from './components/order-review-section/order-review-section.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ShippingMethodFormComponent } from './components/shipping-method-form/shipping-method-form.component';
import { OrderSummaryFormComponent } from './components/order-summary-form/order-summary-form.component';
import { OrderReferenceIdFormComponent } from './components/order-reference-id-form/order-reference-id-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnatchModule } from 'src/app/snatch/snatch.module';
import { PaymentMethodFormComponent } from './components/payment-method-form/payment-method-form.component';
import { InvoiceSectionComponent } from './components/invoice-section/invoice-section.component';
import { ThankYouSectionComponent } from './components/thank-you-section/thank-you-section.component';
import { OrderReferenceIdSectionComponent } from './components/order-reference-id-section/order-reference-id-section.component';
import { PaymentMethodSectionComponent } from './components/payment-method-section/payment-method-section.component';
import { CommonProductModule } from '../common-product/common-product.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import {AddressSelectionComponent} from "./components/address-selection/address-selection.component";
import {AddressEditModalComponent} from "./components/address-edit-modal/address-edit-modal.component";
import { CommonShoppingCartModule } from '../common-shopping-cart/common-shopping-cart.module';
import { PromotionCodeRowComponent } from './components/promotion-code-row/promotion-code-row.component';
import { PromotionCodeRowInvalidComponent } from './components/promotion-code-row-invalid/promotion-code-row-invalid.component';
import { AddressSectionSkeletonComponent } from './components/address-section-skeleton/address-section-skeleton.component';
import { OrderReviewSectionSkeletonComponent } from './components/order-review-section-skeleton/order-review-section-skeleton.component';
import { OrderSummaryFormSkeletonComponent } from './components/order-summary-form-skeleton/order-summary-form-skeleton.component';
import { OrderReferenceIdFormSkeletonComponent } from './components/order-reference-id-form-skeleton/order-reference-id-form-skeleton.component';
import { OrderReviewSectionShippingBatchComponent } from './components/order-review-section-shipping-batch/order-review-section-shipping-batch.component';
import {AddressDeleteModalComponent} from "./components/address-delete-modal/address-delete-modal.component";
import { QuoteOrderReviewSectionComponent } from './components/quote-order-review-section/quote-order-review-section.component';
import { OrderQuoteSectionComponent } from './components/order-quote-section/order-quote-section.component';
import { AdyenComponent } from './components/adyen/adyen.component';

@NgModule({
  declarations: [AddressEditModalComponent,AddressSectionComponent, AddressSelectionComponent, OrderReviewSectionComponent, ShippingMethodFormComponent, OrderSummaryFormComponent, OrderReferenceIdFormComponent, PaymentMethodFormComponent, InvoiceSectionComponent, ThankYouSectionComponent, OrderReferenceIdSectionComponent, PaymentMethodSectionComponent, PromotionCodeRowComponent, PromotionCodeRowInvalidComponent, AddressSectionSkeletonComponent, OrderReviewSectionSkeletonComponent, OrderSummaryFormSkeletonComponent, OrderReferenceIdFormSkeletonComponent, OrderReviewSectionShippingBatchComponent, AddressDeleteModalComponent, QuoteOrderReviewSectionComponent, OrderQuoteSectionComponent, AdyenComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    SnatchModule,
    CommonProductModule,
    NgxsFormPluginModule,
    CommonShoppingCartModule
  ],
  exports: [AddressEditModalComponent,AddressSectionComponent,AddressSelectionComponent, OrderReviewSectionComponent, ShippingMethodFormComponent, OrderSummaryFormComponent, OrderReferenceIdFormComponent, PaymentMethodFormComponent, ThankYouSectionComponent, OrderReferenceIdSectionComponent, PaymentMethodSectionComponent, PromotionCodeRowComponent, AddressSectionSkeletonComponent, OrderReviewSectionSkeletonComponent, OrderSummaryFormSkeletonComponent, OrderReferenceIdFormSkeletonComponent, AddressDeleteModalComponent, QuoteOrderReviewSectionComponent, OrderQuoteSectionComponent, AdyenComponent],
  entryComponents: [
    //  Because its dynamically loaded
    AddressEditModalComponent,
    AddressDeleteModalComponent,
    AdyenComponent
  ]
})
export class CommonCheckoutModule { }
