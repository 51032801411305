import {Component, OnInit, ChangeDetectionStrategy, ViewChild, Input} from '@angular/core';
import {NzCarouselComponent} from "ng-zorro-antd";
import {Select, Store} from "@ngxs/store";
import {Observable, Subject, Subscription} from "rxjs";
import {ISuggestion} from "../../state/suggestions.state";
import {EnvironmentService} from 'src/app/snatch/services';
import {FetchSuggestions} from "../../actions/suggestion.actions";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'leap-suggestion-list',
  templateUrl: './suggestion-list.component.html',
  styleUrls: ['./suggestion-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionListComponent implements OnInit {
	@Select((state: any) => state.suggestions.courseSuggestions)
	courseSuggestions$: Observable<ISuggestion>;

	@Select((state: any) => state.suggestions.courseEventSuggestions)
	courseEventSuggestions$: Observable<ISuggestion>;

	@Select((state: any) => state.suggestions.instructorSuggestions)
	instructorSuggestions$: Observable<ISuggestion>;

	@Select((state: any) => state.suggestions.fetchingSuggestions)
	fetchingSuggestions$: Observable<boolean>;

	@Input('term')
	set allowDay(value: boolean) {
		this._term = value;
		this.goSearchSuggestions();

	}
	private _term;
	private domain:string;
	private ngUnsubscribe = new Subject();

	constructor(private envService: EnvironmentService,private store: Store) {
		this.domain =  this.envService.serviceMarketUrl;
	}

  ngOnInit() {
	  this.goSearchSuggestions();
  }
  goSearchSuggestions(){
	  const context =  this.store.snapshot().core.globalFilters.context;
	  this.store.dispatch(new FetchSuggestions(this._term,context));
  }
}
