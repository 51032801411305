import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxsModule} from "@ngxs/store";
import {SuggestionsState} from "./state/suggestions.state";
import {SuggestionsService} from "./services/suggestions.service";
import {SuggestionsComponent} from "./containers/suggestions/suggestions.component";
import { SuggestionListComponent } from './components/suggestion-list/suggestion-list.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { SuggestionCarouselComponent } from './components/suggestion-list/suggestion-carousel/suggestion-carousel.component';
import {UrlFromBucketPipe} from "./components/suggestion-list/url-from-bucket.pipe";
import {SafeUrlPipe} from "./components/suggestion-list/safe-url.pipe";
import {EllipsisPipe} from "./components/suggestion-list/ellipsis.pipe";
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
@NgModule({
  declarations: [
	  SuggestionsComponent,
	  SuggestionListComponent,
	  SuggestionCarouselComponent,
	  UrlFromBucketPipe,
	  SafeUrlPipe,
	  EllipsisPipe
  ],
  imports: [
    CommonModule,
	  NgxsModule.forFeature([SuggestionsState]),
	  NzCarouselModule,
	  NzSkeletonModule
  ],
  exports:[SuggestionsComponent],
  providers:[SuggestionsService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SuggestionsModule { }
