/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-card-image-skeleton.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ng-zorro-antd/skeleton/ng-zorro-antd-skeleton.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "ng-zorro-antd/skeleton";
import * as i5 from "./product-card-image-skeleton.component";
var styles_ProductCardImageSkeletonComponent = [i0.styles];
var RenderType_ProductCardImageSkeletonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductCardImageSkeletonComponent, data: {} });
export { RenderType_ProductCardImageSkeletonComponent as RenderType_ProductCardImageSkeletonComponent };
export function View_ProductCardImageSkeletonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nz-skeleton", [], [[2, "ant-skeleton-with-avatar", null], [2, "ant-skeleton-active", null]], null, null, i2.View_NzSkeletonComponent_0, i2.RenderType_NzSkeletonComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 638976, null, 0, i4.NzSkeletonComponent, [i1.ChangeDetectorRef, i1.Renderer2, i1.ElementRef], { nzActive: [0, "nzActive"], nzLoading: [1, "nzLoading"], nzAvatar: [2, "nzAvatar"], nzParagraph: [3, "nzParagraph"] }, null), i1.ɵpod(4, { size: 0, shape: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.size; _ck(_v, 2, 0, currVal_2); var currVal_3 = true; var currVal_4 = true; var currVal_5 = _ck(_v, 4, 0, "large", "square"); var currVal_6 = false; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = !!i1.ɵnov(_v, 3).nzAvatar; var currVal_1 = i1.ɵnov(_v, 3).nzActive; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ProductCardImageSkeletonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-card-image-skeleton", [], null, null, null, View_ProductCardImageSkeletonComponent_0, RenderType_ProductCardImageSkeletonComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProductCardImageSkeletonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductCardImageSkeletonComponentNgFactory = i1.ɵccf("leap-product-card-image-skeleton", i5.ProductCardImageSkeletonComponent, View_ProductCardImageSkeletonComponent_Host_0, { size: "size" }, {}, []);
export { ProductCardImageSkeletonComponentNgFactory as ProductCardImageSkeletonComponentNgFactory };
