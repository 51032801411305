/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-card-skeleton.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ng-zorro-antd/card/ng-zorro-antd-card.ngfactory";
import * as i3 from "ng-zorro-antd/card";
import * as i4 from "ng-zorro-antd/core";
import * as i5 from "../../../../../../node_modules/ng-zorro-antd/skeleton/ng-zorro-antd-skeleton.ngfactory";
import * as i6 from "ng-zorro-antd/skeleton";
import * as i7 from "./product-card-skeleton.component";
var styles_ProductCardSkeletonComponent = [i0.styles];
var RenderType_ProductCardSkeletonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductCardSkeletonComponent, data: {} });
export { RenderType_ProductCardSkeletonComponent as RenderType_ProductCardSkeletonComponent };
export function View_ProductCardSkeletonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "nz-card", [["class", "card"]], [[2, "ant-card-loading", null], [2, "ant-card-bordered", null], [2, "ant-card-hoverable", null], [2, "ant-card-small", null], [2, "ant-card-contain-grid", null], [2, "ant-card-type-inner", null], [2, "ant-card-contain-tabs", null]], null, null, i2.View_NzCardComponent_0, i2.RenderType_NzCardComponent)), i1.ɵdid(1, 49152, null, 2, i3.NzCardComponent, [i4.NzConfigService, i1.Renderer2, i1.ElementRef], null, null), i1.ɵqud(603979776, 1, { tab: 0 }), i1.ɵqud(603979776, 2, { grids: 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "nz-skeleton", [], [[2, "ant-skeleton-with-avatar", null], [2, "ant-skeleton-active", null]], null, null, i5.View_NzSkeletonComponent_0, i5.RenderType_NzSkeletonComponent)), i1.ɵdid(5, 638976, null, 0, i6.NzSkeletonComponent, [i1.ChangeDetectorRef, i1.Renderer2, i1.ElementRef], { nzActive: [0, "nzActive"], nzLoading: [1, "nzLoading"], nzTitle: [2, "nzTitle"], nzParagraph: [3, "nzParagraph"] }, null), i1.ɵpod(6, { rows: 0 })], function (_ck, _v) { var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = _ck(_v, 6, 0, 7); _ck(_v, 5, 0, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).nzLoading; var currVal_1 = i1.ɵnov(_v, 1).nzBordered; var currVal_2 = i1.ɵnov(_v, 1).nzHoverable; var currVal_3 = (i1.ɵnov(_v, 1).nzSize === "small"); var currVal_4 = (i1.ɵnov(_v, 1).grids && i1.ɵnov(_v, 1).grids.length); var currVal_5 = (i1.ɵnov(_v, 1).nzType === "inner"); var currVal_6 = !!i1.ɵnov(_v, 1).tab; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = !!i1.ɵnov(_v, 5).nzAvatar; var currVal_8 = i1.ɵnov(_v, 5).nzActive; _ck(_v, 4, 0, currVal_7, currVal_8); }); }
export function View_ProductCardSkeletonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-card-skeleton", [], null, null, null, View_ProductCardSkeletonComponent_0, RenderType_ProductCardSkeletonComponent)), i1.ɵdid(1, 114688, null, 0, i7.ProductCardSkeletonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductCardSkeletonComponentNgFactory = i1.ɵccf("leap-product-card-skeleton", i7.ProductCardSkeletonComponent, View_ProductCardSkeletonComponent_Host_0, {}, {}, []);
export { ProductCardSkeletonComponentNgFactory as ProductCardSkeletonComponentNgFactory };
