import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter, first, withLatestFrom } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import { GetTranslations } from "src/app/core/actions/core.actions";
import { HedwigService } from 'src/app/core/services/hedwig.service';
import { NewConversation, SetConversations, UpdateConversation, NewMessage } from "src/app/inbox/state/inbox.state";
import { NewNotification, SetNotifications } from './core/actions/notifications.actions';
import { QueryParametersCacheService } from './core/services/query-parameters-cache.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { StorageService } from './snatch/services/storage.service';
const ɵ0 = (state) => state.session.accessToken, ɵ1 = (state) => state.core.notifications.notifications;
export class AppComponent {
    constructor(store, queryParametersCacheService, hedwigService, authClient, route, authService, storageService) {
        this.store = store;
        this.queryParametersCacheService = queryParametersCacheService;
        this.hedwigService = hedwigService;
        this.authClient = authClient;
        this.route = route;
        this.authService = authService;
        this.storageService = storageService;
        this.title = 'product-market';
        this.queryParametersCacheService.init();
        this.accessToken$
            .pipe(filter(_ => !!_), first())
            .subscribe(_ => {
            this.initInbox(_);
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.store.dispatch(new GetTranslations());
        });
    }
    ngOnDestroy() { }
    initInbox(accessToken) {
        this.hedwigService.init(accessToken);
        this.hedwigService.getSummary()
            .pipe(untilDestroyed(this), withLatestFrom(this.notifications$))
            .subscribe(([res, notifications]) => {
            this.store.dispatch(new SetConversations(res.channelSummary));
            if (!notifications.length) {
                this.store.dispatch(new SetNotifications(res.notifications.data));
            }
        });
        this.hedwigService.newNotification()
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
            this.store.dispatch(new NewNotification(res));
        });
        this.hedwigService.newMessage()
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
            this.store.dispatch(new NewMessage(_));
        });
        this.hedwigService.newChannel()
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
            this.store.dispatch(new NewConversation(_));
        });
        this.hedwigService.updateChannel()
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
            this.store.dispatch(new UpdateConversation(_));
        });
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], AppComponent.prototype, "accessToken$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], AppComponent.prototype, "notifications$", void 0);
export { ɵ0, ɵ1 };
