import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallbackComponent } from './callback/callback.component';
import { WindowOpenerService } from './services/window-opener.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CallbackComponent],
  exports: [CallbackComponent],
  providers: [WindowOpenerService]
})
export class CallbackModule { }
