import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'leap-product-card-image-skeleton',
  templateUrl: './product-card-image-skeleton.component.html',
  styleUrls: ['./product-card-image-skeleton.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardImageSkeletonComponent implements OnInit {

  @Input() size: 'regular' | 'small' | 'extra-small' = 'regular';
  
  constructor() { }

  ngOnInit() {
  }

}
