import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import {BundleSnapshot, LineItem} from '../../models/line-item.model';

@Component({
  selector: 'leap-line-item',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemComponent implements OnInit, OnDestroy {

  @Input() imageUrl: string;
  @Input() id: string;
  @Input() sku: string;
  @Input() name: string;
  @Input() language: string;
  @Input() deliveryFormat: string;
  @Input() certificateType: string;
  @Input() netTotalWithSurcharges: number;
  @Input() currency: string;
  @Input() quantity: number;
  @Input() masterProductSku: string;
  @Input() bundle: BundleSnapshot;

  @Input() currencySignsMap: Map<string, string>;

  @Output() quantityChange: EventEmitter<LineItem> = new EventEmitter();
  @Output() remove: EventEmitter<LineItem> = new EventEmitter();

  form: FormGroup;

  // Use for takeUntil to unsubscribe onDestroy
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      id: [this.id, Validators.required],
      sku: [this.sku, Validators.required],
      masterProductSku: [this.masterProductSku, Validators.required],
      quantity: [this.quantity, Validators.required],
      language: [this.language, Validators.required],
      deliveryFormat: [this.deliveryFormat, Validators.required],
      certificateType: [this.certificateType],
      license: [this.bundle && this.bundle.licenseSku]
    })

    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(800)
      )
      .subscribe(_ => {
        this.onQuantityChange();
      })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToDetailsFn() {
    return this.goToDetails(this.form.value)
  }

  goToDetails(selectedVariant: any) {
    let queryParams = {
        language: selectedVariant.language,
        deliveryFormat: selectedVariant.deliveryFormat
    };

    if(selectedVariant.certificateType)
      queryParams['certificateType'] = selectedVariant.certificateType;

    if(selectedVariant.license)
      queryParams['license'] = selectedVariant.license;

    this.router.navigate(['products', 'details', selectedVariant.masterProductSku], {
      queryParams: queryParams
    });
  }

  onRemove() {
    this.remove.emit(this.form.value)
  }

  onQuantityChange() {
    this.quantityChange.emit(this.form.value)
  }

}
