<nz-header class="navbar">
    <div class="nav-wrapper">
        <div class="nav-left">
            <a [routerLink]="['/']">
                <ng-container *ngIf="navLogo;else logo">
                    <img [src]="navLogo"
                    [style.width]="(domainConfiguration$ | async)?.logo?.marketplaceHeader?.width"
                    [style.height]="(domainConfiguration$ | async)?.logo?.marketplaceHeader?.height "
                    [style.maxWidth]="(domainConfiguration$ | async)?.logo?.marketplaceHeader?.width "
                    [style.maxHeight]="(domainConfiguration$ | async)?.logo?.marketplaceHeader?.height"
                    class="nav-logo" alt="FutureSkills Prime Logo">
                </ng-container>
            </a>
            <ul nz-menu nzMode="horizontal">
                <li nz-menu-item class="tab active">
                    <ng-container *ngTemplateOutlet="productMarketIcon"></ng-container>
                    <a (click)="toggleMenu(false)"
                      [routerLink]="['/']"
                    >Products</a></li>
                <li nz-menu-item class="tab">
                    <ng-container *ngTemplateOutlet="serviceMarketIcon"></ng-container>
                    <a (click)="navigateToServiceMarket()">Services</a>
                </li>
            </ul>
        </div>
        <div class="nav-center">
            <div #largeScreenFilters class="hide-md">
                <div style="font-size: 16px;" class="categories-container" (click)="toggleMenu(true)">

                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        overflow="visible" preserveAspectRatio="none" viewBox="0 0 578 514" width="22" height="20">
                        <g transform="translate(1, 1)">
                            <path
                                d="M575.33 456.43L399 8.02C397.28 3.1 392.61 0 387.65 0c-3.01 0-4.97 1.03-11.49 3.31-6.46 2.26-9.82 8.24-6.27 18.38-16.46 9.69-59.15 24.09-75.5 26.42-1.33-3.78-1.97-6.62-6.4-9.23V32c0-17.67-14.33-32-32-32h-96c-5.96 0-11.22 2.07-16 4.9C139.22 2.07 133.96 0 128 0H32C14.33 0 0 14.33 0 32v448c0 17.67 14.33 32 32 32h96c5.96 0 11.22-2.07 16-4.9 4.78 2.84 10.04 4.9 16 4.9h96c17.67 0 32-14.33 32-32V118.88l151.43 385.1c1.73 4.92 6.4 8.02 11.35 8.02 3 0 4.96-1.03 11.49-3.31 6.44-2.25 9.83-8.23 6.27-18.38 16.46-9.69 59.15-24.09 75.5-26.42 3.65 10.4 10.13 12.65 16.38 10.46l7.55-2.64c6.23-2.19 9.54-9.07 7.36-15.28zM128 480H32v-64h96v64zm0-96H32V128h96v256zm0-288H32V32h96v64zm128 384h-96v-64h96v64zm0-96h-96V128h96v256zm0-288h-96V32h96v64zm203.15 367.54L303.79 74.88c25.22-4.74 64.01-20.33 75.5-26.42l155.36 388.65c-25.23 4.75-64.01 20.33-75.5 26.43z"
                                vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);" />
                        </g>
                    </svg>&nbsp;Catalog <span class="arrow"><svg version="1.2" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none"
                            viewBox="0 0 258 514" width="9" height="17.95995016019936">
                            <g transform="translate(1, 1)">
                                <path
                                    d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                                    vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);" />
                            </g>
                        </svg></span>
                </div>
                <leap-navbar-filters
                        *ngIf="innerWidth > 1230"
                        [topicsTree]="topicsTree$ | async"
                        (topicSelect)=topicSelect($event)
                        (filterProducts)=filterProducts($event)
                        (filterProduct)=filterProduct($event)
                        (filterSeller)=filterSeller($event)
                        (filterSubtopic)=filterSubtopic($event)
                        (doGlobalSearch)=doGlobalSearch($event)
                ></leap-navbar-filters>
            </div>
        </div>
        <div class="nav-right">
            <ul nz-menu nzTheme="dark" nzMode="horizontal" class="nav-menu" style="display: flex; margin-left: 0px;">
                <li style="vertical-align: middle; padding: 0px; display: flex; align-items: center;">
                    <div style="margin-right: 10px;"></div>
                    <leap-shopping-cart *ngIf="(isLogged$ | async) && (profile$ | async)?.employee?.account?.buyer"
                        [count]="shoppingCartLineItemsCount$ | async"
                        (openShoppingCartDrawer)="openShoppingCartDrawer()"></leap-shopping-cart>
                    <div class="separator"></div>
                </li>


                <li *ngIf="!(isLogged$ | async)">
                    <a (click)="login()" class="sign-in-button">Sign In</a>
                    <button (click)="register()" class="sign-up-button" nz-button nzType="primary">Register</button>
                </li>
                <li *ngIf="isLogged$ | async"
                    style="vertical-align: middle; padding: 0px; display: flex; align-items: center;">
                    <!--          TODO enable when section implemented-->
                    <!--          <leap-product-catalog></leap-product-catalog>-->
                    <leap-messages style="margin-left: 10px; margin-right: 10px;"></leap-messages>
                    <leap-notifications style="margin-left: 10px; margin-right: 10px;"></leap-notifications>
                    <leap-user style="margin-left: 10px;"
                               [hasAlternativeAccounts]="(userDetails$ | async)?.alternativeAccounts"
                               [domain]="(userDetails$ | async)?.domain"
                               [profile$]="profile$"
                               [getProfilePending$]="getProfilePending$"
                               [vmsEnabled]="vmsEnabled$ | async"
                               [integrationEnabled]="integrationEnabled$ | async"
                               (switchAccount)="switchAccount()"
                               (logout)="logout()"></leap-user>
                </li>
            </ul>
        </div>
    </div>
    <div #smallScreenFilters class="show-md second-line" nz-row nzType="flex">
        <leap-navbar-filters
                *ngIf="innerWidth <= 1230"
                style="display: flex; justify-content: space-between; width: 100%;"
                [topicsTree]="topicsTree$ | async"
                (topicSelect)=topicSelect($event)
                (filterProducts)=filterProducts($event)
                (filterProduct)=filterProduct($event)
                (doGlobalSearch)=doGlobalSearch($event)></leap-navbar-filters>
    </div>
</nz-header>
<ng-container *ngIf="!(isLogged$ | async) && showSignInBanner && !isWelcomePage">
    <div class="banner">
        <span i18n="Banner text to prompt user to sign in|Motivates user to sign@@navbar.signInBanner.title">
            <a (click)="login()">Sign in</a> / <a (click)="register()">Register</a> to view product prices, place
            orders, view sellers profile, etc...
        </span>
        <svg (click)="showSignInBanner = false" version="1.2" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none"
            viewBox="0 0 322 514" xml:space="preserve" y="0px" x="0px" id="Layer_1_1553865593792" width="15"
            height="24">
            <g transform="translate(1, 1)">
                <style type="text/css">
                    .st0_1553865593792 {
                        fill: #394042;
                    }
                </style>
                <path
                    d="M193.9,256l102.6-102.6l21.1-21.1c3.1-3.1,3.1-8.2,0-11.3L295,98.4c-3.1-3.1-8.2-3.1-11.3,0L160,222.1  L36.3,98.3c-3.1-3.1-8.2-3.1-11.3,0L2.3,121c-3.1,3.1-3.1,8.2,0,11.3L126.1,256L2.3,379.7c-3.1,3.1-3.1,8.2,0,11.3L25,413.6  c3.1,3.1,8.2,3.1,11.3,0L160,289.9l102.6,102.6l21.1,21.1c3.1,3.1,8.2,3.1,11.3,0l22.6-22.6c3.1-3.1,3.1-8.2,0-11.3L193.9,256z"
                    class="st0_1553865593792" vector-effect="non-scaling-stroke" style="fill: rgb(255, 255, 255);" />
            </g>
        </svg>
    </div>
</ng-container>
<!-- <div class="highlight">

</div> -->
<ng-template #logo>
    <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/ed-marketplace-logo-color.png'" class="nav-logo" alt="Cornerstone Logo">
</ng-template>

<ng-template #productMarketIcon>
    <span class="icon"><svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible" preserveAspectRatio="none" viewBox="0 0 618 514" width="16" height="14">
            <g transform="translate(1, 1)">
                <path
                    d="M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-29.6 47.2-10 110.6 38 130.8v227.4c0 19.4 14.3 35.2 32 35.2h448c17.7 0 32-15.8 32-35.2V249.4c48-20.2 67.6-83.6 38-130.8zM531.8 480l-446.6.3c-.3-.2-1.2-1.3-1.2-3.5V256h2.5c29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.9 33.1h2.5v64H84v32h448v124.8c0 2-.8 3.1-.2 3.2zm46.4-286.5c-7.8 16.6-22.1 27.5-39.3 29.8-3.1.4-6.2.6-9.4.6-19.3 0-37-8-50-22.5L455.7 175l-23.8 26.6c-13 14.5-30.7 22.5-50 22.5s-37-8-50-22.5L308 175l-23.8 26.6c-13 14.5-30.7 22.5-50 22.5s-37-8-50-22.5L160.3 175l-23.8 26.6c-13 14.5-30.7 22.5-50 22.5-3.2 0-6.3-.2-9.4-.6-17.2-2.3-31.5-13.2-39.3-29.8-8.7-18.6-7.5-40.8 3.3-57.9L106 32h404l64.9 103.6c10.8 17.2 12 39.3 3.3 57.9z"
                    fill="currentColor" vector-effect="non-scaling-stroke" />
            </g>
        </svg></span>
</ng-template>

<ng-template #serviceMarketIcon>
    <span class="icon"><svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible" preserveAspectRatio="none" viewBox="0 0 642 514" xml:space="preserve" y="0px" x="0px"
            id="Layer_1" width="18" height="14">
            <g transform="translate(1, 1)">
                <style type="text/css">
                    .st0 {
                        fill: #394042;
                    }
                </style>
                <path
                    d="M608,0H192c-17.7,0-32,14.3-32,32v96c-53,0-96,43-96,96s43,96,96,96s96-43,96-96c0-41.7-26.8-76.9-64-90.1V32  h416v352h-64v-64c0-17.7-14.3-32-32-32H384c-17.7,0-32,14.3-32,32v64h-46.7c-0.6-0.9-1-2-1.6-2.9C286.4,355.5,257,342,226.8,342  c-27.8,0-34.8,10-66.8,10c-32,0-38.9-10-66.8-10c-30.2,0-59.7,13.5-76.9,39.1C6,396.4,0,414.8,0,434.7V472c0,22.1,17.9,40,40,40h240  c22.1,0,40-17.9,40-40v-37.3c0-6.4-0.8-12.6-2-18.7h290c17.7,0,32-14.3,32-32V32C640,14.3,625.7,0,608,0z M224,224  c0,35.3-28.7,64-64,64s-64-28.7-64-64s28.7-64,64-64S224,188.7,224,224z M288,472c0,4.4-3.6,8-8,8H40c-4.4,0-8-3.6-8-8v-37.3  c0-12.8,3.8-25.1,10.8-35.7c10.5-15.6,29.3-25,50.4-25c21.8,0,30,10,66.8,10c36.8,0,45-10,66.8-10c21,0,39.8,9.3,50.4,25  c7.1,10.5,10.9,22.9,10.9,35.7L288,472L288,472z M512,384H384v-64h128V384z"
                    class="st0" vector-effect="non-scaling-stroke" />
            </g>
        </svg></span>
</ng-template>

<leap-menu [visible]="menuState" [items]="catalogs$ | async" (menuClosed)="toggleMenu(false)"></leap-menu>
