/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shopping-cart.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ng-zorro-antd/badge/ng-zorro-antd-badge.ngfactory";
import * as i3 from "ng-zorro-antd/badge";
import * as i4 from "ng-zorro-antd/core";
import * as i5 from "@angular/cdk/observers";
import * as i6 from "./shopping-cart.component";
var styles_ShoppingCartComponent = [i0.styles];
var RenderType_ShoppingCartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShoppingCartComponent, data: {} });
export { RenderType_ShoppingCartComponent as RenderType_ShoppingCartComponent };
export function View_ShoppingCartComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "nz-badge", [["class", "shopping-cart-icon"]], [[2, "ant-badge-status", null]], null, null, i2.View_NzBadgeComponent_0, i2.RenderType_NzBadgeComponent)), i1.ɵdid(1, 4964352, null, 0, i3.NzBadgeComponent, [i4.NzConfigService, i1.Renderer2, i1.ElementRef, i5.ContentObserver, i1.ChangeDetectorRef, i1.NgZone], { nzStyle: [0, "nzStyle"], nzCount: [1, "nzCount"] }, null), i1.ɵpod(2, { backgroundColor: 0 }), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openShoppingCartDrawer.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "29.444444444444"], ["overflow", "visible"], ["preserveAspectRatio", "none"], ["version", "1.2"], ["viewBox", "0 0 578 514"], ["width", "33.125"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:g", [["transform", "translate(1, 1)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M551.991 64H129.28l-8.329-44.423C118.822 8.226 108.911 0 97.362 0H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h78.72l69.927 372.946C150.305 416.314 144 431.42 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-17.993-7.435-34.24-19.388-45.868C506.022 391.891 496.76 384 485.328 384H189.28l-12-64h331.381c11.368 0 21.177-7.976 23.496-19.105l43.331-208C578.592 77.991 567.215 64 551.991 64zM464 416c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32zm-256 0c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32zm294.156-128H171.28l-36-192h406.876l-40 192zM272 196v-8c0-6.627 5.373-12 12-12h36v-36c0-6.627 5.373-12 12-12h8c6.627 0 12 5.373 12 12v36h36c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-36v36c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-36h-36c-6.627 0-12-5.373-12-12z"], ["fill", "currentColor"], ["style", "fill: rgb(142, 143, 166);"], ["vector-effect", "non-scaling-stroke"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, "#FA4616"); var currVal_2 = _co.count; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).nzStatus; _ck(_v, 0, 0, currVal_0); }); }
export function View_ShoppingCartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-shopping-cart", [], null, null, null, View_ShoppingCartComponent_0, RenderType_ShoppingCartComponent)), i1.ɵdid(1, 114688, null, 0, i6.ShoppingCartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShoppingCartComponentNgFactory = i1.ɵccf("leap-shopping-cart", i6.ShoppingCartComponent, View_ShoppingCartComponent_Host_0, { count: "count" }, { openShoppingCartDrawer: "openShoppingCartDrawer" }, []);
export { ShoppingCartComponentNgFactory as ShoppingCartComponentNgFactory };
