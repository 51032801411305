import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SelectConversation, InboxState, SelectLatestConversation, ClearInboxState, CheckDMConversation } from '../../state/inbox.state';
import { filter, first } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ActivatedRoute } from '@angular/router';
import { TypedParamMap } from "../../services/url-storage.service";
const ɵ0 = (state) => state.inbox.selectedConversationId, ɵ1 = (state) => state.inbox.searchForm;
export class ConversationListComponent {
    constructor(store, fb, route) {
        this.store = store;
        this.fb = fb;
        this.route = route;
    }
    ngOnInit() {
        const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
        const queryParams = new TypedParamMap(this.route.snapshot.queryParamMap);
        this.myEmail = userDetails.username;
        this.myDomain = userDetails.domain;
        this.form = this.fb.group({
            term: null
        });
        this.conversations$
            .pipe(filter(_ => {
            return !!(_ && _.length);
        }), first(), untilDestroyed(this))
            .subscribe(_ => {
            const actions = [];
            if (queryParams.get('dmDomain')) {
                actions.push(new CheckDMConversation(queryParams.get('dmDomain')));
            }
            else {
                actions.push(new SelectLatestConversation());
            }
            this.store.dispatch(actions);
        });
    }
    ngOnDestroy() {
        this.store.dispatch(new ClearInboxState());
    }
    selectConversation(conversationId) {
        this.store.dispatch(new SelectConversation(conversationId));
    }
    trackByFunction(index, item) {
        if (!item)
            return null;
        return item.channelId;
    }
    compareMyDomain(domain) {
        return this.myDomain === domain;
    }
}
tslib_1.__decorate([
    Select(InboxState.filteredConversations),
    tslib_1.__metadata("design:type", Observable)
], ConversationListComponent.prototype, "conversations$", void 0);
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], ConversationListComponent.prototype, "selectedConversationId$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], ConversationListComponent.prototype, "searchForm$", void 0);
export { ɵ0, ɵ1 };
