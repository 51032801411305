export namespace SocketEvents {
  /**
   * Contains newly sent message.
   */
  export const NEW_MESSAGE = 'new-message';

  /**
   * Contains newly sent notification.
   */
  export const NEW_NOTIFICATION = 'new-notification';

  /**
   * Contains newly joined channel.
   */
  export const NEW_CHANNEL = 'new-channel';

  /**
   * Contains details of newly updated channel.
   */
  export const UPDATE_CHANNEL = 'update-channel';

  /**
   * Welcome event emitted by the server after successful authentication.
   * Contains the chat summary.
   */
  export const WELCOME = 'welcome';

  /**
   * Sends a message to a channel.
   */
  export const SEND_MESSAGE = 'send-message';

  /**
   * Marks a channel offset as read.
   */
  export const MARK_READ = 'mark-read';

  /**
   * Fetches the messages for a given channel.
   */
  export const FETCH_MESSAGES = 'fetch-messages';

  /**
   * Fetches the channels for the current user.
   */
  export const FETCH_CHANNELS = 'fetch-channels';

  /**
   * Fetches the notifications for the current user.
   */
  export const FETCH_NOTIFICATIONS = 'fetch-notifications';

  /**
   * Updates the access token of the current WS connection.
   */
  export const UPDATE_SESSION_TOKEN = 'update-session-token';

  /**
   * Create a new DM (direct message) channel between the current user's domain
   * and the target domain.
   */
  export const CREATE_DM_CHANNEL = 'create-dm-channel';

  /**
   * Checks if a DM channel between the current user's domain
   * and the target domain exists.
   */
  export const CHECK_DM_CHANNEL = 'check-dm-channel';

  /**
   * Change notification status (archived or not, isNew or not).
   */
  export const CHANGE_NOTIFICATION_STATUS = "change-notification-status";

  /**
   * Fetch notification details.
   */
  export const NOTIFICATION_DETAILS = "notification-details";
}
