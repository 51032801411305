/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./suggestion-list.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./suggestion-carousel/suggestion-carousel.component.ngfactory";
import * as i3 from "./suggestion-carousel/suggestion-carousel.component";
import * as i4 from "@angular/common";
import * as i5 from "./suggestion-list.component";
import * as i6 from "../../../snatch/services/environment.service";
import * as i7 from "@ngxs/store";
var styles_SuggestionListComponent = [i0.styles];
var RenderType_SuggestionListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuggestionListComponent, data: {} });
export { RenderType_SuggestionListComponent as RenderType_SuggestionListComponent };
function View_SuggestionListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "leap-suggestion-carousel", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SuggestionCarouselComponent_0, i2.RenderType_SuggestionCarouselComponent)), i1.ɵdid(1, 4833280, null, 0, i3.SuggestionCarouselComponent, [], { type: [0, "type"], suggestions: [1, "suggestions"], moreLink: [2, "moreLink"], domain: [3, "domain"], fetching: [4, "fetching"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "instructors"; var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.instructorSuggestions$))) == null) ? null : tmp_1_0.suggestions); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 3).transform(_co.instructorSuggestions$))) == null) ? null : tmp_2_0.moreUrl); var currVal_3 = _co.domain; var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 4).transform(_co.fetchingSuggestions$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SuggestionListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nz-card", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SuggestionListComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.instructorSuggestions$))) == null) ? null : tmp_0_0.suggestions.length); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SuggestionListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SuggestionListComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.instructorSuggestions$))) == null) ? null : tmp_0_0.suggestions.length); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SuggestionListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-suggestion-list", [], null, null, null, View_SuggestionListComponent_0, RenderType_SuggestionListComponent)), i1.ɵdid(1, 114688, null, 0, i5.SuggestionListComponent, [i6.EnvironmentService, i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuggestionListComponentNgFactory = i1.ɵccf("leap-suggestion-list", i5.SuggestionListComponent, View_SuggestionListComponent_Host_0, { allowDay: "term" }, {}, []);
export { SuggestionListComponentNgFactory as SuggestionListComponentNgFactory };
