import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../snatch/services';
import { DomainConfiguration } from './actions/auth.actions';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class DomainConfigurationService {
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
    private store: Store
  ) { }

  getDomainConfiguration() {
    if (this.environment.isProdin || this.environment.isEtisalat) {
      this.getDomainConfig()
        .subscribe((res: any) => {
          this.store.dispatch(new DomainConfiguration(res.data));
        })
    } else {
      this.store.dispatch(new DomainConfiguration(null));
      return
    }
  }
  getDomainConfig(): Observable<any> {
    const url = `${this.environment.amberUrl}/domain/details/${this.environment.isEtisalat ? 'etisalat':'fsp'}`;
    return this.http.get<any>(url);
  }
}
