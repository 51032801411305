
import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';

import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';

@Component({
  selector: 'leap-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterContentInit {

  @Input() selectedSlideIndex: number = 0;

  @ContentChildren(CarouselSlideComponent) slides: QueryList<CarouselSlideComponent>;

  constructor() { }

  ngAfterContentInit() { }

}