import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'leap-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartComponent implements OnInit {

  @Input() count: number;
  
  @Output()
  openShoppingCartDrawer: EventEmitter<void> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

}
