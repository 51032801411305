import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { combineLatest, merge, Observable, Subject, BehaviorSubject, } from "rxjs";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime, map, shareReplay, startWith, takeUntil, take, switchMap, scan, } from "rxjs/operators";
import { ChangePage, ClearProducts, FetchFavoriteSuppliers, FetchMySuppliers, FetchProducts, RestoreProductsState, ToggleSort, } from "../../../../products/actions/products.actions";
import { AuthTokenState } from "../../../../auth/state/auth-token.state";
import { AddLineItemToShoppingCart } from "../../../../shopping-cart/actions/shopping-cart.actions";
import { GetSellerProducts, SellerProductChangePage, SellerProductsClearProducts, SellerProductsRestoreProductsState, SellerProductsToggleSort, } from "../../../../seller-page/actions/seller-details.actions";
import { SupplierProductChangePage } from "../../../../my-suppliers/actions/suppliers.actions";
import { PublishToLxpFormService } from "../../services/publish-to-lxp-form.service";
import { LxpPublishingService } from "../../services/lxp-publishing.service";
import { CoreState } from "src/app/core/state/core.state";
const ɵ0 = (state) => state.shoppingCart.addLineItemSku, ɵ1 = (state) => state.shoppingCart.addLineItemPending, ɵ2 = (state) => state.products.searchMetadata.languages, ɵ3 = (state) => state.products.searchMetadata.level, ɵ4 = (state) => state.products.searchMetadata.category, ɵ5 = (state) => state.products.searchMetadata.deliveryFormats, ɵ6 = (state) => state.products.searchMetadata.certificateTypes, ɵ7 = (state) => state.products.searchMetadata.productTypes, ɵ8 = (state) => state.products.searchMetadata.sellers, ɵ9 = (state) => state.products.searchMetadata.courseDeliveryFormats, ɵ10 = (state) => state.core.dictionaries.subTopicsMap, ɵ11 = (state) => state.core.dictionaries.topicsMap, ɵ12 = (state) => state.core.dictionaries.lxpProductStatusMap, ɵ13 = (state) => state.core.dictionaries.catalogsMap, ɵ14 = (state) => state.core.dictionaries.currencySignsMap;
export class ProductFeedComponent {
    constructor(fb, store, router, lxpPublishingService, publishToLxpFormService) {
        this.fb = fb;
        this.store = store;
        this.router = router;
        this.lxpPublishingService = lxpPublishingService;
        this.publishToLxpFormService = publishToLxpFormService;
        this.selectedProductsIds$ = new BehaviorSubject([]);
        this.drawerVisible = false;
        this.shownProducts = [];
        this.formGroup = this.fb.group({
            topic: [null],
            subtopic: [null],
            filter: [null],
            deliveryFormats: [[]],
            productTypes: [[]],
            languages: [[]],
            level: [[]],
            category: [[]],
            sellers: [[]],
            hideRestricted: [null],
            courseDeliveryFormats: [[]],
            lxpProductStatus: [[]],
            catalogs: [[]],
        });
        // Use for takeUntil to unsubscribe onDestroy
        this.ngUnsubscribe = new Subject();
    }
    ngOnInit() {
        if (this.isSellerProductListing) {
            this.ngxsFormPath = "sellerDetails.searchForm";
            this.products$ = this.store.select((state) => state.sellerDetails.allProductSeller);
            this.categoriesMap$ = this.store.select((state) => state.sellerDetails.categoriesMap);
            this.searchForm$ = this.store.select((state) => state.sellerDetails.searchForm);
            this.searchMetadata$ = this.store.select((state) => state.sellerDetails.searchMetadata);
            this.fetchProductsPending$ = this.store.select((state) => state.sellerDetails.fetchProductsPending);
            this.fetchProductsSuccess$ = this.store.select((state) => state.sellerDetails.fetchProductsSuccess);
            this.fetchProductsError$ = this.store.select((state) => state.sellerDetails.fetchProductsError);
            this.sort$ = this.store.select((state) => state.sellerDetails.sort);
            this.pagination$ = this.store.select((state) => state.sellerDetails.pagination);
        }
        else if (this.isSupplierProductListing) {
            this.ngxsFormPath = "suppliers.selectedSupplier.searchForm";
            this.products$ = this.store.select((state) => state.suppliers.selectedSupplier.products);
            this.categoriesMap$ = this.store.select((state) => state.suppliers.selectedSupplier.categoriesMap);
            this.searchForm$ = this.store.select((state) => state.suppliers.selectedSupplier.searchForm);
            this.searchMetadata$ = this.store.select((state) => state.suppliers.selectedSupplier.searchMetadata);
            this.fetchProductsPending$ = this.store.select((state) => state.suppliers.selectedSupplier.fetchProductsPending);
            this.fetchProductsSuccess$ = this.store.select((state) => state.suppliers.selectedSupplier.fetchProductsSuccess);
            this.fetchProductsError$ = this.store.select((state) => state.suppliers.selectedSupplier.fetchProductsError);
            this.sort$ = this.store.select((state) => state.suppliers.selectedSupplier.sort);
            this.pagination$ = this.store.select((state) => state.suppliers.selectedSupplier.pagination);
        }
        else {
            this.ngxsFormPath = "products.searchForm";
            this.products$ = this.store.select((state) => state.products.products);
            this.odysseySellers$ = this.store.select((state) => state.products.customSuppliers);
            this.categoriesMap$ = this.store.select((state) => state.products.categoriesMap);
            this.searchForm$ = this.store.select((state) => state.products.searchForm);
            this.searchMetadata$ = this.store.select((state) => state.products.searchMetadata);
            this.fetchProductsPending$ = this.store.select((state) => state.products.fetchProductsPending);
            this.fetchProductsSuccess$ = this.store.select((state) => state.products.fetchProductsSuccess);
            this.fetchProductsError$ = this.store.select((state) => state.products.fetchProductsError);
            this.sort$ = this.store.select((state) => state.products.sort);
            this.pagination$ = this.store.select((state) => state.products.pagination);
        }
        merge(this.sort$, this.pagination$, this.searchForm$)
            .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
            this.fetchProducts();
        });
        this.constructSortFields();
        this.products$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((products) => (this.shownProducts = [
            ...new Set([...this.shownProducts, ...products]),
        ]));
        this.pageSelectedProducts$ = this.selectedProductsIds$.pipe(map((selectedIds) => this.filterProductsByIds(this.shownProducts, selectedIds)));
        // @ts-ignore
        this.totalSelectedProducts$ = combineLatest([
            this.pageSelectedProducts$.pipe(startWith([]), scan((oldP, newP) => {
                const previousAndCurrent = oldP.concat(newP);
                return [
                    ...new Map(previousAndCurrent.map((item) => [item.sku, item])).values(),
                ];
            }, [])),
            // @ts-ignore
            this.selectedProductsIds$,
        ]).pipe(map(([products, selectedIds]) => products.filter((p) => selectedIds.includes(p.id))), shareReplay(1));
        this.formGroup.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((_) => {
            if (this.isSellerProductListing) {
                this.store.dispatch(new SellerProductChangePage(1));
            }
            else if (this.isSellerProductListing) {
                this.store.dispatch(new SupplierProductChangePage(1));
            }
            else {
                this.store.dispatch(new ChangePage(1));
            }
        });
        if (this.isSellerProductListing) {
            this.store.dispatch(new SellerProductsRestoreProductsState());
        }
        else {
            this.store.dispatch(new RestoreProductsState());
        }
    }
    mapFilters(params, dictionaries) {
        const { languagesMap, levelMap, deliveryFormatsMap, productTypesMap, sellersMap, categoriesMap, topicsMap, courseDeliveryFormatsMap, subTopicsMap, lxpProductStatusMap, catalogsMap, } = dictionaries;
        if (!Object.values(dictionaries).every((e) => !!e)) {
            return {};
        }
        else {
            return {
                languages: params.languages.map((key) => ({
                    id: key,
                    value: languagesMap.find((el) => el.id === key).displayValue,
                })),
                level: params.level.map((key) => ({
                    id: key,
                    value: levelMap.find((el) => el.id === key).displayValue,
                })),
                deliveryFormats: params.deliveryFormats.map((key) => ({
                    id: key,
                    value: deliveryFormatsMap.find((el) => el.id === key).displayValue,
                })),
                productTypes: params.productTypes.map((key) => ({
                    id: key,
                    value: productTypesMap.find((el) => el.id === key).displayValue,
                })),
                category: params.category.map((key) => ({
                    id: key,
                    value: categoriesMap.find((el) => el.id === key).displayValue,
                })),
                sellers: params.sellers &&
                    params.sellers.map((key) => ({
                        id: key,
                        value: sellersMap.find((el) => el.id === key).displayValue,
                    })),
                topic: params.topic
                    ? { id: params.topic, value: topicsMap.get(params.topic) }
                    : undefined,
                subtopic: params.subtopic
                    ? { id: params.subtopic, value: subTopicsMap.get(params.subtopic) }
                    : undefined,
                courseDeliveryFormats: params.courseDeliveryFormats.map((key) => ({
                    id: key,
                    value: courseDeliveryFormatsMap.find((el) => el.id === key)
                        .displayValue,
                })),
                lxpProductStatus: params.lxpProductStatus &&
                    params.lxpProductStatus.map((key) => ({
                        id: key,
                        value: lxpProductStatusMap.get(key),
                    })),
                catalogs: params.lxpProductStatus &&
                    params.catalogs.map((key) => ({
                        id: key,
                        value: catalogsMap.get(key),
                    })),
            };
        }
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (this.isSellerProductListing) {
            this.store.dispatch(new SellerProductsClearProducts(this.domain));
        }
        else {
            this.store.dispatch(new ClearProducts());
        }
    }
    constructSortFields() {
        let fields = [
            {
                value: "name",
                displayValue: "A-Z",
            },
            {
                value: "best",
                displayValue: "Relevance",
            },
        ];
        const isLogged = this.store.selectSnapshot(AuthTokenState.isLogged);
        if (isLogged)
            fields.splice(1, 0, { value: "price", displayValue: "Price" });
        this.sortFields = fields;
    }
    static getItemById(collection, id) {
        return { id, value: collection.find((l) => l.key === id).value };
    }
    trackById(index, item) {
        return item.id;
    }
    openDrawer() {
        this.drawerVisible = true;
    }
    closeDrawer() {
        this.drawerVisible = false;
    }
    addToCart(sku) {
        this.store.dispatch(new AddLineItemToShoppingCart(sku, 1));
    }
    toggleSort(key) {
        if (this.isSellerProductListing) {
            this.store.dispatch(new SellerProductsToggleSort(key));
        }
        else {
            this.store.dispatch(new ToggleSort(key));
        }
    }
    changePage(page) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (this.isSellerProductListing) {
            this.store.dispatch(new SellerProductChangePage(page));
        }
        else {
            this.store.dispatch(new ChangePage(page));
        }
    }
    patchForm(change) {
        this.formGroup.patchValue(change);
    }
    clearForm() {
        this.formGroup.reset({
            deliveryFormats: [],
            productTypes: [],
            languages: [],
            level: [],
            sellers: [],
            category: [],
            hideRestricted: null,
            courseDeliveryFormats: [],
            lxpProductStatus: [],
        });
    }
    favoriteSupplierSelection(selectionType) {
        if (selectionType.showFavorites) {
            this.store.dispatch(new FetchFavoriteSuppliers());
        }
        if (selectionType.showMySuppliers) {
            this.store.dispatch(new FetchMySuppliers());
        }
    }
    filterProductsByIds(products, ids) {
        return products.filter((p) => ids.includes(p.id));
    }
    onSelectProduct(productId, check) {
        const newValue = check
            ? [...this.selectedProductsIds$.value, productId]
            : this.selectedProductsIds$.value.filter((i) => i !== productId);
        this.selectedProductsIds$.next(newValue);
    }
    clearSelectedProducts() {
        this.selectedProductsIds$.next([]);
    }
    bukPublishToLxp() {
        this.totalSelectedProducts$
            .pipe(take(1))
            .subscribe((products) => {
            this.publishToLxpFormService
                .bulkPublishToLxp(products)
                .subscribe(() => {
                this.selectedProductsIds$.next([]);
                this.fetchProducts();
            });
        });
    }
    isProductSelected(productId) {
        return this.selectedProductsIds$.value.includes(productId);
    }
    editPublishedToLxpProduct(product) {
        this.lxpPublishingService
            .getLxpProductDetails(product.sku)
            .pipe(switchMap((res) => {
            const lxpProductDetails = {
                channels: res.data.channels,
                groups: res.data.groups,
                transactionType: {
                    key: res.data.transactionType.id,
                    value: res.data.transactionType.configValue,
                },
                approvalRequired: res.data.approval,
                approvalRule: res.data.approvalPolicy,
                restrictedDeliveryFormats: res.data.restrictedDeliveryFormat && [
                    {
                        sku: product.sku,
                        restrictedDeliveryFormat: res.data.restrictedDeliveryFormat,
                    },
                ],
            };
            return this.publishToLxpFormService.editLxpProduct(product, lxpProductDetails, res.data.id);
        }))
            .subscribe(() => {
            this.selectedProductsIds$.next([]);
            this.fetchProducts();
        });
    }
    publishProductToLxp(product) {
        if (product.typeConfig &&
            product.typeConfig.configKey === "bundle-product") {
            this.publishToLxpFormService.publishBundleToLxp(product).subscribe(() => {
                this.selectedProductsIds$.next([]);
                this.fetchProducts();
            });
            return;
        }
        this.publishToLxpFormService.publishProductToLxp(product).subscribe(() => {
            this.selectedProductsIds$.next([]);
            this.fetchProducts();
        });
    }
    fetchProducts() {
        if (this.isSellerProductListing) {
            this.store.dispatch(new GetSellerProducts(this.domain));
        }
        else if (this.isSupplierProductListing) {
        }
        else {
            this.store.dispatch(new FetchProducts());
        }
        this.products$.subscribe((_) => (this.selectedFilters$ = combineLatest(this.searchForm$, this.languagesMap$, this.levelMap$, this.deliveryFormatsMap$, this.productTypesMap$, this.sellersMap$, this.categoryMapForFilter$, this.topicsMap$, this.courseDeliveryFormatsMap$, this.subTopicsMap$, this.lxpProductStatusMap$, this.catalogsMap$).pipe(map(([{ model }, languagesMap, levelMap, deliveryFormatsMap, productTypesMap, sellersMap, categoriesMap, topicsMap, courseDeliveryFormatsMap, subTopicsMap, lxpProductStatusMap, catalogsMap,]) => {
            return this.mapFilters(model, {
                languagesMap,
                levelMap,
                deliveryFormatsMap,
                productTypesMap,
                sellersMap,
                categoriesMap,
                topicsMap,
                courseDeliveryFormatsMap,
                subTopicsMap,
                lxpProductStatusMap,
                catalogsMap,
            });
        }), takeUntil(this.ngUnsubscribe), shareReplay())));
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "addLineItemSku$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "addLineItemPending$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "languagesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "levelMap$", void 0);
tslib_1.__decorate([
    Select(ɵ4),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "categoryMapForFilter$", void 0);
tslib_1.__decorate([
    Select(ɵ5),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "deliveryFormatsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ6),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "certificateTypesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ7),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "productTypesMap$", void 0);
tslib_1.__decorate([
    Select(ɵ8),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "sellersMap$", void 0);
tslib_1.__decorate([
    Select(ɵ9),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "courseDeliveryFormatsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ10),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "subTopicsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ11),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "topicsMap$", void 0);
tslib_1.__decorate([
    Select(ɵ12),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "lxpProductStatusMap$", void 0);
tslib_1.__decorate([
    Select(ɵ13),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "catalogsMap$", void 0);
tslib_1.__decorate([
    Select(AuthTokenState.isLogged),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "isLogged$", void 0);
tslib_1.__decorate([
    Select(CoreState.isPermittedToPublishToLxp),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "isPermittedToPublishToLxp$", void 0);
tslib_1.__decorate([
    Select(ɵ14),
    tslib_1.__metadata("design:type", Observable)
], ProductFeedComponent.prototype, "currencySignsMap$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
