import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'leap-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less']
})
export class SearchBarComponent implements OnInit {

  @Input() size: string = 'large';
  @Input()
  form: FormGroup;
  @Input()
  ngxsFormPath: string;
  @Input() placeholder: string;

  constructor(private fb: FormBuilder) {}

  ngOnInit() { }
}
