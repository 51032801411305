/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./searchable-multiselect.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/input";
import * as i3 from "../../../../../node_modules/ng-zorro-antd/radio/ng-zorro-antd-radio.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "ng-zorro-antd/radio";
import * as i6 from "@angular/common";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "../../../../../node_modules/ng-zorro-antd/checkbox/ng-zorro-antd-checkbox.ngfactory";
import * as i9 from "ng-zorro-antd/checkbox";
import * as i10 from "./searchable-multiselect.component";
var styles_SearchableMultiselectComponent = [i0.styles];
var RenderType_SearchableMultiselectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchableMultiselectComponent, data: {} });
export { RenderType_SearchableMultiselectComponent as RenderType_SearchableMultiselectComponent };
function View_SearchableMultiselectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "search-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "input", [["class", "search-input"], ["nz-input", ""], ["type", "text"]], [[2, "ant-input-disabled", null], [2, "ant-input-lg", null], [2, "ant-input-sm", null]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.filterChanged($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NzInputDirective, [i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, ":svg:svg", [["class", "search-btn"], ["overflow", "visible"], ["preserveAspectRatio", "none"], ["version", "1.2"], ["viewBox", "0 0 24 24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [[":xmlns:default", "http://www.w3.org/2000/svg"], ["d", "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"], ["style", "fill: rgb(156, 164, 166);"], ["vector-effect", "non-scaling-stroke"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = (i1.ɵnov(_v, 2).nzSize === "large"); var currVal_2 = (i1.ɵnov(_v, 2).nzSize === "small"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SearchableMultiselectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["No results for '", "'"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter; _ck(_v, 1, 0, currVal_0); }); }
function View_SearchableMultiselectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "token-wrapper"]], [[2, "bolded", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.highlight; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.token; _ck(_v, 1, 0, currVal_1); }); }
function View_SearchableMultiselectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "control-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "label", [["nz-radio", ""]], [[2, "ant-radio-wrapper-checked", null], [2, "ant-radio-wrapper-disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NzRadioComponent_0, i3.RenderType_NzRadioComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.NzRadioComponent]), i1.ɵprd(512, null, i6.ɵNgStyleImpl, i6.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i6.NgStyle, [i6.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(5, 4898816, [[1, 4]], 0, i5.NzRadioComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i7.FocusMonitor], { nzValue: [0, "nzValue"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "span", [["class", "display-value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_5)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.nzRadioSelectionStyles; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.id; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.tokens; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).checked; var currVal_1 = i1.ɵnov(_v, 5).nzDisabled; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SearchableMultiselectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_3)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "nz-radio-group", [], [[2, "ant-radio-group-large", null], [2, "ant-radio-group-small", null], [2, "ant-radio-group-solid", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.radioSelectionModel = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.selectionChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NzRadioGroupComponent_0, i3.RenderType_NzRadioGroupComponent)), i1.ɵdid(5, 1753088, null, 1, i5.NzRadioGroupComponent, [i1.ChangeDetectorRef, i1.Renderer2, i1.ElementRef], null, null), i1.ɵqud(603979776, 1, { radios: 1 }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.NzRadioGroupComponent]), i1.ɵdid(8, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(10, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SearchableMultiselectComponent_4)), i1.ɵdid(12, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasActiveFilter && (_co.shownItems.length === 0)); _ck(_v, 3, 0, currVal_0); var currVal_11 = _co.radioSelectionModel; _ck(_v, 8, 0, currVal_11); var currVal_12 = _co.shownItems; var currVal_13 = _co.trackById; _ck(_v, 12, 0, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 5).nzSize === "large"); var currVal_2 = (i1.ɵnov(_v, 5).nzSize === "small"); var currVal_3 = (i1.ɵnov(_v, 5).nzButtonStyle === "solid"); var currVal_4 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 10).ngClassValid; var currVal_9 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
function View_SearchableMultiselectComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["No results for '", "'"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter; _ck(_v, 1, 0, currVal_0); }); }
function View_SearchableMultiselectComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "token-wrapper"]], [[2, "bolded", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.highlight; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.token; _ck(_v, 1, 0, currVal_1); }); }
function View_SearchableMultiselectComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "control-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "label", [["nz-checkbox", ""]], [[2, "label-disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).hostClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NzCheckboxComponent_0, i8.RenderType_NzCheckboxComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.NzCheckboxComponent]), i1.ɵdid(3, 4964352, null, 0, i9.NzCheckboxComponent, [i1.ElementRef, i1.Renderer2, [2, i9.NzCheckboxWrapperComponent], i1.ChangeDetectorRef, i7.FocusMonitor], { nzValue: [0, "nzValue"], nzDisabled: [1, "nzDisabled"], nzChecked: [2, "nzChecked"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["class", "display-value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_9)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "span", [["class", "option-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["(", ")"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.id; var currVal_2 = ((_v.context.$implicit.count === 0) && !_co.isSelected(_v.context.$implicit.id)); var currVal_3 = _co.isSelected(_v.context.$implicit.id); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.tokens; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.count === 0) && !_co.isSelected(_v.context.$implicit.id)); _ck(_v, 1, 0, currVal_0); var currVal_5 = _v.context.$implicit.count; _ck(_v, 8, 0, currVal_5); }); }
function View_SearchableMultiselectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "nz-checkbox-wrapper", [], null, [[null, "nzOnChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nzOnChange" === en)) {
        var pd_0 = (_co.selectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NzCheckboxWrapperComponent_0, i8.RenderType_NzCheckboxWrapperComponent)), i1.ɵdid(1, 49152, null, 0, i9.NzCheckboxWrapperComponent, [i1.Renderer2, i1.ElementRef], null, { nzOnChange: "nzOnChange" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SearchableMultiselectComponent_7)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SearchableMultiselectComponent_8)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasActiveFilter && (_co.shownItems.length === 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.shownItems; var currVal_2 = _co.trackById; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_SearchableMultiselectComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "expand-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Show ", " more..."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.numberOfSearchResults - _co.maxCollapsedCount); _ck(_v, 2, 0, currVal_0); }); }
function View_SearchableMultiselectComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "collapse-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.collapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show less..."]))], null, null); }
export function View_SearchableMultiselectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "list-container"]], [[2, "expanded", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_6)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_10)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchableMultiselectComponent_11)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSearchInput; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.enableRadioSelection; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.enableRadioSelection; _ck(_v, 7, 0, currVal_3); var currVal_4 = ((!_co.expanded && ((((_co.items == null) ? null : _co.items.length) - ((_co.shownItems == null) ? null : _co.shownItems.length)) > 0)) && (((_co.shownItems == null) ? null : _co.shownItems.length) >= _co.maxCollapsedCount)); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.expanded; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.expanded; _ck(_v, 2, 0, currVal_1); }); }
export function View_SearchableMultiselectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-searchable-multiselect", [], null, null, null, View_SearchableMultiselectComponent_0, RenderType_SearchableMultiselectComponent)), i1.ɵdid(1, 638976, null, 0, i10.SearchableMultiselectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchableMultiselectComponentNgFactory = i1.ɵccf("leap-searchable-multiselect", i10.SearchableMultiselectComponent, View_SearchableMultiselectComponent_Host_0, { items: "items", selectedItems: "selectedItems", itemTemplate: "itemTemplate", enableRadioSelection: "enableRadioSelection", showSearchInput: "showSearchInput" }, { itemsStateChanged: "itemsStateChanged" }, []);
export { SearchableMultiselectComponentNgFactory as SearchableMultiselectComponentNgFactory };
