import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilService} from "../../../../snatch/services/form-util.service";
import { Address } from 'src/app/snatch/models/address.model';
import {Observable, Subject} from "rxjs";
import {AmberError} from "../../../../snatch/models/amber-response.model";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'leap-address-delete-modal',
  templateUrl: './address-delete-modal.component.html',
  styleUrls: ['./address-delete-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDeleteModalComponent implements OnInit, OnDestroy {

  @Input() success: boolean;
  @Input() pending: boolean;
  @Input() error: HttpErrorResponse = null;

  @Output() deleteAddress: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  private ngUnsubscribe = new Subject();

  constructor(

  ) {}

  ngOnInit() {
    this.error = null;
  }

  deleteAddressFn() {
    this.deleteAddress.emit()
  }

  closeModal() {
    this.cancel.emit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
