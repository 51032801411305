import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import {Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {ICatalog, ICatalogItem, ITopicItem} from "src/app/snatch/models/dictionary.model";
import {SelectCatalog, SelectSubtopic, SelectTopic} from "src/app/products/actions/products.actions";

@Component({
  selector: 'leap-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit,OnChanges, AfterViewInit {

  @Input() set visible(value: boolean) {
    this.menuState = value;
  }
  @Input() items: ICatalog;
  @Output() menuClosed = new EventEmitter();

  public menuState = false;
  public selectedCatalog: ICatalogItem;
  public selectedTopic = null;
  public selectedTopicIndex = 0;
  public subTopicsVisible = false;

  constructor(private store: Store) { }

  ngOnInit() {
    this.parseSvgIcons();
    let self = this;
    setInterval(function(){
      self.parseSvgIcons()
    }, 500)
  }

  ngOnChanges(changes: SimpleChanges){
    if('items' in changes && this.items && this.items.catalogs.length > 0){
      this.parseSvgIcons();
      if(!this.selectedCatalog){
        this.selectedCatalog = this.items.catalogs[0];
      }
    }
  }

  ngAfterViewInit(): void {
    this.parseSvgIcons();
  }

  public selectTopic(topic: string) {
    this.store.dispatch([new SelectTopic(topic)]);
    this.closeMenu();
  }

  public selectSubtopic(subTopic: string) {
    this.store.dispatch([new SelectSubtopic(subTopic)]);
    this.closeMenu();
  }

  public selectCatalog(catalog: string) {
    this.store.dispatch([new SelectCatalog([catalog])]);
    this.closeMenu();
  }

  public toggleCatalog(catalog: ICatalogItem) {
    if(this.selectedCatalog.code !== catalog.code){
      this.selectedCatalog = catalog;
      if(this.subTopicsVisible) {
        this.subTopicsVisible =  false;
        this.selectedTopicIndex = 0;
        this.selectedTopic = null;
      }
    }
  }

  public toggleSubTopics(topic?: ITopicItem) {
    if(topic) {
      if(!this.subTopicsVisible){
        const {code,title} = topic;
        this.subTopicsVisible =  true;
        this.selectedTopicIndex = this.items.topics.findIndex(i => i.code === code && i.parentCatalog === this.selectedCatalog.code);
        this.selectedTopic = {code,title};
      }
    } else  this.subTopicsVisible =  false;
  }

  public closeMenu(){
    this.menuState = false;
    this.menuClosed.emit();
  }

  public checkIfNoSubTopicsExist() {
    const findSubTopics = this.items ?
        !!this.items.subTopics
            .find(s => s.parentTopics.includes(this.selectedTopic.code) && s.parentCatalogs.includes(this.selectedCatalog.code))
        : false;
    return this.items ? !findSubTopics : false;
  }

  parseSvgIcons() {
    let svgContainers = document.getElementsByClassName('topic-icon');
    let svgCatalogsContainers = document.getElementsByClassName('menu-icon');
    // @ts-ignore
    for (let svgContainer of svgContainers) {
      // @ts-ignore
      for (let svg of svgContainer.children) {
        svg.style.width = 'auto';
        svg.style.height = '22px';
      }
    }

    // @ts-ignore
    for (let svgContainer of svgCatalogsContainers) {
      // @ts-ignore
      for (let svg of svgContainer.children) {
        svg.style.width = 'auto';
        svg.style.height = '22px';
      }
    }
  }
}
