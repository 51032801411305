<div
  class="modal-trigger-container"
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzTrigger="click"
  (click)="markAsSeen()"
>
  <ng-container *ngIf="latestReceivedMessage$ | async as latestReceivedMessage">
    <ng-container *ngIf="latestSeenMessage$ | async as latestSeenMessage">
      <div
        *ngIf="unseenMessages$ | async"
        class="new-messages-notification"
      ></div>
    </ng-container>
  </ng-container>
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 514 514"
    xml:space="preserve"
    y="0px"
    x="0px"
    id="Layer_1_1574852472151"
    width="28"
    height="28"
  >
    <g transform="translate(1, 1)">
      <style type="text/css">
        .st0_1574852472151 {
          fill: #394042;
        }
      </style>
      <path
        d="M448,0H64C28.7,0,0,28.7,0,64v288c0,35.3,28.7,64,64,64h96v84c0,7.1,5.8,12,12,12c2.4,0,4.9-0.7,7.1-2.4  L304,416h144c35.3,0,64-28.7,64-64V64C512,28.7,483.3,0,448,0z M480,352c0,17.6-14.4,32-32,32H293.3l-8.5,6.4L192,460v-76H64  c-17.6,0-32-14.4-32-32V64c0-17.6,14.4-32,32-32h384c17.6,0,32,14.4,32,32V352z M280,240H136c-4.4,0-8,3.6-8,8v16c0,4.4,3.6,8,8,8  h144c4.4,0,8-3.6,8-8v-16C288,243.6,284.4,240,280,240z M376,144H136c-4.4,0-8,3.6-8,8v16c0,4.4,3.6,8,8,8h240c4.4,0,8-3.6,8-8v-16  C384,147.6,380.4,144,376,144z"
        class="st0_1574852472151"
        vector-effect="non-scaling-stroke"
        style="fill: rgb(142, 143, 166);"
      />
    </g>
  </svg>
</div>
<nz-dropdown-menu
  #menu="nzDropdownMenu"
  nzTrigger="click"
  nzPlacement="bottomLeft"
>
  <ul
    class="vertical-menu"
    nz-menu
    [nzMode]="'inline'"
    style="border: none; width: 100%;"
  >
    <ng-container
      *ngIf="(conversationsLimited$ | async)?.length; else emptyInboxTemplate"
    >
      <li
        *ngFor="
          let conversation of conversationsLimited$ | async;
          let i = index
        "
        nz-menu-item
        [nzSelected]="false"
        (click)="redirectToConversation(conversation)"
      >
        <ng-container
          *ngTemplateOutlet="
            messageTemplate;
            context: {
              timestamp: conversation.channel.lastMessage?.createdAt,
              message: conversation.channel.lastMessage
                ? conversation.channel.lastMessage.context.message ||
                  'System Message'
                : '',
              name: conversation.recipient?.displayName,
              newMessages: conversation.unreadCount,
              status: conversation.channel.status,
              quoteCycle: conversation.channel.context.data?.quoteCycle,
              agreement: conversation.channel.context.data?.agreement,
              travelAndExpenses:
                conversation.channel.context.data?.travelAndExpenses,
              cancellation: conversation.channel.context.data?.cancellation,
              amIBuyer: compareMyDomain(
                conversation.channel.context.data?.buyer.name
              ),
              isMessageMine:
                conversation.channel.lastMessage?.sender?.domain === myDomain
            }
          "
        ></ng-container>
        <hr *ngIf="i != 2" />
      </li>
      <div class="open-inbox-wrapper">
        <a (click)="redirectToInbox()">Open Inbox</a>
      </div>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<ng-template
  #messageTemplate
  let-timestamp="timestamp"
  let-message="message"
  let-isMessageMine="isMessageMine"
  let-name="name"
  let-newMessages="newMessages"
  let-amIBuyer="amIBuyer"
  let-status="status"
  let-quoteCycle="quoteCycle"
  let-agreement="agreement"
  let-travelAndExpenses="travelAndExpenses"
  let-cancellation="cancellation"
>
  <div class="message-container">
    <div class="top-container">
      <div style="display: flex;">
        <div style="display: flex;">
          <div class="image-placeholder"></div>
          <h5>{{ name }}</h5>
        </div>
      </div>
      <span class="timestamp">{{
        timestamp | date: "dd/MM/yyyy hh:mm a"
      }}</span>
    </div>
    <div class="content-container">
      <span
        class="last-message"
        [ngClass]="{ unread: newMessages, 'no-last-message': !message }"
        ><span class="you-span" *ngIf="message && isMessageMine">You:</span>
        {{ message || "New conversation" | ellipsis: [70] }}</span
      >
      <nz-badge
        *ngIf="newMessages"
        [nzStyle]="{
          marginBottom: '-4px',
          backgroundColor: '#FA4616',
          borderRadius: '4px',
          fontSize: '12px',
          paddingLeft: '4px',
          paddingRight: '4px',
          height: '18px'
        }"
        [nzCount]="newMessages"
      ></nz-badge>
    </div>
    <div class="bottom-container" [ngSwitch]="status">
      <ng-container *ngSwitchCase="'waiting-quote'">
        <span *ngIf="amIBuyer">Waiting Quote from Supplier</span>
        <span *ngIf="!amIBuyer" class="leap-yellow">Waiting for Quote</span>
      </ng-container>
      <ng-container *ngSwitchCase="'waiting-accept'">
        <span *ngIf="amIBuyer" class="leap-yellow"
          >Quote expires on
          {{ quoteCycle?.expiresAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
        <span *ngIf="!amIBuyer"
          >Quote expires on
          {{ quoteCycle?.expiresAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <ng-container *ngSwitchCase="'waiting-pay'">
        <span *ngIf="amIBuyer" class="leap-yellow"
          >Confirmation required until
          {{ agreement?.payment?.expiresAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
        <span *ngIf="!amIBuyer"
          >Waiting for buyer confirmation until
          {{ agreement?.payment?.expiresAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <ng-container
        *ngIf="
          status === 'tne-in-progress' || status === 'cancellation-in-progress'
        "
      >
        <ng-container
          *ngIf="travelAndExpenses?.status.configKey === 'waiting-submit'"
        >
          <span *ngIf="amIBuyer"
            >Waiting for Supplier's T&E until
            {{
              travelAndExpenses?.submitExpiresAt | date: "dd/MM/yyyy hh:mm a"
            }}</span
          >
          <span *ngIf="!amIBuyer" class="leap-yellow"
            >T&E required until
            {{
              travelAndExpenses?.submitExpiresAt | date: "dd/MM/yyyy hh:mm a"
            }}</span
          >
        </ng-container>
        <ng-container
          *ngIf="travelAndExpenses?.status.configKey === 'disputed'"
        >
          <span>Dispute started on DD Mon, YYYY XX:XX</span>
        </ng-container>
        <ng-container
          *ngIf="travelAndExpenses?.status.configKey === 'waiting-accept'"
        >
          <span *ngIf="amIBuyer" class="leap-yellow"
            >T&E approval required until
            {{
              travelAndExpenses?.approveExpiresAt | date: "dd/MM/yyyy hh:mm a"
            }}</span
          >
          <span *ngIf="!amIBuyer"
            >Waiting for T&E approval until
            {{
              travelAndExpenses?.approveExpiresAt | date: "dd/MM/yyyy hh:mm a"
            }}</span
          >
        </ng-container>
        <ng-container
          *ngIf="travelAndExpenses?.status.configKey === 'waiting-payment'"
        >
          <span *ngIf="amIBuyer" class="leap-yellow"
            >T&E confirmation required until
            {{
              travelAndExpenses?.payment?.expiresAt | date: "dd/MM/yyyy hh:mm a"
            }}</span
          >
          <span *ngIf="!amIBuyer"
            >Waiting for T&E confirmation until
            {{
              travelAndExpenses?.payment?.expiresAt | date: "dd/MM/yyyy hh:mm a"
            }}</span
          >
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'waiting-delivery'">
        <span
          >Delivery expected on
          {{ agreement?.deliveryStartsAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <ng-container *ngSwitchCase="'waiting-event-details'">
        <span
          >Delivery expected on
          {{ agreement?.deliveryStartsAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <ng-container *ngSwitchCase="'expired'">
        <span class="leap-grey"
          >Closed on
          {{ quoteCycle?.expiredAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <!-- Cancellation Request Sent -->
      <ng-container
        *ngIf="
          status === 'cancellation-in-progress' &&
          cancellation?.status.configKey === 'pending-platform-approval'
        "
      >
        <span
          >Cancellation request sent on
          {{ cancellation?.startedAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <!-- In Delivery -->
      <ng-container *ngSwitchCase="'in-delivery'">
        <span
          >Delivery started on
          {{ agreement?.deliveryStartsAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
      <!-- Completed -->
      <ng-container *ngSwitchCase="'completed'">
        <span
          >Completed on
          {{ quoteCycle?.completedAt | date: "dd/MM/yyyy hh:mm a" }}</span
        >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #emptyInboxTemplate>
  <div class="empty-container">
    <nz-empty [nzNotFoundContent]="'Inbox is empty'"></nz-empty>
  </div>
</ng-template>
