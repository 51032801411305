import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { PromotionCode } from 'src/app/common/common-shopping-cart/models/promotion-code.model';

@Component({
  selector: '[leap-promotion-code-row-invalid]',
  templateUrl: './promotion-code-row-invalid.component.html',
  styleUrls: ['./promotion-code-row-invalid.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionCodeRowInvalidComponent implements OnInit {

  @Input() freeze: boolean;
  @Input() promotionCode: PromotionCode;
  @Input() currencySignsMap: Map<string, string>;
  @Output() remove: EventEmitter<string> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  removeFn() {
    this.remove.emit(this.promotionCode.code);
  }

}
