import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'leap-seller-image-and-name',
  templateUrl: './seller-image-and-name.component.html',
  styleUrls: ['./seller-image-and-name.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SellerImageAndNameComponent implements OnInit {

  @Input() size: 'regular' | 'small' = 'regular';
  @Input() imageUrl: string;
  @Input() name: string;
  @Input() domain: string;

  constructor() { }

  ngOnInit() { }
}
