import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { InboxState } from './state/inbox.state';
import { InboxComponent } from './containers/inbox/inbox.component';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { ConversationTileComponent } from './components/conversation-tile/conversation-tile.component';
import { SnatchModule } from '../snatch/snatch.module';
import { ConversationListComponent } from './containers/conversation-list/conversation-list.component';
import { CommonUtilsModule } from '../common/common-utils/common-utils.module';
import { ConversationComponent } from './containers/conversation/conversation.component';
import { ComposeMessageComponent } from './components/compose-message/compose-message.component';
import { MessageComponent } from './components/message/message.component';
import { MessageSystemComponent } from './components/message-system/message-system.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollContainerDirective } from './directives/scroll-container.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MessageWithContextComponent } from './components/message-with-context/message-with-context.component';
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [InboxComponent, FilterMenuComponent, ConversationTileComponent, ConversationListComponent, ConversationComponent, ComposeMessageComponent, MessageComponent, MessageSystemComponent, ScrollContainerDirective, MessageWithContextComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([InboxState]),
    NgxsFormPluginModule,
    InfiniteScrollModule,
    SnatchModule,
    CommonUtilsModule
  ]
})
export class InboxModule { }
