<div class="user-component-container">
  <div class="user-thumbnail" nz-dropdown [nzDropdownMenu]="menu">
    <ng-container *ngTemplateOutlet="profilePhotoTemplate"></ng-container>
  </div>
  <nz-dropdown-menu
    #menu="nzDropdownMenu"
    nzTrigger="click"
    nzPlacement="bottomRight"
  >
    <ul class="user-component-menu" nz-menu>
      <li class="user-details no-effect-on-hover" nz-menu-item>
        <div class="user-thumbnail">
          <ng-container *ngTemplateOutlet="profilePhotoTemplate"></ng-container>
        </div>
        <div class="user-detail-text">
          <div
            class="username"
            *ngIf="
              (profile$ | async)?.displayName ||
              (profile$ | async)?.employee?.firstName
            "
          >
            {{
              (profile$ | async)?.displayName ||
                (profile$ | async)?.employee?.firstName +
                  " " +
                  (profile$ | async)?.employee?.lastName
            }}
          </div>
          <div
            class="username"
            *ngIf="
              !(profile$ | async)?.displayName &&
              !(profile$ | async)?.employee?.firstName
            "
          >
            Hello There
          </div>
          <div class="email">
            {{ (profile$ | async)?.employee?.email }}
          </div>
          <div class="company" *ngIf="false">
            <div class="company-logo">
              <nz-avatar
                nzSize="small"
                nzIcon="anticon anticon-user"
                nzSrc=""
              ></nz-avatar>
            </div>
            <div class="company-name">Leapest NL</div>
          </div>
        </div>
      </li>

      <li nz-menu-divider></li>

      <li nz-menu-item *ngIf="menuInit" class="user-routes no-effect-on-hover">
        <div class="section-left">
          <div class="user-route-sections">
            <div class="section-title">
              {{ userMenuConfig?.productsAndCourses?.title }}
            </div>
            <div *ngFor="let item of userMenuConfig?.productsAndCourses?.items">
              <leap-user-menu-item
                [title]="item.title"
                [svgTemplate]="item.svgTemplate"
                [hoverClass]="item.hoverClass"
                (click)="item.onClickCB(item)"
                *leapHasGroup="item.groups"
              >
              </leap-user-menu-item>
            </div>
          </div>

          <div
            class="user-route-sections"
            *leapHasGroup="userMenuConfig?.instructorServices?.groups"
          >
            <div class="section-title">
              {{ userMenuConfig?.instructorServices?.title }}
            </div>
            <div *ngFor="let item of userMenuConfig?.instructorServices?.items">
              <leap-user-menu-item
                [title]="item.title"
                [svgTemplate]="item.svgTemplate"
                [hoverClass]="item.hoverClass"
                (click)="item.onClickCB(item)"
                *leapHasGroup="item.groups"
              >
              </leap-user-menu-item>
            </div>
          </div>
          <div class="user-route-sections" *ngIf="vmsEnabled">
            <div class="section-title">
              {{ userMenuConfig?.vendorManagement?.title }}
            </div>
            <div *ngFor="let item of userMenuConfig?.vendorManagement?.items">
              <leap-user-menu-item
                [title]="item.title"
                [svgTemplate]="item.svgTemplate"
                [hoverClass]="item.hoverClass"
                (click)="item.onClickCB(item)"
                *leapHasGroup="item.groups"
              >
              </leap-user-menu-item>
            </div>
          </div>
        </div>
        <nz-divider class="divider-inbetween" nzType="vertical"></nz-divider>
        <div class="section-right">
          <div class="user-route-sections">
            <div class="section-title">
              {{ userMenuConfig?.generalLeapestSettings?.title }}
            </div>
            <div
              *ngFor="let item of userMenuConfig?.generalLeapestSettings?.items"
            >
              <leap-user-menu-item
                [title]="item.title"
                [svgTemplate]="item.svgTemplate"
                [hoverClass]="item.hoverClass"
                (click)="item.onClickCB(item)"
                *leapHasGroup="item.groups"
              >
              </leap-user-menu-item>
            </div>
            <div *ngIf="integrationEnabled">
              <leap-user-menu-item
                [title]="'Integrations & Data'"
                [svgTemplate]="svgIconMap['integrationFramework']"
                [hoverClass]="'hover-leap-green-paste'"
                (click)="integrationRedirect()"
              >
              </leap-user-menu-item>
            </div>
          </div>
        </div>
      </li>

      <li nz-menu-divider *ngIf="hasAlternativeAccounts"></li>
      <li
        nz-menu-item
        *ngIf="hasAlternativeAccounts"
        class="sign-out-container"
      >
        <leap-user-menu-item
          title="Switch Account"
          [stickToRightEdge]="false"
          [hoverClass]="'hover-leap-green-paste'"
          (click)="switchAccount.emit()"
        >
        </leap-user-menu-item>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item class="sign-out-container">
        <leap-user-menu-item
          title="Sign out"
          [stickToRightEdge]="false"
          [hoverClass]="'hover-leap-orance'"
          (click)="logout.emit()"
        >
        </leap-user-menu-item>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>

<ng-template #profilePhotoTemplate>
  <nz-avatar
    *ngIf="!profilePhotoUrl"
    nzSize="large"
    nzIcon="anticon anticon-user"
    [nzSrc]="defaultProfilePhotoUrl"
  ></nz-avatar>
  <nz-avatar
    *ngIf="profilePhotoUrl"
    nzSize="large"
    nzIcon="anticon anticon-user"
    [nzSrc]="profilePhotoUrl"
  ></nz-avatar>
</ng-template>

<div #allSvgContainer style="display: none;">
  <svg
    name="integrationFramework"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 386 514"
    id="integration-icon"
    width="16"
    height="22"
  >
    <g transform="translate(1, 1)">
      <path
        d="M136 320h-16c-4.4 0-8 3.6-8 8v96c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-96c0-4.4-3.6-8-8-8zm64-96h-16c-4.4 0-8 3.6-8 8v192c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V232c0-4.4-3.6-8-8-8zm40 72v128c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8zM369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01z"
        vector-effect="non-scaling-stroke"
        style="fill: rgb(142, 143, 166);"
      />
    </g>
  </svg>

  <svg
    name="transactionRecording"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 546 514"
    id="Layer_1"
    width="20"
    height="19"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1 {
            fill: #fa4616;
          }
          .cls-2 {
            fill: #8e8fa6;
          }
        </style>
      </defs>
      <path
        d="M527.79,288H290.5l158,158a16.53,16.53,0,0,0,11.62,4.81,15.31,15.31,0,0,0,10.57-4.13,239.5,239.5,0,0,0,73.13-140.86C545.19,296.39,537.34,288,527.79,288ZM459.88,412.12,367.76,320H508.64A208.82,208.82,0,0,1,459.88,412.12Z"
        class="cls-1"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M379.87,443.87,224,288V50.71c0-8.83-7.18-16.21-15.74-16.21a15.19,15.19,0,0,0-2.11.15C87,51.49-4.1,155.6.14,280.37,4.47,407.53,113.18,512,240.13,512l2.91,0A238.14,238.14,0,0,0,378.3,468C386.2,462.35,386.72,450.72,379.87,443.87ZM242.63,480l-2.51,0c-109,0-204.3-91.92-208-200.72A207.43,207.43,0,0,1,192,69.83V301.25l9.37,9.37L343.21,452.46A205.77,205.77,0,0,1,242.63,480Z"
        class="cls-2"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M512,223.2C503.72,103.74,408.26,8.28,288.8,0c-.35,0-.7,0-1,0C279.1,0,272,7.45,272,16.23V240H495.77C504.91,240,512.59,232.32,512,223.2ZM304,208V33.9C393.25,47.71,464.28,118.75,478.1,208Z"
        class="cls-1"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="mySellerFeed"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562846380412"
    width="22"
    height="22"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562846380412 {
            fill: #8e8fa6;
          }
          .cls-2_1562846380412 {
            fill: #fa4616;
          }
        </style>
      </defs>
      <path
        d="M34,5.79H6a4,4,0,0,0-4,4V32a4,4,0,0,0,4,4H34a4,4,0,0,0,4-4V9.79A4,4,0,0,0,34,5.79ZM36,32a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V13.79H36Zm0-20.22H4v-2a2,2,0,0,1,2-2H34a2,2,0,0,1,2,2Z"
        class="cls-1_1562846380412"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M9.34,21.61a2.79,2.79,0,1,0-2.79-2.79A2.79,2.79,0,0,0,9.34,21.61ZM8,18.82a1.37,1.37,0,1,1,1.37,1.37A1.36,1.36,0,0,1,8,18.82Z"
        class="cls-2_1562846380412"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M16.71,23H31.77a1.9,1.9,0,0,0,1.9-1.9V17.92a1.9,1.9,0,0,0-1.9-1.9H16.71a1.91,1.91,0,0,0-1.91,1.9V21.1A1.91,1.91,0,0,0,16.71,23Zm.15-4.93H31.62v2.87H16.86Z"
        class="cls-2_1562846380412"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M9.34,30.54a2.79,2.79,0,1,0-2.79-2.78A2.79,2.79,0,0,0,9.34,30.54ZM8,27.76a1.37,1.37,0,1,1,1.37,1.37A1.37,1.37,0,0,1,8,27.76Z"
        class="cls-2_1562846380412"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M16.71,32H31.77a1.91,1.91,0,0,0,1.9-1.91V26.86A1.91,1.91,0,0,0,31.77,25H16.71a1.92,1.92,0,0,0-1.91,1.91v3.27A1.92,1.92,0,0,0,16.71,32Zm.15-5H31.62v3H16.86Z"
        class="cls-2_1562846380412"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="orderTemplates"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562846380414"
    width="22"
    height="22"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562846380414 {
            fill: #8e8fa6;
          }
          .cls-2_1562846380414 {
            fill: #fa4616;
          }
        </style>
      </defs>
      <path
        d="M32.45,11.64H18.18a.62.62,0,0,0-.61.61v1.23a.62.62,0,0,0,.61.62H32.45a.62.62,0,0,0,.62-.62V12.25A.62.62,0,0,0,32.45,11.64Z"
        class="cls-1_1562846380414"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M32.45,26.08H18.18a.62.62,0,0,0-.61.62v1.22a.62.62,0,0,0,.61.62H32.45a.62.62,0,0,0,.62-.62V26.7A.62.62,0,0,0,32.45,26.08Z"
        class="cls-1_1562846380414"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M7.85,15.39l3.86-3.82a.32.32,0,0,0,0-.46L11,10.34a.33.33,0,0,0-.45,0L7.63,13.18,6.39,11.93a.31.31,0,0,0-.45,0l-.77.75a.34.34,0,0,0,0,.46L7.4,15.39A.31.31,0,0,0,7.85,15.39Z"
        class="cls-2_1562846380414"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M7.84,29.86,11.74,26a.32.32,0,0,0,0-.46L11,24.76a.32.32,0,0,0-.46,0L7.63,27.63,6.37,26.36a.32.32,0,0,0-.46,0l-.77.76a.33.33,0,0,0,0,.47L7.4,29.86A.31.31,0,0,0,7.84,29.86Z"
        class="cls-2_1562846380414"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M34.32,4.25H5.07A3.37,3.37,0,0,0,1.69,7.62V32.37a3.38,3.38,0,0,0,3.38,3.38H34.32a3.37,3.37,0,0,0,3.37-3.38V7.62A3.37,3.37,0,0,0,34.32,4.25ZM12.94,33.5H5.07a1.13,1.13,0,0,1-1.13-1.13V21.12h9Zm0-14.63h-9V7.62A1.13,1.13,0,0,1,5.07,6.5h7.87Zm22.5,13.5a1.12,1.12,0,0,1-1.12,1.13H15.19V21.12H35.44Zm0-13.5H15.19V6.5H34.32a1.12,1.12,0,0,1,1.12,1.12Z"
        class="cls-1_1562846380414"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="productOrders"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562846380413"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562846380413 {
            fill: #8e8fa6;
          }
          .cls-2_1562846380413 {
            fill: #fa4616;
          }
        </style>
      </defs>
      <path
        d="M29.26,6.5H23a5.09,5.09,0,0,0,0-.56,3.94,3.94,0,0,0-7.88,0,3.43,3.43,0,0,0,.05.56H9A3.37,3.37,0,0,0,5.63,9.87V34.62A3.38,3.38,0,0,0,9,38H29.26a3.37,3.37,0,0,0,3.37-3.38V9.87A3.37,3.37,0,0,0,29.26,6.5ZM19.13,4.25a1.69,1.69,0,1,1-1.69,1.69A1.68,1.68,0,0,1,19.13,4.25ZM30.38,34.62a1.13,1.13,0,0,1-1.12,1.13H9a1.14,1.14,0,0,1-1.13-1.13V9.87A1.13,1.13,0,0,1,9,8.75h3.37v1.41a.85.85,0,0,0,.84.84H25a.85.85,0,0,0,.84-.84V8.75h3.38a1.13,1.13,0,0,1,1.12,1.12Z"
        class="cls-1_1562846380413"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M26.24,18A.85.85,0,0,0,25,18l-8.27,8.21-3.55-3.56a.84.84,0,0,0-1.19,0l-.6.6a.84.84,0,0,0,0,1.19l4.74,4.76a.85.85,0,0,0,1.2,0l9.47-9.4a.86.86,0,0,0,0-1.2l-.61-.6Z"
        class="cls-2_1562846380413"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="productCatalogSettings"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    xml:space="preserve"
    y="0px"
    x="0px"
    id="Layer_1_1562846380412"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <style type="text/css">
        .st0_1562846380412 {
          fill: #fa4616;
        }
        .st1_1562846380412 {
          fill: #8e8fa6;
        }
      </style>
      <g>
        <path
          d="M39.3,29.5l-0.9-0.5c0-0.5,0-1,0-1.5l0.9-0.6c0.6-0.3,0.8-1,0.6-1.6c-0.4-1.4-1.2-2.7-2.2-3.8   c-0.4-0.5-1.1-0.6-1.7-0.3l-0.9,0.6c-0.4-0.3-0.8-0.5-1.3-0.7V20c0-0.6-0.4-1.2-1.1-1.3c-1.4-0.3-2.9-0.3-4.3,0   c-0.6,0.1-1.1,0.7-1.1,1.3v1.1c-0.5,0.2-0.9,0.5-1.3,0.7l-0.9-0.6c-0.6-0.3-1.3-0.2-1.7,0.3c-1,1.1-1.7,2.4-2.2,3.8   c-0.2,0.6,0.1,1.3,0.6,1.6l0.9,0.6c0,0.5,0,1,0,1.5l-0.9,0.5c-0.6,0.3-0.8,1-0.6,1.6c0.4,1.4,1.2,2.7,2.2,3.8   c0.4,0.5,1.1,0.6,1.7,0.3l0.9-0.6c0.4,0.3,0.8,0.6,1.3,0.8v1.1c0,0.6,0.4,1.2,1.1,1.3c1.4,0.3,2.9,0.3,4.3,0   c0.6-0.1,1.1-0.7,1.1-1.3v-1.2c0.5-0.2,0.9-0.4,1.3-0.7l0.9,0.6c0.6,0.3,1.3,0.2,1.7-0.3c1-1.1,1.7-2.4,2.2-3.8   C40.1,30.5,39.9,29.8,39.3,29.5z M36.2,29.9l1.7,1c-0.3,0.8-0.8,1.6-1.3,2.3l-1.7-1c-0.8,0.8-1.9,1.4-3,1.7v2   c-0.9,0.1-1.8,0.1-2.7,0v-2c-1.1-0.3-2.2-0.9-3-1.7l-1.7,1c-0.6-0.7-1-1.5-1.3-2.3l1.8-1c-0.3-1.1-0.3-2.3,0-3.5l-1.7-1   c0.3-0.8,0.8-1.6,1.3-2.3l1.7,1c0.8-0.8,1.9-1.4,3-1.7v-2c0.9-0.1,1.8-0.1,2.7,0v2c1.1,0.3,2.2,0.9,3,1.7l1.7-1   c0.6,0.7,1,1.5,1.3,2.3l-1.8,1C36.5,27.6,36.5,28.8,36.2,29.9z"
          class="st0_1562846380412"
          vector-effect="non-scaling-stroke"
        />
        <path
          d="M30.6,24.4c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S32.7,24.4,30.6,24.4L30.6,24.4z    M30.6,30.1c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9c1,0,1.9,0.8,1.9,1.9c0,0,0,0,0,0C32.5,29.2,31.6,30.1,30.6,30.1z"
          class="st0_1562846380412"
          vector-effect="non-scaling-stroke"
        />
        <path
          d="M32.4,8.9c-0.1-0.2-0.2-0.4-0.3-0.5l-3.4-5.5C28.4,2.3,27.8,2,27.2,2H5.8C5.1,2,4.5,2.3,4.2,2.8L0.8,8.3   c-1.5,2.3-0.8,5.3,1.5,6.8c0.2,0.1,0.3,0.2,0.5,0.3v12c0,1,0.8,1.9,1.8,2h15.1l0-0.1c0-0.3,0-0.7,0-1.1v-0.8H4.7c0,0,0,0,0-0.1   v-6.4h17.7l0,0c0.6-0.6,1.2-1.2,1.9-1.7l0.3-0.2h-20v-3.1h0c1.5,0,2.9-0.6,3.9-1.7c2,2.1,5.3,2.3,7.5,0.3c0.1-0.1,0.2-0.2,0.3-0.3   c2,2.1,5.3,2.3,7.5,0.3c0.1-0.1,0.2-0.2,0.3-0.3c1,1.1,2.4,1.7,3.9,1.7l0,1.7l0.1,0c0.6-0.1,1.1-0.1,1.7-0.1l0.1,0v-1.9   c1.2-0.6,2-1.5,2.5-2.8C33.1,11.4,33,10.1,32.4,8.9z M28.2,13.8C28.2,13.8,28.2,13.8,28.2,13.8c-1,0-1.9-0.4-2.6-1.2l-1.4-1.5   L23,12.7c-0.6,0.7-1.4,1.1-2.4,1.1c-0.9,0-1.8-0.3-2.5-0.9c-0.1-0.1-0.2-0.2-0.3-0.3l-1.4-1.5l-1.4,1.5c-0.6,0.7-1.4,1.1-2.4,1.1   c-0.9,0-1.8-0.3-2.5-0.9c-0.1-0.1-0.2-0.2-0.3-0.3l-1.4-1.5l-1.3,1.5c-0.6,0.7-1.6,1.1-2.5,1.1c0,0,0,0,0,0c0,0,0,0,0,0   c-0.2,0-0.3,0-0.5,0c-0.9-0.1-1.6-0.7-2-1.5c-0.4-1-0.4-2.1,0.2-3l3.4-5.4h21.3l3.4,5.4c0.6,0.9,0.6,2,0.2,2.9   c-0.3,0.8-1.1,1.4-2,1.5C28.5,13.8,28.4,13.8,28.2,13.8z"
          class="st1_1562846380412"
          vector-effect="non-scaling-stroke"
        />
      </g>
    </g>
  </svg>

  <svg
    name="favourites"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562918246208"
    width="22"
    height="22"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562918246208 {
            fill: #fa4616;
          }
          .cls-2_1562918246208 {
            fill: #8e8fa6;
          }
        </style>
      </defs>
      <path
        d="M27.83,21.37a1.88,1.88,0,0,0-1.54-1.3l-3.16-.46-1.41-2.87A1.93,1.93,0,0,0,20,15.67a1.9,1.9,0,0,0-1.71,1.06L16.88,19.6l-3.16.47a1.9,1.9,0,0,0-1.06,3.25l2.28,2.24-.54,3.15a1.91,1.91,0,0,0,.76,1.87,2,2,0,0,0,1.12.37,1.89,1.89,0,0,0,.89-.22L20,29.24l2.83,1.49a1.91,1.91,0,0,0,2.77-2l-.53-3.15,2.28-2.23A1.88,1.88,0,0,0,27.83,21.37Zm-5.12,3.42.64,3.74L20,26.77l-3.36,1.77.64-3.74-2.71-2.65,3.75-.55L20,18.19l1.68,3.41,3.75.54Z"
        class="cls-1_1562918246208"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M34.63,4.41H5.38A3.38,3.38,0,0,0,2,7.79V32.54a3.37,3.37,0,0,0,3.37,3.37H34.63A3.38,3.38,0,0,0,38,32.54V7.79A3.39,3.39,0,0,0,34.63,4.41ZM4.26,7.79A1.13,1.13,0,0,1,5.38,6.66H8.76v4.5H4.26Zm31.5,24.75a1.13,1.13,0,0,1-1.13,1.12H5.38a1.12,1.12,0,0,1-1.12-1.12V13.41h31.5Zm0-21.38H11V6.66H34.63a1.14,1.14,0,0,1,1.13,1.13Z"
        class="cls-2_1562918246208"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="editInstructorProfile"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562846380408"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562846380408 {
            fill: #fa4616;
          }
          .cls-2_1562846380408 {
            fill: #8e8fa6;
          }
        </style>
      </defs>
      <path
        d="M38.43,21.21l-2.06-2.06A1.9,1.9,0,0,0,35,18.6a1.94,1.94,0,0,0-1.36.55L20.46,32.36l-.45,4a1.06,1.06,0,0,0,1,1.17h.12l4.05-.45,13.2-13.21A1.93,1.93,0,0,0,38.43,21.21ZM24.35,35.31,22,35.58l.26-2.35,9.31-9.31L33.66,26ZM35,24.66l-2.08-2.08L35,20.5h0l2.06,2.08Z"
        class="cls-1_1562846380408"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M9,26.19c1.16,0,2.32.95,5.31.95s4.16-.95,5.32-.95a6,6,0,0,1,3.32,1l1.36-1.35a7.93,7.93,0,0,0-4.68-1.54c-1.7,0-2.52.95-5.32.95S10.7,24.3,9,24.3a8,8,0,0,0-8,8v2.46a2.85,2.85,0,0,0,2.85,2.85H18.31a3,3,0,0,1-.19-1.39l.06-.51H3.86a1,1,0,0,1-.95-1V32.27A6.08,6.08,0,0,1,9,26.19Z"
        class="cls-2_1562846380408"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M3.66,17a1,1,0,0,0,1-1v-1a9.68,9.68,0,1,1,19.35,0v1a4.84,4.84,0,0,1-4.84,4.84H17.21a1.93,1.93,0,0,0-1.94-1.93H13.34a1.94,1.94,0,1,0,0,3.87h5.8A6.8,6.8,0,0,0,25.92,16v-1a11.62,11.62,0,1,0-23.23,0v1A1,1,0,0,0,3.66,17Z"
        class="cls-2_1562846380408"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M14.3,9.26a5.78,5.78,0,0,1,4.31,9.66s0,0,0,0h.52a2.93,2.93,0,0,0,2.46-1.37,8,8,0,0,0,.45-2.5,7.75,7.75,0,1,0-12.56,6,1.49,1.49,0,0,1,0-.21A3.75,3.75,0,0,1,10,18.94,5.78,5.78,0,0,1,14.3,9.26Z"
        class="cls-2_1562846380408"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="instructorOrders"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    xml:space="preserve"
    y="0px"
    x="0px"
    id="Layer_1_1562846380411"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <style type="text/css">
        .st0_1562846380411 {
          fill: #8e8fa6;
        }
        .st1_1562846380411 {
          fill: #fa4616;
        }
      </style>
      <g>
        <path
          d="M9.9,37.9h20.2c1.8,0,3.3-1.5,3.3-3.3V9.9c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.4-1-2.3-1h-6.4l0-0.1   c0-0.2,0-0.4,0-0.5c0-2.1-1.7-3.8-3.8-3.8c0,0,0,0,0,0c-2.1,0-3.8,1.7-3.8,3.8c0,0.2,0,0.4,0,0.6l0,0.1H9.9c0,0,0,0,0,0   c-0.9,0-1.7,0.3-2.3,0.9C7,8.2,6.7,9,6.7,9.8l-0.1,0l0.1,0v24.8C6.7,36.4,8.1,37.9,9.9,37.9z M20,4.1L20,4.1c1,0,1.8,0.8,1.8,1.8   s-0.8,1.8-1.8,1.8S18.2,7,18.2,6C18.2,5,19,4.1,20,4.1z M8.7,9.9c0-0.7,0.6-1.2,1.3-1.2h3.5v1.5c0,0.4,0.3,0.7,0.7,0.7H26   c0.4,0,0.7-0.3,0.7-0.7V8.6h3.5c0.7,0,1.2,0.6,1.3,1.2v24.8c0,0.7-0.6,1.2-1.3,1.3H9.9c-0.7,0-1.2-0.6-1.3-1.3V9.9z"
          class="st0_1562846380411"
          vector-effect="non-scaling-stroke"
        />
        <path
          d="M13.6,22.4c0.4,0,0.7-0.3,0.7-0.7v-0.6c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8v0.6c0,1.5-1.2,2.8-2.8,2.8H22   c-0.1-0.7-0.6-1.2-1.3-1.2h-1.2c-0.7,0.1-1.3,0.7-1.2,1.4c0.1,0.6,0.6,1.2,1.2,1.2h3.6c2.4,0,4.3-1.9,4.3-4.3v-0.6   c0-4-3.2-7.2-7.2-7.2s-7.2,3.2-7.2,7.2l0,0v0.6C12.9,22.1,13.2,22.4,13.6,22.4z"
          class="st1_1562846380411"
          vector-effect="non-scaling-stroke"
        />
        <path
          d="M23.6,26.8h-0.2c-0.5,0-0.9,0.1-1.4,0.3c-1.3,0.4-2.7,0.4-4,0c-0.4-0.2-0.9-0.3-1.4-0.3h-0.2   c-2.7,0-4.9,2.2-4.9,4.9c0,0.7,0.6,1.3,1.3,1.3h14.2c0.7,0,1.3-0.6,1.3-1.3C28.5,29,26.3,26.8,23.6,26.8z M23.4,28.3h0.2   c1.8,0,3.4,1.4,3.5,3.3H13.1c0.1-1.8,1.6-3.3,3.4-3.3h0.2c0.3,0,0.6,0.1,0.9,0.2c1.6,0.6,3.4,0.6,5,0   C22.8,28.4,23.1,28.3,23.4,28.3z"
          class="st1_1562846380411"
          vector-effect="non-scaling-stroke"
        />
        <path
          d="M20.1,17.7L20.1,17.7c1.9,0,3.4,1.5,3.4,3.4c0,0.8-0.3,1.7-0.9,2.3l0,0v0.2l0.1,0.1h0.4c0.7,0,1.3-0.4,1.6-0.9   c0.9-2.5-0.5-5.3-3-6.2s-5.3,0.5-6.2,3c-0.7,2,0,4.1,1.6,5.4l0.2,0.2v-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.4,0.1-0.8,0.2-1.1   l0.1-0.1l-0.1-0.1c-1.3-1.4-1.1-3.5,0.2-4.8C18.4,18,19.2,17.7,20.1,17.7z"
          class="st1_1562846380411"
          vector-effect="non-scaling-stroke"
        />
      </g>
    </g>
  </svg>

  <svg
    name="instructorServiceSettings"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    xml:space="preserve"
    y="0px"
    x="0px"
    id="Layer_1_1562846380410"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <style type="text/css">
        .st0_1562846380410 {
          fill: #fa4616;
        }
        .st1_1562846380410 {
          fill: #8e8fa6;
        }
      </style>
      <g>
        <path
          d="M38.5,28.8l-0.9-0.6c0-0.5,0-1,0-1.5l0.9-0.6c0.6-0.3,0.8-1,0.6-1.6c-0.4-1.4-1.2-2.7-2.2-3.8   c-0.4-0.5-1.1-0.6-1.7-0.3l-1,0.6c-0.4-0.3-0.8-0.5-1.3-0.8v-1.1c0-0.6-0.4-1.2-1.1-1.3c-1.4-0.3-2.9-0.3-4.3,0   c-0.6,0.1-1.1,0.7-1.1,1.3v1.1c-0.5,0.2-0.9,0.5-1.3,0.8l-0.9-0.6c-0.6-0.3-1.3-0.2-1.7,0.3c-1,1.1-1.7,2.4-2.1,3.8   c-0.2,0.6,0.1,1.3,0.6,1.6l0.9,0.6c0,0.5,0,1,0,1.5L21,28.8c-0.6,0.3-0.8,1-0.6,1.6c0.4,1.4,1.2,2.7,2.1,3.8   c0.4,0.5,1.1,0.6,1.7,0.3l0.9-0.6c0.4,0.3,0.8,0.6,1.3,0.8v1.1c0,0.6,0.4,1.2,1.1,1.3c1.4,0.3,2.9,0.3,4.3,0   c0.6-0.1,1.1-0.7,1.1-1.3v-1.2c0.5-0.2,0.9-0.5,1.3-0.8l1,0.6c0.6,0.3,1.3,0.2,1.7-0.3c1-1.1,1.7-2.4,2.2-3.8   C39.3,29.7,39,29.1,38.5,28.8z M35.4,29.2l1.7,1c-0.3,0.8-0.8,1.6-1.3,2.3l-1.7-1c-0.8,0.8-1.9,1.4-3,1.8v2c-0.9,0.1-1.8,0.1-2.7,0   v-2c-1.1-0.3-2.2-0.9-3-1.8l-1.7,1c-0.6-0.7-1-1.5-1.3-2.3l1.8-1c-0.3-1.1-0.3-2.3,0-3.5l-1.7-1c0.3-0.8,0.8-1.6,1.3-2.3l1.7,1   c0.8-0.8,1.9-1.4,3-1.8v-2c0.9-0.1,1.8-0.1,2.7,0v2c1.1,0.3,2.2,0.9,3,1.8l1.7-1c0.6,0.7,1,1.5,1.3,2.3l-1.8,1   C35.7,26.8,35.7,28,35.4,29.2z"
          class="st0_1562846380410"
          vector-effect="non-scaling-stroke"
        />
        <path
          d="M29.7,23.7c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0   C33.5,25.4,31.8,23.7,29.7,23.7z M29.7,29.3c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9c1,0,1.9,0.8,1.9,1.9c0,0,0,0,0,0   C31.6,28.5,30.8,29.3,29.7,29.3z"
          class="st0_1562846380410"
          vector-effect="non-scaling-stroke"
        />
        <g>
          <path
            d="M33.2,5.3H11.1c-1,0-1.8,0.8-1.8,1.8v5c-2.8,0.1-5.1,2.4-5.1,5.2c0,2.9,2.3,5.2,5.2,5.2c0,0,0,0,0,0    c2.9,0,5.2-2.3,5.2-5.2c0-2.2-1.4-4.1-3.4-4.9V7.2h21.8v9.2l0.1,0c0.6,0.2,1.1,0.4,1.7,0.7l0.2,0.1V7.1C35,6.1,34.2,5.3,33.2,5.3z     M12.7,17.3c0,0.9-0.3,1.7-1,2.3c-0.6,0.6-1.4,1-2.3,1c0,0,0,0,0,0c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-1-1.4-1-2.3    c0-1.8,1.5-3.3,3.3-3.3c0,0,0,0,0,0c0.9,0,1.7,0.3,2.3,1C12.3,15.6,12.7,16.4,12.7,17.3z"
            class="st1_1562846380410"
            vector-effect="non-scaling-stroke"
          />
          <path
            d="M17.1,25.6c-0.9-1.3-2.5-2.1-4.1-2.1c0,0-0.1,0-0.1,0c-0.7,0-1.2,0.1-1.7,0.3c-0.5,0.1-1,0.3-1.9,0.3    c-0.9,0-1.4-0.1-1.9-0.3c-0.5-0.1-0.9-0.3-1.7-0.3c0,0-0.1,0-0.1,0c-1.6,0-3.2,0.8-4.1,2.1c-0.6,0.9-0.9,1.9-0.9,2.9v2    c0,1.2,1,2.2,2.2,2.2h12.8c0,0,0,0,0,0c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.7-1,0.7-1.6v-2c0-0.3,0-0.6-0.1-0.9h0.2l0-0.1    c0-0.6,0-1.1,0.1-1.7l0-0.1L17.1,25.6L17.1,25.6z M2.7,28.5c0-0.7,0.2-1.3,0.5-1.8c0.6-0.8,1.5-1.3,2.5-1.3c0,0,0.1,0,0.1,0    c0.5,0,0.8,0.1,1.2,0.2c0.6,0.2,1.2,0.3,2.3,0.3c1.1,0,1.7-0.2,2.3-0.3c0.4-0.1,0.8-0.2,1.3-0.2c1,0,2,0.5,2.6,1.3    c0.4,0.5,0.6,1.2,0.6,1.8l0,2c0,0.2-0.1,0.3-0.3,0.3H3c-0.2,0-0.3-0.1-0.3-0.3V28.5z"
            class="st1_1562846380410"
            vector-effect="non-scaling-stroke"
          />
        </g>
      </g>
    </g>
  </svg>

  <svg
    name="dashboard"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562846380415"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562846380415 {
            fill: #8e8fa6;
          }
          .cls-2_1562846380415 {
            fill: #fa4616;
          }
        </style>
      </defs>
      <path
        d="M6.32,23.55H16.71v10.7H6.32V23.55M4,23.2V34.6a2,2,0,0,0,2,2H17.05a2,2,0,0,0,2-2V23.2a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2Z"
        class="cls-1_1562846380415"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M6.32,5.75H16.71v10.7H6.32V5.75M4,5.4V16.8a2,2,0,0,0,2,2H17.05a2,2,0,0,0,2-2V5.4a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2Z"
        class="cls-1_1562846380415"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M23.29,23.55H33.68v10.7H23.29V23.55M21,23.2V34.6a2,2,0,0,0,2,2H34a2,2,0,0,0,2-2V23.2a2,2,0,0,0-2-2H23a2,2,0,0,0-2,2Z"
        class="cls-1_1562846380415"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M23.29,5.75H33.68v10.7H23.29V5.75M21,5.4V16.8a2,2,0,0,0,2,2H34a2,2,0,0,0,2-2V5.4a2,2,0,0,0-2-2H23a2,2,0,0,0-2,2Z"
        class="cls-2_1562846380415"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>

  <svg
    name="knowledgeBase"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.2"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1562846380414"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1562846380414 {
            fill: #8e8fa6;
          }
          .cls-2_1562846380414 {
            fill: #fa4616;
          }
        </style>
      </defs>
      <path
        d="M32.51,9l-5.9-5.9a3.38,3.38,0,0,0-2.38-1H9.87A3.39,3.39,0,0,0,6.5,5.51V34.76a3.37,3.37,0,0,0,3.37,3.37H30.13a3.37,3.37,0,0,0,3.37-3.37V11.41A3.4,3.4,0,0,0,32.51,9Zm-8-4.6a1.1,1.1,0,0,1,.52.3l5.9,5.9a1.05,1.05,0,0,1,.3.52H24.5Zm6.75,30.34a1.13,1.13,0,0,1-1.12,1.12H9.87a1.13,1.13,0,0,1-1.12-1.12V5.51A1.13,1.13,0,0,1,9.87,4.38H22.25V11.7a1.68,1.68,0,0,0,1.69,1.68h7.31Z"
        class="cls-1_1562846380414"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M19.2,29.74h1.6a.73.73,0,0,0,.8-.62V20.36a.73.73,0,0,0-.8-.63H19.2a.73.73,0,0,0-.8.63v8.76A.73.73,0,0,0,19.2,29.74Z"
        class="cls-2_1562846380414"
        vector-effect="non-scaling-stroke"
      />
      <circle
        r="1.6"
        cy="16.73"
        cx="20"
        class="cls-2_1562846380414"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>
  <svg
    name="mySuppliers"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1588253237084"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1588253237084 {
            fill: #fa4616;
          }
          .cls-2_1588253237084 {
            fill: #8e8fa6;
          }
        </style>
      </defs>
      <path
        d="M27.87,5.29H12.12a.88.88,0,0,0-.87.88V18.42a.86.86,0,0,0,.59.83H28.2a.86.86,0,0,0,.55-.83V6.17A.88.88,0,0,0,27.87,5.29ZM18.25,7h3.5v3.5h-3.5Zm8.09,10.5H13V7h3.5v3.5a1.75,1.75,0,0,0,1.75,1.75h3.5a1.75,1.75,0,0,0,1.75-1.75V7H27v10.5Z"
        class="cls-1_1588253237084"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M37.5,20.75H21.75a.87.87,0,0,0-.87.88V33.88a.85.85,0,0,0,.59.83H37.82a.85.85,0,0,0,.56-.83V21.63A.88.88,0,0,0,37.5,20.75ZM27.88,22.5h3.5V26h-3.5ZM36,33H22.63V22.5h3.5V26a1.75,1.75,0,0,0,1.75,1.75h3.5A1.75,1.75,0,0,0,33.13,26V22.5h3.5V33Z"
        class="cls-2_1588253237084"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M18.25,20.75H2.5a.87.87,0,0,0-.87.88V33.88a.85.85,0,0,0,.59.83H18.57a.85.85,0,0,0,.56-.83V21.63A.88.88,0,0,0,18.25,20.75ZM8.63,22.5h3.5V26H8.63ZM16.71,33H3.38V22.5h3.5V26a1.75,1.75,0,0,0,1.75,1.75h3.5A1.75,1.75,0,0,0,13.88,26V22.5h3.5V33Z"
        class="cls-2_1588253237084"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>
  <svg
    name="transactions"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1588852091758"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1588852091758 {
            fill: #fa4616;
          }
          .cls-2_1588852091758 {
            fill: #8e8fa6;
          }
        </style>
      </defs>
      <path
        d="M22.87,25l-3.16-.95a.92.92,0,0,1-.62-.89.89.89,0,0,1,.83-.93h2a1.68,1.68,0,0,1,.9.26.57.57,0,0,0,.71-.05l.83-.79a.56.56,0,0,0,0-.85,4,4,0,0,0-2.21-.8V18.31a.56.56,0,0,0-.56-.56H20.41a.57.57,0,0,0-.57.56V20a3.13,3.13,0,0,0-3,3.17,3.19,3.19,0,0,0,2.22,3.05l3.17.95a.92.92,0,0,1,.62.89A.89.89,0,0,1,22,29H20a1.68,1.68,0,0,1-.9-.26.57.57,0,0,0-.71.05l-.83.79a.55.55,0,0,0,0,.85,4,4,0,0,0,2.2.8v1.71a.57.57,0,0,0,.57.56h1.12a.56.56,0,0,0,.56-.56v-1.7a3.13,3.13,0,0,0,3-3.17A3.19,3.19,0,0,0,22.87,25Z"
        class="cls-1_1588852091758"
        vector-effect="non-scaling-stroke"
      />
      <rect
        rx="0.56"
        height="2.25"
        width="9"
        y="6.5"
        x="11.97"
        class="cls-2_1588852091758"
        vector-effect="non-scaling-stroke"
      />
      <rect
        rx="0.56"
        height="2.25"
        width="9"
        y="11"
        x="11.97"
        class="cls-2_1588852091758"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M33.48,8.89,27.58,3A3.38,3.38,0,0,0,25.2,2H10.84A3.39,3.39,0,0,0,7.47,5.38V34.63A3.37,3.37,0,0,0,10.84,38H31.1a3.37,3.37,0,0,0,3.37-3.37V11.28A3.4,3.4,0,0,0,33.48,8.89Zm-8-4.6a1.1,1.1,0,0,1,.52.3l5.9,5.89a1.13,1.13,0,0,1,.3.53H25.47Zm6.75,30.34a1.13,1.13,0,0,1-1.12,1.12H10.84a1.13,1.13,0,0,1-1.12-1.12V5.38a1.13,1.13,0,0,1,1.12-1.13H23.22v7.32a1.68,1.68,0,0,0,1.69,1.68h7.31Z"
        class="cls-2_1588852091758"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>
  <svg
    name="notificationSettings"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 42 42"
    id="Layer_1_1587572149777"
    width="24"
    height="24"
  >
    <g transform="translate(1, 1)">
      <defs>
        <style>
          .cls-1_1587572149777 {
            fill: #fa4616;
          }
          .cls-2_1587572149777 {
            fill: #8e8fa6;
          }
        </style>
      </defs>
      <path
        d="M39.31,30.29l-.94-.55a7.62,7.62,0,0,0,0-1.51l.94-.56a1.36,1.36,0,0,0,.63-1.59,9.88,9.88,0,0,0-2.16-3.77,1.37,1.37,0,0,0-1.7-.27l-.94.57a8.43,8.43,0,0,0-1.3-.75v-1.1a1.38,1.38,0,0,0-1.08-1.34,10.15,10.15,0,0,0-4.32,0,1.37,1.37,0,0,0-1.06,1.34v1.12a7.45,7.45,0,0,0-1.31.76l-.94-.57a1.37,1.37,0,0,0-1.7.27,9.88,9.88,0,0,0-2.16,3.77,1.36,1.36,0,0,0,.62,1.6l.94.57a9.39,9.39,0,0,0,0,1.5l-.94.56a1.37,1.37,0,0,0-.62,1.59,9.49,9.49,0,0,0,2.16,3.77,1.4,1.4,0,0,0,1.7.27l.94-.57a6.31,6.31,0,0,0,1.31.76v1.12a1.36,1.36,0,0,0,1.06,1.34,10.15,10.15,0,0,0,4.32,0,1.38,1.38,0,0,0,1.08-1.34V36.09a7.31,7.31,0,0,0,1.3-.75l.94.56a1.38,1.38,0,0,0,1.7-.26,9.88,9.88,0,0,0,2.16-3.77A1.41,1.41,0,0,0,39.31,30.29Zm-3.08.43,1.74,1a8,8,0,0,1-1.34,2.32l-1.74-1a6.77,6.77,0,0,1-3,1.74v2a8,8,0,0,1-2.68,0v-2a6.86,6.86,0,0,1-3-1.74l-1.73,1a7.81,7.81,0,0,1-1.34-2.32l1.81-1a6.8,6.8,0,0,1,0-3.47l-1.74-1a7.68,7.68,0,0,1,1.34-2.32l1.74,1a6.59,6.59,0,0,1,3-1.74v-2a8.1,8.1,0,0,1,2.69,0v2a6.88,6.88,0,0,1,3,1.74l1.73-1A7.68,7.68,0,0,1,38,26.24l-1.81,1A6.8,6.8,0,0,1,36.23,30.72Z"
        class="cls-1_1587572149777"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M30.57,25.21A3.77,3.77,0,1,0,34.35,29,3.77,3.77,0,0,0,30.57,25.21Zm0,5.66A1.89,1.89,0,1,1,32.46,29,1.89,1.89,0,0,1,30.57,30.87Z"
        class="cls-1_1587572149777"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M15.13,33.56A2.16,2.16,0,0,1,13,31.4H10.8a4.33,4.33,0,0,0,8.65,0H17.29A2.16,2.16,0,0,1,15.13,33.56Z"
        class="cls-2_1587572149777"
        vector-effect="non-scaling-stroke"
      />
      <path
        d="M19.6,29a10.83,10.83,0,0,1,.17-1.9H3.24a1,1,0,0,1-.76-1.76c2.35-2.24,4-4.75,4-11.62a8.44,8.44,0,0,1,8.65-8.23,8.44,8.44,0,0,1,8.64,8.23,23.64,23.64,0,0,0,.67,6.18,11.51,11.51,0,0,1,2-1.05,23.05,23.05,0,0,1-.47-5.13A10.54,10.54,0,0,0,16.21,3.41V2.23a1.08,1.08,0,0,0-2.16,0V3.41A10.54,10.54,0,0,0,4.32,13.7C4.32,20.08,2.87,22,1,23.75a3.14,3.14,0,0,0-.76,3.46,3.22,3.22,0,0,0,3,2H19.61C19.61,29.15,19.6,29.07,19.6,29Z"
        class="cls-2_1587572149777"
        vector-effect="non-scaling-stroke"
      />
    </g>
  </svg>
</div>
