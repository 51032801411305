import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import {NgTemplateOutlet} from "@angular/common";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'leap-user-menu-item',
  templateUrl: './user-menu-item.component.html',
  styleUrls: ['./user-menu-item.component.less', '../../../navbar.less'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuItemComponent implements OnInit {

  @Input() title : string;
  @Input() svgTemplate : any;
  @Input() hoverClass = '';
  @Input() stickToRightEdge : boolean = true;

  securedSvg : SafeHtml;
  isHovering = false;

  constructor(private sanitizer: DomSanitizer, private renderer: Renderer2)
  {}

  ngOnInit(): void {
    if (this.svgTemplate) {
      this.securedSvg = this.sanitizer.bypassSecurityTrustHtml(this.svgTemplate);
    }
  }

  @HostListener('mouseenter', ['$event'])
  onMouseOver(event) {
    this.isHovering = true;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event) {
    this.isHovering = false;
  }

  @HostListener('click', ['$event'])
  onMouseClick(event) {
    setTimeout(_ => this.isHovering = false, 1000);
  }
}
