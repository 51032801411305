/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./label-optional.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./label-optional.component";
var styles_LabelOptionalComponent = [i0.styles];
var RenderType_LabelOptionalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabelOptionalComponent, data: {} });
export { RenderType_LabelOptionalComponent as RenderType_LabelOptionalComponent };
export function View_LabelOptionalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "leap-optional"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Optional"]))], null, null); }
export function View_LabelOptionalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["leap-label-optional", ""]], null, null, null, View_LabelOptionalComponent_0, RenderType_LabelOptionalComponent)), i1.ɵdid(1, 49152, null, 0, i2.LabelOptionalComponent, [], null, null)], null, null); }
var LabelOptionalComponentNgFactory = i1.ɵccf("span[leap-label-optional]", i2.LabelOptionalComponent, View_LabelOptionalComponent_Host_0, {}, {}, []);
export { LabelOptionalComponentNgFactory as LabelOptionalComponentNgFactory };
