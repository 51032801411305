import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  OpenShoppingCartDrawer,
  CloseShoppingCartDrawer,
  QuantityChangeLineItemInShoppingCart,
  RemoveLineItemFromShoppingCart,
  AddLineItemToShoppingCart,
  GetShoppingCartDetails,
  EmptyCartAfterPayment,
  FetchPromotionCodes,
  RemovePromotionCode,
  AddPromotionCode,
  AddPromotionCodeFailure,
  AddPromotionCodeSuccess,
  RemovePromotionCodeSuccess,
  RemovePromotionCodeFailure,
  EditShippingMethod,
  CleanErrors, CleanShippingBatches, UpdatePurchaseOrderIdOnItem, FetchAvailablePurchaseOrders, AddReferenceId
} from "../actions/shopping-cart.actions";
import { LineItem } from '../models/line-item.model';
import { ShoppingCart } from '../models/shopping-cart.model';
import { ShippingGroup } from '../models/shipping-group.model';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { map, tap, catchError, filter } from 'rxjs/operators';
import { AmberError, AmberResponse } from "src/app/snatch/models/amber-response.model";
import { UtilsService } from 'src/app/snatch/services/utils.service';
import { PromotionCode } from 'src/app/common/common-shopping-cart/models/promotion-code.model';
import { AuthTokenState } from 'src/app/auth/state/auth-token.state';
import { HelpersService } from 'src/app/core/services/helpers.service';
import { Address } from 'src/app/snatch/models/address.model';
import { GraphQLError } from "../../snatch/models/graphql-error.model";
import { HttpErrorResponse } from "@angular/common/http";

export class ShoppingCartStateModel {
  drawerVisible: boolean;

  shippingGroups: ShippingGroup[];
  shippingBatches: any[];

  lineItems: LineItem[];
  netTotal: number;
  grossTotal: number;
  taxTotal: number;
  discountTotal: number;
  surchargeTotal: number;
  shippingTotal: number;
  currency: string;
  defaultShippingAddress: Address;

  appliedPromotionCodes: PromotionCode[];
  invalidPromotionCodes: PromotionCode[];
  promotionCodes: PromotionCode[];

  referenceId: string;
  addLineItemSku: string;

  addLineItemPending: boolean;
  addLineItemSuccess: boolean;
  addLineItemError: AmberError;

  removeLineItemPending: boolean;
  removeLineItemSuccess: boolean;
  removeLineItemError: AmberError;

  editShippingMethodPending: boolean;
  editShippingMethodSuccess: boolean;
  editShippingMethodError: AmberError;

  quantityChangeLineItemPending: boolean;
  quantityChangeLineItemSuccess: boolean;
  quantityChangeLineItemError: AmberError;

  addPromotionCodePending: boolean;
  addPromotionCodeSuccess: boolean;
  addPromotionCodeError: AmberError;

  removePromotionCodePending: boolean;
  removePromotionCodeSuccess: boolean;
  removePromotionCodeError: AmberError;

  availablePurchaseOrdersPerSeller: any;
  availablePurchaseOrdersPerSellerPending: any;
  availablePurchaseOrdersPerSellerSuccess: any;
  availablePurchaseOrdersPerSellerError: AmberError;

  updatePurchaseOrdersPerItem: any;
  updatePurchaseOrdersPerItemPending: any;
  updatePurchaseOrdersPerItemSuccess: any;
  updatePurchaseOrdersPerItemFailed: any;
  updatePurchaseOrdersPerItemError: HttpErrorResponse
}

const defaults: ShoppingCartStateModel = {
  drawerVisible: false,

  shippingGroups: [],
  shippingBatches: [],

  lineItems: [],
  netTotal: 0,
  grossTotal: 0,
  taxTotal: 0,
  discountTotal: 0,
  surchargeTotal: 0,
  shippingTotal: 0,
  currency: undefined,
  defaultShippingAddress: undefined,

  appliedPromotionCodes: [],
  invalidPromotionCodes: [],
  promotionCodes: [],

  referenceId: undefined,
  addLineItemSku: undefined,

  addLineItemPending: false,
  addLineItemSuccess: false,
  addLineItemError: undefined,

  removeLineItemPending: false,
  removeLineItemSuccess: false,
  removeLineItemError: undefined,

  quantityChangeLineItemPending: false,
  quantityChangeLineItemSuccess: false,
  quantityChangeLineItemError: undefined,

  editShippingMethodPending: false,
  editShippingMethodSuccess: false,
  editShippingMethodError: undefined,

  addPromotionCodePending: false,
  addPromotionCodeSuccess: false,
  addPromotionCodeError: undefined,

  removePromotionCodePending: false,
  removePromotionCodeSuccess: false,
  removePromotionCodeError: undefined,

  availablePurchaseOrdersPerSeller: [],
  availablePurchaseOrdersPerSellerPending: false,
  availablePurchaseOrdersPerSellerSuccess: false,
  availablePurchaseOrdersPerSellerError: undefined,

  updatePurchaseOrdersPerItem: undefined,
  updatePurchaseOrdersPerItemPending: false,
  updatePurchaseOrdersPerItemSuccess: false,
  updatePurchaseOrdersPerItemFailed: false,
  updatePurchaseOrdersPerItemError: undefined
}

@State<ShoppingCartStateModel>({
  name: 'shoppingCart',
  defaults
})
export class ShoppingCartState {
  constructor(
    private service: ShoppingCartService,
    private utilsService: UtilsService,
    private store: Store,
    private helpers: HelpersService) {}

  ngxsOnInit({ dispatch }: StateContext<ShoppingCartStateModel>) {
    this.store.select(AuthTokenState.isLogged)
      .pipe(
        filter(_ => !!_)
      )
      .subscribe(_ => {
        dispatch(new GetShoppingCartDetails());
      })
  }

  @Action(OpenShoppingCartDrawer)
  openShoppingCartDrawer({ patchState, dispatch }: StateContext<ShoppingCartStateModel>) {
    patchState({ drawerVisible: true });
    dispatch(new CleanErrors());
  }

  @Action(CloseShoppingCartDrawer)
  closeShoppingCartDrawer({ patchState }: StateContext<ShoppingCartStateModel>) {
    patchState({ drawerVisible: false });
  }

  private updateCart(patchState: any, data: ShoppingCart) {
    patchState(this.helpers.buildShoppingCart(data))
  }

  @Action(GetShoppingCartDetails)
  getShoppingCartDetails({ patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: AddLineItemToShoppingCart) {
    return this.service.getShoppingCartDetails()
      .pipe(
        map((response: any) => response.data.getMyBasketDetails),
        filter(data => !!data),
        tap((data: ShoppingCart) => {
          this.updateCart(patchState, data);
          return dispatch([]);
        }),
        catchError((graphqlError: GraphQLError) => {
          const error = this.utilsService.formatGraphqlError(graphqlError);
          return dispatch([]);
        })
      )
  }

  @Action(AddLineItemToShoppingCart)
  addLineItem({ getState, patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: AddLineItemToShoppingCart) {
    let itemAlreadyInCart = getState().lineItems.find(_ => _.productVariantSnapshot.sku === action.sku );
    if(itemAlreadyInCart){ 
      itemAlreadyInCart.quantity = action.quantity
      return dispatch([new QuantityChangeLineItemInShoppingCart(itemAlreadyInCart), new OpenShoppingCartDrawer()]);
    }

    dispatch(new CleanErrors());

    patchState({
      addLineItemSku: action.sku,
      addLineItemPending: true,
      addLineItemSuccess: false,
      addLineItemError: undefined,
    });
    return this.service.addLineItem(action.sku, action.quantity)
    .pipe(
      map((response: any) => response.data.addBasketItem),
      tap((data: any) => {
        this.updateCart(patchState, data);

        patchState({
          addLineItemSku: undefined,
          addLineItemPending: false,
          addLineItemSuccess: true,
          addLineItemError: undefined,
        })

        return dispatch([new OpenShoppingCartDrawer()]);
      }),
      catchError((graphqlError: GraphQLError) => {

        patchState({
          addLineItemSku: undefined,
          addLineItemPending: false,
          addLineItemSuccess: false,
          addLineItemError: this.utilsService.formatGraphqlError(graphqlError)
        })

        return dispatch([]);
      })
    )
  }

  @Action(QuantityChangeLineItemInShoppingCart)
  quantityChangeLineItem({ patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: RemoveLineItemFromShoppingCart) {

    dispatch(new CleanErrors());

    patchState({
      quantityChangeLineItemPending: true,
      quantityChangeLineItemSuccess: false,
      quantityChangeLineItemError: undefined,
    })

    return this.service.editLineItem(action.lineItem.id, action.lineItem.quantity)
    .pipe(
      map((response: any) => response.data.editBasketItem),
      tap((data: ShoppingCart) => {

        this.updateCart(patchState, data);

        patchState({
          quantityChangeLineItemPending: false,
          quantityChangeLineItemSuccess: true,
          quantityChangeLineItemError: undefined,
        })

        return dispatch([]);
      }),
      catchError((graphqlError: GraphQLError) => {
        patchState({
          quantityChangeLineItemPending: false,
          quantityChangeLineItemSuccess: false,
          quantityChangeLineItemError: this.utilsService.formatGraphqlError(graphqlError),
        })
        return dispatch([]);
      })
    )
  }

  @Action(RemoveLineItemFromShoppingCart)
  removeLineItem({ patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: RemoveLineItemFromShoppingCart) {

    dispatch(new CleanErrors());

    patchState({
      removeLineItemPending: true,
      removeLineItemSuccess: false,
      removeLineItemError: undefined,
    })

    return this.service.removeLineItem(action.lineItem.id)
    .pipe(
      map((response: any) => response.data.removeBasketItem),
      tap((data: any) => {
        this.updateCart(patchState, data);

        patchState({
          removeLineItemPending: false,
          removeLineItemSuccess: true,
          removeLineItemError: undefined,
        })

        return dispatch([]);
      }),
      catchError((graphqlError: GraphQLError) => {
        patchState({
          removeLineItemPending: false,
          removeLineItemSuccess: false,
          removeLineItemError: this.utilsService.formatGraphqlError(graphqlError)
        })

        return dispatch([]);
      })
    )
  }

  @Action(EditShippingMethod)
  editShippingMethod({ patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: EditShippingMethod) {

    dispatch(new CleanErrors());

    patchState({
      editShippingMethodPending: true,
      editShippingMethodSuccess: false,
      editShippingMethodError: undefined,
    })

    return this.service.editShippingMethod(action.shippingGroupId, action.shippingMethodId)
    .pipe(
      map((response: any) => response.data.editShippingMethod),
      tap((data: any) => {
        this.updateCart(patchState, data);

        patchState({
          editShippingMethodPending: false,
          editShippingMethodSuccess: true,
          editShippingMethodError: undefined,
        })

        return dispatch([]);
      }),
      catchError((graphqlError: GraphQLError) => {
        patchState({
          editShippingMethodPending: false,
          editShippingMethodSuccess: false,
          editShippingMethodError: this.utilsService.formatGraphqlError(graphqlError)
        })

        return dispatch([]);
      })
    )
  }

  @Action(AddPromotionCode)
  addPromotionCode({ patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: AddPromotionCode) {

    dispatch(new CleanErrors());

    patchState({ addPromotionCodePending: true, addPromotionCodeSuccess: false, addPromotionCodeError: undefined })

    return this.service.addPromotionCode(action.promotionCode)
    .pipe(
      map((response: any) => response.data.addPromoCode),
      tap((data: any) => {
        this.updateCart(patchState, data);

        patchState({ addPromotionCodePending: false, addPromotionCodeSuccess: true, addPromotionCodeError: undefined })

        return dispatch(new AddPromotionCodeSuccess());
      }),
      catchError((graphqlError: GraphQLError) => {
        patchState({
          addPromotionCodePending: false,
          addPromotionCodeSuccess: false,
          addPromotionCodeError: this.utilsService.formatGraphqlError(graphqlError)
        })
        return dispatch(new AddPromotionCodeFailure());
      })
    )
  }

  @Action(RemovePromotionCode)
  removePromotionCode({ patchState, dispatch }: StateContext<ShoppingCartStateModel>, action: RemovePromotionCode) {

    dispatch(new CleanErrors());

    patchState({ removePromotionCodePending: true, removePromotionCodeSuccess: false, removePromotionCodeError: undefined })

    return this.service.removePromotionCode(action.promotionCode)
    .pipe(
      map((response: any) => response.data.removePromoCode),
      tap((data: any) => {
        this.updateCart(patchState, data)

        patchState({ removePromotionCodePending: false, removePromotionCodeSuccess: true, removePromotionCodeError: undefined })

        return dispatch(new RemovePromotionCodeSuccess());
      }),
      catchError((graphqlError: GraphQLError) => {

        patchState({
          removePromotionCodePending: false,
          removePromotionCodeSuccess: false,
          removePromotionCodeError: this.utilsService.formatGraphqlError(graphqlError) })

        return dispatch(new RemovePromotionCodeFailure());
      })
    )
  }

  @Action(EmptyCartAfterPayment)
  emptyCartAfterPayment({ patchState }: StateContext<ShoppingCartStateModel>) {
    patchState(defaults)
  }

  @Action(FetchPromotionCodes)
  fetchPromotionCodes({ patchState }: StateContext<ShoppingCartStateModel>) {
    return this.service.fetchPromotionCodes()
      .pipe(
          map(response => response.data.promotionCodes),
          tap((promotionCodes) => {
            patchState({ promotionCodes })
          })
      );
  }

  @Action(CleanErrors)
  cleanErrors({ patchState }: StateContext<CleanErrors>) {
    patchState({
      addLineItemError: undefined,
      removeLineItemError: undefined,
      editShippingMethodError: undefined,
      quantityChangeLineItemError: undefined,
      addPromotionCodeError: undefined,
      removePromotionCodeError: undefined
    })
  }


  @Action(CleanShippingBatches)
  cleanShoppingBatches({ patchState }: StateContext<ShoppingCartStateModel>) {
    patchState({
      shippingBatches : [],
      shippingGroups: []
    })
  }

  @Action(UpdatePurchaseOrderIdOnItem)
  updatePurchaseOrderIdOnItem({patchState, dispatch, getState}: StateContext<ShoppingCartStateModel>, action: UpdatePurchaseOrderIdOnItem) {
    patchState({
      updatePurchaseOrdersPerItemPending: true
    });

    return this.service.updatePurchaseOrderOnBasketItem(action.id, action.purchaseOrderIds)
      .pipe(
        map((response: any) => response.data.editBasketItemPurchaseOrder),
        tap((data) => {
          this.updateCart(patchState, data);

          patchState({
            updatePurchaseOrdersPerItemPending: false,
            updatePurchaseOrdersPerItemSuccess: true
          });
          return dispatch([]);
        }),
        catchError((httpError: HttpErrorResponse) => {
          patchState({
            updatePurchaseOrdersPerItemPending: false,
            updatePurchaseOrdersPerItemSuccess: false,
            updatePurchaseOrdersPerItemFailed: true,
            updatePurchaseOrdersPerItemError: httpError
          });
          return dispatch([]);
        })
      );
  }

  @Action(FetchAvailablePurchaseOrders)
  fetchAvailablePurchaseOrders({ patchState, dispatch, getState }: StateContext<ShoppingCartStateModel>, action : FetchAvailablePurchaseOrders) {

    patchState({
      availablePurchaseOrdersPerSellerPending: true,
      availablePurchaseOrdersPerSellerSuccess: false,
      availablePurchaseOrdersPerSellerError: undefined
    });

    return this.service.fetchAvailablePurchaseOrders(action.sellerIds).pipe(
      map((response: AmberResponse<any>) => response.data),
      map((response: any) => {
        patchState({
          availablePurchaseOrdersPerSeller: response,
          availablePurchaseOrdersPerSellerPending: false,
          availablePurchaseOrdersPerSellerSuccess: true,
          availablePurchaseOrdersPerSellerError: undefined
        });
        return dispatch([]);
      }),
      catchError((httpError: HttpErrorResponse) => {
        const error = (<AmberResponse<any>>httpError.error).error;
        patchState({
          availablePurchaseOrdersPerSeller: [],
          availablePurchaseOrdersPerSellerPending: false,
          availablePurchaseOrdersPerSellerSuccess: false,
          availablePurchaseOrdersPerSellerError: error
        });
        return dispatch([]);
      })
    );
  }

  @Action(AddReferenceId)
  addReferenceId({ patchState }: StateContext<ShoppingCartStateModel>, action : AddReferenceId) {
    patchState({ referenceId: action.referenceId });
  }
}
