import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter, first, withLatestFrom } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";

import { GetTranslations } from "src/app/core/actions/core.actions";
import { HedwigService } from 'src/app/core/services/hedwig.service';
import { NewConversation, SetConversations, UpdateConversation, NewMessage } from "src/app/inbox/state/inbox.state";
import { NewNotification, SetNotifications } from './core/actions/notifications.actions';
import { NotificationDto } from './core/models/hedwig-models';
import { QueryParametersCacheService } from './core/services/query-parameters-cache.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { StorageService } from './snatch/services/storage.service';

@Component({
  selector: 'leap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'product-market';

  @Select((state: any) => state.session.accessToken)
  accessToken$: Observable<string>;

  @Select((state) => state.core.notifications.notifications)
  notifications$: Observable<NotificationDto[]>;

  constructor(
    private store: Store,
    private queryParametersCacheService: QueryParametersCacheService,
    private hedwigService: HedwigService,
    @Inject(OKTA_AUTH) private readonly authClient: OktaAuth,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.queryParametersCacheService.init();
    this.accessToken$
      .pipe(
        filter(_ => !!_),
        first()
      )
      .subscribe(_ => {
        this.initInbox(_);
      })
  }

  async ngOnInit() {
    this.store.dispatch(new GetTranslations());
  }

  ngOnDestroy() { }

  initInbox(accessToken: string) {
    this.hedwigService.init(accessToken);

    this.hedwigService.getSummary()
      .pipe(untilDestroyed(this),
        withLatestFrom(this.notifications$)
      )
      .subscribe(([res, notifications]) => {
        this.store.dispatch(new SetConversations(res.channelSummary));
        if (!notifications.length) {
          this.store.dispatch(new SetNotifications(res.notifications.data));
        }
      });

    this.hedwigService.newNotification()
      .pipe(untilDestroyed(this))
      .subscribe((res: NotificationDto) => {
        this.store.dispatch(new NewNotification(res));
      });

    this.hedwigService.newMessage()
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.store.dispatch(new NewMessage(_))
      });

    this.hedwigService.newChannel()
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.store.dispatch(new NewConversation(_))
      });

    this.hedwigService.updateChannel()
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.store.dispatch(new UpdateConversation(_))
      });
  }
}
