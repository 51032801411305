import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { LineItemPriceAndSurchargeComponent } from './components/line-item-price-and-surcharge/line-item-price-and-surcharge.component';
import { LineItemVerboseComponent } from './components/line-item-verbose/line-item-verbose.component';
import { CommonProductModule } from "../common-product/common-product.module";
import { ReactiveFormsModule } from "@angular/forms";
import { ShoppingCartTheadBarComponent } from './components/shopping-cart-thead-bar/shopping-cart-thead-bar.component';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    CommonProductModule,
    ReactiveFormsModule
  ],
  declarations: [ LineItemPriceAndSurchargeComponent, LineItemVerboseComponent, ShoppingCartTheadBarComponent ],
  exports: [ LineItemPriceAndSurchargeComponent, LineItemVerboseComponent, ShoppingCartTheadBarComponent ],
  providers: []
})
export class CommonShoppingCartModule { }
