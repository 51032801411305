import { Pipe, PipeTransform } from '@angular/core';
import {UtilsService} from "../services/utils.service";

@Pipe({
  name: 'urlFromBucket'
})
export class UrlFromBucketPipe implements PipeTransform {
  constructor( private utils: UtilsService ){}
  transform(value: unknown, bucketDetails: { s3Key: string, s3Bucket: string }): unknown {
   if (!bucketDetails) return '';
    const { s3Key, s3Bucket } = bucketDetails;
    return s3Key && s3Bucket ? this.utils.getFileUrl(s3Key, s3Bucket) : undefined;
  }

}
