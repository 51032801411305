import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from "@angular/forms";
import { BehaviorSubject, forkJoin, of } from "rxjs";
import { NzMessageService, NzModalRef } from "ng-zorro-antd";
import { untilDestroyed } from "ngx-take-until-destroy";

import { MultiSelectComponentOption } from "src/app/snatch/components/multi-select/multi-select.component";
import {
  ProductShort,
  ProductVariantShort,
} from "../../models/product-short.model";
import { EnvironmentService } from "src/app/snatch/services";
import { LxpPublishingService } from "../../services/lxp-publishing.service";
import { SimpleOption } from "../../models/simple-option.interface";
import { ApprovalPolicy } from "../../models/approval-policy.interface";
import { isMonthlyConsumptionInvoice } from "../../utils/transaction-type.utils";
import { PublishToLxpFormValueDto } from "../../models/publish-to-lxp-form-dto.interface";
import { Store } from "@ngxs/store";
import { debounce } from "lodash";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";

@Component({
  selector: "leap-publish-to-lxp-modal",
  templateUrl: "./publish-to-lxp-modal.component.html",
  styleUrls: ["./publish-to-lxp-modal.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishToLxpModalComponent implements OnInit, OnDestroy {
  @Input() products: ProductShort[];
  @Input() formInitialValue?: PublishToLxpFormValueDto;
  @Input() currencySignsMap: Map<string, string>;

  channels$: BehaviorSubject<SimpleOption[]> = new BehaviorSubject([]);
  groups$: BehaviorSubject<SimpleOption[]> = new BehaviorSubject([]);
  transactionTypes$: BehaviorSubject<SimpleOption[]> = new BehaviorSubject([]);
  approvalRules$: BehaviorSubject<ApprovalPolicy[]> = new BehaviorSubject([]);

  channelsInput$: BehaviorSubject<string> = new BehaviorSubject("");

  form: FormGroup;
  isLoading: boolean;
  saharaUrl: string;
  languages: Map<string, string[]>;
  deliveryFormats: Map<string, string[]>;
  examProducts: ProductShort[];
  nonExamProducts: ProductShort[];
  resultData: PublishToLxpFormValueDto;
  stepMap = ["Verify Items", "Publishing Options"];
  step = 0;

  get restrictedDeliveryFormats(): FormArray {
    return this.form.get("restrictedDeliveryFormats") as FormArray;
  }

  get isBulk(): boolean {
    return this.products && this.products.length > 1;
  }
  productType: string;
  constructor(
    private environment: EnvironmentService,
    private lxpPublishingService: LxpPublishingService,
    private modalRef: NzModalRef,
    private fb: FormBuilder,
    private store: Store,
    private messageService: NzMessageService
  ) {}

  ngOnInit() {
    this.saharaUrl = this.environment.saharaUrl;

    this.initForm();
    this.initAdditionalProductsInfo();

    forkJoin([
      this.lxpPublishingService.getChannels(),
      this.lxpPublishingService.getGroups(),
      this.lxpPublishingService.getTransactionTypes(),
      this.lxpPublishingService.getApprovalRules("consumption-request"),
    ]).subscribe(([channels, groups, transactionTypes, approvalRules]) => {
      this.channels$.next(channels.data);
      this.groups$.next(groups.data);
      this.transactionTypes$.next(transactionTypes.data);
      this.approvalRules$.next(approvalRules.data);
      this.setInitialFormValue();
    });
    if (
      this.environment.isQA ||
      this.environment.isPreview ||
      this.environment.isProdin
    ) {
      let selectedVariant = this.store.selectSnapshot(
        (state) => state.productDetails.selectedVariant
      );
      this.setProductType(selectedVariant.deliveryFormat);
    }

    this.channelsInput$
      .asObservable()
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap((channelName: string) =>
          this.lxpPublishingService.getChannels(channelName).pipe(
            catchError((_) => {
              this.messageService.create(
                "error",
                "Error getting the list of channels",
                {
                  nzDuration: 2000,
                  nzPauseOnHover: false,
                }
              );

              return of(null);
            })
          )
        )
      )
      .subscribe((channels) => {
        if (channels && channels.data && channels.data.length) {
          this.channels$.next(channels.data);
        }
      });
  }

  ngOnDestroy(): void {
    // for unsubscribe
  }

  cancel() {
    this.modalRef.close(false);
  }

  back() {
    this.step--;
  }

  next() {
    this.step++;
  }

  submit() {
    const dto: PublishToLxpFormValueDto = this.formValueToDto(this.form.value);
    this.isLoading = true;
    this.resultData = dto;
    this.modalRef.triggerOk();
  }

  simpleOptionToMultiselectOption(
    option: SimpleOption
  ): MultiSelectComponentOption<SimpleOption> {
    return {
      value: option,
      label: option.value,
    };
  }

  isMultipleDeliverableExam(product: ProductShort): boolean {
    return (
      product && product.productVariants.length > 1 && this.isExam(product)
    );
  }

  isExam(product): boolean {
    return product && product.typeConfig.configKey === "Exam";
  }

  distinctExams(exams: boolean): ProductShort[] {
    return this.products.filter((product: ProductShort) =>
      exams ? this.isExam(product) : !this.isExam(product)
    );
  }

  getDeliveryFormatGroup(sku: string): FormGroup {
    return this.restrictedDeliveryFormats.controls.find(
      (group) => group.value.sku === sku
    ) as FormGroup;
  }

  private initForm() {
    this.form = new FormGroup({
      channels: new FormControl([], this.isBundle ? Validators.required : []),
      groups: new FormControl([]),
      transactionType: new FormControl(
        null,
        this.isBundle ? [] : Validators.required
      ),
      approvalRequired: new FormControl(
        { disabled: true, value: null },
        this.isBundle ? [] : Validators.required
      ),
      approvalRule: new FormControl(
        { disabled: true, value: null },
        this.isBundle ? [] : Validators.required
      ),
      restrictedDeliveryFormats: new FormArray([]),
    });
    this.form
      .get("transactionType")
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: SimpleOption) => {
        if (isMonthlyConsumptionInvoice(value.value)) {
          this.form.get("approvalRequired").enable({ emitEvent: false });
          this.form.get("approvalRule").enable({ emitEvent: false });
        } else {
          this.form.get("approvalRequired").disable({ emitEvent: false });
          this.form.get("approvalRule").disable({ emitEvent: false });
        }
      });
    this.form
      .get("approvalRequired")
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        if (value) {
          this.form.get("approvalRule").enable({ emitEvent: false });
        } else {
          this.form.get("approvalRule").disable({ emitEvent: false });
        }
      });
  }

  private initAdditionalProductsInfo() {
    this.examProducts = this.distinctExams(true);
    this.nonExamProducts = this.distinctExams(false);

    for (const product of this.products) {
      this.languages = {
        ...this.languages,
        [product.sku]: [
          ...new Set(
            product.productVariants.map((i: ProductVariantShort) => i.language)
          ),
        ],
      };
      if (!this.isMultipleDeliverableExam(product)) {
        this.deliveryFormats = {
          ...this.languages,
          [product.sku]: [
            ...new Set(
              product.productVariants.map((i: ProductVariantShort) =>
                i.deliveryFormat === "product-assessment"
                  ? i.deliveryFormatConfig.configValue
                  : i.deliveryFormat
              )
            ),
          ],
        };
      } else {
        const distinctDeliveryFormats = [];
        let defaultDeliveryFormat = null;
        for (const productVariant of product.productVariants) {
          if (productVariant.defaultVariant) {
            defaultDeliveryFormat =
              productVariant.deliveryFormatConfig.configKey;
          }
          if (
            !distinctDeliveryFormats.find(
              (df) => df.key === productVariant.deliveryFormatConfig.configKey
            )
          ) {
            distinctDeliveryFormats.push({
              key: productVariant.deliveryFormatConfig.configKey,
              value: productVariant.deliveryFormatConfig.configValue,
            });
          }
        }
        distinctDeliveryFormats.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        );

        this.restrictedDeliveryFormats.push(
          this.fb.group({
            sku: [product.sku],
            restrictedDeliveryFormat: [defaultDeliveryFormat],
            deliveryFormats: [distinctDeliveryFormats],
            name: [product.name],
          })
        );
      }
    }
  }

  private setInitialFormValue() {
    if (this.formInitialValue) {
      this.form.patchValue({
        channels: this.channels$.value.filter(
          (c: SimpleOption) =>
            this.formInitialValue.channels.findIndex(
              (cc) => c.key === cc.key
            ) !== -1
        ),
        groups: this.groups$.value.filter(
          (g: SimpleOption) =>
            this.formInitialValue.groups.findIndex((gg) => g.key === gg.key) !==
            -1
        ),
        transactionType: this.transactionTypes$.value.find(
          (t) => t.value === this.formInitialValue.transactionType.value
        ),
        approvalRequired: this.formInitialValue.approvalRequired,
        approvalRule:
          this.formInitialValue.approvalRule &&
          this.approvalRules$.value.find(
            (r) => r.id === this.formInitialValue.approvalRule.id
          ),
      });

      if (this.formInitialValue.restrictedDeliveryFormats) {
        for (const { sku, restrictedDeliveryFormat } of this.formInitialValue
          .restrictedDeliveryFormats) {
          const deliveryFormatSelection = this.restrictedDeliveryFormats.controls.find(
            (c) => c.get("sku").value === sku
          );
          if (deliveryFormatSelection) {
            deliveryFormatSelection.patchValue({
              restrictedDeliveryFormat,
            });
          }
        }
      }
    }
  }

  private formValueToDto(formValue: {
    channels: SimpleOption[];
    groups: SimpleOption[];
    transactionType?: SimpleOption;
    approvalRequired?: boolean;
    approvalRule?: ApprovalPolicy;
    restrictedDeliveryFormats: {
      sku: string;
      restrictedDeliveryFormat: string;
    }[];
  }): PublishToLxpFormValueDto {
    return {
      channels: formValue.channels,
      groups: formValue.groups,
      transactionType: formValue.transactionType,
      approvalRequired: formValue.approvalRequired,
      approvalRule: formValue.approvalRule,
      restrictedDeliveryFormats: formValue.restrictedDeliveryFormats,
    };
  }
  setProductType(deliveryFormat) {
    switch (deliveryFormat) {
      case "diagnostics":
        this.productType = "Diagnostic";
        break;
      case "product-assessment":
        this.productType = "Assessment";
        break;
      case "lab":
        this.productType = "vLab";
        break;
      case "eLearning":
        this.productType = "course";
        break;
    }
  }

  private get isBundle(): boolean {
    return Boolean(!this.isBulk && this.products[0].bundle);
  }

  onSearch(channelName: string): void {
    this.channelsInput$.next(channelName);
  }
}
