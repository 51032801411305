import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'leap-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlErrorComponent {
  _text: string;
  _hide = true;
  _errors: any;
  _templateRef: TemplateRef<any>;

  @Input()
  set text(value) {
    if (value !== this._text) {
      this._text = value;
      this.cdr.detectChanges();
    }
  }

  @Input()
  set errors(value) {
    if (value !== this._errors) {
      this._errors = value;
      this.cdr.detectChanges();
    }
  }

  @Input()
  set templateRef(value) {
    if (value !== this._templateRef) {
      this._templateRef = value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
