import * as tslib_1 from "tslib";
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
const ɵ0 = (state) => state.session.groups;
// Reference for the tutorial:
// https://juristr.com/blog/2018/02/angular-permission-directive/
export class HasGroupDirective {
    constructor(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.groups = [];
        this.logicalOp = 'AND';
        this.isHidden = true;
    }
    ngOnInit() {
        this.groups$.subscribe(_ => {
            this.currentUserGroups = _;
            this.updateView();
        });
    }
    set leapHasGroup(val) {
        this.groups = val;
        this.updateView();
    }
    set leapHasGroupOp(permop) {
        this.logicalOp = permop;
        this.updateView();
    }
    updateView() {
        if (this.checkGroup()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        }
        else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }
    checkGroup() {
        let hasGroup = false;
        if (!this.groups || !this.groups.length) {
            return true; //means public
        }
        if (this.currentUserGroups) {
            for (const checkGroup of this.groups) {
                const groupFound = this.currentUserGroups.find(x => x.toUpperCase() === checkGroup.toUpperCase());
                if (groupFound) {
                    hasGroup = true;
                    if (this.logicalOp === 'OR') {
                        break;
                    }
                    if (this.logicalOp === 'NOR') {
                        break;
                    }
                }
                else {
                    hasGroup = false;
                    if (this.logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }
        if (this.logicalOp === 'NOR') {
            hasGroup = !hasGroup;
        }
        return hasGroup;
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], HasGroupDirective.prototype, "groups$", void 0);
export { ɵ0 };
