import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ICountAggregate } from '../../models/products-search-params.model';

@Component({
  selector: 'leap-searchable-multiselect',
  templateUrl: './searchable-multiselect.component.html',
  styleUrls: ['./searchable-multiselect.component.less']
})
export class SearchableMultiselectComponent implements OnInit, OnChanges {
  maxCollapsedCount = 4;
  @Input()
  items: Array<ICountAggregate> = [];
  @Input()
  selectedItems: Array<string> = [];
  @Input()
  itemTemplate: TemplateRef<any>;
  @Input()
  enableRadioSelection: boolean = false;
  @Input()
  showSearchInput: boolean = true;

  @Output()
  itemsStateChanged = new EventEmitter<string[]>();

  public radioSelectionModel: any = null;

  filter: string;
  expanded: boolean;
  shownItems: Array<SearchableItem>;
  numberOfSearchResults: number;

  nzRadioSelectionStyles = {
    lineHeight: '20px',
    fontSize: '14px'
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    const { items } = changes;
    if (items) {
      this.shownItems = this.computeShownItems(items.currentValue, this.filter);
    }
    this.radioSelectionModel = Array.isArray(this.selectedItems) ? (this.selectedItems.length ? this.selectedItems[0] : null) : this.selectedItems;
  }

  computeShownItems(items: Array<ICountAggregate>, filter: string): Array<SearchableItem> {
    if (!items) {
      return [];
    }

    let filterApplied = false;
    if (filter && filter.trim() !== '') {
      filter = filter.trim().toLowerCase();
      filterApplied = true;
      items = items.filter(i => i.displayValue && i.displayValue.toLowerCase().indexOf(filter) > -1);
    }

    let result: Array<SearchableItem> = items.map(i => {
      const { displayValue } = i;
      const tokenizedItem = { id: i.id, displayValue, count: i.count, tokens: null };
      // Tokenize the displayValue for highlight if filter is applied
      if (filterApplied) {
        const pos = i.displayValue.toLowerCase().indexOf(filter);
        tokenizedItem.tokens = [
          { token: displayValue.substr(0, pos) },
          { token: displayValue.substr(pos, filter.length), highlight: true },
          { token: displayValue.substr(pos + filter.length) }
        ];
      } else {
        tokenizedItem.tokens = [{ token: i.displayValue }];
      }

      return tokenizedItem;
    });
    this.numberOfSearchResults = result.length;

    if (!this.expanded) {
      const selectedLength = this.selectedItems ? this.selectedItems.length : 0;
      const resultSetSize = Math.max(this.maxCollapsedCount, selectedLength);
      result = result.slice(0, resultSetSize);
    }

    if (filterApplied) {
      result = result.sort((i1, i2) => {
        const included1 = this.selectedItems.includes(i1.id);
        const included2 = this.selectedItems.includes(i2.id);
        if (included1 && !included2) {
          return 1;
        } else if (!included1 && included2) {
          return -1;
        } else {
          return i1.displayValue.localeCompare(i2.displayValue);
        }
      });
    }

    return result;
  }

  ngOnInit() { }

  trackById(index, item: ICountAggregate) {
    return item.id;
  }

  isSelected(key: string): boolean {
    return this.selectedItems && this.selectedItems.includes(key);
  }

  collapse() {
    this.expanded = false;
    this.shownItems = this.computeShownItems(this.items, this.filter);
  }

  expand() {
    this.expanded = true;
    this.shownItems = this.computeShownItems(this.items, this.filter);
  }

  filterChanged(filter: string) {
    this.filter = filter;
    this.shownItems = this.computeShownItems(this.items, filter);
  }

  selectionChanged(array: string[]) {
    this.itemsStateChanged.emit(array);
  }

  get hasActiveFilter(): boolean {
    return this.filter && this.filter !== '';
  }
}

interface SearchableItem extends ICountAggregate {
  tokens: Array<{ token: string; highlight?: boolean }>;
}
