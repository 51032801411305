import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Pipe({
  name: 'amberError'
})
export class AmberErrorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value instanceof HttpErrorResponse) {
      switch (value.status) {
        case 400:
          const { error: amberError } = value.error;
          let message;
          switch(amberError.message) {
            case 'ec.virgo.assignment.approach.open':
              message = 'Assignment must be open';
              break;
            case 'ec.virgo.assignment.approach.same-account':
              message = 'Cannot invite instructor from the same account';
              break;
            case 'ec.virgo.assignment.approach.already-invited':
              message = 'Instructor already invited to assignment';
              break;
            case 'ec.payment.cc.failed':
              message = 'Payment failed';
              break;
          }
          return amberError.message;
        case 401:
          return 'It appears you are not authorized to do this, possibly due to an expired session. Please log in and try again.';
        case 403:
          return 'You do not have permissions to perform this action. Please contact your administrator or technical support for more details.';
        case 404:
          return 'The requested resource could not be found.';
        case 500:
          return 'Something went wrong with our servers. Please wait a bit and try again.';
      }
    }
    return value;
  }
}
