import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from "@angular/core";
import { NavigateToLogout } from "../../../auth/actions/auth.actions";
import { Select, Store } from "@ngxs/store";
import { AuthTokenState } from "../../../auth/state/auth-token.state";
import { Observable, Subject } from "rxjs";
import { OpenShoppingCartDrawer } from "src/app/shopping-cart/actions/shopping-cart.actions";
import { map, filter } from "rxjs/operators";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { SelectSubtopic, GlobalFilterProducts } from "src/app/products/actions/products.actions";
import { DoGlobalSearch, ClearGlobalSearch } from "../../actions/global-filters.actions";
import { EnvironmentService } from "src/app/snatch/services";
import { GetVmsFlag } from "../../actions/core.actions";
import { GetCatalogTree } from "../../actions/dictionaries.actions";
import { untilDestroyed } from "ngx-take-until-destroy";
const ɵ0 = (state) => state.core.getProfilePending, ɵ1 = (state) => state.core.profile, ɵ2 = (state) => state.core.vmsEnabled, ɵ3 = (state) => state.session.groups, ɵ4 = (state) => state.session.userDetails, ɵ5 = (state) => state.core.dictionaries.topicsTree, ɵ6 = (state) => state.shoppingCart.lineItems, ɵ7 = (state) => state.core.dictionaries.catalogTree, ɵ8 = (state) => state.core.integrationEnabled, ɵ9 = (state) => state.domain.domainConfiguration;
export class NavbarComponent {
    constructor(store, route, router, environment) {
        this.store = store;
        this.route = route;
        this.router = router;
        this.environment = environment;
        this.menuState = false;
        this.showSignInBanner = true;
        // Use for takeUntil to unsubscribe onDestroy
        this.ngUnsubscribe = new Subject();
        this.innerWidth = window.innerWidth;
        this.shoppingCartLineItemsCount$ = this.shoppingCartlineItems$
            .pipe(map(_ => _.length));
    }
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }
    ngOnInit() {
        this.domainConfiguration$.subscribe(data => {
            if (data && data.logo) {
                this.navLogo = `${this.environment.s3Url}/${this.environment.cdnBucket}${data.logo.marketplaceHeader.url}`;
            }
        });
        this.isLogged$
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
            this.isLogged = _;
        });
        this.isWelcomePage = this.route.snapshot["_routerState"].url.startsWith("/welcome");
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd), map((_) => _.urlAfterRedirects.startsWith("/welcome")))
            .subscribe((_) => {
            this.isWelcomePage = _;
        });
        this.store.dispatch(new GetCatalogTree());
        this.userDetails$
            .subscribe(data => data && data.domain ? this.store.dispatch(new GetVmsFlag(data.domain)) : null);
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    login() {
        this.router.navigate(['login']);
    }
    register() {
        const url = `${this.environment.saharaUrl}/register`;
        window.open(url, "_blank", "noopener,noreferrer");
    }
    switchAccount() {
        this.router.navigate(['login']);
    }
    logout() {
        this.store.dispatch(new NavigateToLogout());
    }
    openShoppingCartDrawer() {
        this.store.dispatch(new OpenShoppingCartDrawer());
    }
    topicSelect(subTopic) {
        this.store.dispatch([
            new SelectSubtopic(subTopic)
        ]);
        this.router.navigate(["products"], { queryParams: { subtopic: subTopic } });
    }
    filterProducts(filter) {
        this.store.dispatch([
            new GlobalFilterProducts(filter, null, null),
            new ClearGlobalSearch()
        ]);
        this.router.navigate(['products'], { queryParams: { filter } });
    }
    filterProduct({ hit }) {
        this.store.dispatch([
            new ClearGlobalSearch()
        ]);
        this.router.navigate(['products', 'details', hit.key]);
        if (this.menuState) {
            this.menuState = false;
        }
    }
    filterSeller({ hit }) {
        this.store.dispatch([
            new GlobalFilterProducts(null, [hit.key], null),
            new ClearGlobalSearch()
        ]);
        this.router.navigate(["products"], { queryParams: { sellers: hit.key } });
    }
    filterSubtopic({ hit }) {
        this.store.dispatch([
            new GlobalFilterProducts(null, [], hit.key),
            new ClearGlobalSearch()
        ]);
        this.router.navigate(["products"], { queryParams: { subtopic: hit.key } });
    }
    doGlobalSearch(ev) {
        this.store.dispatch([
            new DoGlobalSearch(ev.context)
        ]);
    }
    navigateToServiceMarket() {
        window.location.href = `${this.environment.serviceMarketUrl}${this.isLogged ? '?leapest-force-login=true' : ''}`;
    }
    toggleMenu(state) {
        this.menuState = state;
    }
}
tslib_1.__decorate([
    Select(AuthTokenState.isLogged),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "isLogged$", void 0);
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "getProfilePending$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "profile$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "vmsEnabled$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "groups$", void 0);
tslib_1.__decorate([
    Select(ɵ4),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "userDetails$", void 0);
tslib_1.__decorate([
    Select(ɵ5),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "topicsTree$", void 0);
tslib_1.__decorate([
    Select(ɵ6),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "shoppingCartlineItems$", void 0);
tslib_1.__decorate([
    Select(ɵ7),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "catalogs$", void 0);
tslib_1.__decorate([
    Select(ɵ8),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "integrationEnabled$", void 0);
tslib_1.__decorate([
    Select(ɵ9),
    tslib_1.__metadata("design:type", Observable)
], NavbarComponent.prototype, "domainConfiguration$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
