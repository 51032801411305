import * as tslib_1 from "tslib";
import { Action, State, Store } from "@ngxs/store";
import { UrlStorageService } from "../../products/services/url-storage.service";
import { SuggestionsService } from "../services/suggestions.service";
import { FetchSuggestions } from "../actions/suggestion.actions";
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
export var SuggestionType;
(function (SuggestionType) {
    SuggestionType["COURSES"] = "courses";
    SuggestionType["COURSE_EVENTS"] = "course_events";
    SuggestionType["INSTRUCTORS"] = "instructors";
    SuggestionType["PRODUCTS"] = "products";
})(SuggestionType || (SuggestionType = {}));
;
let SuggestionsState = class SuggestionsState {
    constructor(store, service, urlStorageService) {
        this.store = store;
        this.service = service;
        this.urlStorageService = urlStorageService;
    }
    FetchSuggestions({ patchState }, action) {
        patchState({ fetchingSuggestions: true });
        return this.service.getProduct(8, 0, action.filter, action.context).pipe(catchError(res => {
            patchState({ fetchingSuggestions: false });
            return of([]);
        }), tap((res) => {
            if (res.data) {
                patchState({
                    courseSuggestions: res.data.courseSuggestions,
                    courseEventSuggestions: res.data.courseEventSuggestions,
                    instructorSuggestions: res.data.instructorSuggestions,
                    fetchingSuggestions: false
                });
            }
        }));
    }
};
tslib_1.__decorate([
    Action(FetchSuggestions),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchSuggestions]),
    tslib_1.__metadata("design:returntype", void 0)
], SuggestionsState.prototype, "FetchSuggestions", null);
SuggestionsState = tslib_1.__decorate([
    State({
        name: 'suggestions',
        defaults: {
            courseSuggestions: undefined,
            courseEventSuggestions: undefined,
            instructorSuggestions: undefined,
            fetchingSuggestions: false
        }
    }),
    tslib_1.__metadata("design:paramtypes", [Store,
        SuggestionsService,
        UrlStorageService])
], SuggestionsState);
export { SuggestionsState };
