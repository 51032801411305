import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { SHOPPING_CART } from 'src/app/core/graphql/shipping-cart.fragment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AmberResponse } from "../../snatch/models/amber-response.model";
import { EnvironmentService } from "../../snatch/services";
import { SessionService } from "../../snatch/services/session.service";


const GET_SHOPPING_CART_DETAILS = gql`
  query {
    getMyBasketDetails {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const ADD_LINE_ITEM_TO_SHOPPING_CART = gql`
  mutation addBasketItem($input: BasketAddItem!) {
    addBasketItem(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const EDIT_LINE_ITEM_FROM_SHOPPING_CART = gql`
  mutation editBasketItem($input: BasketEditItem!) {
    editBasketItem(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const REMOVE_LINE_ITEM_FROM_SHOPPING_CART = gql`
  mutation removeBasketItem($input: BasketRemoveItem!) {
    removeBasketItem(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const EDIT_SHIPPING_METHOD = gql`
  mutation editShippingMethod($input: BasketShippingMethodEdit!) {
    editShippingMethod(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const ADD_PROMOTION_CODE = gql`
  mutation addPromoCode($input: BasketPromoCode!) {
    addPromoCode(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const REMOVE_PROMOTION_CODE = gql`
  mutation removePromoCode($input: BasketPromoCode!) {
    removePromoCode(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;

const GET_PROMOTION_CODES = gql`
query promotionCodes($input: PromotionCodeListQuery!) {
  promotionCodes(input: $input) {
    code,
    discountPercentage,
    unlimitedUsage,
    unitLimit,
    validTill
  }
}`;

const UPDATE_PURCHASE_ORDER = gql`
    mutation editBasketItemPurchaseOrder($input: BasketPurchaseOrderItem!) {
        editBasketItemPurchaseOrder(input: $input) {
            ...basketViewFragment
        }
    }
    ${SHOPPING_CART}
`

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  constructor(private apollo: Apollo,
              private environmentService: EnvironmentService,
              private sessionService: SessionService,
              private httpClient: HttpClient) { }

  getShoppingCartDetails() {
    return this.apollo
      .watchQuery({
        query: GET_SHOPPING_CART_DETAILS
      })
      .valueChanges;
  }

  addLineItem(sku: string, quantity: number = 1) {
    return this.apollo.mutate({
      mutation: ADD_LINE_ITEM_TO_SHOPPING_CART,
      variables: {
        input: { sku, quantity }
      }
    });
  }

  editLineItem(id: string, quantity: number) {
    return this.apollo.mutate({
      mutation: EDIT_LINE_ITEM_FROM_SHOPPING_CART,
      variables: {
        input: { id, quantity }
      }
    });
  }

  removeLineItem(id: string) {
    return this.apollo.mutate({
      mutation: REMOVE_LINE_ITEM_FROM_SHOPPING_CART,
      variables: {
        input: { id }
      }
    });
  }

  editShippingMethod(shippingGroupId: string, shippingMethodId: string) {
    return this.apollo.mutate({
      mutation: EDIT_SHIPPING_METHOD,
      variables: {
        input: { shippingGroupId, shippingMethodId }
      }
    });
  }

  fetchPromotionCodes(): Observable<any> {
    return this.apollo
        .watchQuery({
        query: GET_PROMOTION_CODES,
        variables: { input: { available: true } }
      })
      .valueChanges;
  }

  addPromotionCode(promotionCode: string) {
    return this.apollo.mutate({
        mutation: ADD_PROMOTION_CODE,
        variables: { input: { promoCode: promotionCode } }
      });
  }

  removePromotionCode(promotionCode: string) {
    return this.apollo.mutate({
        mutation: REMOVE_PROMOTION_CODE,
        variables: { input: { promoCode: promotionCode } }
      });
  }

  updatePurchaseOrderOnBasketItem(id: string, purchaseOrderIds: [string]) {
    return this.apollo.mutate({
      mutation: UPDATE_PURCHASE_ORDER,
      variables: { input : {
          id : id,
          purchaseOrderIds: purchaseOrderIds
        }
      }
    });
  }

  fetchAvailablePurchaseOrders(sellerIds: Array<String>) {
    const url = this.environmentService.amberUrl +
      '/odyssey/orders/purchase/listBySellerAccountIds';
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.sessionService.getToken()}`
    });

    return this.httpClient.post<AmberResponse<any>>(url, sellerIds, { params: params, headers: headers });
  }
}
