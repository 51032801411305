
import { AmberError } from 'src/app/snatch/models/amber-response.model';
import { Seller } from "../model/seller.model";
import { ProductShort } from "../../common/common-product/models/product-short.model";
import { ISearchMetadata } from "../../products/state/products.state";

export class GetSellerDetails {
    static readonly type = '[Seller Details] GetSellerDetails';
    constructor(public domain: string) {}
}
export class GetSellerDetailsSuccess {
    static readonly type = '[Seller Details] GetSellerDetails  success';
    constructor(public payload: Seller) {}
}
export class GetSellerDetailsFailure {
    static readonly type = '[Seller Details] GetSellerDetails failure';
    constructor(public payload: AmberError) {}
}
export class ToggleTab {
    static readonly type = '[Seller Details] Toggle Tab';
    constructor(public readonly key: any) {}
}
export class RestoreState {
    static readonly type = '[Seller Details] Restore State';
    constructor() {}
}
export class GetSellerPopularProducts {
    static readonly type = '[Seller Details] Getting popular products';
    constructor(public domain: string) {}
}
export class GetSellerProducts {
    static readonly type = '[Seller Details] Getting all products';
    constructor(public domain: string) {}
}
export class GetSellerProductsSuccess {
    static readonly type = '[Seller Details] GetSellerProducts  success';
    constructor(public payload: { products: ProductShort[], metadata: ISearchMetadata }) {}
}
export class GetSellerProductsFailure {
    static readonly type = '[Seller Details] GetSellerProducts failure';
    constructor(public payload: AmberError) {}
}
export class SellerProductChangePage {
    static readonly type = '[Seller Details] Change Page product list';
    constructor(public readonly payload: number) {}
}
export class SellerProductsRestoreProductsState {
    static readonly type = '[Seller Details] Restore State product list';
    constructor() {}
}
export class SellerProductsClearProducts {
    static readonly type = '[Seller Details] Clear seller products';
    constructor(public domain: string) {}
}
export class SellerProductsToggleSort {
    static readonly type = '[Seller Details] Seller Products Toggle Sort';
    constructor(public readonly key: string) {}
}

export class PreferSupplierFromSellerPage {
    static readonly type = '[Seller Details] Prefer seller from seller page';
    constructor(public domain: string, public preferred: boolean) {}
}

export class PreferSupplierFromSellerPageSuccess {
    static readonly type = '[Seller Details] Prefer seller from seller page - success';
    constructor(public payload: any) {}
}

export class PreferSupplierFromSellerPageFailure {
    static readonly type = '[Seller Details] Prefer seller from seller page - failure';
    constructor(public error: AmberError) {}
}
