import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leap-error-unauthorized',
  templateUrl: './error-unauthorized.component.html',
  styleUrls: ['./error-unauthorized.component.less']
})
export class ErrorUnauthorizedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
