<div class="outer">
  <div class="middle">
    <div class="inner">
      <div class="image-container">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/ed-marketplace-logo-color.png'" alt="EdCast Logo">
      </div>
      <div class="row col-xs-12 header" id="orbit-container">
        <img class="orbit-container" [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_1.svg'" alt="" id="img1">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_2.svg'" alt="" id="img2">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_3.svg'" alt="" id="img3">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_4.svg'" alt="" id="img4">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_5.svg'" alt="" id="img5">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_6.svg'" alt="" id="img6">
        <img [src]="environment.s3Url + '/' + environment.cdnBucket + '/images/book_not_found_7.svg'" alt="" id="img7">
        <span style="margin:0px 40px">404</span>
      </div>
      <div class="row col-xs-12 title">
        <span i18n="We are sorry this page doesnt exists|@@not-found-page.title">We're sorry, but this page does not exist</span>
      </div>
      <div class="row col-xs-12 body-header">
        <span i18n="We are sorry this page doesnt exists|@@not-found-page.body.title">Here are some suggestions:</span>
      </div>
      <div class="row col-md-12 body-core" style="margin-left: 30%;">
        <ul>
          <li i18n="First suggestion|@@not-found-page.body.suggestion1">Check for any mistakes in the URL of the page (e.g., what's written before .leapest.com ... )</li>
          <li i18n="Second suggestion|@@not-found-page.body.suggestion2">If you're certain that the URL is correct, try reloading the page</li>
          <li i18n="Third suggestion|@@not-found-page.body.suggestion3">If you are trying to access materials from a learning portal, try contacting your training provider</li>
          <li i18n="Fourth suggestion|@@not-found-page.body.suggestion4">Otherwise, consider contacting us at <a href="mailto:support@edcast.com">support@edcast.com</a></li>
        </ul>
      </div>
      <div class="row col-xs-12 body-core center-spaced">
        <span i18n="Page not found punchline|@@not-found-page.body.punchline">Our apologies for the inconvenience</span>
      </div>
    </div>
  </div>
</div>
