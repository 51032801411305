import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Injectable()
export class ShoppingCartGuard implements CanActivate {

  constructor(private store: Store, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
    const lineItems: any[] = this.store.snapshot().shoppingCart.lineItems;
    const allowed = !!lineItems.length || (state.url.includes('step=overview') && state.url.includes('orderNumber='));
    if (!allowed) this.router.navigate(['products']);

    return allowed;
  }
}
