//**********************************************************
// For now I am keeping it here until Dimi moves it somewhere
//**********************************************************
export const NotFound = 'base.not-found';
export const Forbidden = 'base.forbidden';
export const BasketIsEmptyErrorKey = 'basket.prepare-checkout.empty';
export const ErrorMessages = {
    'network.error': 'Something went wrong, please try again.',
    'basket.edit.promo-code-not-applicable' : 'Promotional code not applicable.',
    'basket.edit.promo-code-invalid' : 'Invalid promotional code.',
    'basket.edit.promo-code-revoked': 'Promotional code is revoked.',
    'basket.edit.promotion-not-started' : 'Promotion has not started.',
    'basket.checkout.invalid-card-details' : 'Your credit card was declined. Possibly because of insufficient funds or because of other restrictions on the card applied by your bank. If all of the card information seems correct (card number, CVC code, and billing zip code) and if you have tried multiple times unsuccessfully, please get in touch with your card issuer to ascertain the cause for the decline, and ask them to allow these payments.',
    'basket.add.already-in-basket' : 'Product already in basket.',
    'basket.edit.promo-code-expired' : 'Promotion code is expired.',
    'basket.prepare-checkout.empty' : 'Basket is empty.',
    'basket.edit.promo-code-reached-max-usage': 'This coupon is no longer available or expired. Please contact the issuer.'
};
