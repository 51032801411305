import { ProductShort } from 'src/app/common/common-product/models/product-short.model';
import { ISearchMetadata } from '../state/products.state';
import { AmberError } from 'src/app/snatch/models/amber-response.model';

export class FetchProducts {
  static readonly type = '[Product List] Fetch';
  constructor() {}
}
export class RestoreProductsState {
  static readonly type = '[Product List] Restore State';
  constructor() {}
}
export class FetchProductsSuccess {
  static readonly type = '[Product List] Fetch success';
  constructor(public payload: { products: ProductShort[], metadata: ISearchMetadata }) {}
}
export class FetchProductsFailure {
  static readonly type = '[Product List] Fetch failure';
  constructor(public payload: AmberError) {}
}
export class ToggleSort {
  static readonly type = '[Product List] Toggle Sort';
  constructor(public readonly key: string) {}
}
export class ChangePage {
  static readonly type = '[Product List] Change Page';
  constructor(public readonly payload: number) {}
}
export class ClearProducts {
  static readonly type = '[Product List] Clear';
  constructor() {}
}

export class FetchCategories {
  static readonly type = '[Product List] Fetch Categories';
  constructor(public code: string, public isCatalog?: boolean) {}
}
export class SelectTopic {
  static readonly type = '[Product List] Select Topic';
  constructor(public topic: string) {}
}
export class SelectSubtopic {
  static readonly type = '[Product List] Select Sub-Topic';
  constructor(public subTopic: string) {}
}
export class SelectCatalog {
  static readonly type = '[Product List] Select Catalog';
  constructor(public catalogs: string[]) {}
}
export class GlobalFilterProducts {
  static readonly type = '[Product List] Global Filter Products';
  constructor(public term: string, public sellers: string[], public subtopic: string) {}
}
export class FetchFavoriteSuppliers {
  static readonly type = '[Product List] Fetch favorite suppliers';
  constructor() {}
}
export class FetchMySuppliers {
  static readonly type = '[Product List] Fetch all suppliers from Odyssey';
  constructor() {}
}
