import { State, Action, StateContext, Selector, Store, NgxsOnInit } from '@ngxs/store';
import { InboxMessage, InboxConversation } from '../containers/models/inbox-message.model';
import produce from 'immer';
import { Navigate } from '@ngxs/router-plugin';
import { HedwigService } from 'src/app/core/services/hedwig.service';

export interface IPageable {
    limit?: number;
    page?: number;
}
export interface ISearchParams {
    topInstructors?: boolean;
    onlineDeliveries?: boolean;
    accredited?: boolean;
    minDayRate?: number;
    maxDayRate?: number;
    englishLevel?: string;
    otherLanguages?: Array<string>;
    topics?: Array<string>;
    countries?: Array<string>;
    coursePrograms?: Array<string>;
    filter?: string;
}

export class ChangeTypeFilter {
    static type = '[Inbox] Change Type Filter';
    constructor(public readonly type: string) { }
}
export class CreateDMConversation {
    static type = '[Inbox] Create DM Conversation';
    constructor(public readonly message: string) { }
}
export class CheckDMConversation {
    static type = '[Inbox] Check DM Conversation';
    constructor(public readonly domain: string) { }
}
export class FetchMessages {
    static type = '[Inbox] Fetch Messages';
    constructor(public channelId: string) { }
}
export class FetchOlderMessages {
    static type = '[Inbox] Fetch Older Messages';
    constructor() { }
}
export class SendMessage {
    static type = '[Inbox] Send Message';
    constructor(public message: string) { }
}
export class NewMessage {
    static type = '[Inbox] New Message';
    constructor(public message: InboxMessage) { }
}

export class MarkReadMessage {
    static type = '[Inbox] Mark Read Message';
    constructor() { }
}

export class SetConversations {
    static type = '[Inbox] Set Conversations';
    constructor(public conversations: any[]) { }
}

export class MarkAsSeen {
    static type = '[Inbox] Mark Conversations as Seen'
}

export class FetchConversations {
    static type = '[Inbox] Fetch Conversations';
    constructor() { }
}

export class NewConversation {
    static type = '[Inbox] New Conversation';
    constructor(public readonly conversation: InboxConversation) { }
}

export class UpdateConversation {
    static type = '[Inbox] Update Conversation';
    constructor(public readonly conversation: InboxConversation) { }
}

export class SelectConversation {
    static type = '[Inbox] Select Conversation';
    constructor(public conversationId: string) { }
}

export class SelectLatestConversation {
    static type = '[Inbox] Select Latest Conversation';
    constructor() { }
}

export class RedirectToInbox {
    static type = '[Inbox] Redirect To Inbox';
    constructor() { }
}

export class ClearInboxState {
    static type = '[Inbox] Clear Inbox State';
    constructor() { }
}

export interface InboxStateModel {
    typeFilter: 'dm';
    myDomain: undefined;

    selectedConversationId: string;
    selectedConversation: any;

    newMessageInSelectedConversation: boolean;
    conversations: InboxConversation[];
    messages: InboxMessage[];

    scrollToBottom: boolean;
    fetchingOlderMessages: boolean;

    searchForm: { model: { term: string } };

    latestSeenMessage: number;
    latestSeenMessageTimestamp: number;
    latestReceivedMessage: number;
    latestReceivedMessageTimestamp: number;
    unseenMessages: boolean;
}

@State<InboxStateModel>({
    name: 'inbox',
    defaults: {
        typeFilter: 'dm',
        myDomain: undefined,
        selectedConversationId: undefined,
        selectedConversation: undefined,

        newMessageInSelectedConversation: false,
        conversations: [],
        messages: [],

        scrollToBottom: false,
        fetchingOlderMessages: false,
        searchForm: { model: { term: undefined } },

        latestSeenMessage: undefined,
        latestSeenMessageTimestamp: undefined,
        latestReceivedMessage: undefined,
        latestReceivedMessageTimestamp: undefined,
        unseenMessages: false
    }
})
export class InboxState implements NgxsOnInit {

    constructor(
        private hedwigService: HedwigService,
        private store: Store,
    ) { }

    ngxsOnInit({ patchState }: StateContext<InboxStateModel>) {
        const userDetails = this.store.selectSnapshot((state: any) => state.session.userDetails);
        patchState({ myDomain: userDetails ? userDetails.domain : null});
    }

    @Selector()
    static filteredConversations(state: InboxStateModel) {
        const filter = (state.searchForm.model.term || '').toLocaleLowerCase();
        let conversations = state.conversations;
        switch ('dm') {
            case 'dm':
                conversations = conversations.filter(conversation => conversation.channel.type === 'dm')
            // tslint:disable-next-line:no-switch-case-fall-through
            default:
                break;
        }
        const filteredConversations = conversations.filter(conversation => {
            return conversation.channel.name.toLocaleLowerCase().indexOf(filter) >= 0
        });
        return filteredConversations;
    }

    @Action(RedirectToInbox)
    redirectToInbox(ctx: StateContext<InboxStateModel>) {

        setTimeout(_ => {
            const state = ctx.getState();
            const latestConversation = state.conversations[0];
            ctx.patchState({
                selectedConversationId: latestConversation.channelId,
                selectedConversation: latestConversation,
                typeFilter: 'dm'
            })

            ctx.dispatch([new Navigate(['inbox'])]);
        }, 1000);
    }

    @Action(FetchMessages)
    fetchMessages(ctx: StateContext<InboxStateModel>, { channelId }: FetchMessages) {
        ctx.patchState({ scrollToBottom: false })

        const state = ctx.getState();
        const lastMessageId = (state.selectedConversation.channel && state.selectedConversation.channel.lastMessage) ? state.selectedConversation.channel.lastMessage.id : 1;

        return this.hedwigService.fetchMessages(channelId, lastMessageId, 10)
            .subscribe(resp => {
                ctx.patchState({ messages: resp.result.reverse(), scrollToBottom: true });
                ctx.dispatch(new MarkReadMessage());
            })
    }

    @Action(FetchOlderMessages)
    fetchOlderMessages(ctx: StateContext<InboxStateModel>) {
        ctx.patchState({ fetchingOlderMessages: true })
        const state = ctx.getState();

        if (!state.messages || !state.messages.length) return;

        const lastMessageId = state.messages[0].id;
        return this.hedwigService.fetchMessages(state.selectedConversationId, lastMessageId, 10)
            .subscribe(resp => {
                const messages = produce(state.messages, messages => {
                    return resp.result.filter(_ => _.id !== lastMessageId).reverse().concat(messages)
                });

                ctx.patchState({ messages: messages, fetchingOlderMessages: false })
            })
    }

    @Action(ChangeTypeFilter)
    changeTypeFilter(ctx: StateContext<InboxStateModel>, { type }: ChangeTypeFilter) {
        ctx.patchState({ typeFilter: 'dm', conversations: ctx.getState().conversations })
    }

    @Action(SelectConversation)
    selectConversation(ctx: StateContext<InboxStateModel>, { conversationId }: SelectConversation) {
        const selectedConversation = ctx.getState().conversations.find(_ => _.channelId === conversationId);
        ctx.patchState({ selectedConversationId: conversationId, selectedConversation, messages: [] });
        ctx.dispatch([new FetchMessages(conversationId)]);
    }

    @Action(SendMessage)
    sendMessage(ctx: StateContext<InboxStateModel>, { message }: SendMessage) {
        ctx.patchState({ newMessageInSelectedConversation: false })
        const state = ctx.getState();
        this.hedwigService.sendMessage(state.selectedConversationId, message)
            .subscribe(_ => {
                const messages = produce(state.messages, messages => {
                    messages.push(_)
                });

                ctx.patchState({ messages, newMessageInSelectedConversation: true });
            })
    }

    @Action(NewMessage)
    newMessage(ctx: StateContext<InboxStateModel>, { message }: NewMessage) {
        ctx.patchState({ newMessageInSelectedConversation: false, scrollToBottom: false })
        const state = ctx.getState();
        let unseenMessages = true;
        if (message['channel'].id === state.selectedConversationId) {
            const messages = produce(state.messages, messages => {
                messages.push(message)
            });

            unseenMessages = false;

            ctx.patchState({ messages, newMessageInSelectedConversation: true, scrollToBottom: true })
            ctx.dispatch([new MarkReadMessage()]);

        }

        // if (!message.sender || (message.sender.domain !== this.store.selectSnapshot(state => state.session.userDetails.domain)))
        ctx.patchState({ latestReceivedMessage: message.id, unseenMessages })
        ctx.dispatch([new FetchConversations()]);
    }

    @Action(MarkReadMessage)
    markReadMessage(ctx: StateContext<InboxStateModel>, { }: MarkReadMessage) {
        const state = ctx.getState();
        const messageId = state.messages.length ? state.messages[state.messages.length - 1].id : 1;
        const messageTimestamp = state.messages.length ? state.messages[state.messages.length - 1].timestamp : 1;
        this.hedwigService.markReadMessage(state.selectedConversationId, messageId)
            .subscribe(_ => { })

        const conversations = produce(state.conversations, conversations => {
            let conversation = conversations.find(_ => _.channelId === state.selectedConversationId);
            conversation.unreadCount = 0;
        });
        const latestSeenMessage = state.latestSeenMessageTimestamp > messageTimestamp ? state.latestSeenMessage : messageId;
        const latestSeenMessageTimestamp = state.latestSeenMessageTimestamp > messageTimestamp ? state.latestSeenMessageTimestamp : messageTimestamp;

        ctx.patchState({ conversations, latestSeenMessage, latestSeenMessageTimestamp, unseenMessages: false })
    }

    @Action(SetConversations)
    setConversations(ctx: StateContext<InboxStateModel>, { conversations }: SetConversations) {
        const filteredConversations = conversations.filter(conversation => conversation.channel.type === 'dm');
        const lastConversation = filteredConversations[filteredConversations.length - 1];
        const latestSeenMessage = lastConversation.lastReadMessageId;
        const latestReceivedMessage = lastConversation.channel.lastMessage ? lastConversation.channel.lastMessage.id : 0;
        const latestReceivedMessageTimestamp = lastConversation.channel.lastMessage ? lastConversation.channel.lastMessage.timestamp : 0;
        const unseenMessages = !!lastConversation.unreadCount;

        ctx.patchState({ conversations: this.formatConversations(conversations), latestSeenMessage, latestReceivedMessage, latestReceivedMessageTimestamp, unseenMessages })
    }

    @Action(NewConversation)
    newConversation(ctx: StateContext<InboxStateModel>, { conversation }: NewConversation) {
        const state = ctx.getState();

        const conversations: InboxConversation[] = <any>produce(state.conversations, conversations => {
            conversations.unshift(<any>conversation);
            return conversations;
        });

        ctx.patchState({ conversations })

        if (conversation.channel.lastMessage.sender !== this.store.selectSnapshot(state => state.session.userDetails.username))
            ctx.patchState({
                latestReceivedMessage: conversation.channel.lastMessage.id,
                latestReceivedMessageTimestamp: conversation.channel.lastMessage.timestamp,
                unseenMessages: true
            })

        ctx.dispatch([]);
    }

    @Action(UpdateConversation)
    updateConversation(ctx: StateContext<InboxStateModel>, { conversation }: UpdateConversation) {
        const state = ctx.getState();
        let updatedIndex = -1;
        const conversations: InboxConversation[] = <any>produce(state.conversations, conversations => {
            updatedIndex = conversations.findIndex(_ => _.channel.id === conversation.channelId);
            if (updatedIndex >= 0) {
                let updateConversation = conversation;
                if (state.selectedConversation.channel.id === conversation.channelId)
                    updateConversation = {
                        ...updateConversation,
                        unreadCount: 0
                    };

                conversations[updatedIndex] = updateConversation;
            }

            return conversations
        });

        const patchObject: any = { conversations }

        if (updatedIndex >= 0 && (state.selectedConversation.channel.id === conversations[updatedIndex].channelId)) {
            patchObject.selectedConversation = patchObject.conversations[updatedIndex]
        }

        ctx.patchState(patchObject)

        ctx.dispatch([]);
    }

    @Action(SelectLatestConversation)
    selectLatestConversation(ctx: StateContext<InboxStateModel>) {
        const state = ctx.getState();
        const conversations = (state.conversations && state.conversations.filter(conversation => conversation.channel.type === 'dm'));
        const latestConversationId = state.selectedConversationId ? state.selectedConversationId : conversations.length ? conversations[0].channelId : undefined;
        if (latestConversationId) ctx.dispatch([new SelectConversation(latestConversationId)])
    }


    @Action(FetchConversations)
    fetchConversations(ctx: StateContext<InboxStateModel>) {
        const state = ctx.getState();
        // ctx.patchState({
        //     conversations: []
        // });
        return this.hedwigService.fetchChannels()
            .subscribe(conversations => {
                ctx.patchState({ conversations: this.formatConversations(conversations) })
            })
    }

    @Action(MarkAsSeen)
    markAsSeen(ctx: StateContext<InboxStateModel>) {
        const state = ctx.getState();

        ctx.patchState({
            latestSeenMessage: state.latestReceivedMessage,
            latestSeenMessageTimestamp: state.latestReceivedMessageTimestamp,
            unseenMessages: false
        })
    }

    @Action(CreateDMConversation)
    createDMConversation(ctx: StateContext<InboxStateModel>, { message }: CreateDMConversation) {
        this.hedwigService.createDMChannel(ctx.getState().selectedConversation.recipient.name)
            .subscribe((conversation: InboxConversation) => {

                ctx.patchState({
                    selectedConversationId: conversation.channelId,
                    selectedConversation: conversation,
                    typeFilter: 'dm',
                    messages: []
                })
                ctx.dispatch([new SendMessage(message), new FetchConversations(), new FetchMessages(conversation.channelId)]);
            })
    }

    @Action(CheckDMConversation)
    checkDMConversation(ctx: StateContext<InboxStateModel>, { domain }: CheckDMConversation) {
        this.hedwigService.checkDMChannel(domain)
            .subscribe((conversation: InboxConversation) => {

                const formattedConversation = conversation;

                if (!conversation.channelId) {
                    formattedConversation.channelId = 'new-dm';
                    formattedConversation.channel = {
                        accounts: [{ name: ctx.getState().myDomain }, <any>formattedConversation.recipient],
                        closed: false,
                        createdAt: null,
                        updatedAt: null,
                        context: <any>{},
                        id: 'new-dm',
                        key: `__hedwig-dm-${ctx.getState().myDomain}-${formattedConversation.recipient.name}`,
                        lastMessage: undefined,
                        name: 'Direct Chat',
                        status: 'active',
                        type: 'dm'
                    }
                }

                const conversations = produce(ctx.getState().conversations, (draft) => {
                    draft.unshift(formattedConversation)
                })

                ctx.patchState({
                    selectedConversationId: formattedConversation.channelId,
                    selectedConversation: formattedConversation,
                    typeFilter: 'dm',
                    conversations,
                    messages: []
                })

                const actions = [];
                if (conversation.channelId) actions.push(new FetchMessages(conversation.channelId));
                actions.push(new Navigate(['inbox']));
                ctx.dispatch(actions);
            })
    }

    @Action(ClearInboxState)
    clearInboxState(ctx: StateContext<InboxStateModel>) {
        const conversations = produce(ctx.getState().conversations, (draft) => {
            return draft.filter(_ => _.channelId !== 'new-dm')
        })

        ctx.patchState({
            selectedConversation: undefined,
            selectedConversationId: undefined,
            typeFilter: undefined,
            conversations,
            searchForm: { model: { term: undefined } }
        })
    }

    formatConversations(conversations: InboxConversation[]): InboxConversation[] {
        return conversations.sort((a, b) => {
            return (a.channel && b.channel && (a.channel.updatedAt > b.channel.updatedAt)) ? -1
                : ((a.channel && b.channel && (a.channel.updatedAt < b.channel.updatedAt)) ? 1 : 0);
        });
    }
}
