import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewChildren,
  QueryList, ContentChildren, AfterViewInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { Profile } from 'src/app/common/common-profile/models/profile.model';
import { filter, map } from 'rxjs/operators';
import { UtilsService } from 'src/app/snatch/services/utils.service';
import { EnvironmentService } from 'src/app/snatch/services';
import { Router } from '@angular/router';

enum DropdownItemColors {
  leapViolet = 'hover-leap-violet',
  leapGreenPaste = 'hover-leap-green-paste',
  leapBlue = 'hover-leap-blue'
}

@Component({
  selector: 'leap-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less', '../../navbar.less']
})
export class UserComponent implements OnInit, AfterViewInit {

  userMenuConfig : any = {};
  svgIconMap : any = {};
  menuInit = false;

  @Input() hasAlternativeAccounts: boolean;
  @Input() domain: string;
  @Input() getProfilePending$: Observable<boolean>;
  @Input() vmsEnabled: boolean;
  @Input() profile$: Observable<Profile>;
  @Input() integrationEnabled: boolean;

  @Output() logout = new EventEmitter();
  @Output() switchAccount = new EventEmitter();

  @ViewChildren('allSvgContainer') allSvgContainer: QueryList<any>;

  profilePhotoUrl: string;

  constructor(
    private utils: UtilsService,
    private environment: EnvironmentService,
    private router: Router
  ) {}

  get defaultProfilePhotoUrl() {
    return this.environment.defaultProfilePhotoUrl;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.profile$.pipe(filter(_ => !!_))
      .subscribe((_ : any) => {
        this.setProfilePhotoUrl(_.employee);
      })
  }

  ngAfterViewInit(): void {
    this.parseSVGIcons();
    this.initMenuConfig();
    setTimeout(_ => this.menuInit = true);
  }

  integrationRedirect(){
    window.open(`${this.environment.integrationFramework}`)
  }

  setProfilePhotoUrl(profile: Profile): void {
    if (profile) {
      let s3Key = profile.image && profile.image.s3Key;
      let s3Bucket = profile.image && profile.image.s3Bucket;
      this.profilePhotoUrl = s3Key && s3Bucket ?
        this.utils.getFileUrl(s3Key, s3Bucket) : undefined;
    }
  }

  parseSVGIcons() {
    const allSVGNodes =
        this.allSvgContainer &&
        this.allSvgContainer.first &&
        this.allSvgContainer.first.nativeElement.childNodes;
    if (allSVGNodes && allSVGNodes.length) {
      allSVGNodes.forEach((node) => {
        const nodeName = node.attributes.name.nodeValue || node.attributes.id.nodeValue;
        this.svgIconMap[nodeName] = node.outerHTML;
      });
    }
  }

  initMenuConfig() {

    this.userMenuConfig = {
      productsAndCourses : {
        title : 'PRODUCTS AND ORDERS',
        items: [
          {
            svgTemplate : this.svgIconMap['mySellerFeed'],
            title : 'My Seller Feed',
            hoverClass : DropdownItemColors.leapViolet,
            groups : ['seller'],
            onClickCB : () => this.router.navigate([`seller/page/${this.domain}`])
          },
          {
            svgTemplate : this.svgIconMap['orderTemplates'],
            title : 'Order Templates',
            hoverClass : DropdownItemColors.leapViolet,
            groups : ['buyer'],
            onClickCB : () => this.router.navigate(['order-templates'])
          },
          {
            svgTemplate : this.svgIconMap['productOrders'],
            title : 'Product Orders',
            hoverClass : DropdownItemColors.leapViolet,
            groups : ['buyer'],
            onClickCB : () => this.router.navigate(['orders'])
          },
          // {
          //   svgTemplate : this.svgIconMap['productCatalogSettings'],
          //   title : 'Product Catalog Settings',
          //   hoverClass : DropdownItemColors.leapViolet,
          //   groups : ['buyer'],
          //   onClickCB : this.redirectToRoute.bind(this)
          // },
          {
            svgTemplate : this.svgIconMap['favourites'],
            title : 'Favorites',
            hoverClass : DropdownItemColors.leapViolet,
            groups : ['buyer'],
            onClickCB : () => this.router.navigate(['favorites'])
          }
        ]
      },
      vendorManagement: {
	      title: 'VENDOR MANAGEMENT',
	      items: [
		      {
			      svgTemplate: this.svgIconMap['mySuppliers'],
			      title: 'My Suppliers',
			      hoverClass: DropdownItemColors.leapBlue,
			      groups: ['buyer'],
			      onClickCB: () => this.router.navigate(['my-suppliers'])
		      },
          {
            svgTemplate: this.svgIconMap['transactionRecording'],
            title: 'Transaction Recording',
            hoverClass: DropdownItemColors.leapBlue,
            groups: ['buyer'],
            onClickCB: () => this.router.navigate(['transaction-recording'])
          },
          {
            svgTemplate: this.svgIconMap['transactions'],
            title: 'Transaction Reports',
            hoverClass: DropdownItemColors.leapBlue,
            groups: ['buyer'],
            onClickCB: () => this.router.navigate(['transactions'])
          },
          {
            svgTemplate: this.svgIconMap['orderTemplates'],
            title: 'Purchase Orders',
            hoverClass: DropdownItemColors.leapBlue,
            groups: ['buyer'],
            onClickCB: () => this.router.navigate(['purchase-orders'])
          }
	      ]
      }
      ,
      instructorServices : {
        title : 'INSTRUCTOR SERVICES',
        groups: ['seller'],
        items: [
          {
            svgTemplate : this.svgIconMap['editInstructorProfile'],
            title : 'Edit Instructor Profile',
            hoverClass : DropdownItemColors.leapBlue,
            groups : ['seller'],
            onClickCB : () => window.open(`${this.environment.instructorMarketUrl}/profile/instructor`)
          },
          // {
          //   svgTemplate : this.svgIconMap['instructorOrders'],
          //   title : 'Instructor Orders',
          //   hoverClass : DropdownItemColors.leapBlue,
          //   groups : ['seller'],
          //   onClickCB : this.redirectToRoute.bind(this)
          // },
          {
            svgTemplate : this.svgIconMap['instructorServiceSettings'],
            title : 'Instructor Service Settings',
            hoverClass : DropdownItemColors.leapBlue,
            groups : ['seller'],
            onClickCB : () => window.open(`${this.environment.instructorMarketUrl}/settings`)
          }
        ]
      },
      generalLeapestSettings : {
        title : 'GENERAL SETTINGS',
        items: [
          {
            svgTemplate : this.svgIconMap['dashboard'],
            title : 'Admin Panel',
            hoverClass : DropdownItemColors.leapGreenPaste,
            groups : null,
            onClickCB : () => window.open(`${this.environment.saharaUrl}?leapest-force-login=true`)
          },
          {
            svgTemplate : this.svgIconMap['notificationSettings'],
            title : 'Notification Settings',
            hoverClass : DropdownItemColors.leapGreenPaste,
            groups : null,
            onClickCB : () => this.router.navigate([`notifications/settings`])
          },
          {
            svgTemplate : this.svgIconMap['knowledgeBase'],
            title : 'Knowledge Base',
            hoverClass : DropdownItemColors.leapGreenPaste,
            groups : null,
            onClickCB : () => window.open(`${this.environment.saharaUrl}/support?leapest-force-login=true`)
          }
        ]
      }
    }
    
  }

  redirectToExternalLink(menuItem: any) {
    console.log('Redirect to external link');
  }

  redirectToRoute(menuItem: any) {
    console.log('Redirect to router state');
  }


}
