import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AmberError } from 'src/app/snatch/models/amber-response.model';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'leap-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeMessageComponent implements OnInit {

  @Input() recipient: string;
  @Input() pending: boolean;
  @Input() error: AmberError;

  readonly textMaxLength = 120;

  @Output() reply = new EventEmitter<any>();
  @Output() send = new EventEmitter<string>();

  form = this.fb.group({
    text: [null, []]
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  submitFn() {
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].updateValueAndValidity();
      }
    }

    if (this.form.valid) {
      this.reply.emit(this.form.value)
      this.form.reset();
      this.form.markAsPristine();
    }
  }

  sendMessage() {
    if (this.form.value.text && this.form.value.text.length) {
      this.send.emit(this.form.value.text);
      this.form.reset();
    }
  }

}
