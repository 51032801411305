/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-bar.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-bar.component";
import * as i4 from "@angular/router";
var styles_LoadingBarComponent = [i0.styles];
var RenderType_LoadingBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingBarComponent, data: {} });
export { RenderType_LoadingBarComponent as RenderType_LoadingBarComponent };
function View_LoadingBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "indeterminate"]], null, null, null, null, null))], null, null); }
export function View_LoadingBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-loading-bar", [], null, null, null, View_LoadingBarComponent_0, RenderType_LoadingBarComponent)), i1.ɵdid(1, 245760, null, 0, i3.LoadingBarComponent, [i4.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingBarComponentNgFactory = i1.ɵccf("leap-loading-bar", i3.LoadingBarComponent, View_LoadingBarComponent_Host_0, {}, {}, []);
export { LoadingBarComponentNgFactory as LoadingBarComponentNgFactory };
