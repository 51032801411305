/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-filters.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/global-search-bar/global-search-bar.component.ngfactory";
import * as i3 from "../components/global-search-bar/global-search-bar.component";
import * as i4 from "@ngxs/store";
import * as i5 from "../../../../snatch/services/environment.service";
import * as i6 from "@angular/common";
import * as i7 from "./navbar-filters.component";
import * as i8 from "@angular/forms";
var styles_NavbarFiltersComponent = [i0.styles];
var RenderType_NavbarFiltersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarFiltersComponent, data: {} });
export { RenderType_NavbarFiltersComponent as RenderType_NavbarFiltersComponent };
export function View_NavbarFiltersComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "leap-global-search-bar", [], null, [[null, "filterProducts"], [null, "filterProduct"], [null, "filterSeller"], [null, "filterSubtopic"], [null, "typeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterProducts" === en)) {
        var pd_0 = (_co.filterProductsFn($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterProduct" === en)) {
        var pd_1 = (_co.filterProductFn($event) !== false);
        ad = (pd_1 && ad);
    } if (("filterSeller" === en)) {
        var pd_2 = (_co.filterSellerFn($event) !== false);
        ad = (pd_2 && ad);
    } if (("filterSubtopic" === en)) {
        var pd_3 = (_co.filterSubtopicFn($event) !== false);
        ad = (pd_3 && ad);
    } if (("typeSelected" === en)) {
        var pd_4 = (_co.onTypeSelected($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_GlobalSearchBarComponent_0, i2.RenderType_GlobalSearchBarComponent)), i1.ɵdid(1, 245760, null, 0, i3.GlobalSearchBarComponent, [i4.Store, i5.EnvironmentService], { form: [0, "form"], searchHits: [1, "searchHits"] }, { filterProducts: "filterProducts", filterProduct: "filterProduct", filterSeller: "filterSeller", filterSubtopic: "filterSubtopic", typeSelected: "typeSelected" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchForm; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.searchHits$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NavbarFiltersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-navbar-filters", [], null, null, null, View_NavbarFiltersComponent_0, RenderType_NavbarFiltersComponent)), i1.ɵdid(1, 114688, null, 0, i7.NavbarFiltersComponent, [i8.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarFiltersComponentNgFactory = i1.ɵccf("leap-navbar-filters", i7.NavbarFiltersComponent, View_NavbarFiltersComponent_Host_0, { topicsTree: "topicsTree" }, { topicSelect: "topicSelect", filterProducts: "filterProducts", filterProduct: "filterProduct", filterSeller: "filterSeller", filterSubtopic: "filterSubtopic", doGlobalSearch: "doGlobalSearch" }, []);
export { NavbarFiltersComponentNgFactory as NavbarFiltersComponentNgFactory };
