/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./suggestions.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/suggestion-list/suggestion-list.component.ngfactory";
import * as i3 from "../../components/suggestion-list/suggestion-list.component";
import * as i4 from "../../../snatch/services/environment.service";
import * as i5 from "@ngxs/store";
import * as i6 from "./suggestions.component";
var styles_SuggestionsComponent = [i0.styles];
var RenderType_SuggestionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuggestionsComponent, data: {} });
export { RenderType_SuggestionsComponent as RenderType_SuggestionsComponent };
export function View_SuggestionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-suggestion-list", [], null, null, null, i2.View_SuggestionListComponent_0, i2.RenderType_SuggestionListComponent)), i1.ɵdid(1, 114688, null, 0, i3.SuggestionListComponent, [i4.EnvironmentService, i5.Store], { allowDay: [0, "allowDay"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.term; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SuggestionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-suggestions", [], null, null, null, View_SuggestionsComponent_0, RenderType_SuggestionsComponent)), i1.ɵdid(1, 114688, null, 0, i6.SuggestionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuggestionsComponentNgFactory = i1.ɵccf("leap-suggestions", i6.SuggestionsComponent, View_SuggestionsComponent_Host_0, { term: "term" }, {}, []);
export { SuggestionsComponentNgFactory as SuggestionsComponentNgFactory };
