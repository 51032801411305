import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EnvironmentService } from "../../snatch/services";
import { SessionService } from "../../snatch/services/session.service";
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
import * as i3 from "../../snatch/services/environment.service";
import * as i4 from "../../snatch/services/session.service";
const DO_GLOBAL_SEARCH = gql `
  query doGlobalSearch($input: SearchQuery!) {
    results: doGlobalSearch(input: $input) {
      products {
        key
        value
        isExactMatch
      }
      sellers {
        key
        value
        isExactMatch
      }
      subtopics {
        key
        value
        isExactMatch
      }
    }
  }
`;
const DO_GLOBAL_SEARCH_PRODUCTS = gql `
  query doGlobalSearch($input: SearchQuery!) {
    results: doGlobalSearch(input: $input) {
      productHits {
        product {
          sku,
          name,
          productVariants {
            sku,
            name,
            language,
            deliveryFormat,
            certificateType
          }
        }
        highlight
      }
    }
  }
`;
const DO_GLOBAL_SEARCH_COURSES = gql `
 query globalSearch($input: GlobalSearchQuery!) {
    results: globalSearch(input: $input) {
      courses {
        course { id, 
        code, 
        solarCourse { 
        format { 
        configValue 
        } } }
        highlight
      }
    }
  }
`;
const DO_GLOBAL_SEARCH_INSTRUCTORS = gql `
  query globalSearch($input: GlobalSearchQuery!) {
    results: globalSearch(input: $input) {
      instructors {
        instructor {
          id,
          displayName,
          accountDisplayName,
          image {
            s3Key,
            s3Bucket
          }
        },
        highlight
      }
    }
  }
`;
const GET_MY_EMPLOYEE = gql `
  query {
    employee: getMyEmployee {
      email,
      firstName,
      lastName,
      isAccountAdmin,
      account {
        id,
        currency,
        buyer,
        seller,
        vmsEnabled,
        lxpConfigured
      }
    }
  }
`;
const GET_CATALOGS = gql `
  query {
    catalogs: listAllCatalogs {
      name,
      code,
      catalogSvg,
      categories {
        code,
        name, 
        topics {
          code,
          title,
          imageSvg,
          children {
            code,
            title
          }
        }
      }
    }
  }
`;
const GET_DICTIONARIES = gql `
  query {
    currencies: getConfigurationsByType(configType: "currency") {
      key: configKey,
      value: configValue
    }
    countries: getConfigurationsByType(configType: "country") {
      key: configKey,
      value: configValue
    }
  }
`;
const GET_STATES = gql `
  query {
    states: getStates {
      countryCode,
      code,
      name
    }
  }
`;
const GET_SELLERS = gql `
  query {
    sellers: listSellerAccounts {
      name,
      displayName
    }
  }
`;
const GET_CATEGORIES = gql `
  query {
    categories: listAllCategories {
      name,
      code
    }
  }
`;
export class MiscService {
    constructor(apollo, http, envService, httpClient, sessionService) {
        this.apollo = apollo;
        this.http = http;
        this.envService = envService;
        this.httpClient = httpClient;
        this.sessionService = sessionService;
    }
    getEmployee() {
        return this.apollo
            .watchQuery({
            query: GET_MY_EMPLOYEE
        })
            .valueChanges;
    }
    getCatalogs() {
        return this.apollo
            .watchQuery({
            query: GET_CATALOGS
        })
            .valueChanges;
    }
    getDictionaries() {
        return this.apollo
            .watchQuery({
            query: GET_DICTIONARIES,
            pollInterval: 60000
        })
            .valueChanges;
    }
    getStates() {
        return this.apollo
            .watchQuery({
            query: GET_STATES
        })
            .valueChanges;
    }
    doGlobalSearch(term) {
        let input = {
            limit: 5,
            term,
        };
        return this.apollo
            .watchQuery({
            query: DO_GLOBAL_SEARCH,
            variables: { input }
        })
            .valueChanges;
    }
    doGlobalSearchProducts(term) {
        let input = {
            limit: 5,
            term,
        };
        return this.apollo
            .watchQuery({
            query: DO_GLOBAL_SEARCH,
            variables: { input }
        })
            .valueChanges;
    }
    doGlobalSearchCourses(term) {
        let input = {
            limit: 5,
            term,
        };
        return this.apollo
            .use('virgo')
            .watchQuery({
            query: DO_GLOBAL_SEARCH_COURSES,
            variables: { input }
        })
            .valueChanges;
    }
    doGlobalSearchInstructors(term) {
        let input = {
            limit: 5,
            term,
        };
        return this.apollo
            .use('virgo')
            .watchQuery({
            query: DO_GLOBAL_SEARCH_INSTRUCTORS,
            variables: { input }
        })
            .valueChanges;
    }
    getProductMarketplaceTranslations(language = 'en') {
        const url = this.envService.saharaUrl + '/api/translation/dictionary/' + language + '/product-marketplace';
        return this.http.get(url);
    }
    getUserViewPermissionSettings(domain) {
        const url = this.envService.saharaUrl + '/api/account-features/details/' + domain;
        const params = new HttpParams();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.sessionService.getToken()}`
        });
        return this.httpClient.get(url, { params: params, headers: headers });
    }
}
MiscService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MiscService_Factory() { return new MiscService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.EnvironmentService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i4.SessionService)); }, token: MiscService, providedIn: "root" });
