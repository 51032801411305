import { State, StateContext, Action } from '@ngxs/store';
import { DomainConfiguration } from '../actions/auth.actions';

import { Injectable } from '@angular/core';

export class DomainConfigurationStateModel {
    public domainConfiguration?: Object;
}

@State<DomainConfigurationStateModel>({
    name: 'domain',
    defaults: {
        domainConfiguration: undefined
    }
})
@Injectable()
export class DomainConfigurationState {
    @Action(DomainConfiguration)
    DomainConfiguration(
        { patchState }: StateContext<DomainConfigurationStateModel>, action: DomainConfiguration
    ) {
        patchState({
            domainConfiguration: action.payload
        });
    }
}