import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IFilter, SortTypes } from "src/app/snatch/models/filters.model";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { FilterService } from "../../services/filter.service";

@Component({
  selector: "[leap-suppliers-sort-bar]",
  templateUrl: "./multi-table-sort-bar.component.html",
  styleUrls: ["./multi-table-sort-bar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiTableSortBarComponent implements OnInit {
  @Input() title?: string;
  @Input() sortFields: IFilter[];

  @Output() toggleSort = new EventEmitter<IFilter[]>();

  public multiSelectForms: FormGroup;

  private selectedFilters: IFilter[] = [];


  constructor(private filterService: FilterService) {
  }

  ngOnInit() {
    this.initMultiSelectForms();

  }


  initMultiSelectForms() {
    let group = {};
    this.sortFields.forEach(s => {
      if (s.type == SortTypes.FIELD) {
        group[s.value] = new FormArray([]);
      }
    });

    this.multiSelectForms = new FormGroup(group);

  }


  public selectApiFilter(ev, filter: IFilter) {
    let { checked, value,nextSibling } = ev.target;
    const formArray = this.multiSelectForms.controls[filter.value] as FormArray;
    if (checked) {
      formArray.push(new FormControl(value));
    } else {
      const findIndex = formArray.value.findIndex(v => v === value);
      formArray.removeAt(findIndex);
    }
    this.sortFields.forEach( s => {
      if(s.value === filter.value){
	      s.items.forEach(i => {
		      if (i.label == nextSibling.textContent) i.checked = checked;
	      })
      }
    })

  }

  public selectSortFilter(filter: IFilter) {
    const getPrevOrder = Number(this.after(filter.order, "="));
    let order = 0;
    if (getPrevOrder === 0) {
      order = 1;
    } else if (getPrevOrder === 1) {
      order = -1;
    } else {
      order = 1;
    }
    filter.order = `$${filter.value}=${order}`;
    this.pushFilter(filter, true);
  }

  public applyFieldFilters(filter: IFilter) {
    const formArray = this.multiSelectForms.controls[filter.value] as FormArray;

    let query = "";
    formArray.value.forEach(v => {
      query += `&${filter.value}=${v}`;
    });
    filter.order = query;

    this.pushFilter(filter);
  }

  public pushFilter(filter, sort = false) {
    const findTheSameIndex = this.selectedFilters.findIndex(s => s.value == filter.value);

    if (findTheSameIndex >= 0) {
      this.selectedFilters.splice(findTheSameIndex, 1);
      if (sort) {
        this.selectedFilters.unshift(filter);
      } else this.selectedFilters.push(filter);
    } else {
      if (sort) {
        this.selectedFilters.unshift(filter);
      } else this.selectedFilters.push(filter);
    }
    this.filterService.setFilter(filter,sort);
		this.callFilters(filter);
	}

  private callFilters(filter: IFilter) {
	  const filters = this.filterService.getSelectedFiltersParamsAsObject();
	  const applyFilter = {...filter};
	  applyFilter.order =  filters;
	  applyFilter.action(applyFilter);
  }

  public resetFieldFilter(filter: IFilter) {
    this.filterService.removeFilter(filter);

	  const filters = this.filterService.getSelectedFiltersParamsAsObject();
    filter.order = filters;
    filter.action(filter);

	  this.sortFields.forEach( s => {
		  if(s.value === filter.value){
			  s.items.forEach(i => {
				 i.checked = false;
			  })
		  }
	  })
  }

  public isFilterSelected(filterKey: string) {
    return this.selectedFilters.find(s => s.value == filterKey);
  }

  public getDirection(filter: IFilter) {
    const isSelected = this.selectedFilters.find(s => s.value == filter.value);
    if (isSelected) {
      return Number(this.after(isSelected.order, "="));
    }
    return null;
  }

  public after(str, substr) {
    return str.slice(str.indexOf(substr) + substr.length, str.length);
  }
}
