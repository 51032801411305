<nz-card
  class="card {{ status }}"
  [ngClass]="{ active: isActive }"
  style="cursor: pointer"
  [ngClass]="{ active: isActive }"
>
  <div class="top-container">
    <div style="display: flex;">
      <ng-container *ngIf="imageUrl; else imagePlaceholder">
        <div class="image-container">
          <img class="image" alt="Image" [src]="imageUrl" />
        </div>
      </ng-container>
      <ng-template #imagePlaceholder>
        <div class="image-placeholder"></div>
      </ng-template>
      <h5>{{ recipient?.displayName | ellipsis: [35] }}</h5>
    </div>
    <span class="timestamp">{{ timestamp | date: "dd/MM/yyyy hh:mm a" }}</span>
  </div>
  <div class="message-container">
    <span
      class="last-message"
      [ngClass]="{ unread: _unreadCount, 'no-last-message': !lastMessage }"
      ><span
        class="you-span"
        *ngIf="lastMessage && myDomain === lastMessageSenderDomain"
        >You:</span
      >
      {{ lastMessage || "New conversation" | ellipsis: [55] }}</span
    >
    <nz-badge
      [nzStyle]="{
        backgroundColor: '#FA4616',
        borderRadius: '4px',
        fontSize: '12px',
        paddingLeft: '4px',
        paddingRight: '4px',
        height: '18px'
      }"
      [nzCount]="_unreadCount"
    ></nz-badge>
  </div>
  <div class="bottom-container" [ngSwitch]="status">
    <!-- Default -->
    <ng-container *ngSwitchDefault> </ng-container>
  </div>
</nz-card>
