import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ShippingMethod } from 'src/app/shopping-cart/models/shipping-method.model';

@Component({
  selector: 'leap-shipping-method-form',
  templateUrl: './shipping-method-form.component.html',
  styleUrls: ['./shipping-method-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingMethodFormComponent implements OnInit {

  @Input() currencySignsMap: Map<string, string>;
  @Input() currency: string;
  @Input() selectedMethod: ShippingMethod;
  @Input() shippingMethods: ShippingMethod[];
  @Output() changeMethod: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onChangeMethod(method: any) {
    this.changeMethod.emit(method);
  }

}
