import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'leap-message-with-context',
  templateUrl: './message-with-context.component.html',
  styleUrls: ['./message-with-context.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageWithContextComponent implements OnInit {

  @Input() orientation: 'left' | 'right';
  @Input() imageUrl: string;
  @Input() timestamp: string;
  @Input() sender: string;

  constructor() { }

  ngOnInit() { }

}
