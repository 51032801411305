import { Injectable } from '@angular/core';
import { SessionCheckFailure, SessionCheckSuccess } from './actions/auth.actions';
import { SessionService } from '../snatch/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private sessionService: SessionService
  ) {}

  async initSession(): Promise<SessionCheckSuccess | SessionCheckFailure> {
    const token = this.sessionService.getToken();
    const isAuth = !!token;
    return isAuth ? new SessionCheckSuccess() : new SessionCheckFailure();
  }
}
