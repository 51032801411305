import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

import { ISearchMetadata, ISearchParams } from "../../../../products/state/products.state";
import { ICountAggregate } from "../../../../snatch/models/products-search-params.model";

@Component({
  selector: 'leap-product-filter-menu',
  templateUrl: './product-filter-menu.component.html',
  styleUrls: ['./product-filter-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductFilterMenuComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() searchMetadata: ISearchMetadata;
  @Input() searchForm: { model: ISearchParams };
  @Input() odysseySellers: Array<any>;
  @Input() isPermittedToPublishToLxp: boolean;
  @Input() isAuthorized: boolean;

  @Output() filterPatched = new EventEmitter<Partial<ISearchParams>>();
  @Output() favoriteSupplierSelection = new EventEmitter<any>();

  languagesPanelActive = true;
  deliveryFormatsPanelActive = true;
  productTypesPanelActive = true;
  categoriesPanelActive = true;
  levelPanelActive = true;
  sellersPanelActive = true;
  courseDeliveryPanelActive = true;
  // FIXME when BE refactored for my suppliers enable myFavourites and mySuppliers
  showFavorites: boolean = false;
  showMySuppliers: boolean = false;
  selectedSellerOdyssey: string = 'thesalmon';
  ngUnSubscribe: Subject<any>;
  nzRadioSelectionStyles = {
    lineHeight: '20px',
    fontSize: '14px'
  };
  showPublishedToLxp: boolean;
  showNotPpublishedToLxp: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    const routeParams = this.activatedRoute.snapshot.queryParams;
    // FIXME when BE refactored for my suppliers enable myFavourites and mySuppliers
    // if (routeParams.hasOwnProperty('favorites')) {
    //   this.showFavorites = true;
    // }
    // if (routeParams.hasOwnProperty('mysuppliers')) {
    //   this.showMySuppliers = true;
    // }
    this.customSellerSelection();
  }

  languagesSelected(languages: Array<string>) {
    this.filterPatched.emit({ languages });
  }

  deliveryFormatsSelected(deliveryFormats: Array<string>) {
    this.filterPatched.emit({ deliveryFormats });
  }

  productTypesSelected(productTypes: Array<string>) {
    this.filterPatched.emit({ productTypes });
  }

  courseDeliveryFormatsSelected(courseDeliveryFormats: Array<string>) {
    this.filterPatched.emit({ courseDeliveryFormats });
  }

  categoriesSelected(category: Array<string>) {
    this.filterPatched.emit({ category });
  }

  sellersSelected(sellers: Array<string>) {
    this.filterPatched.emit({ sellers });
  }

  levelSelected(level: Array<string>) {
    this.filterPatched.emit({ level });
  }

  publishedToLXPSelected(lxpProductStatus: string[]) {
    this.filterPatched.emit({ lxpProductStatus });
  }

  trackById(index, item: ICountAggregate) {
    return item.id;
  }

  customSellerSelection() {
    this.favoriteSupplierSelection.emit({
      showFavorites: this.showFavorites,
      showMySuppliers: this.showMySuppliers
    });

    this.navigateToParams();
  }

  selectionChanged() {
    console.log(this.selectedSellerOdyssey);
  }

  navigateToParams() {
    let queryParamMap: any = this.activatedRoute.snapshot.queryParamMap;
    let paramsToPersist: any = { ...queryParamMap['params'] };

    if (this.showMySuppliers) {
      paramsToPersist['mysuppliers'] = true;
    }
    else {
      delete paramsToPersist['mysuppliers'];
    }

    if (this.showFavorites) {
      paramsToPersist['favorites'] = true;
    }
    else {
      delete paramsToPersist['favorites'];
    }

    const yOffset = window.scrollY;
    this.router
      .navigate([], { relativeTo: this.activatedRoute, queryParams: paramsToPersist, replaceUrl: true })
      .then(() => window.scrollTo(0, yOffset));
  }

  ngOnDestroy(): void {
    // this.ngUnSubscribe.unsubscribe();
  }

}
