import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'leap-product-card-image',
  templateUrl: './product-card-image.component.html',
  styleUrls: ['./product-card-image.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardImageComponent implements OnInit, OnChanges {

  @Input() size: 'regular' | 'small' | 'extra-small' = 'regular';
  @Input() imageUrl: string;
  @Output() onClick: EventEmitter<void> = new EventEmitter()


  imageLoading = false;
  brokenImage = false;
  
  constructor() { }

  ngOnInit() {
    if (this.imageUrl) this.imageLoading = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { imageUrl } = changes;
    if (imageUrl && imageUrl.currentValue) this.imageLoading = true;
  }

  onClickFn() {
    this.onClick.emit();
  }

}
