import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mediaFilter',
  pure:false
})
export class MediaFilterPipe implements PipeTransform {

  transform(items: any[], filter:string): any {
    switch (filter) {
      case 'all':
        return items;
      case 'promotionalMaterials':
        return items.filter( i=> i.type == 'promotionalMaterials');
      case 'videos':
        return items.filter( i=> i.type == 'videos');
      default:
        return items;
    }
  }

}
