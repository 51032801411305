import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationDto } from 'src/app/core/models/hedwig-models';
import { NotificationsState } from 'src/app/core/state/notifications.state';

@Component({
  selector: 'leap-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less', '../../navbar.less']
})
export class NotificationsComponent implements OnInit {

  notReadNotificationsExist$: Observable<boolean>;
  recentNotArchivedNotifications$: Observable<NotificationDto[]>;
  dropdownPanelVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Select(NotificationsState.notReadNotifications)
  notReadNotifications$: Observable<NotificationDto[]>;

  @Select(NotificationsState.notArchivedNotifications)
  notArchivedNotifications$: Observable<NotificationDto[]>;

  constructor() { }

  ngOnInit() {
    this.notReadNotificationsExist$ = this.notReadNotifications$.pipe(map((n: NotificationDto[]) => n.length > 0));
    this.recentNotArchivedNotifications$ = this.notArchivedNotifications$.pipe(map((n: NotificationDto[]) => n.slice(0, 3)));
  }

  showDropdownPanel(value: boolean) {
    this.dropdownPanelVisible$.next(value);
  }
}
