/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shopping-cart-thead-bar.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ng-zorro-antd/table/ng-zorro-antd-table.ngfactory";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "ng-zorro-antd/table";
import * as i5 from "./shopping-cart-thead-bar.component";
var styles_ShoppingCartTheadBarComponent = [i0.styles];
var RenderType_ShoppingCartTheadBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShoppingCartTheadBarComponent, data: {} });
export { RenderType_ShoppingCartTheadBarComponent as RenderType_ShoppingCartTheadBarComponent };
export function View_ShoppingCartTheadBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "td", [["class", "wrapper"]], [[4, "left", null], [4, "right", null], [4, "text-align", null]], null, null, i2.View_NzTdComponent_0, i2.RenderType_NzTdComponent)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 573440, null, 0, i4.NzTdComponent, [i1.ElementRef, i3.NzUpdateHostClassService], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Item"])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Price"])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quantity"])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).nzLeft; var currVal_1 = i1.ɵnov(_v, 2).nzRight; var currVal_2 = i1.ɵnov(_v, 2).nzAlign; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ShoppingCartTheadBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["leap-shopping-cart-thead-bar", ""]], null, null, null, View_ShoppingCartTheadBarComponent_0, RenderType_ShoppingCartTheadBarComponent)), i1.ɵdid(1, 114688, null, 0, i5.ShoppingCartTheadBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShoppingCartTheadBarComponentNgFactory = i1.ɵccf("[leap-shopping-cart-thead-bar]", i5.ShoppingCartTheadBarComponent, View_ShoppingCartTheadBarComponent_Host_0, {}, {}, []);
export { ShoppingCartTheadBarComponentNgFactory as ShoppingCartTheadBarComponentNgFactory };
