import { Action, Selector, State, StateContext } from '@ngxs/store';

import { FetchNotifications, NewNotification, SetNotifications, ChangeNotificationsStatus } from '../actions/notifications.actions';
import { FetchNotificationResult, NotificationDto } from '../models/hedwig-models';
import { HedwigService } from '../services/hedwig.service';

export interface NotificationsStateModel {
  notifications: NotificationDto[];
}

const defaults: NotificationsStateModel = {
  notifications: [],
};

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults,
})
export class NotificationsState {
  @Selector()
  static archivedNotifications(state: NotificationsStateModel): NotificationDto[] {
    return state.notifications.filter((n: NotificationDto) => n.isArchived);
  }

  @Selector()
  static notArchivedNotifications(state: NotificationsStateModel): NotificationDto[] {
    return state.notifications.filter((n: NotificationDto) => !n.isArchived);
  }

  @Selector([NotificationsState.notArchivedNotifications])
  static notReadNotifications(_, notArchivedNotifications: NotificationDto[]): NotificationDto[] {
    return notArchivedNotifications.filter((n: NotificationDto) => n.isNew);
  }

  constructor(
    private hedwigService: HedwigService,
  ) { }

  @Action(FetchNotifications)
  fetchNotifications({ patchState }: StateContext<NotificationsStateModel>) {
    this.hedwigService.fetchNotifications(1, 0, null).subscribe((res: FetchNotificationResult) => {
      patchState({ notifications: res.data });
    });
  }

  @Action(ChangeNotificationsStatus)
  changeNotificationsStatus({ patchState, getState }: StateContext<NotificationsStateModel>, action: ChangeNotificationsStatus) {
    this.hedwigService.changeNotificationStatus(action.payload).subscribe(() => {
      const prevState = getState();
      const notifications = prevState.notifications.map((n) => {
        return action.payload.notificationsIds.includes(n.id)
          ? {
            ...n,
            isNew: action.payload.isNew !== undefined ? action.payload.isNew : n.isNew,
            isArchived: action.payload.isArchived !== undefined ? action.payload.isArchived : n.isArchived,
          }
          : n;
      });
      patchState({ notifications });
    });
  }

  @Action(NewNotification)
  newNotificationGotten({ patchState, getState }: StateContext<NotificationsStateModel>, action: NewNotification) {
    const prevState = getState();
    patchState({ notifications: [action.payload, ...prevState.notifications], });
  }

  @Action(SetNotifications)
  setNotifications({ patchState }: StateContext<NotificationsStateModel>, action: SetNotifications) {
    patchState({ notifications: action.payload });
  }

}
