import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { LayoutComponent } from "./containers/layout/layout.component";
import { NavbarComponent } from "./containers/navbar/navbar.component";
import { ErrorNotFoundComponent } from "./containers/errors/error-not-found/error-not-found.component";
import { ErrorUnauthorizedComponent } from "./containers/errors/error-unauthorized/error-unauthorized.component";
import { ErrorUnauthenticatedComponent } from "./containers/errors/error-unauthenticated/error-unauthenticated.component";
import { AuthModule } from "../auth/auth.module";
import { UserComponent } from "./containers/navbar/components/user/user.component";
import { NotificationsComponent } from "./containers/navbar/components/notifications/notifications.component";
import { AppsComponent } from "./containers/navbar/components/apps/apps.component";
import { CallbackComponent } from "../common/callback/callback/callback.component";
import { CallbackModule } from "../common/callback/callback.module";
import { SnatchModule } from "../snatch/snatch.module";
import { LoadingBarComponent } from "./containers/loading-bar/loading-bar.component";
import { FooterComponent } from "./containers/footer/footer.component";
import { CommonUtilsModule } from "../common/common-utils/common-utils.module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

import { NavbarFiltersComponent } from "./containers/navbar/navbar-filters/navbar-filters.component";
import { ProductCatalogComponent } from "./containers/navbar/components/product-catalog/product-catalog.component";
import { ShoppingCartComponent } from "./containers/navbar/components/shopping-cart/shopping-cart.component";
import { SellersFeedComponent } from "./containers/navbar/components/sellers-feed/sellers-feed.component";
import { ShoppingCartModule } from "../shopping-cart/shopping-cart.module";
import { ShoppingCartPageComponent } from "../shopping-cart/containers/shopping-cart-page/shopping-cart-page.component";
import { UserMenuItemComponent } from "./containers/navbar/components/user/user-menu-item/user-menu-item.component";

import { CoreState } from "./state/core.state";
import { GlobalFiltersState } from "./state/global-filters.state";
import { DictionariesState } from "./state/dictionaries.state";
import { ProductsModule } from "../products/products.module";
import { ProductsComponent } from "../products/containers/products/products.component";
import { GlobalSearchBarComponent } from "./containers/navbar/components/global-search-bar/global-search-bar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { TranslateService } from "./services/translate.service";
import { HelpersService } from "./services/helpers.service";
import { ShoppingCartGuard } from "../snatch/guards/shopping-cart.guard";
import { MenuComponent } from "./containers/navbar/components/menu/menu.component";
import { MessagesComponent } from "./containers/navbar/components/messages/messages.component";
import { InboxComponent } from "../inbox/containers/inbox/inbox.component";
import { InboxModule } from "../inbox/inbox.module";
import { SuggestionsModule } from "../suggestions/suggestions.module";
import { NotificationsState } from './state/notifications.state';
import { OktaAuthGuard, OktaCallbackComponent } from "@okta/okta-angular";
import { InitSessionGuard } from "../snatch/guards/init-session.guard";

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    NzToolTipModule,
    AuthModule,
    CallbackModule,
    FormsModule,
    ReactiveFormsModule,

    RouterModule.forRoot([
      {
        path: 'login/callback',
        component: OktaCallbackComponent,
        canDeactivate: [InitSessionGuard],
      },
      { path: 'login', loadChildren: async () => import('../login/login.module').then((m) => m.LoginModule) },
      { path: "logout", redirectTo: "welcome", pathMatch: "full" },
      {
        path: "",
        component: LayoutComponent,
        children: [
          { path: "redirect/callback", component: CallbackComponent },
          { path: "", redirectTo: "welcome", pathMatch: "full" },
          { path: "welcome", loadChildren: () => import("../welcome/welcome.module").then(m => m.WelcomeModule) },

          // Bundle Group Details
          {
            path: "bundle-group/details/:sku",
            loadChildren: "../bundle-group-details/bundle-group-details.module#BundleGroupDetailsModule"
          },

          // Products & Details
          {
            path: "products/details/:sku",
            loadChildren: "../product-details/product-details.module#ProductDetailsModule"
          },
          { path: "products", component: ProductsComponent },

          //Inbox
          { path: "inbox", component: InboxComponent, canActivate: [OktaAuthGuard] },

          // // Orders & Details
          {
            path: "orders/details/:number",
            loadChildren: () => import("../order-details/order-details.module").then(m => m.OrderDetailsModule),
            canActivate: [OktaAuthGuard]
          },
          {
            path: "orders",
            loadChildren: () => import("../orders/orders.module").then(m => m.OrdersModule),
            canActivate: [OktaAuthGuard]
          },
          {
            path: "order-templates",
            loadChildren: () => import("../order-templates/order-templates.module").then(m => m.OrderTemplatesModule),
            canActivate: [OktaAuthGuard]
          },

          { path: 'transactions', loadChildren: () => import('../transactions/transactions.module').then(m => m.TransactionsModule), canActivate: [OktaAuthGuard] },

          {
            path: 'transaction-recording',
            loadChildren: () => import('../transaction-recording/transaction-recording.module').then(m => m.TransactionRecordingModule),
            canActivate: [OktaAuthGuard]
          },

          {
            path: "purchase-orders",
            loadChildren: () => import("../purchase-orders/purchase-orders.module").then(m => m.PurchaseOrdersModule),
            canActivate: [OktaAuthGuard]
          },

          //Favorites section
          {
            path: "favorites",
            loadChildren: () => import("../favorites/favorites.module").then(m => m.FavoritesModule),
            canActivate: [OktaAuthGuard]
          },
          //My suppliers screen
          { path: "my-suppliers", loadChildren: "../my-suppliers/my-suppliers.module#MySuppliersModule" },
          { path: "purchase-orders", loadChildren: "../purchase-orders/purchase-orders.module#PurchaseOrdersModule" },

          { path: "shopping-cart", component: ShoppingCartPageComponent, canActivate: [OktaAuthGuard] },
          {
            path: "checkout",
            loadChildren: () => import("../checkout/checkout.module").then(m => m.CheckoutModule),
            canActivate: [OktaAuthGuard, ShoppingCartGuard]
          },
          {
            path: "seller/page/:domain",
            loadChildren: () => import("../seller-page/seller-page.module").then(m => m.SellerPageModule)
          },
          {
            path: "notifications",
            loadChildren: () => import("../notifications/notifications.module").then(m => m.NotificationsModule),
            canActivate: [OktaAuthGuard],
          },
          { path: "401", component: ErrorUnauthenticatedComponent },
          { path: "403", component: ErrorUnauthorizedComponent },
          { path: "404", component: ErrorNotFoundComponent },
          { path: "**", component: ErrorNotFoundComponent }

        ]
      }
    ], { scrollPositionRestoration: "enabled" }),

    NgxsModule.forFeature([CoreState, GlobalFiltersState, DictionariesState, NotificationsState]),
    NgxsFormPluginModule,

    SnatchModule,
    CommonUtilsModule,

    ShoppingCartModule,
    ProductsModule,
    InboxModule,
    SuggestionsModule,
  ],
  declarations: [
    LayoutComponent,
    NavbarComponent,
    ErrorNotFoundComponent,
    ErrorUnauthorizedComponent,
    ErrorUnauthenticatedComponent,
    UserComponent,
    UserMenuItemComponent,
    NotificationsComponent,
    AppsComponent,
    LoadingBarComponent,
    FooterComponent,
    NavbarFiltersComponent,
    ProductCatalogComponent,
    ShoppingCartComponent,
    SellersFeedComponent,
    GlobalSearchBarComponent,
    MenuComponent,
    MessagesComponent
  ],
  providers: [TranslateService, HelpersService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
}
