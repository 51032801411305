import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import {Apollo} from "apollo-angular";
import {EnvironmentService} from "../../snatch/services";
import {SessionService} from "../../snatch/services/session.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";


const SUGGESTIONS = gql`
    query Suggestions($input: SuggestionSearchQuery!){
    courseSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseListItem {
                    name
                    sellerName,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
    courseEventSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseEventListItem {
                    name
                    sellerName
                    startDate,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
    instructorSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on InstructorListItem {
                    name
                    sellerName
                    individual
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
}
`;
export const getQueryAccorgindToContext =  (context: string) => {
	switch (context) {
		case 'products':
			return gql`
    query Suggestions($input: SuggestionSearchQuery!){
    courseSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseListItem {
                    name
                    sellerName,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
    courseEventSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseEventListItem {
                    name
                    sellerName
                    startDate,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
    instructorSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on InstructorListItem {
                    name
                    sellerName
                    individual
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
}
`;
		case 'courses':
			return gql`
    query Suggestions($input: SuggestionSearchQuery!){
    courseEventSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseEventListItem {
                    name
                    sellerName
                    startDate,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
}
`;
		case 'instructors':
			return  gql`
    query Suggestions($input: SuggestionSearchQuery!){
    courseEventSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseEventListItem {
                    name
                    sellerName
                    startDate,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }  
}
`;
		default:
			return gql`
    query Suggestions($input: SuggestionSearchQuery!){
    courseSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseListItem {
                    name
                    sellerName,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
    courseEventSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on CourseEventListItem {
                    name
                    sellerName
                    startDate,
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
    instructorSuggestions(input: $input) {
        moreUrl
        suggestions {
            id
            url
            details {
                ... on InstructorListItem {
                    name
                    sellerName
                    individual
                    image {
                        s3Bucket
                        s3Key
                    }
                }
            }
        }
    }
}
`;
	}
};

@Injectable()
export class SuggestionsService {

  constructor(  private apollo: Apollo,
                private environmentService: EnvironmentService,
                private sessionService: SessionService,
                private http: HttpClient) { }


	getProduct(limit: number,page:number, filter = '', context: string): Observable<any> {
    const input =  {
	    page,
	    limit,
	    filter
    };

		return this.apollo
    .use('virgo')
		.watchQuery({
			query: getQueryAccorgindToContext(context),
			variables: {
				input
			}
		})
		.valueChanges;
	}
}
