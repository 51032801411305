import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Select } from '@ngxs/store';
import { debounceTime } from 'rxjs/operators';
import {GenericSearchHit} from 'src/app/core/state/global-filters.state';
import { Observable } from 'rxjs';
import {SearchHit} from 'src/app/core/state/global-filters.state';

@Component({
  selector: 'leap-navbar-filters',
  templateUrl: './navbar-filters.component.html',
  styleUrls: ['./navbar-filters.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarFiltersComponent implements OnInit {

  @Input() topicsTree: any[];
  @Output() topicSelect: EventEmitter<string> = new EventEmitter();
  @Output() filterProducts: EventEmitter<string> = new EventEmitter();
  @Output() filterProduct: EventEmitter<{context:string,hit:GenericSearchHit}> = new EventEmitter();
  @Output() filterSeller: EventEmitter<{context:string,hit:GenericSearchHit}> = new EventEmitter();
  @Output() filterSubtopic: EventEmitter<{context:string,hit:GenericSearchHit}> = new EventEmitter();
  @Output() doGlobalSearch: EventEmitter<{context:string}> = new EventEmitter();

	@Select((state: any) => state.core.globalFilters.searchHits)
	searchHits$: Observable<{ courses: SearchHit[]; instructors: SearchHit[]; products:GenericSearchHit[], sellers: GenericSearchHit[], topics: GenericSearchHit[]}>;
	searchForm: FormGroup = this.fb.group({
		term: [null],
		type: ['products']
	});
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.searchForm.controls.term.valueChanges
      .pipe(debounceTime(500))
      .subscribe(_ => {
        const type = this.searchForm.get('type').value;
        this.doGlobalSearch.emit({context:type});
      })
  }

  filterProductsFn(filter: string) {
    this.filterProducts.emit(filter);
  }
  
  filterProductFn(term: {context:string, hit:GenericSearchHit}) {
    this.filterProduct.emit(term);
  }

  filterSellerFn(term: {context:string, hit:GenericSearchHit}) {
      this.filterSeller.emit(term);
  }

  filterSubtopicFn(term: {context:string, hit:GenericSearchHit}) {
      this.filterSubtopic.emit(term);
  }

	onTypeSelected(type: string) {
		this.searchForm.patchValue({ type })
	}
}
