export const environment = {
  production: true,
  env: 'preview',
  host: 'leapestpreview.com',

  hedwigUrl: 'https://hedwig.leapestpreview.com',
  saharaUrl: 'https://my.leapestpreview.com',
  instructorMarketUrl: 'https://instructors.leapestpreview.com',
  serviceMarketUrl: 'https://instructors.leapestpreview.com',
  integrationFramework: 'https://integration.leapestpreview.com',
  amberBaseUrl: 'https://amber.leapestpreview.com',
  amberUrl: 'https://amber.leapestpreview.com/api',
  bouncerUrl: 'https://account.leapestpreview.com',
  sphinxUrl: 'https://downloads.leapestpreview.com',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAWLQHGLXHE6ZVEZWE',
    s3Url: 'https://downloads.leapestpreview.com/proxy/service/jwt',
    cdnBucket: 'leap-preview-cdn',
    uploadsBucket: 'leap-preview-product-marketplace-uploads',
  },

  adyen: {
    clientKey: 'test_ZVI4LE36HVE2FCXGOAHXRIADDA7XNF5K'
  },
  okta: {
    issuer: 'https://edcasteu.oktapreview.com/oauth2/aus3qjxzm45xSXp3S0x7',
    clientId: '00a-preview-env',
    prefix: 'preview'
  }
};
