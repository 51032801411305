<nz-layout class="layout">
    <nz-sider [nzWidth]="130" class="sider-height-100" style="background:#fff;">
      <leap-filter-menu [typeFilter]="typeFilter$ | async" (changeTypeFilter)="onChangeTypeFilter($event)"></leap-filter-menu>
    </nz-sider>
    <nz-content>
      <div class="inbox-main">
        <div class="left">
          <leap-conversation-list></leap-conversation-list>
        </div>
        <div class="right">
          <leap-conversation 
            [conversationId]="(conversation$ | async)?.channelId"
            [pending]="pending"></leap-conversation>
        </div>
      </div>
      <!-- <div class="layout-margin-bottom" style="width: 100%;"></div> -->
    </nz-content>
</nz-layout>
