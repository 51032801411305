<leap-global-search-bar
        [form]="searchForm"
        [searchHits]="searchHits$ | async"
        (filterProducts)="filterProductsFn($event)"
        (filterProduct)="filterProductFn($event)"
        (filterSeller)="filterSellerFn($event)"
        (filterSubtopic)="filterSubtopicFn($event)"
        (typeSelected)="onTypeSelected($event)"
></leap-global-search-bar>


