/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading-bar/loading-bar.component.ngfactory";
import * as i3 from "../loading-bar/loading-bar.component";
import * as i4 from "@angular/router";
import * as i5 from "../navbar/navbar.component.ngfactory";
import * as i6 from "../navbar/navbar.component";
import * as i7 from "@ngxs/store";
import * as i8 from "../../../snatch/services/environment.service";
import * as i9 from "../../../../../node_modules/ng-zorro-antd/layout/ng-zorro-antd-layout.ngfactory";
import * as i10 from "ng-zorro-antd/layout";
import * as i11 from "../../../shopping-cart/containers/shopping-cart-drawer/shopping-cart-drawer.component.ngfactory";
import * as i12 from "../../../shopping-cart/containers/shopping-cart-drawer/shopping-cart-drawer.component";
import * as i13 from "../footer/footer.component.ngfactory";
import * as i14 from "../footer/footer.component";
import * as i15 from "./layout.component";
import * as i16 from "@angular/common";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { navBar: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "main", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "leap-loading-bar", [], null, null, null, i2.View_LoadingBarComponent_0, i2.RenderType_LoadingBarComponent)), i1.ɵdid(3, 245760, null, 0, i3.LoadingBarComponent, [i4.Router, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, [[1, 0]], null, 1, "leap-navbar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NavbarComponent_0, i5.RenderType_NavbarComponent)), i1.ɵdid(5, 245760, [["navBar", 4]], 0, i6.NavbarComponent, [i7.Store, i4.ActivatedRoute, i4.Router, i8.EnvironmentService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "nz-content", [["class", "content content-height-100"]], null, null, null, i9.View_NzContentComponent_0, i9.RenderType_NzContentComponent)), i1.ɵdid(7, 49152, null, 0, i10.NzContentComponent, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(8, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "leap-shopping-cart-drawer", [], null, null, null, i11.View_ShoppingCartDrawerComponent_0, i11.RenderType_ShoppingCartDrawerComponent)), i1.ɵdid(11, 114688, null, 0, i12.ShoppingCartDrawerComponent, [i7.Store, i4.Router, i4.ActivatedRoute], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "leap-footer", [], null, null, null, i13.View_FooterComponent_0, i13.RenderType_FooterComponent)), i1.ɵdid(13, 114688, null, 0, i14.FooterComponent, [i8.EnvironmentService, i7.Store, i4.Router], { instructorMarketUrl: [0, "instructorMarketUrl"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 9, 0); _ck(_v, 11, 0); var currVal_0 = _co.getInstructorServiceUrl; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 245760, null, 0, i15.LayoutComponent, [i7.Actions, i1.Renderer2, i16.DOCUMENT, i8.EnvironmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("leap-layout", i15.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
