import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { fromEvent, Observable, Subscriber } from 'rxjs';

import { EnvironmentService } from 'src/app/snatch/services';
import { SocketEvents } from '../constants/headwig-ws-events.const';
import { FetchNotificationResult, NotificationDto, SummaryDto, CnangeNotificationsStatusPayload } from '../models/hedwig-models';

@Injectable({ providedIn: 'root' })
export class HedwigService {

  private socketUrl: string = this.environment.hedwigUrl;
  private socket: SocketIOClient.Socket;

  constructor(
    private environment: EnvironmentService,
  ) { }

  init(accessToken: string) {
    this.socket = io(
      `${this.socketUrl}?accessToken=${accessToken}`,
      {
        transports: ['websocket'],
        upgrade: false
      });
  }

  getSummary(): Observable<SummaryDto> {
    return fromEvent(this.socket, SocketEvents.WELCOME);
  }

  newNotification(): Observable<NotificationDto> {
    return fromEvent(this.socket, SocketEvents.NEW_NOTIFICATION);
  }

  notificationsUpdated() {
    return fromEvent(this.socket, SocketEvents.CHANGE_NOTIFICATION_STATUS);
  }

  changeNotificationStatus(payload: CnangeNotificationsStatusPayload): Observable<any> {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.CHANGE_NOTIFICATION_STATUS, {
        notifications: payload.notificationsIds,
        isNew: payload.isNew,
        isArchived: payload.isArchived,
      }, (data) => { // add types
        observer.next(data);
        observer.complete();
      });
    });
  }

  fetchNotifications = (page: number, limit: number, archived: boolean): Observable<FetchNotificationResult> => {
    return new Observable((observer: Subscriber<any>) => {
      this.socket.emit(
        SocketEvents.FETCH_NOTIFICATIONS,
        { page, limit, archived },
        (data: FetchNotificationResult) => {
          observer.next(data);
          observer.complete();
        }
      );
    });
  }

  sendMessage(channelId: string, message: string): Observable<any> {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.SEND_MESSAGE, { channelId, message }, (data) => {
        observer.next(data);
        observer.complete();
      });
    })
  }

  markReadMessage(channelId: string, messageId: number): Observable<any> {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.MARK_READ, { channelId, messageId }, (data) => {
        observer.next(data);
        observer.complete();
      });
    })
  }

  newMessage = (): Observable<any> => {
    return fromEvent(this.socket, SocketEvents.NEW_MESSAGE);
  }

  fetchMessages = (channelId: string, cursor: number, size: number): Observable<any> => {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.FETCH_MESSAGES, { channelId, cursor, size }, (data) => {
        observer.next(data);
        observer.complete();
      });
    })
  }

  fetchChannels = (): Observable<any> => {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.FETCH_CHANNELS, {}, (data) => {
        observer.next(data);
        observer.complete();
      });
    })
  }

  newChannel = (): Observable<any> => {
    return fromEvent(this.socket, SocketEvents.NEW_CHANNEL)
  }

  updateChannel = (): Observable<any> => {
    return fromEvent(this.socket, SocketEvents.UPDATE_CHANNEL)
  }

  createDMChannel = (domain: string): Observable<any> => {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.CREATE_DM_CHANNEL, { domain }, (data) => {
        observer.next(data);
        observer.complete();
      });
    })
  }

  checkDMChannel = (domain: string): Observable<any> => {
    return new Observable<any>((observer: Subscriber<any>) => {
      this.socket.emit(SocketEvents.CHECK_DM_CHANNEL, { domain }, (data) => {
        observer.next(data);
        observer.complete();
      });
    });
  }
}
