import * as tslib_1 from "tslib";
import { State, Action } from '@ngxs/store';
import { DomainConfiguration } from '../actions/auth.actions';
export class DomainConfigurationStateModel {
}
let DomainConfigurationState = class DomainConfigurationState {
    DomainConfiguration({ patchState }, action) {
        patchState({
            domainConfiguration: action.payload
        });
    }
};
tslib_1.__decorate([
    Action(DomainConfiguration),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, DomainConfiguration]),
    tslib_1.__metadata("design:returntype", void 0)
], DomainConfigurationState.prototype, "DomainConfiguration", null);
DomainConfigurationState = tslib_1.__decorate([
    State({
        name: 'domain',
        defaults: {
            domainConfiguration: undefined
        }
    })
], DomainConfigurationState);
export { DomainConfigurationState };
