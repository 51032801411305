import { Params } from '@angular/router';

export function queryParamStringToObject(queryString: string): Params {
  if (!queryString) return {};

  const queryParams = new URLSearchParams(queryString);
  const res = {};

  for (let key of queryParams.keys()) {
    res[key] = queryParams.getAll(key);
  }

  return res;
}