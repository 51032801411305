import { AmberError } from "src/app/snatch/models/amber-response.model";
import { Profile } from "src/app/common/common-profile/models/profile.model";

export class GetEmployee {
  static readonly type = '[Core] Get Employee';
  constructor() {}
}

export class GetProfile {
  static readonly type = '[Core] Get Profile';
  constructor() {}
}

export class GetTranslations {
  static readonly type = '[Core] Get Translations';
  constructor() {}
}

export class GetProfileSuccess {
  static readonly type = '[Core] Get Profile success';
  constructor(public payload: Partial<Profile>) {}
}
export class GetProfileFailure {
  static readonly type = '[Core] Get Profile failure';
  constructor(public payload: AmberError) {}
}

export class RedirectOnError {
  static readonly type = '[Core] Redirect to page based on error';
  constructor(public errorKey: string) {}
}

export class GetVmsFlag {
  static readonly type = '[Core] Get Profile VMS view Permissions';
  constructor(public domain: string) {}
}
