<div class="seller-container" [ngClass]="size">
  <ng-container *ngIf="imageUrl; else imagePlaceholder">
      <div class="seller-image-container">
          <img class="seller-image"
               alt="{{ name }}"
               [src]="imageUrl">
      </div>
  </ng-container>

  <a class="seller-name"
     nz-tooltip
     nzTooltipPlacement="bottom"
     [nzTooltipTitle]="name.length > 26 ? name : ''"
     routerLink="/seller/page/{{ domain }}">
    {{ name }}
  </a>
</div>

<ng-template #imagePlaceholder>
  <div class="seller-image-container">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30" width="40" height="30"><defs><style>.cls-1{fill:#737394;}</style></defs><path class="cls-1" d="M29.75,9H24.5V5.63A1.12,1.12,0,0,0,23.38,4.5H16.62A1.12,1.12,0,0,0,15.5,5.63V9H10.25A2.25,2.25,0,0,0,8,11.25v12a2.25,2.25,0,0,0,2.25,2.25h19.5A2.25,2.25,0,0,0,32,23.25v-12A2.25,2.25,0,0,0,29.75,9ZM17,6h6V9H17ZM30.5,23.25a.76.76,0,0,1-.75.75H10.25a.76.76,0,0,1-.75-.75V16.5H17v1.88a1.12,1.12,0,0,0,1.13,1.12h3.75A1.12,1.12,0,0,0,23,18.38V16.5h7.5ZM18.5,18V16.5h3V18Zm12-3H9.5V11.25a.76.76,0,0,1,.75-.75h19.5a.76.76,0,0,1,.75.75Z"/></svg>
  </div>
</ng-template>
