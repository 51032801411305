<div class="search-container">
  <form [formGroup]="form" [ngxsForm]="'core.globalFilters.searchForm'" novalidate>

    <input
      class="search-input"
      nz-input
      nzSize="large"
      formControlName="term"
      (keyup.enter)="onKeyEnterSet($event)"
      [nzAutocomplete]="auto"
      placeholder="Search in {{ form.get('type').value }}..."
    />

    <nz-autocomplete [nzOverlayClassName]="'global-search-overlay'" #auto>
      <p class="label">SEARCH IN <span class="search-in">{{ form.get('type').value.toUpperCase() }}</span></p>
      <nz-auto-option class="auto-option-wrapper" *ngIf="form?.controls?.term.value"  [nzLabel]="form?.controls?.term.value" [nzValue]="form?.controls?.term.value">
        <p class="auto-option" (click)="goTo(form.get('type').value, form?.controls?.term.value)">{{form?.controls?.term.value}}</p>
      </nz-auto-option>
      <nz-auto-optgroup *ngIf="searchHits?.courses?.length" [nzLabel]="titleCourses">
        <nz-auto-option *ngFor="let hit of searchHits.courses" [nzValue]="hit?.course?.code">
          <p class="title-variant" [innerHTML]="hit.highlight || hit.course.name"></p>
          <p class="delivery">{{hit?.course?.solarCourse?.format?.configValue}}</p>
        </nz-auto-option>
        <nz-auto-option>
          <p class="browse" (click)="goTo('courses')">Browse Courses</p>
        </nz-auto-option>
      </nz-auto-optgroup>
      <nz-auto-optgroup *ngIf="searchHits?.instructors?.length" [nzLabel]="titleInstructors">
        <nz-auto-option *ngFor="let hit of searchHits.instructors" [nzValue]="hit.instructor.id">
          <div class="instructor-wrapper">
            <div class="instructor-image">
              <img *ngIf="hit?.instructor?.image.s3Key" [src]="hit?.instructor?.image | urlFromBucket:hit?.instructor?.image">
              {{hit?.instructor?.image | urlFromBucket}}
              <img *ngIf="!hit?.instructor?.image.s3Key" [src]="defaultProfilePhotoUrl">
            </div>
            <div class="info">
              <p class="title-variant" [innerHTML]="hit.highlight || hit.instructor.displayName"></p>
              <p class="delivery">{{hit?.instructor?.accountDisplayName}}</p>
            </div>
          </div>
        </nz-auto-option>
        <nz-auto-option>
          <p class="browse" (click)="goTo('instructors')">Browse instructors</p>
        </nz-auto-option>
      </nz-auto-optgroup>
      <nz-auto-optgroup *ngIf="searchHits?.products?.length" [nzLabel]="titleProducts">
        <nz-auto-option *ngFor="let hit of searchHits.products" (click)="onEnterProduct(hit)" [nzValue]="hit.key">
          <p class="auto-option" [innerHTML]="hit.value"></p>
        </nz-auto-option>
        <nz-auto-option>
          <p class="browse" (click)="goTo('products')">Browse Products</p>
        </nz-auto-option>
      </nz-auto-optgroup>
      <nz-auto-optgroup *ngIf="searchHits?.sellers?.length" [nzLabel]="titleSellers">
        <nz-auto-option *ngFor="let hit of searchHits.sellers" (click)="onEnterSeller(hit)" [nzValue]="hit.key">
          <p class="auto-option" [innerHTML]="hit.value"></p>
        </nz-auto-option>
      </nz-auto-optgroup>
      <nz-auto-optgroup *ngIf="searchHits?.topics?.length" [nzLabel]="titleTopics">
        <nz-auto-option *ngFor="let hit of searchHits.topics" (click)="onEnterTopic(hit)" [nzValue]="hit.key">
          <p class="auto-option" [innerHTML]="hit.value"></p>
        </nz-auto-option>
      </nz-auto-optgroup>
    </nz-autocomplete>
  </form>
  <ng-container>
    <span class="search-dropdown" nz-dropdown [nzDropdownMenu]="menu">
      <span [ngSwitch]="form.get('type').value">
        <!-- <p *ngSwitchCase="'courses'">
          Courses
        </p> -->
        <p *ngSwitchCase="'instructors'">
          Instructors
        </p>
         <p *ngSwitchCase="'products'">
          Products
        </p>
        <p *ngSwitchDefault>
          Products
        </p>
      </span>
      <i nz-icon nzType="down"></i>
    </span>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="selectType('products')">Products</li>
        <!-- <li nz-menu-item (click)="selectType('courses')">Courses</li> -->
        <li nz-menu-item (click)="selectType('instructors')">Instructors</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
  <i nz-icon *ngIf="globalSearchPending$ | async" [nzType]="'loading'"></i>
  <span>
      <svg *ngIf="!(globalSearchPending$ | async)" xmlns="http://www.w3.org/2000/svg" version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24"
           class="search-btn">
    <g>
      <path xmlns:default="http://www.w3.org/2000/svg"
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            style="fill: rgb(156, 164, 166);" vector-effect="non-scaling-stroke"/>
    </g>
  </svg>
  </span>
</div>
<ng-template #titleCourses>
  <p class="label template">MATCHING COURSES</p>
</ng-template>
<ng-template #titleInstructors>
  <p class="label template">MATCHING INSTRUCTORS</p>
</ng-template>
<ng-template #titleProducts>
  <p class="label template">MATCHING PRODUCTS</p>
</ng-template>
<ng-template #titleSellers>
  <p class="label template">MATCHING SELLERS</p>
</ng-template>
<ng-template #titleTopics>
  <p class="label template">MATCHING SUB (TOPICS)</p>
</ng-template>
