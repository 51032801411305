import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {Actions, ofActionSuccessful} from "@ngxs/store";
import {takeUntil} from "rxjs/operators";
import {OpenShoppingCartDrawer} from "../../../shopping-cart/actions/shopping-cart.actions";
import {DOCUMENT} from "@angular/common";
import {EnvironmentService} from "../../../snatch/services";

@Component({
  selector: 'leap-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @ViewChild('navBar', { read: ElementRef, static: true }) navBar: ElementRef;

  constructor(
      private actions$: Actions,
      private renderer2: Renderer2,
      @Inject(DOCUMENT) private document: Document,
      private environmentService: EnvironmentService) {}

  ngOnInit(): void {
    this.actions$.pipe(
        ofActionSuccessful(OpenShoppingCartDrawer),
        takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {

      const navBarElement =
          [].filter.call(this.navBar.nativeElement.children, _ => _.nodeName.toLowerCase() === 'nz-header')[0];
      const navBarRect = navBarElement.getBoundingClientRect();
      const marginTopForDrawer = Math.abs(navBarRect.y) > navBarRect.height ? 0 : (navBarRect.y + navBarRect.height);
      const drawer = this.document.querySelector('.ant-drawer.ant-drawer-right > .ant-drawer-content-wrapper ');
      const main = this.document.querySelector('.main');
      const drawerHeight = main.clientHeight - marginTopForDrawer;
      const drawerMask = this.document.querySelector('.ant-drawer.ant-drawer-right>.ant-drawer-mask');
      const itemsContainer = this.document.querySelector('.list-item-drawer-container');
      const btnContainer = this.document.querySelector('.bottom-container > .ant-spin-container.ng-star-inserted');
      const itemsContainerHeight = itemsContainer.clientHeight - btnContainer.clientHeight < drawerHeight ? drawerHeight - 25 - btnContainer.clientHeight : itemsContainer.clientHeight;

      this.renderer2.setStyle(drawer,'margin-top',marginTopForDrawer + 'px');
      this.renderer2.setStyle(drawer,'height',drawerHeight + 'px');
      this.renderer2.setStyle(drawerMask,'margin-top',marginTopForDrawer + 'px');
      this.renderer2.setStyle(itemsContainer,'height',itemsContainerHeight + 'px');
    });
  }

  get getInstructorServiceUrl() {
    return this.environmentService.instructorMarketUrl + '/register/instructor';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
