<span>{{ currencySignsMap?.get(currency) }} {{ netAmount | number : '1.2-2' }}</span>

<ng-container *ngIf="surchargeTotal">
    <small>SURCHARGES</small>
    <span class="font-small surcharge">{{ currencySignsMap?.get(currency) }} {{ surchargeTotal | number : '1.2-2' }}
    <i class="info-circle"
       nz-popover [nzContent]="surchargesInfoTemplate" nzTrigger="hover"
       nz-icon type="info-circle" theme="fill"></i></span>
</ng-container>

<ng-container *ngIf="discountTiers?.length">
    <small>
        Pricing Tier
        <i class="info-circle"
           nz-popover [nzContent]="pricingTierTemplate" nzTrigger="hover"
           nz-icon type="info-circle" theme="fill">
        </i>
    </small>
</ng-container>

<ng-template #surchargesInfoTemplate>
    <strong class="surcharge-main">The following additional costs are applied:</strong>
    <div nz-row class="font-small">
        <div nz-col nzMd="16">Type</div>
        <div nz-col nzMd="8" class="right">Cost</div>
    </div>
    <hr>
    <div nz-row class="font-small vertical-margin-10" *ngFor="let surcharge of surcharges">
        <div nz-col nzMd="16">{{ surcharge.name }}</div>
        <div nz-col nzMd="8" class="right font-big">{{ currencySignsMap?.get(currency) }} {{ surcharge.amount | number : '1.2-2' }}</div>
    </div>
</ng-template>

<ng-template #pricingTierTemplate>
    <nz-table
        #tierPricingTable
        nzBordered
        [nzData]="discountTiers"
        [nzFrontPagination]="false">
        <thead>
        <tr>
            <th nzWidth="30%">Minimum number of licenses</th>
            <th nzWidth="30%">Maximum number of licenses</th>
            <th nzWidth="20%">Price</th>
            <th nzWidth="20%">Overage Price</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of tierPricingTable.data; let index = index">
            <td>{{ data.quantity }}</td>
            <td>{{ tierPricingTable.data[index + 1] ? tierPricingTable.data[index + 1].quantity - 1 : 'Unlimited' }}</td>
            <td>{{ currencySignsMap?.get(currency) }} {{ data.price }}</td>
            <td>{{ currencySignsMap?.get(currency) }} {{ data.overagePrice }}</td>
        </tr>
        </tbody>
    </nz-table>
</ng-template>
