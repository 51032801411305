export class SessionCheckSuccess {
  static readonly type = '[Auth] Session Check Success';
  constructor() {}
}

export class SessionCheckFailure {
  static readonly type = '[Auth] Session Check Failure';
  constructor() {}
}

export class NavigateToLogout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}

export class DomainConfiguration {
  static readonly type = '[Domain] Domain Configuration';
  constructor(public payload : Object) {}
}

export class SetSessionCookie {
  static readonly type = '[Auth] Set Session Cookie';
  constructor() {}
}

export class ListenForSessionChange {
  static readonly type = '[Auth] Listen For Session Change';
  constructor() {}
}
