import { IBreadcrumb } from '../models/breadcrumb.interface';

export const BREADCRUMBS_CONFIGURATION: { [key: string]: IBreadcrumb } = {

    Products: { label: 'Products', path: '/products', withQueryParams: true },
    ProductDetails: { label: 'Product Details' },

    Orders: { label: 'Courses', path: '/courses', withQueryParams: true },
    OrderDetails: { label: 'Course Details', path: '/course-details' },

    Checkout: { label: 'Checkout' },
    ShoppingCart: { label: 'Shopping Cart', path: '/shopping-cart' },

    OrderTemplatesList: { label: 'Order Templates', path: '/order-templates' },
    OrderTemplateDetails: { label: '', path: '/details' },

    Inbox: { label: 'Inbox', path: '/inbox' },

    PurchaseOrders: { label: 'Order Records', path: '/purchase-orders' },
    PurchaseOrderDetails: { label: 'Purchase Order Record' },

    BundleGroupDetails: { label: 'Bundle Group Details' },
};
