import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { ApolloError } from "apollo-client";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./environment.service";
export class AmberRestService {
    constructor(http, environment) {
        this.http = http;
        this.environment = environment;
    }
    static from(observable) {
        return AmberRestService.applyOperators(observable);
    }
    static applyOperators(observable) {
        return observable.pipe(map(_body => DeferredResource.success(_body.data)), catchError(err => of(DeferredResource.error(err))), startWith(DeferredResource.pending()));
    }
    get(endpoint, opts) {
        const url = this.getEndpoint(endpoint);
        return AmberRestService.applyOperators(this.http.get(url, opts));
    }
    post(endpoint, body, opts) {
        const url = this.getEndpoint(endpoint);
        return AmberRestService.applyOperators(this.http.post(url, body, opts));
    }
    getEndpoint(endpoint) {
        if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
            return endpoint;
        }
        else {
            return `${this.environment.amberUrl}${endpoint}`;
        }
    }
}
AmberRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AmberRestService_Factory() { return new AmberRestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvironmentService)); }, token: AmberRestService, providedIn: "root" });
export class DeferredResource {
    constructor(pending, error, response) {
        this.pending = pending;
        this.error = error;
        this.response = response;
    }
    static pending() {
        return new DeferredResource(true, undefined, undefined);
    }
    static error(err) {
        return new DeferredResource(false, err, undefined);
    }
    static success(result) {
        return new DeferredResource(false, undefined, result);
    }
    get hasData() {
        return !!this.response;
    }
    get isSuccess() {
        return !this.pending && !this.error && !!this.response;
    }
    get isPending() {
        return this.pending && !this.response;
    }
    get isReFetching() {
        return this.pending && this.response;
    }
    merge(other) {
        if (!other) {
            return this;
        }
        return new DeferredResource(this.pending, this.error, this.response || other.response);
    }
    unwrap(selector) {
        if (this.response) {
            return new DeferredResource(this.pending, this.error, selector(this.response));
        }
        return this;
    }
}
export var DeferredResourceUtils;
(function (DeferredResourceUtils) {
    DeferredResourceUtils.wrapObservable = (observable) => {
        return observable.pipe(map(body => {
            if (body.errors) {
                throw new ApolloError({ graphQLErrors: body.errors });
            }
            return DeferredResource.success(body.data);
        }), catchError(err => of(DeferredResource.error(err))), startWith(DeferredResource.pending()));
    };
    DeferredResourceUtils.wrapAmberObservable = (observable) => {
        return observable.pipe(map(body => DeferredResource.success(body.data)), catchError(err => of(DeferredResource.error(err))), startWith(DeferredResource.pending()));
    };
    DeferredResourceUtils.wrapAmberObservableWithFlags = (observable) => {
        return observable.pipe(map(body => DeferredResource.success(body)), catchError(err => of(DeferredResource.error(err))), startWith(DeferredResource.pending()));
    };
})(DeferredResourceUtils || (DeferredResourceUtils = {}));
