import {NgModule} from '@angular/core';
import {ApolloModule, Apollo} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink, from} from 'apollo-link';

import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { DefaultOptions } from 'apollo-client';
import { SessionService } from './snatch/services/session.service';

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
})
export class GraphQLModule {

  constructor(private apollo: Apollo, private httpLink: HttpLink, private store: Store, private sessionService: SessionService) {
    const bazaarLink = from([
      this.requestInterceptor(),
      this.responseInterceptor(),
      httpLink.create({ uri: environment.amberUrl + '/bazaar/graphql' })
    ]);

    apollo.createDefault({
      link: bazaarLink,
      cache: new InMemoryCache(),
      defaultOptions: this.defaultOptions()
    })

    const virgoLink = from([
      this.requestInterceptor(),
      this.responseInterceptor(),
      httpLink.create({ uri: environment.amberUrl + '/virgo/graphql' })
    ]);
    
    apollo.createNamed('virgo', {
      link: virgoLink,
      cache: new InMemoryCache(),
      defaultOptions: this.defaultOptions()
    })
  }

  requestInterceptor() {
    return new ApolloLink((operation, forward) => {
      // Get the authentication token from local storage if it exists
      const token = this.sessionService.getToken()
      // Use the setContext method to set the HTTP headers.
      operation.setContext({
          headers: {
              'Authorization': token ? `Bearer ${token}` : ''
          }
      });

      // Call the next link in the middleware chain.
      return forward(operation);
    });
  }

  responseInterceptor() {
    return new ApolloLink((operation, forward) => {
      return forward(operation).map(response => {
        if (!response.data && response.errors && response.errors.length) {
          const errorKey = response.errors[0].message;
          // store.dispatch(new RedirectOnError(errorKey));
        }
        return response;
      });
    });
  }

  defaultOptions(): DefaultOptions {
    const options: DefaultOptions = {
      watchQuery: {
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    }

    return options
  }
}
