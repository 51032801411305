import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Host,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FormSubmitDirective } from './form-submit.directive';
import { EMPTY, merge, Observable } from 'rxjs';
import { ControlErrorComponent } from '../components/control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';

export const defaultErrors = {
  required: error => `Field is required`,
  minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`,
  minSize: ({ min }) => `Select at least ${min} elements`,
  pattern: () => `Invalid format`
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});

@Directive({
  // tslint:disable-next-line
  selector: '[formControl], [formControlName], [leapFormControlProxy]'
})
export class ControlErrorDirective implements OnInit, OnDestroy {
  container: ViewContainerRef;
  ref: ComponentRef<ControlErrorComponent>;
  submit$: Observable<Event>;

  @Input()
  leapFormControlProxy: string;
  @Input()
  errorTemplateRef: TemplateRef<any>;

  constructor(
    @Self()
    @Optional()
    private control: NgControl,
    private parentForm: FormGroupDirective,
    @Optional()
    @Host()
    private form: FormSubmitDirective,
    @Inject(FORM_ERRORS) private errors,
    private resolver: ComponentFactoryResolver,
    vcr: ViewContainerRef,
    @Optional()
    @Host()
    controlErrorContainer: ControlErrorContainerDirective
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    if (!this.form) {
      return;
    }
    const control = this.control ? this.control : this.parentForm.form.get(this.leapFormControlProxy);
    merge(this.submit$, control.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const controlErrors = control.errors;
        if (controlErrors) {
          const firstKey = Object.keys(controlErrors)[0];
          const formatErrorFn = this.errors[firstKey];
          const text = formatErrorFn ? formatErrorFn(controlErrors[firstKey]) : null;
          this.setError(text, controlErrors);
        } else {
          this.setError(null, null);
        }
      });
  }

  setError(text: string, errors: any) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
    if (this.errorTemplateRef) {
      this.ref.instance.errors = errors;
      this.ref.instance.templateRef = this.errorTemplateRef;
    }
  }

  ngOnDestroy() {}
}
