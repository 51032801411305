import {Action, State, StateContext, Store} from "@ngxs/store";
import {UrlStorageService} from "../../products/services/url-storage.service";
import {SuggestionsService} from "../services/suggestions.service";
import {FetchSuggestions} from "../actions/suggestion.actions";
import {catchError, tap} from "rxjs/operators";
import {AmberResponse} from 'src/app/snatch/models/amber-response.model';
import {S3Resource} from 'src/app/snatch/models/s3-resource.model';
import {of} from "rxjs";

export interface SuggestionsStateModel {
	courseSuggestions: ISuggestion;
	courseEventSuggestions: ISuggestion;
	instructorSuggestions: ISuggestion;
	fetchingSuggestions: boolean;
}
export interface ISuggestion {
	moreUrl:string;
	suggestions:ISuggestionItem[] | ICourseEventSuggestion[] | IInstructorSuggestion[];
}

export interface ISuggestionItem {
	id:string;
	url:string;
	details:ISuggestionDetails;
}
export interface ISuggestionDetails {
	name:string;
	sellerName:string;
	image:S3Resource
}
export interface ICourseEventSuggestion extends S3Resource{
	details:ISuggestionCourseEventDetails;
}
export interface IInstructorSuggestion extends S3Resource{
	details:ISuggestionInstructorDetails;
}
interface ISuggestionInstructorDetails extends ISuggestionDetails{
	individual:boolean;
}
interface ISuggestionCourseEventDetails extends ISuggestionDetails{
	startDate:string;
}
export enum SuggestionType {
	COURSES = 'courses',
	COURSE_EVENTS = 'course_events',
	INSTRUCTORS = 'instructors',
	PRODUCTS = 'products'
};
@State<SuggestionsStateModel>({
	name: 'suggestions',
	defaults: {
		courseSuggestions: undefined,
		courseEventSuggestions: undefined,
		instructorSuggestions: undefined,
		fetchingSuggestions:false
	}
})

export class SuggestionsState {
	constructor(
	private store: Store,
	private service: SuggestionsService,
	private urlStorageService: UrlStorageService
	) {
	}

	@Action(FetchSuggestions)
	FetchSuggestions({patchState}: StateContext<SuggestionsStateModel>,action:FetchSuggestions) {
		patchState({ fetchingSuggestions: true });
		return this.service.getProduct(8,0,action.filter, action.context).pipe(
		catchError(res => {
			patchState({ fetchingSuggestions: false });
			return of([])
		}),
		tap((res: AmberResponse<any>) => {
			if(res.data){
				patchState({
					courseSuggestions: res.data.courseSuggestions,
					courseEventSuggestions: res.data.courseEventSuggestions,
					instructorSuggestions: res.data.instructorSuggestions,
					fetchingSuggestions:false
				});
			}
		})
		)

	}
}
