import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthStateModel, AuthTokenState } from '../../auth/state/auth-token.state';
import { EnvironmentService } from '../../snatch/services';
import { SessionService } from 'src/app/snatch/services/session.service';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor {
  constructor(private store: Store, private environment: EnvironmentService, private sessionService: SessionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const session: AuthStateModel = this.store.selectSnapshot(state => state.session);
    if (AuthTokenState.isLogged(session) && request.url.startsWith(this.environment.amberBaseUrl)) {
      let token = this.sessionService.getToken();
      const newRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
      });
      return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
