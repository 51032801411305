import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {GenericSearchHit} from 'src/app/core/state/global-filters.state';
import { Store, Select } from '@ngxs/store';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import {SearchHit} from 'src/app/core/state/global-filters.state';
import {EnvironmentService} from "src/app/snatch/services";
import {ClearGlobalSearch} from "../../../../actions/global-filters.actions";

import {SetContext} from "../../../../actions/global-filters.actions";

@Component({
  selector: 'leap-global-search-bar',
  templateUrl: './global-search-bar.component.html',
  styleUrls: ['./global-search-bar.component.less']
})
export class GlobalSearchBarComponent implements OnInit, OnDestroy {

	@Input()
	form: FormGroup;

	@Input() searchHits: { courses: SearchHit[]; instructors: SearchHit[]; products: GenericSearchHit[], sellers: GenericSearchHit[], topics: GenericSearchHit[] };
	@Output() goToProductDetails: EventEmitter<{ sku: string, attributes: any }> = new EventEmitter();

	@Output() filterProducts: EventEmitter<string> = new EventEmitter();
	@Output() filterProduct: EventEmitter<{context:string,hit:GenericSearchHit}> = new EventEmitter();
	@Output() filterSeller: EventEmitter<{context:string,hit:GenericSearchHit}> = new EventEmitter();
	@Output() filterSubtopic: EventEmitter<{context:string,hit:GenericSearchHit}> = new EventEmitter();

	@Output() filter: EventEmitter<{context:string,hit:SearchHit}> = new EventEmitter();
	@Output() typeSelected: EventEmitter<string> = new EventEmitter();
	@Select((state: any) => state.core.globalFilters.globalSearchPending)
	globalSearchPending$: Observable<boolean>;

	ngUnsubscribe = new Subject()

	defaultProfilePhotoUrl: string;

	constructor(private store: Store, private envService:EnvironmentService) {}

	ngOnInit() {
		this.defaultProfilePhotoUrl = this.envService.defaultProfilePhotoUrl;

		this.store.select((state: any) => state.core.globalFilters.searchForm.model)
		.pipe(skip(1), filter(_ => !_), takeUntil(this.ngUnsubscribe))
		.subscribe(_ => {
			this.form.reset();
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	onKeyEnterSet(ev:any){
		const type = this.form.get('type').value;
		const term = ev.target.value;


		this.dispatchRedirect(term)
	}

	goTo(type, term = '') {
		this.store.dispatch( new ClearGlobalSearch() );
			switch (type) {
				case 'courses':
					window.location.href = `${this.envService.serviceMarketUrl}/courses?filter=${term}`;
					break;
				case 'instructors':
					window.location.href = `${this.envService.serviceMarketUrl}/instructors?filter=${term}`;
					break;
				default:
					this.filterProducts.emit(term)
					break
			}
			this.filterProducts.emit(term);
			return;
	}

	selectType(type: string) {
		this.typeSelected.emit(type);
		this.store.dispatch( new SetContext(type));
	}

	onEnterProduct(hit: GenericSearchHit) {
		this.filterProduct.emit({hit, context: 'products'});
	}

	onEnterTopic(hit: GenericSearchHit) {
		this.filterSubtopic.emit({hit, context: 'products'});
	}

	onEnterSeller(hit: GenericSearchHit) {
		this.filterSeller.emit({hit, context: 'products'});
	}

	dispatchRedirect(term: string) {
		if (this.searchHits) {
			if (this.searchHits.products && this.searchHits.products.length) {
				const exactMatch = this.searchHits.products.find(el => el.key === term);
				if (exactMatch) { this.onEnterProduct(exactMatch); return; }
			}
			if (this.searchHits.sellers && this.searchHits.sellers.length) {
				const exactMatch = this.searchHits.sellers.find(el => el.key === term);
				if (exactMatch) { this.onEnterSeller(exactMatch); return; }
			}
			if (this.searchHits.topics && this.searchHits.topics.length) {
				const exactMatch = this.searchHits.topics.find(el => el.key === term);
				if (exactMatch) { this.onEnterTopic(exactMatch); return; }
			}
			if (this.searchHits.courses && this.searchHits.courses.length) {
				const exactMatch = this.searchHits.courses.find(el => el.course.code === term);
				if (exactMatch) { window.location.href = `${this.envService.serviceMarketUrl}/courses/details/${term}?leapest-force-login=true`; return; }
			}
			if (this.searchHits.instructors && this.searchHits.instructors.length) {
				const exactMatch = this.searchHits.instructors.find(el => el.instructor.id === term);
				if (exactMatch) { window.location.href = `${this.envService.serviceMarketUrl}/instructors/profile/${term}?leapest-force-login=true`; return; }
			}
		}

		const type = this.form.get('type').value;
		this.goTo(type, term);
	}
}
