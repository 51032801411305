import { filter, finalize, take, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { CallbackResultModel } from '../models/callback-result.model';
import * as i0 from "@angular/core";
export class WindowOpenerService {
    openWindowForResult(url, state, queryParams) {
        const clone = Object.assign({}, queryParams, { state: btoa(CallbackResultModel.toJSON(state)) });
        const urlParams = Object.keys(clone).reduce((arr, key) => {
            arr.push(`${key}=${clone[key]}`);
            return arr;
        }, []);
        const fullUrl = url + '?' + urlParams.join('&');
        const popup = window.open(fullUrl);
        // TODO: timeout and check for ad blocker
        window.focus();
        return fromEvent(window, 'message').pipe(filter((e) => e.origin === window.location.origin), filter((e) => e.data.event === state.event), take(1), tap((event) => console.log('EVENT', event.data)), finalize(() => popup.close()));
    }
}
WindowOpenerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowOpenerService_Factory() { return new WindowOpenerService(); }, token: WindowOpenerService, providedIn: "root" });
