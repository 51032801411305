import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
} from "@angular/forms";

export interface MultiSelectComponentOption<T = any> {
  label: string;
  value: T;
  disabled?: boolean;
}

// TODO: add posibility to pass 'nz-options' through the 'ng-content'
@Component({
  selector: "leap-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectComponent,
      multi: true,
    },
  ],
})
export class MultiSelectComponent<T = any> implements ControlValueAccessor {
  @Input() value: T[] = [];
  @Input() placeholder: string;
  @Input() allowClear: boolean = true;
  @Input() showSearch: boolean;
  @Input() disabled: boolean;
  @Input() valueKey: string;
  @Input() set options(o: MultiSelectComponentOption<T>[]) {
    if (!Array.isArray(o)) {
      const type = 0 == null ? o : typeof o;
      throw new Error(
        `'options' param of MultiSelectComponent must be array but passed ${type}`
      );
    }
    this._options = o;
  }

  @Output() change: EventEmitter<T[]> = new EventEmitter();

  @Output() onSearch: EventEmitter<string> = new EventEmitter();

  @HostListener("blur") onBlur() {
    this.onTouched;
  }

  control: FormControl = new FormControl(null);
  _options: MultiSelectComponentOption<T>[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.valueChanges.subscribe((value: T) => {
      const newValue: T[] = [...this.value, value];
      this.patchValue(newValue);
    });
  }

  onChange: (_: T[]) => void;
  onTouched: () => void;

  get availableOptions(): MultiSelectComponentOption<T>[] {
    return this._options.filter(
      (o: MultiSelectComponentOption<T>) => !this.selectedOptions.includes(o)
    );
  }

  get selectedOptions(): MultiSelectComponentOption<T>[] {
    return this.value
      ? this._options.filter((o: MultiSelectComponentOption<T>) =>
          this.value.includes(o.value)
        )
      : [];
  }

  writeValue(value: T[]) {
    this.value = value || [];
  }

  registerOnChange(fn: (value: T[]) => any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  unselectOption(option: MultiSelectComponentOption<T>) {
    const filteredValue: T[] = this.value.filter((i: T) => i !== option.value);
    this.patchValue(filteredValue);
  }

  private patchValue(value: T[]) {
    this.value = value;
    this.emitChanges(this.value);
    this.resetInputValue();
  }

  private emitChanges(value: T[]) {
    this.change.emit(value);
    this.onChange(value);
  }

  private resetInputValue() {
    this.control.patchValue(null, { emitEvent: false });
    this.changeDetectorRef.detectChanges();
  }

  onSearchEvent(value: string) {
    this.onSearch.emit(value);
  }
}
