import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[leap-shopping-cart-thead-bar]',
  templateUrl: './shopping-cart-thead-bar.component.html',
  styleUrls: ['./shopping-cart-thead-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartTheadBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
