<td class="label promotionCode">Promotion code {{ promotionCode.code }} 
    <i class="info-circle"
        style="font-size: 15px;"
        nz-popover [nzContent]="promotionInfoTemplate" nzTrigger="hover"
        nz-icon type="info-circle" theme="fill"></i>
</td>
<td class="value promotionCode">-{{ currencySignsMap?.get(currency) }} {{ promotionCode.totalDiscount | number : '1.2-2' }} <ng-container *ngTemplateOutlet="deleteIconTemplate"></ng-container>
</td>

<ng-template #deleteIconTemplate>
    <svg *ngIf="!freeze" (click)="removeFn()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" xml:space="preserve" y="0px" x="0px" id="Layer_1_1565614274602" width="14" height="16"><g transform="translate(1, 1)"><path d="M336,64l-33.6-44.8C293.3,7.1,279.1,0,264,0h-80c-15.1,0-29.3,7.1-38.4,19.2L112,64H24C10.7,64,0,74.7,0,88v2  c0,3.3,2.7,6,6,6h26v368c0,26.5,21.5,48,48,48h288c26.5,0,48-21.5,48-48V96h26c3.3,0,6-2.7,6-6v-2c0-13.3-10.7-24-24-24H336z   M184,32h80c5,0,9.8,2.4,12.8,6.4L296,64H152l19.2-25.6C174.2,34.4,179,32,184,32z M384,464c0,8.8-7.2,16-16,16H80  c-8.8,0-16-7.2-16-16V96h320V464z M208,420V156c0-6.6,5.4-12,12-12h8c6.6,0,12,5.4,12,12v264c0,6.6-5.4,12-12,12h-8  C213.4,432,208,426.6,208,420z M128,420V156c0-6.6,5.4-12,12-12h8c6.6,0,12,5.4,12,12v264c0,6.6-5.4,12-12,12h-8  C133.4,432,128,426.6,128,420z M288,420V156c0-6.6,5.4-12,12-12h8c6.6,0,12,5.4,12,12v264c0,6.6-5.4,12-12,12h-8  C293.4,432,288,426.6,288,420z" class="st0_1565614274602" vector-effect="non-scaling-stroke"/></g></svg>
</ng-template>

<ng-template #promotionInfoTemplate>
    <strong class="promotion-main">Promotion code {{ promotionCode.code }}  for {{ discountPercentage }}% off is applied to  the following items:</strong>
    <span class="promotion-main-sub">The displayed prices below are without surcharges applied</span>

    <div nz-row class="font-small">
        <div nz-col nzMd="10">&nbsp;</div>
        <div nz-col nzMd="7" class="right">Cost</div>
        <div nz-col nzMd="2" class="right">Qty</div>
        <div nz-col nzMd="5" class="right">Total</div>
    </div>
    <hr>
    <div nz-row class="vertical-align-middle font-small vertical-margin-10" *ngFor="let item of promotionCode.aggregation">
        <div nz-col nzMd="10">{{ item.productName }}</div>
        <div nz-col nzMd="7" class="right font-big"><del class="font-small">{{ currencySignsMap?.get(currency) }} {{ item.netAmount | number : '1.2-2' }}</del> {{ currencySignsMap?.get('EUR') }} {{ item.netAmountWithDiscount | number : '1.2-2' }}</div>
        <div nz-col nzMd="2" class="right font-big">{{ item.quantity }}</div>
        <div nz-col nzMd="5" class="right font-big">{{ currencySignsMap?.get(currency) }} {{ item.netTotalWithDiscount | number : '1.2-2' }}</div>
    </div>
</ng-template>
