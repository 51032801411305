import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

export interface IFilterOption {
    text: string;
    value: string;
    byDefault?: boolean;
}

@Component({
    selector: 'leap-searchable-filter-panel',
    templateUrl: './searchable-filter-panel.component.html',
    styleUrls: ['searchable-filter-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchableFilterPanelComponent implements OnInit, OnDestroy {

    @Input() set options(value: IFilterOption[]) {
        this._options$.next(value);
    };

    @Output() valueChanges: EventEmitter<string[]> = new EventEmitter();
    @Output() searchChanged: EventEmitter<string> = new EventEmitter();

    form = new FormGroup({
        'search': new FormControl(''),
        'options': new FormGroup({}),
    });
    filteredOptions$: Observable<IFilterOption[]>;

    private _options$: BehaviorSubject<IFilterOption[]> = new BehaviorSubject([]);

    constructor() {
    }

    ngOnInit() {
        this._options$.subscribe((value: IFilterOption[]) => {
            this.setOptionsFormGroup(value);
        });

        this.form.get('search').valueChanges
            .pipe(
                untilDestroyed(this),
                startWith(''),
                distinctUntilChanged()
            )
            .subscribe((searchValue: string) => this.searchChanged.emit(searchValue));

        this.filteredOptions$ = combineLatest([
            this._options$,
            this.form.get('search').valueChanges.pipe(startWith(''))
        ]).pipe(
            untilDestroyed(this),
            map(([options, searchValue]: [IFilterOption[], string]) => {
                return options.filter((o: IFilterOption) => o.text.toLowerCase().includes(searchValue.toLowerCase()));
            })
        );
    }

    ngOnDestroy() {
    }

    reset() {
        this.form.reset({
            'search': ''
        });
        this.emitChanges();
    }

    save() {
        this.emitChanges();
    }

    private setOptionsFormGroup(options: IFilterOption[]) {
        const optionsControls: { [value: string]: FormControl } = {};

        options.forEach((o: IFilterOption) => {
            optionsControls[o.value] = new FormControl(o.byDefault);
        });

        this.form.setControl('options', new FormGroup(optionsControls));
    }

    private emitChanges() {
        const value: { [value: string]: boolean } = this.form.get('options').value;
        const emitValue: string[] = Object.entries(value)
            .filter(([key, value]: [string, boolean]) => value)
            .map(([key, value]: [string, boolean]) => key);
        this.valueChanges.emit(emitValue);
    }
}
