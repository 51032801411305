import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { PromotionCode } from 'src/app/common/common-shopping-cart/models/promotion-code.model';

@Component({
  selector: '[leap-promotion-code-row]',
  templateUrl: './promotion-code-row.component.html',
  styleUrls: ['./promotion-code-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionCodeRowComponent implements OnInit {

  @Input() freeze: boolean;
  @Input() promotionCode: PromotionCode;
  @Input() currencySignsMap: Map<string, string>;
  @Input() currency: string;
  @Output() remove: EventEmitter<string> = new EventEmitter();

  private discountPercentage = null;

  constructor() { }

  ngOnInit() {
    this.discountPercentage =
        this.promotionCode &&
        this.promotionCode.aggregation &&
        this.promotionCode.aggregation.length &&
        this.promotionCode.aggregation[0].discountPercentage * 100;
  }

  removeFn() {
    this.remove.emit(this.promotionCode.code);
  }
}
