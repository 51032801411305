<nz-card class="card">
  <section class="top-bar">
    <div class="top-bar__column">
      <label
        *ngIf="isPermittedToPublishToLxp"
        nz-checkbox
        [ngModel]="checked"
        (ngModelChange)="emitSelectCard($event)"
        [nzDisabled]="!canBePublishedToLxp"
      ></label>
    </div>
    <div class="top-bar__column">
      <span
        *ngIf="isPermittedToPublishToLxp"
        class="lxp-label top-bar__item"
        [ngClass]="{
          'lxp-label--style-published': publishedToLxp,
          'lxp-label--style-unpublishable':
            !canBePublishedToLxp || (isBundleType && publishedToLxp)
        }"
        [nzTooltipTitle]="lxpLabelTooltip"
        nzTooltipPlacement="bottom"
        nz-tooltip
        (click)="canBePublishedToLxp && onLxpLabelClick()"
      >
        <svg
          *ngIf="canBePublishedToLxp"
          version="1.2"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          overflow="visible"
          preserveAspectRatio="none"
          viewBox="0 0 512 512"
          width="14"
          height="14"
        >
          <g transform="translate(0, 0)">
            <path
              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"
              style="fill: currentColor; stroke-width: 0px;"
              vector-effect="non-scaling-stroke"
            />
          </g>
        </svg>
        <svg
          *ngIf="!canBePublishedToLxp"
          version="1.2"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          overflow="visible"
          preserveAspectRatio="none"
          viewBox="0 0 512 512"
          xml:space="preserve"
          y="0px"
          x="0px"
          id="Layer_1"
          width="14"
          height="14"
        >
          <g transform="translate(0, 0)">
            <path
              d="M256,8C119,8,8,119,8,256s111,248,248,248s248-111,248-248S393,8,256,8z M256,472c-118.7,0-216-96.1-216-216&#10;&#9;c0-118.7,96.1-216,216-216c118.7,0,216,96.1,216,216C472,374.7,375.9,472,256,472z M350.8,186.7L281.5,256l69.3,69.3&#10;&#9;c4.7,4.7,4.7,12.3,0,17l-8.5,8.5c-4.7,4.7-12.3,4.7-17,0L256,281.5l-69.3,69.3c-4.7,4.7-12.3,4.7-17,0l-8.5-8.5&#10;&#9;c-4.7-4.7-4.7-12.3,0-17l69.3-69.3l-69.3-69.3c-4.7-4.7-4.7-12.3,0-17l8.5-8.5c4.7-4.7,12.3-4.7,17,0l69.3,69.3l69.3-69.3&#10;&#9;c4.7-4.7,12.3-4.7,17,0l8.5,8.5C355.4,174.4,355.4,182,350.8,186.7L350.8,186.7z"
              class="st0"
              style="fill: currentColor; stroke-width: 0px;"
              vector-effect="non-scaling-stroke"
            />
          </g>
        </svg>
        LXP
      </span>

      <ng-container
        *ngIf="
          isLogged &&
          !(addLineItemPending && addLineItemSku === selectedVariant?.sku)
        "
      >
        <span
          class="cart-button top-bar__item"
          nz-tooltip
          [nzTitle]="addToBasketDisabled ? getTooltipTemplate() : null"
          [ngClass]="{ disabled: addToBasketDisabled }"
          (click)="addToBasketDisabled ? '' : addToCartFn()"
          *leapHasGroup="['buyer']"
        >
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 578 514"
            width="33.125"
            height="29.444444444444"
          >
            <g transform="translate(1, 1)">
              <path
                d="M551.991 64H129.28l-8.329-44.423C118.822 8.226 108.911 0 97.362 0H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h78.72l69.927 372.946C150.305 416.314 144 431.42 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-17.993-7.435-34.24-19.388-45.868C506.022 391.891 496.76 384 485.328 384H189.28l-12-64h331.381c11.368 0 21.177-7.976 23.496-19.105l43.331-208C578.592 77.991 567.215 64 551.991 64zM464 416c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32zm-256 0c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32zm294.156-128H171.28l-36-192h406.876l-40 192zM272 196v-8c0-6.627 5.373-12 12-12h36v-36c0-6.627 5.373-12 12-12h8c6.627 0 12 5.373 12 12v36h36c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-36v36c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-36h-36c-6.627 0-12-5.373-12-12z"
                fill="currentColor"
                vector-effect="non-scaling-stroke"
              />
            </g>
          </svg>
        </span>
      </ng-container>
    </div>
  </section>

  <section class="card-content">
    <i
      class="loading-icon"
      nz-icon
      [nzType]="'loading'"
      *ngIf="addLineItemPending && addLineItemSku === selectedVariant?.sku"
    ></i>

    <div
      class="badge contract"
      *ngIf="(restricted && !hasAccess) || isNotContractedSupplier"
    >
      Contract Required
    </div>

    <a
      href="javascript:void(0)"
      [routerLink]="getRouterLink()"
      [queryParams]="getQueryParams()"
    >
      <leap-product-card-image [imageUrl]="imageUrl"></leap-product-card-image>
    </a>

    <div
      class="price-container"
      [ngSwitch]="currency"
      *leapHasGroup="['buyer']"
    >
      <a
        class="price-link"
        href="javascript:void(0)"
        [routerLink]="getRouterLink()"
        [queryParams]="getQueryParams()"
      >
        <ng-container
          *ngIf="price !== null && price !== undefined; else noPrice"
        >
          <span
            >{{ currencySignsMap?.get(currency) }}
            {{ price | number: "1.2-2" }}</span
          >
          <ng-container *ngIf="isPromotionPricingApplied">
            <span
              nz-tooltip
              nzTooltipPlacement="right"
              nzTooltipTitle="Valid upto: {{
                promotionPricingDiscountEndDate | date: 'dd MMM yyyy'
              }}"
              class="price-valid"
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                overflow="visible"
                preserveAspectRatio="none"
                viewBox="0 0 13 15"
                width="13"
                height="15"
              >
                <g transform="translate(0, 0)">
                  <g transform="translate(0, 0) rotate(0)">
                    <path
                      style="stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(247, 155, 66);"
                      d="M11.60714,1.875h-1.39286v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-5.57143v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-1.39286c-0.76897,0 -1.39286,0.62988 -1.39286,1.40625v10.3125c0,0.77637 0.62388,1.40625 1.39286,1.40625h10.21429c0.76897,0 1.39286,-0.62988 1.39286,-1.40625v-10.3125c0,-0.77637 -0.62388,-1.40625 -1.39286,-1.40625zM1.39286,2.8125h10.21429c0.25536,0 0.46429,0.21094 0.46429,0.46875v1.40625h-11.14286v-1.40625c0,-0.25781 0.20893,-0.46875 0.46429,-0.46875zM11.60714,14.0625h-10.21429c-0.25536,0 -0.46429,-0.21094 -0.46429,-0.46875v-7.96875h11.14286v7.96875c0,0.25781 -0.20893,0.46875 -0.46429,0.46875zM8.55156,11.25293c0.13638,0.1377 0.13638,0.36035 0,0.49805l-0.1654,0.16699c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-1.39286,-1.41211l-1.39576,1.40918c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-0.1654,-0.16699c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l1.39576,-1.40918l-1.39576,-1.40918c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l0.1654,-0.16699c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l1.39576,1.40918l1.39576,-1.40918c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l0.1654,0.16699c0.13638,0.1377 0.13638,0.36035 0,0.49805l-1.39866,1.41211z"
                      vector-effect="non-scaling-stroke"
                    />
                  </g>
                  <defs>
                    <path
                      id="path-1659940866239599"
                      d="M11.60714,1.875h-1.39286v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-5.57143v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-1.39286c-0.76897,0 -1.39286,0.62988 -1.39286,1.40625v10.3125c0,0.77637 0.62388,1.40625 1.39286,1.40625h10.21429c0.76897,0 1.39286,-0.62988 1.39286,-1.40625v-10.3125c0,-0.77637 -0.62388,-1.40625 -1.39286,-1.40625zM1.39286,2.8125h10.21429c0.25536,0 0.46429,0.21094 0.46429,0.46875v1.40625h-11.14286v-1.40625c0,-0.25781 0.20893,-0.46875 0.46429,-0.46875zM11.60714,14.0625h-10.21429c-0.25536,0 -0.46429,-0.21094 -0.46429,-0.46875v-7.96875h11.14286v7.96875c0,0.25781 -0.20893,0.46875 -0.46429,0.46875zM8.55156,11.25293c0.13638,0.1377 0.13638,0.36035 0,0.49805l-0.1654,0.16699c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-1.39286,-1.41211l-1.39576,1.40918c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-0.1654,-0.16699c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l1.39576,-1.40918l-1.39576,-1.40918c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l0.1654,-0.16699c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l1.39576,1.40918l1.39576,-1.40918c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l0.1654,0.16699c0.13638,0.1377 0.13638,0.36035 0,0.49805l-1.39866,1.41211z"
                      vector-effect="non-scaling-stroke"
                    />
                  </defs>
                </g>
              </svg>
            </span>
          </ng-container>
        </ng-container>
        <ng-template #noPrice>N/A</ng-template>
      </a>
    </div>
    <div class="actual-price">
      <ng-container
        *ngIf="isPromotionPricingApplied && actualAmount; else noPromotion"
      >
        <span
          >{{ currencySignsMap?.get(currency) }}
          {{ actualAmount | number: "1.2-2" }}</span
        >
      </ng-container>
      <ng-template #noPromotion>
        <div class="no-promotion"></div>
      </ng-template>
    </div>

    <div class="product-type-container">
      <span>{{ type }}</span>
    </div>

    <a
      href="javascript:void(0)"
      [routerLink]="getRouterLink()"
      [queryParams]="getQueryParams()"
    >
      <h3
        class="product-title"
        nz-tooltip
        [nzTitle]="
          addToBasketDisabled
            ? getTooltipTemplate()
            : name.length > 60
            ? fullNameTooltipTemplate
            : null
        "
        [ngClass]="{ disabled: addToBasketDisabled }"
      >
        <span
          >{{ name | ellipsis: ["60"] }}{{ suffix ? " - " + suffix : "" }}</span
        >
      </h3>
    </a>

    <div class="seller-container">
      <!--      TODO Should be rolled back, when avatar aspect ratio restriction will be implemented-->
      <!--      <leap-seller-image-and-name-->
      <!--        [size]="'small'"-->
      <!--        [imageUrl]="sellerImageUrl"-->
      <!--        [domain]="sellerDomain"-->
      <!--        [name]="sellerName"-->
      <!--      ></leap-seller-image-and-name>-->
      <a
        class="seller-name"
        nz-tooltip
        nzTooltipPlacement="bottom"
        [nzTooltipTitle]="sellerName.length > 26 ? sellerName : ''"
        routerLink="/seller/page/{{ sellerDomain }}"
      >
        {{ sellerName }}
      </a>
    </div>
    <div class="separator"></div>

    <div class="options-container">
      <ng-container *ngTemplateOutlet="selectLanguageTemplate"></ng-container>
      <ng-container *ngIf="!isBundleType">
        <ng-container
          *ngTemplateOutlet="selectDeliveryFormatTemplate"
        ></ng-container>
        <ng-container *ngTemplateOutlet="selectTypeTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="isBundleType">
        <ng-container *ngTemplateOutlet="selectLicenseTemplate"></ng-container>
      </ng-container>
    </div>
  </section>
</nz-card>

<ng-template #selectLanguageTemplate>
  <div class="option" [formGroup]="form">
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 514 514"
      width="16"
      height="16"
    >
      <g transform="translate(1, 1)">
        <path
          d="M128 216c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 384c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176s224 79 224 176-100.5 176-224 176z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
      </g>
    </svg>
    <nz-select formControlName="language" style="width: 100%">
      <nz-option
        *ngFor="let item of languages | orderBy"
        [nzValue]="item?.configKey"
        [nzLabel]="item?.configValue"
      ></nz-option>
    </nz-select>
  </div>
</ng-template>

<ng-template #selectDeliveryFormatTemplate>
  <div class="option" [formGroup]="form">
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 514 514"
      width="16"
      height="16"
    >
      <g transform="translate(1, 1)">
        <path
          d="M497.9 97.98L414.02 14.1c-9-9-21.19-14.1-33.89-14.1H176c-26.5.1-47.99 21.6-47.99 48.09V165.7c-5.97 0-11.94-1.68-24.13-5.03-1.7-.46-3.36-.66-4.96-.66-5.56 0-10.43 2.5-13.66 5.79-17.95 18.26-17.07 17.77-41.7 24.5-6.7 1.81-11.97 7.21-13.78 14.07-6.47 24.67-6.09 24.16-24.02 42.32-4.95 5.04-6.9 12.48-5.08 19.43 6.56 24.38 6.52 24.39 0 48.76-1.82 6.95.12 14.4 5.08 19.45 18 18.15 17.58 17.79 24.02 42.29 1.8 6.88 7.08 12.27 13.78 14.1 1.8.48 2.92.8 4.46 1.21V496c0 5.55 2.87 10.69 7.59 13.61 4.66 2.91 10.59 3.16 15.56.7l56.84-28.42 56.84 28.42c2.25 1.12 4.72 1.69 7.16 1.69h272c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM384.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zM33.28 316.68c5.7-22.3 5.7-30.23.01-52.39 15.65-16.2 19.56-22.98 25.63-45.06 21.57-6.13 28.06-9.92 43.88-25.69 9.8 2.62 16.82 4.15 25.21 4.15 8.28 0 15.25-1.49 25.19-4.16 15.56 15.51 22.49 19.58 43.86 25.68 5.98 21.95 9.71 28.63 25.65 45.07-5.77 22.45-5.76 30 0 52.4-15.62 16.17-19.55 22.96-25.61 44.96-14.63 3.92-24 7.36-37.25 19.36-9.94-4.53-20.78-6.89-31.85-6.89s-21.9 2.36-31.85 6.9c-13.18-11.88-22.56-15.34-37.23-19.33-5.97-21.89-9.72-28.57-25.64-45zm101.89 133.01c-4.5-2.25-9.81-2.25-14.31 0l-40.84 20.42V409.9c.12.12.19.17.31.29 3.75 3.82 8.68 5.79 13.64 5.79 3.5 0 7.02-.98 10.16-2.97 7.25-4.59 15.56-6.88 23.87-6.88s16.62 2.29 23.87 6.86c3.16 2.02 6.68 3.01 10.17 3.01 4.96 0 9.87-1.99 13.63-5.79.13-.13.21-.18.34-.32v60.22l-40.84-20.42zm344.84 14.32c0 8.8-7.2 16-16 16h-256V391.9c1.54-.4 2.65-.71 4.44-1.19 6.7-1.82 11.97-7.22 13.77-14.08 6.47-24.68 6.09-24.16 24.03-42.32 4.95-5.04 6.9-12.49 5.07-19.44-6.53-24.33-6.55-24.34 0-48.76 1.83-6.95-.12-14.4-5.07-19.45-18-18.15-17.58-17.79-24.03-42.29-1.8-6.87-7.07-12.27-13.75-14.09-24.23-6.57-23.89-6.23-41.72-24.52-2.94-2.97-6.78-4.52-10.74-5.16V48.09c0-8.8 7.2-16.09 16-16.09h176.03v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
      </g>
    </svg>
    <nz-select formControlName="deliveryFormat" style="width: 100%">
      <nz-option
        *ngFor="let item of deliveryFormats | orderBy"
        [nzValue]="item?.configKey"
        [nzLabel]="item?.configValue"
      ></nz-option>
    </nz-select>
  </div>
</ng-template>

<ng-template #selectTypeTemplate>
  <div
    class="option"
    [formGroup]="form"
    [ngStyle]="{ visibility: certificateTypes?.length ? 'inherit' : 'hidden' }"
  >
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 499.8999938964844 514"
      xml:space="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1554977240245"
      width="16"
      height="16"
    >
      <g transform="translate(1, 1)">
        <path
          d="M143.8,223.8h166.5c2,0,3.7-1.7,3.7-3.7v-14.8c0-2-1.7-3.7-3.7-3.7H143.8c-2,0-3.7,1.7-3.7,3.7v14.8  C140.1,222.1,141.7,223.8,143.8,223.8z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M143.8,311.4H228l14-14v-4.5c0-2-1.7-3.7-3.7-3.7h-94.5c-2,0-3.7,1.7-3.7,3.7v14.8C140.1,309.7,141.7,311.4,143.8,311.4z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M105.5,190.4H75.9c-4.1,0-7.4,3.3-7.4,7.4v29.6c0,4.1,3.3,7.4,7.4,7.4h29.6c4.1,0,7.4-3.3,7.4-7.4v-29.6  C112.9,193.8,109.6,190.4,105.5,190.4z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M141.3,136.1h54.5c0.7,0,1.2-1.7,1.2-3.7v-14.8c0-2-0.5-3.7-1.2-3.7h-54.5c-0.7,0-1.2,1.7-1.2,3.7v14.8  C140.1,134.5,140.6,136.1,141.3,136.1z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M105.5,102.8H75.9c-4.1,0-7.4,3.3-7.4,7.4v29.6c0,4.1,3.3,7.4,7.4,7.4h29.6c4.1,0,7.4-3.3,7.4-7.4v-29.6  C112.9,106.1,109.6,102.8,105.5,102.8z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M105.5,365.7H75.9c-4.1,0-7.4,3.3-7.4,7.4v29.6c0,4.1,3.3,7.4,7.4,7.4h29.6c4.1,0,7.4-3.3,7.4-7.4v-29.6  C112.9,369,109.6,365.7,105.5,365.7z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M183.5,376.8h-39.7c-2,0-3.7,1.7-3.7,3.7v14.8c0,2,1.7,3.7,3.7,3.7h36.5L183.5,376.8z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M105.5,278.1H75.9c-4.1,0-7.4,3.3-7.4,7.4v29.6c0,4.1,3.3,7.4,7.4,7.4h29.6c4.1,0,7.4-3.3,7.4-7.4v-29.6  C112.9,281.4,109.6,278.1,105.5,278.1z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M352,361.9V464c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V48c0-8.8,7.2-16,16-16h176v104c0,13.3,10.7,24,24,24h104v74.9  l32-32v-71c0-12.7-5.1-25-14.1-34L286,14c-9-9-21.2-14.1-33.9-14.1H48C21.5,0,0,21.5,0,48v416c0,26.5,21.5,48,48,48h288  c26.5,0,48-21.5,48-48V329.9L352,361.9z M256,32.5c2.8,0.7,5.3,2.1,7.4,4.2l83.9,83.9c2.1,2.1,3.5,4.6,4.2,7.4H256V32.5z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
        <path
          d="M490.7,195l-35.3-35.3c-5.6-5.6-13.1-8.7-21-8.7c-7.9,0-15.3,3.1-21,8.7l-210,209.9l-0.4,0.4l-7.6,67.6  c-0.5,4.5,1,8.9,3.9,12.2c2.9,3.3,7.2,5.2,11.6,5.2c0.4,0,0.7,0,1-0.1c0.2,0,0.4,0,0.6,0l67.3-7.5l0.5-0.1l210.3-210.3  C502.2,225.5,502.2,206.6,490.7,195z M424.7,268.5L269.2,424l-48,5.3l5.3-48L382,225.8L424.7,268.5z M473.3,219.7l-31.4,31.4  l-42.7-42.7l31.4-31.4c1-1,2.3-1.6,3.6-1.6c1.4,0,2.8,0.6,3.7,1.6l35.3,35.3C475.3,214.4,475.3,217.7,473.3,219.7z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
      </g>
    </svg>
    <nz-select formControlName="certificateType" style="width: 100%">
      <nz-option
        *ngFor="let item of certificateTypes | orderBy"
        [nzValue]="item?.configKey"
        [nzLabel]="item?.configValue"
      ></nz-option>
    </nz-select>
  </div>
</ng-template>

<ng-template #selectLicenseTemplate>
  <div class="option" [formGroup]="form">
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 514 514"
      width="16"
      height="16"
    >
      <g transform="translate(1, 1)">
        <path
          d="M497.9 97.98L414.02 14.1c-9-9-21.19-14.1-33.89-14.1H176c-26.5.1-47.99 21.6-47.99 48.09V165.7c-5.97 0-11.94-1.68-24.13-5.03-1.7-.46-3.36-.66-4.96-.66-5.56 0-10.43 2.5-13.66 5.79-17.95 18.26-17.07 17.77-41.7 24.5-6.7 1.81-11.97 7.21-13.78 14.07-6.47 24.67-6.09 24.16-24.02 42.32-4.95 5.04-6.9 12.48-5.08 19.43 6.56 24.38 6.52 24.39 0 48.76-1.82 6.95.12 14.4 5.08 19.45 18 18.15 17.58 17.79 24.02 42.29 1.8 6.88 7.08 12.27 13.78 14.1 1.8.48 2.92.8 4.46 1.21V496c0 5.55 2.87 10.69 7.59 13.61 4.66 2.91 10.59 3.16 15.56.7l56.84-28.42 56.84 28.42c2.25 1.12 4.72 1.69 7.16 1.69h272c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM384.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zM33.28 316.68c5.7-22.3 5.7-30.23.01-52.39 15.65-16.2 19.56-22.98 25.63-45.06 21.57-6.13 28.06-9.92 43.88-25.69 9.8 2.62 16.82 4.15 25.21 4.15 8.28 0 15.25-1.49 25.19-4.16 15.56 15.51 22.49 19.58 43.86 25.68 5.98 21.95 9.71 28.63 25.65 45.07-5.77 22.45-5.76 30 0 52.4-15.62 16.17-19.55 22.96-25.61 44.96-14.63 3.92-24 7.36-37.25 19.36-9.94-4.53-20.78-6.89-31.85-6.89s-21.9 2.36-31.85 6.9c-13.18-11.88-22.56-15.34-37.23-19.33-5.97-21.89-9.72-28.57-25.64-45zm101.89 133.01c-4.5-2.25-9.81-2.25-14.31 0l-40.84 20.42V409.9c.12.12.19.17.31.29 3.75 3.82 8.68 5.79 13.64 5.79 3.5 0 7.02-.98 10.16-2.97 7.25-4.59 15.56-6.88 23.87-6.88s16.62 2.29 23.87 6.86c3.16 2.02 6.68 3.01 10.17 3.01 4.96 0 9.87-1.99 13.63-5.79.13-.13.21-.18.34-.32v60.22l-40.84-20.42zm344.84 14.32c0 8.8-7.2 16-16 16h-256V391.9c1.54-.4 2.65-.71 4.44-1.19 6.7-1.82 11.97-7.22 13.77-14.08 6.47-24.68 6.09-24.16 24.03-42.32 4.95-5.04 6.9-12.49 5.07-19.44-6.53-24.33-6.55-24.34 0-48.76 1.83-6.95-.12-14.4-5.07-19.45-18-18.15-17.58-17.79-24.03-42.29-1.8-6.87-7.07-12.27-13.75-14.09-24.23-6.57-23.89-6.23-41.72-24.52-2.94-2.97-6.78-4.52-10.74-5.16V48.09c0-8.8 7.2-16.09 16-16.09h176.03v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01z"
          vector-effect="non-scaling-stroke"
          style="fill: rgb(142, 143, 166);"
        />
      </g>
    </svg>
    <nz-select formControlName="license" style="width: 100%">
      <nz-option
        *ngFor="let item of licenses | orderBy"
        [nzValue]="item"
        [nzLabel]="item?.licenseTypeValue"
      ></nz-option>
    </nz-select>
  </div>
</ng-template>

<ng-template #fullNameTooltipTemplate>
  <span>{{ name }}{{ suffix ? " - " + suffix : "" }}</span>
</ng-template>
