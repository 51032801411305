/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./control-error.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/ng-zorro-antd/form/ng-zorro-antd-form.ngfactory";
import * as i4 from "ng-zorro-antd/form";
import * as i5 from "./control-error.component";
var styles_ControlErrorComponent = [i0.styles];
var RenderType_ControlErrorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ControlErrorComponent, data: {} });
export { RenderType_ControlErrorComponent as RenderType_ControlErrorComponent };
function View_ControlErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ControlErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ControlErrorComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { errors: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co._errors); var currVal_1 = _co._templateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ControlErrorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._text; _ck(_v, 0, 0, currVal_0); }); }
function View_ControlErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nz-form-explain", [], null, null, null, i3.View_NzFormExplainComponent_0, i3.RenderType_NzFormExplainComponent)), i1.ɵdid(1, 49152, null, 0, i4.NzFormExplainComponent, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ControlErrorComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["default", 2]], 0, 0, null, View_ControlErrorComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._templateRef && _co._errors); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ControlErrorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._text || _co._errors); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ControlErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-control-error", [], null, null, null, View_ControlErrorComponent_0, RenderType_ControlErrorComponent)), i1.ɵdid(1, 49152, null, 0, i5.ControlErrorComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var ControlErrorComponentNgFactory = i1.ɵccf("leap-control-error", i5.ControlErrorComponent, View_ControlErrorComponent_Host_0, { text: "text", errors: "errors", templateRef: "templateRef" }, {}, []);
export { ControlErrorComponentNgFactory as ControlErrorComponentNgFactory };
