import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AmberResponse } from '../models/amber-response.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { AbstractControl, ValidatorFn, Validators, FormControl } from '@angular/forms';

@Injectable()
export class ValidationService {
  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  validateEmail() {
    const pattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$';
    return Validators.pattern(pattern);
  }

  validateEmailNotTaken = (control: AbstractControl) => {
    return timer(200).pipe(
      take(1),
      switchMap(() => this.getIsEmailTaken(control.value)),
      map(res => res.data['value']),
      map(res => {
        const unique = res === 'false';
        return unique ? null : { emailTaken: true };
      })
    );
  };

  validateCountryCodeVATMatch = (control: AbstractControl) => {
    const countryCode = control.parent && control.parent.value.registeredCountry;
    const vatNumber = control.value;

    if (!countryCode || !vatNumber) return of(null);
    const europeanCountries = {
      AT: true,
      BE: true,
      BG: true,
      HR: true,
      CY: true,
      CZ: true,
      DK: true,
      EE: true,
      FI: true,
      FR: true,
      DE: true,
      GR: true,
      HU: true,
      IE: true,
      IT: true,
      LV: true,
      LT: true,
      LU: true,
      MT: true,
      NL: true,
      PL: true,
      PT: true,
      RO: true,
      SK: true,
      SI: true,
      ES: true,
      SE: true,
      GB: true
    };
    if (!europeanCountries[countryCode]) return of(null);

    return timer(200).pipe(
      switchMap(() => this.getIsCountryCodeVATMatch(countryCode, vatNumber)),
      map(res => {
        const valid = res.data['valid'];
        return valid ? null : { custom: { message: 'Unrecognized VAT number' } };
      })
    );
  };

  private getIsEmailTaken(email: string): Observable<AmberResponse<any>> {
    const url = this.envService.amberUrl + '/agora/account/check/email';
    const params = new HttpParams().set('email', email);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<AmberResponse<any>>(url, { params: params, headers: headers });
  }

  getIsCountryCodeVATMatch(countryCode: string, vatNumber: string): Observable<AmberResponse<any>> {
    const url = this.envService.saharaUrl + '/api/location/vat/validate';
    const params = new HttpParams().set('countryCode', countryCode).set('vatNumber', vatNumber);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<AmberResponse<any>>(url, { params: params, headers: headers });
  }

  validateArraySize(min: number): ValidatorFn {
    return (control: AbstractControl) => {
      const { value } = control;
      if (value instanceof Array) {
        return value.length >= min ? null : { minSize: { min } };
      }
      return { minSize: true };
    };
  }

  validateCheckboxRequired = (control: FormControl) => {
    let checked = control.value;
    if (!checked) {
        return { checkRequired: true }
    }
    return null;
  }
}
