import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { SortCaretComponent } from './components/sort-bar/sort-caret/sort-caret.component';
import { SortBarComponent } from './components/sort-bar/sort-bar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { NgxsModule } from '@ngxs/store';
import { TabsComponent } from './components/tabs/tabs.component';
import { UpdatesSectionComponent } from "./components/updates-section/updates-section.component";
import { HyperlinksAnchorComponent } from './components/hyperlinks-anchor/hyperlinks-anchor.component';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule,
    NgxsFormPluginModule,
    NzAnchorModule
  ],
  declarations: [
    SortBarComponent,
    SortCaretComponent,
    SearchBarComponent,
    TabsComponent,
    UpdatesSectionComponent,
    HyperlinksAnchorComponent
  ],
  exports: [
    SortCaretComponent,
    SortBarComponent,
    SearchBarComponent,
    TabsComponent,
    UpdatesSectionComponent,
    HyperlinksAnchorComponent
  ]
})
export class CommonUtilsModule { }
