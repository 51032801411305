import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductCardSkeletonComponent } from './components/product-card-skeleton/product-card-skeleton.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnatchModule } from 'src/app/snatch/snatch.module';
import { ProductCardImageSkeletonComponent } from './components/product-card-image-skeleton/product-card-image-skeleton.component';
import { SellerImageAndNameComponent } from './components/seller-image-and-name/seller-image-and-name.component';
import { ProductCardImageComponent } from './components/product-card-image/product-card-image.component';
import { ProductNoResultsComponent } from './components/product-no-results/product-no-results.component';
import { ProductFilterMenuComponent } from './components/product-filter-menu/product-filter-menu.component';
import { ProductAppliedFiltersComponent } from './components/product-applied-filters/product-applied-filters.component';
import { ProductFilterPillComponent } from './components/product-applied-filters/product-filter-pill/product-filter-pill.component';
import { ProductFeedComponent } from './containers/product-feed/product-feed.component';
import { CommonUtilsModule } from "../common-utils/common-utils.module";
import { ProductsCarouselSectionComponent } from "./components/products-carousel/products-carousel-section.component";
import { ProductCardShortComponent } from "./components/product-card-short/product-card-short.component";
import { HideRestrictedCheckboxComponent } from './components/hide-restricted-checkbox/hide-restricted-checkbox.component';
import { SuggestionsModule } from "../../suggestions/suggestions.module";
import { PublishToLxpModalComponent } from './components/publish-to-lxp-modal/publish-to-lxp-modal.component';

import { RouterModule, Routes } from '@angular/router';

const entryComponents = [
  PublishToLxpModalComponent
];

const components = [
  ...entryComponents,
  ProductCardComponent,
  ProductCardSkeletonComponent,
  ProductCardImageSkeletonComponent,
  SellerImageAndNameComponent,
  ProductCardImageComponent,
  ProductNoResultsComponent,
  ProductFilterMenuComponent,
  ProductAppliedFiltersComponent,
  ProductFilterPillComponent,
  ProductFeedComponent,
  ProductsCarouselSectionComponent,
  ProductCardShortComponent,
  HideRestrictedCheckboxComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    SnatchModule,
    CommonUtilsModule,
    SuggestionsModule,
    RouterModule
  ],
  entryComponents,
  exports: [...components],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonProductModule { }
