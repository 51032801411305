import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'span[leap-label-optional]',
  templateUrl: './label-optional.component.html',
  styleUrls: ['./label-optional.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelOptionalComponent {}
