/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-menu-item.component.less.ngstyle";
import * as i1 from "../../../navbar.less.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./user-menu-item.component";
import * as i5 from "@angular/platform-browser";
var styles_UserMenuItemComponent = [i0.styles, i1.styles];
var RenderType_UserMenuItemComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_UserMenuItemComponent, data: {} });
export { RenderType_UserMenuItemComponent as RenderType_UserMenuItemComponent };
function View_UserMenuItemComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "span", [["class", "icon"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.securedSvg || ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_UserMenuItemComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 7, "div", [["class", "user-route-item"]], null, null, null, null, null)), i2.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2]), i2.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i2.ɵpad(3, 2), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_UserMenuItemComponent_1)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "user-route-item"; var currVal_1 = _ck(_v, 3, 0, (_co.hoverClass || ""), (!_co.stickToRightEdge ? "dont-stick-to-right-edge" : "")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.svgTemplate; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; _ck(_v, 7, 0, currVal_3); }); }
export function View_UserMenuItemComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "leap-user-menu-item", [], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i2.ɵnov(_v, 1).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i2.ɵnov(_v, 1).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i2.ɵnov(_v, 1).onMouseClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_UserMenuItemComponent_0, RenderType_UserMenuItemComponent)), i2.ɵdid(1, 114688, null, 0, i4.UserMenuItemComponent, [i5.DomSanitizer, i2.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMenuItemComponentNgFactory = i2.ɵccf("leap-user-menu-item", i4.UserMenuItemComponent, View_UserMenuItemComponent_Host_0, { title: "title", svgTemplate: "svgTemplate", hoverClass: "hoverClass", stickToRightEdge: "stickToRightEdge" }, {}, []);
export { UserMenuItemComponentNgFactory as UserMenuItemComponentNgFactory };
