import {Component, OnInit} from '@angular/core';
import {EnvironmentService} from "../../../../snatch/services";

@Component({
  selector: 'leap-error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.less']
})
export class ErrorNotFoundComponent implements OnInit {

  constructor(public environment: EnvironmentService) {}

  ngOnInit() {}
}
