import * as tslib_1 from "tslib";
import { OnInit, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { SendMessage, FetchOlderMessages, MarkReadMessage, CheckDMConversation, CreateDMConversation } from '../../state/inbox.state';
import { filter } from 'rxjs/operators';
import { ScrollContainerDirective } from '../../directives/scroll-container.directive';
import { untilDestroyed } from 'ngx-take-until-destroy';
const ɵ0 = (state) => state.inbox.selectedConversation, ɵ1 = (state) => state.inbox.messages, ɵ2 = (state) => state.inbox.newMessageInSelectedConversation, ɵ3 = (state) => state.inbox.scrollToBottom, ɵ4 = (state) => state.inbox.fetchingOlderMessages, ɵ5 = (state) => state.core.dictionaries.currencySignsMap;
export class ConversationComponent {
    constructor(store) {
        this.store = store;
        this.actionClick = new EventEmitter();
        this.scrolledToTop = new Subject();
    }
    ngOnInit() {
        const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
        this.myEmail = userDetails.username;
        this.myDomain = userDetails.domain;
        this.newMessage$
            .pipe(filter(_ => !!_), untilDestroyed(this))
            .subscribe(_ => setTimeout(_ => this.conversationContainerRef.nativeElement.scrollTop = this.conversationContainerRef.nativeElement.scrollHeight, 200));
        this.scrollToBottom$
            .pipe(filter(_ => !!_), untilDestroyed(this))
            .subscribe(_ => {
            setTimeout(_ => this.conversationContainerRef.nativeElement.scrollTop = this.conversationContainerRef.nativeElement.scrollHeight, 100);
        });
        this.messages$.subscribe(_ => {
            console.log(_ && _.length);
        });
    }
    ngOnDestroy() { }
    getRecipientDisplayName(conversation) {
        return conversation.recipient && conversation.recipient.displayName;
    }
    getRecipientLogoUrl(conversation) {
        return conversation.recipient && conversation.recipient.logoUrl;
    }
    sendMessage(message) {
        if (this.conversationId === 'new-dm') {
            this.store.dispatch(new CreateDMConversation(message));
        }
        else {
            this.store.dispatch(new SendMessage(message));
        }
    }
    onScrollDown() {
        this.store.dispatch(new MarkReadMessage());
    }
    onScrollUp() {
        const messages = this.store.selectSnapshot((state) => state.inbox.messages);
        if (messages && messages.length)
            this.store.dispatch(new FetchOlderMessages());
    }
    createDMConversation(domain) {
        this.store.dispatch(new CheckDMConversation(domain));
    }
    getSenderDisplayName(senderDomain, accounts) {
        return accounts.find(_ => _.name === senderDomain).displayName;
    }
    getSenderLogoUrl(senderDomain, accounts) {
        return accounts.find(_ => _.name === senderDomain).logoUrl;
    }
    trackById(index, item) {
        return item.id;
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], ConversationComponent.prototype, "conversation$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], ConversationComponent.prototype, "messages$", void 0);
tslib_1.__decorate([
    Select(ɵ2),
    tslib_1.__metadata("design:type", Observable)
], ConversationComponent.prototype, "newMessage$", void 0);
tslib_1.__decorate([
    Select(ɵ3),
    tslib_1.__metadata("design:type", Observable)
], ConversationComponent.prototype, "scrollToBottom$", void 0);
tslib_1.__decorate([
    Select(ɵ4),
    tslib_1.__metadata("design:type", Observable)
], ConversationComponent.prototype, "fetchingOlderMessages$", void 0);
tslib_1.__decorate([
    Select(ɵ5),
    tslib_1.__metadata("design:type", Observable)
], ConversationComponent.prototype, "currencySignsMap$", void 0);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
