import { State, StateContext, Selector, Action } from '@ngxs/store';
import { ListenForSessionChange, NavigateToLogout, SessionCheckFailure, SessionCheckSuccess, SetSessionCookie } from '../actions/auth.actions';
import { GetEmployee } from 'src/app/core/actions/core.actions';
import { SessionService } from 'src/app/snatch/services/session.service';
import { StorageService } from 'src/app/snatch/services/storage.service';
import { EnvironmentService } from 'src/app/snatch/services';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Inject } from '@angular/core';
import * as oktaHelpers from 'src/app/snatch/utils/okta-helpers';
import { timer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export class AuthStateModel {
  public state: AuthState;
  public userDetails?: any; // TODO: add types
  public groups?: string[];
}

export enum AuthState {
  GUEST = 0,
  LOGGED = 1,
  PROMPTED_FOR_RELOG = 2
}

@State<AuthStateModel>({
  name: 'session',
  defaults: {
    state: null,
    userDetails: undefined,
    groups: []
  }
})
export class AuthTokenState {
  @Selector()
  static isLogged({ state }: AuthStateModel): boolean {
    return Boolean(state === AuthState.LOGGED || state === AuthState.PROMPTED_FOR_RELOG);
  }

  intervalSessionCheckInMs: number = 5000;

  constructor(
    private sessionService: SessionService,
    private storageService: StorageService,
    private environment: EnvironmentService,
    @Inject(OKTA_AUTH) private readonly _authClient: OktaAuth,
  ) {}

  @Action(ListenForSessionChange)
  async listenForSessionChange({ getState, dispatch }: StateContext<AuthStateModel>, action: ListenForSessionChange) {
    timer(0, this.intervalSessionCheckInMs).pipe(
      map(async () => {
        const skipRoute = window.location.pathname.startsWith('/login/callback')
        const oktaIsAuthenticated = await this._authClient.isAuthenticated();
        const activeCookieSession = oktaHelpers.checkForActiveSession();
        if (!skipRoute) {
          if (!oktaIsAuthenticated) {
            // If cookie is present, redirect to okta
            // If cookie is not present, but okta tokens exist, sign out
            if (activeCookieSession) {
              await oktaHelpers.oktaSignInWithRedirect(this._authClient)
            } else if (getState().state === 1) { // In case the session is still set to 1, Navigate to Logout
              dispatch([new NavigateToLogout()]);
            }
          } else {
            await oktaHelpers.checkForSessionMismatch(this._authClient, this.storageService, this.sessionService);
          }
        }

        if (oktaIsAuthenticated && !activeCookieSession) {
            dispatch([new NavigateToLogout()]);
        }

        return;
      })
    )
    .subscribe()
  }

  @Action(SessionCheckSuccess)
  async sessionCheckSuccess({ dispatch, patchState }: StateContext<AuthStateModel>, action: SessionCheckSuccess) {
    const oktaUser = await this.sessionService.getLoggedInUserDetails();
    patchState({
      state: AuthState.LOGGED,
      userDetails: {
        username: oktaUser['mkpEmail'],
        domain: oktaUser['mkpDomain'],
        groups: oktaUser['mkpGroups']
      },
      groups: oktaUser['mkpGroups']
    });

    dispatch(new GetEmployee());
  }

  @Action(SetSessionCookie)
  async setSessionCookie({ }: StateContext<AuthStateModel>) {
    const oktaTokenStorage = this.storageService.get('okta-token-storage');
    const accessTokenData = oktaTokenStorage && oktaTokenStorage['accessToken'];
    const refreshToken = oktaTokenStorage && oktaTokenStorage['refreshToken'];
    const claims = accessTokenData && accessTokenData['claims'];
    const accessToken = accessTokenData && accessTokenData['accessToken'];
    const authTime = this.sessionService.decodeToken(accessToken).auth_time;

    const cookiePayload = {
      username: claims.mkpEmail,
      domain: claims.mkpDomain,
      authAt: authTime,
      expiresAt: refreshToken.expiresAt,
      initiator: window.location.origin
    }

    const cookieDomain = this.environment.getHost;
    this.setCookie("mkp-session", JSON.stringify(cookiePayload), cookieDomain, refreshToken.expiresAt*1000)
  }

  @Action(SessionCheckFailure)
  sessionCheckFailure({ dispatch, patchState, setState }: StateContext<AuthStateModel>, action: SessionCheckFailure) {
    setState({ state: AuthState.GUEST, groups: [] });
  }

  setCookie(cname, cvalue, domain, expiresAt) {
    const d = new Date();
    d.setTime(expiresAt);
    let expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/;domain=.${domain}`;
  }

  @Action(NavigateToLogout)
  async navigateToLogout(ctx: StateContext<AuthStateModel>) {
    const cookieDomain = this.environment.getHost;
    document.cookie = `mkp-session=;expires=-999999;path=/;domain=.${cookieDomain}`; 
    
    const baseUrl = new URL(window.location.href);
    const postLogoutRedirectUri = `${baseUrl.origin}/logout`;
    await this._authClient.signOut({ postLogoutRedirectUri });
  }

  private async checkIfLoggedOutFromAnotherApp(dispatch) {
    const isAuthenticated = await this._authClient.isAuthenticated();
    const activeSession = oktaHelpers.checkForActiveSession();
    if (isAuthenticated && !activeSession) {
        dispatch([new NavigateToLogout()]);
    }
  }

  private async checkForSessionMismatch() {
    return await oktaHelpers.checkForSessionMismatch(this._authClient, this.storageService, this.sessionService);
  }
}
