import * as tslib_1 from "tslib";
import { Store } from '@ngxs/store';
import { SessionCheckFailure, SessionCheckSuccess, SetSessionCookie } from 'src/app/auth/actions/auth.actions';
export class InitSessionGuard {
    constructor(store) {
        this.store = store;
    }
    canDeactivate(next) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.store.dispatch([new SetSessionCookie(), new SessionCheckSuccess()]);
            }
            catch (e) {
                yield this.store.dispatch(new SessionCheckFailure());
            }
            return true;
        });
    }
}
