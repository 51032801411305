/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-filter-pill.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "./product-filter-pill.component";
var styles_ProductFilterPillComponent = [i0.styles];
var RenderType_ProductFilterPillComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductFilterPillComponent, data: {} });
export { RenderType_ProductFilterPillComponent as RenderType_ProductFilterPillComponent };
export function View_ProductFilterPillComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "pill-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["nz-icon", ""], ["theme", "outline"], ["title", "Remove filter"], ["type", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removed.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { type: [0, "type"], theme: [1, "theme"] }, null)], function (_ck, _v) { var currVal_0 = "close"; var currVal_1 = "outline"; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ProductFilterPillComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-filter-pill", [], null, null, null, View_ProductFilterPillComponent_0, RenderType_ProductFilterPillComponent)), i1.ɵdid(1, 114688, null, 0, i4.ProductFilterPillComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductFilterPillComponentNgFactory = i1.ɵccf("leap-product-filter-pill", i4.ProductFilterPillComponent, View_ProductFilterPillComponent_Host_0, {}, { removed: "removed" }, ["*"]);
export { ProductFilterPillComponentNgFactory as ProductFilterPillComponentNgFactory };
