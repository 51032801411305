import * as tslib_1 from "tslib";
import { Action, State, Store } from "@ngxs/store";
import { MiscService } from '../services/misc.service';
import { map } from "rxjs/operators";
import { GetCatalogTree } from "../actions/dictionaries.actions";
export class DictionariesStateModel {
}
let DictionariesState = class DictionariesState {
    constructor(miscService, store) {
        this.miscService = miscService;
        this.store = store;
    }
    ngxsOnInit({ patchState, getState }) {
        let currencySignsMap = new Map()
            .set('USD', '$')
            .set('EUR', '€')
            .set('INR', '₹')
            .set('GBP', '£')
            .set('DKK', 'kr')
            .set('AED', 'AED');
        let lxpProductStatusMap = new Map()
            .set('published', 'Published')
            .set('notPublished', 'Not Published');
        patchState({ currencySignsMap, dictionaryFetchingPending: true, lxpProductStatusMap });
        this.miscService.getDictionaries()
            .pipe(map(response => response.data))
            .subscribe(({ currencies, countries, }) => {
            patchState({
                currencies,
                currenciesMap: this.convertArrayToMap(currencies),
                countries,
                countriesMap: this.convertArrayToMap(countries),
                dictionaryFetchingPending: false
            });
        });
        this.miscService.getStates()
            .pipe(map(response => response.data.states))
            .subscribe((states) => {
            patchState(Object.assign({}, getState(), { statesMap: this.convertStatesToMap(states) }));
        });
    }
    fetchCatalogTree({ patchState, dispatch, getState }, action) {
        patchState({ catalogTreeFetchingPending: true });
        this.miscService.getCatalogs()
            .pipe(map(_ => _.data))
            .subscribe((data) => {
            const catalogs = data.catalogs ? data.catalogs : [];
            patchState({
                catalogTree: this.mapCatalogs(catalogs).menu,
                topicsMap: this.mapCatalogs(catalogs).topicsMap,
                subTopicsMap: this.mapCatalogs(catalogs).subTopicsMap,
                catalogsMap: this.convertCategoriesArrayToMap(catalogs),
                catalogTreeFetchingSuccess: true,
                catalogTreeFetchingPending: false
            });
            return dispatch([]);
        }, error => {
            patchState({
                catalogTreeFetchingPending: false,
                catalogTreeFetchingError: error,
                catalogTreeFetchingFailed: true
            });
            return dispatch([]);
        });
    }
    convertArrayToMap(arr) {
        return new Map(arr.map(i => [i.key, i.value]));
    }
    convertCategoriesArrayToMap(arr) {
        return new Map(arr.map(i => [i.code, i.name]));
    }
    convertSubTopics(arr) {
        return new Map(arr.map(i => [i.code, i.title]));
    }
    convertStatesToMap(arr) {
        let result = new Map();
        arr.forEach((_) => {
            const cCode = _.countryCode;
            const sCode = _.code;
            const name = _.name;
            if (!result.has(cCode))
                result.set(cCode, []);
            result.set(cCode, [...result.get(cCode), { code: sCode, name }]);
        });
        return result;
    }
    mapCatalogs(arr) {
        const menu = {
            catalogs: [],
            topics: [],
            subTopics: []
        };
        const uniqueSubTopics = {};
        arr.forEach(item => {
            const { categories } = item, restData = tslib_1.__rest(item, ["categories"]);
            menu.catalogs.push(restData);
            const mapCategory = categories ? this.mapTopics(categories, item.code) : null;
            const { mTopics, mSubTopics } = mapCategory;
            // FIXME move check if topic if empty to Backend
            mSubTopics.forEach(s => {
                const findTheSame = Object.keys(uniqueSubTopics).find(k => k === s.code);
                if (findTheSame) {
                    uniqueSubTopics[findTheSame].parentTopics.push(...s.parentTopics);
                    uniqueSubTopics[findTheSame].parentCatalogs.push(item.code);
                }
                else {
                    uniqueSubTopics[s.code] = s;
                }
            });
            menu.topics.push(...mTopics);
        });
        menu.subTopics.push(...Object.values(uniqueSubTopics));
        const topicsMap = this.convertSubTopics(menu.topics);
        const subTopicsMap = this.convertSubTopics(menu.subTopics);
        return { menu, topicsMap, subTopicsMap };
    }
    mapTopics(arr, parentCatalog) {
        const mTopics = [];
        const mSubTopics = [];
        arr.forEach(item => {
            const topics = item.topics;
            topics.forEach(e => {
                const { children } = e, restData = tslib_1.__rest(e, ["children"]);
                const findEl = mTopics.find(el => el.code === e.code && el.parentCatalog === parentCatalog);
                if (!findEl) {
                    mTopics.push(Object.assign({}, restData, { parentCatalog }));
                    children.forEach(c => {
                        const findSubTopic = mSubTopics.find(s => s.code === c.code);
                        if (!findSubTopic) {
                            mSubTopics.push(Object.assign({}, c, { parentTopics: [e.code], parentCatalogs: [parentCatalog] }));
                        }
                    });
                }
            });
        });
        return { mTopics: mTopics.sort((a, b) => (a.title > b.title) ? 1 : -1), mSubTopics: mSubTopics.sort((a, b) => (a.title > b.title) ? 1 : -1) };
    }
};
tslib_1.__decorate([
    Action(GetCatalogTree),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, GetCatalogTree]),
    tslib_1.__metadata("design:returntype", void 0)
], DictionariesState.prototype, "fetchCatalogTree", null);
DictionariesState = tslib_1.__decorate([
    State({
        name: 'dictionaries',
        defaults: {
            catalogs: {
                catalogs: [],
                topics: [],
                subTopics: []
            },
            currencies: [],
            countries: [],
            topicsTree: [],
            catalogsMap: undefined,
            currenciesMap: undefined,
            currencySignsMap: undefined,
            topicsMap: undefined,
            countriesMap: undefined,
            statesMap: undefined,
            subTopicsMap: undefined,
            lxpProductStatusMap: undefined,
            dictionaryFetchingPending: false,
            catalogTreeFetchingPending: false,
            catalogTreeFetchingSuccess: true,
            catalogTreeFetchingFailed: false,
            catalogTreeFetchingError: undefined,
            catalogTree: {
                catalogs: [],
                topics: [],
                subTopics: []
            }
        }
    }),
    tslib_1.__metadata("design:paramtypes", [MiscService, Store])
], DictionariesState);
export { DictionariesState };
