import * as i0 from "@angular/core";
export class TimezoneService {
    constructor() { }
    detectTimezone(timezones) {
        const offset = new Date().getTimezoneOffset();
        const match = timezones.find(t => t.offset === offset);
        return match ? match.key : null;
    }
}
TimezoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimezoneService_Factory() { return new TimezoneService(); }, token: TimezoneService, providedIn: "root" });
