import { LineItem } from '../models/line-item.model';

export class OpenShoppingCartDrawer {
  static readonly type = '[Shopping Cart] Open Shopping Cart Drawer';
  constructor() {}
}
export class CloseShoppingCartDrawer {
  static readonly type = '[Shopping Cart] Close Shopping Cart Drawer';
  constructor() {}
}
export class GetShoppingCartDetails {
  static readonly type = '[Shopping Cart] Get Shopping Cart Details';
  constructor() {}
}

export class AddLineItemToShoppingCart {
  static readonly type = '[Shopping Cart] Add Line Item';
  constructor(public sku: string, public quantity?: number) {}
}
export class RemoveLineItemFromShoppingCart {
  static readonly type = '[Shopping Cart] Remove Line Item';
  constructor(public lineItem: LineItem) {}
}
export class QuantityChangeLineItemInShoppingCart {
  static readonly type = '[Shopping Cart] Quantity Change Line Item';
  constructor(public lineItem: LineItem) {}
}
export class EditShippingMethod {
  static readonly type = '[Shopping Cart] Edit Shipping Method';
  constructor(public shippingGroupId: string, public shippingMethodId: string) {}
}

export class EmptyCartAfterPayment {
  static readonly type = '[Shopping Cart] Empty Cart After Payment';
  constructor() {}
}

export class FetchPromotionCodes {
  static readonly type = '[Shopping Cart] Fetch Promotion Codes';
  constructor() {}
}

export class AddPromotionCode {
  static readonly type = '[Shopping Cart] Add Promotion Code';
  constructor(public promotionCode: string) {}
}
export class AddPromotionCodeSuccess {
  static readonly type = '[Shopping Cart] Add Promotion Code Success';
  constructor() {}
}
export class AddPromotionCodeFailure {
  static readonly type = '[Shopping Cart] Add Promotion Code Failure';
  constructor() {}
}

export class RemovePromotionCode {
  static readonly type = '[Shopping Cart] Remove Promotion Code';
  constructor(public promotionCode: string) {}
}
export class RemovePromotionCodeSuccess {
  static readonly type = '[Shopping Cart] Add Promotion Code Success';
  constructor() {}
}
export class RemovePromotionCodeFailure {
  static readonly type = '[Shopping Cart] Add Promotion Code Failure';
  constructor() {}
}

export class CleanErrors {
  static readonly type = '[Shopping Cart] Clean Errors';
  constructor() {}
}

export class CleanShippingBatches {
  static readonly type = '[Shopping Cart] Clean Shipping Batches';
  constructor() {}
}

export class FetchAvailablePurchaseOrders {
  static readonly type = '[Shopping Cart] Fetch Available Purchase Orders per sellers';
  constructor(public sellerIds: Array<String>) { }
}

export class UpdatePurchaseOrderIdOnItem {
  static readonly type = '[Shopping Cart] Update Purchase Order Id on item';
  constructor(public id: string, public purchaseOrderIds: [string]) {}
}

export class AddReferenceId {
  static readonly type = '[Shopping Cart] Add reference ID';
  constructor(public referenceId: string) {}
}
