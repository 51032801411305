import { Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

// In app.module we need to init the OKTA SDK
export function getOktaFactory(router: Router): any {
  const oktaAuth = createOktaClient({ issuer: environment.okta.issuer, clientId: environment.okta.clientId });
  const factoryOut = { oktaAuth };
  factoryOut['onAuthRequired'] = async () => {
    const activeSession = await checkForActiveSession();
    if (activeSession) {
      await oktaSignInWithRedirect(oktaAuth);
    } else {
      router.navigate(['/login']);
    }
  };

  return factoryOut;
}


export async function oktaSignInWithRedirect(oktaClient: any) {
  try {
    await oktaClient.signInWithRedirect();
  } catch (e) { }
}

export function checkForActiveSession() {
  try {
      const cookie = getCookie('mkp-session');
      const cookieClaims = cookie && cookie.length && JSON.parse(cookie);

      if (cookieClaims && cookieClaims.username) {
          return true
      }
  } catch (e) { }

  return false;
}

export async function checkForSessionMismatch(oktaClient: any, storageService: any, sessionService: any) {
  try {
      const cookie = getCookie('mkp-session');
      const cookieClaims = cookie && cookie.length && JSON.parse(cookie);

      if (cookieClaims && cookieClaims.username) {
          
          const oktaTokenStorage = storageService.get('okta-token-storage');
          const accessTokenData = oktaTokenStorage && oktaTokenStorage['accessToken'];
          const accessToken = accessTokenData && accessTokenData['accessToken'];
          const authTime = sessionService.decodeToken(accessToken).auth_time;
          
          if (cookieClaims.authAt > authTime) { // stale session, must sign in with redirect
              await oktaClient.signInWithRedirect()
          } 
      }
  } catch (e) { }

  return false;
}

function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// Config OKTA Client with session storage for `lxp` session
// and local storage for `platform` session
function createOktaClient(
  config: any
): OktaAuth {
  const oktaParams = {
    issuer: config.issuer,
    clientId: config.clientId,
    redirectUri: getRedirectUri(),
    scopes: ['openid', 'profile', 'offline_access'],
    useInteractionCodeFlow: false,
  };

  const oktaAuth = new OktaAuth(oktaParams);

  return oktaAuth;
}

function getRedirectUri() {
  return `${window.location.origin}/login/callback`;
}
