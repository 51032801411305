import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './state';
import { AuthService } from './auth.service';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature(AuthState)],
  providers: [AuthService],
  declarations: []
})
export class AuthModule {}
