import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonProductModule } from '../common/common-product/common-product.module';
import { ShoppingCartDrawerComponent } from './containers/shopping-cart-drawer/shopping-cart-drawer.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NgxsModule } from '@ngxs/store';
import { ShoppingCartState } from './state/shopping-cart.state';
import { LineItemComponent } from './components/line-item/line-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ShoppingCartPageComponent } from './containers/shopping-cart-page/shopping-cart-page.component';
import { CommonCheckoutModule } from '../common/common-checkout/common-checkout.module';
import { CommonShoppingCartModule } from '../common/common-shopping-cart/common-shopping-cart.module';
import { SnatchModule } from '../snatch/snatch.module';
import { ShoppingCartReviewSectionComponent } from './components/shopping-cart-review-section/shopping-cart-review-section.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ShoppingCartDrawerComponent, LineItemComponent, ShoppingCartPageComponent, ShoppingCartReviewSectionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    NgxsModule.forFeature([ShoppingCartState]),
    NgxsFormPluginModule,
    CommonProductModule,
    CommonCheckoutModule,
    CommonShoppingCartModule,
    SnatchModule,
    RouterModule
  ],
  exports: [ShoppingCartDrawerComponent, ShoppingCartPageComponent]
})
export class ShoppingCartModule { }
