import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { InboxConversation } from 'src/app/inbox/containers/models/inbox-message.model';
import { map } from 'rxjs/operators';
import { SelectConversation, MarkAsSeen } from 'src/app/inbox/state/inbox.state';
import { EnvironmentService } from "../../../../../snatch/services";
@Component({
  selector: 'leap-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesComponent implements OnInit {
  
  @Select((state: any) => state.inbox.conversations)
  conversations$: Observable<InboxConversation[]>;

  @Select((state: any) => state.inbox.latestSeenMessage)
  latestSeenMessage$: Observable<number>;

  @Select((state: any) => state.inbox.latestReceivedMessage)
  latestReceivedMessage$: Observable<number>;

  @Select((state: any) => state.inbox.unseenMessages)
  unseenMessages$: Observable<number>;

  conversationsLimited$: Observable<InboxConversation[]>;

  myEmail: string;
  myDomain: string;

  constructor(private store: Store, private environment: EnvironmentService) { }

  ngOnInit() {
    const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
    this.myEmail = userDetails.username;
    this.myDomain = userDetails.domain;

    this.conversationsLimited$ = this.conversations$.pipe(map(_ => _.slice(0, 3)))
  }

  redirectToInbox() {
    this.store.dispatch(new Navigate(['inbox']));
  }

  redirectToConversation(conversation: any) {
    if (conversation.channel.type != 'dm') {
      window.location.href= `${this.environment.serviceMarketUrl}/inbox?quoteCycleCode=${conversation.channel.context.data.quoteCycle.code}&leapest-force-login=true`;
    } else {
      this.store.dispatch([new SelectConversation(conversation.channelId), new Navigate(['inbox'])])
    }

  }

  markAsSeen() {
    this.store.dispatch([ new MarkAsSeen() ])
  }

  compareMyDomain(domain: string) {
    return this.myDomain === domain;
  }

}
