export interface IFilter {
	type: SortTypes;
	value: string;
	displayValue: string;
	sort(...args): Array<any>;
	items?: Array<ISelect>;
	order: any,
	dates?: any,
	types?: string,
	search?: string,
	fields?: string[];
	external?: true;
	action?: any;
	orderType?: string;
	align?: 'center'
}

export interface IFiltersSummary {
	order?: any;
	dates?: any;
	types?: string;
	search?: string;
}

interface ISelect {
	label: string;
	value: boolean;
	checked: boolean;
}

export enum OrderTypes {
	OBJECT = 'object',
	STRING = 'string'
}
export enum SortTypes {
	ALPHABETICAL = "alphabetical",
	MULTISELECT = "multiselect",
	BOOLEAN = "boolean",
	NUMERICAL = "numerically",
	DATE = "date",
	SEARCH = "search",
	EMPTY = "empty",
	DATES_PERIOD = "dates_period",
	SELECT = "select",
	FIELD = "field",
	SORT = "sort",
	NONE = 'none'
}

export const singleFilters = [
	SortTypes.DATE
];
export interface ISelectedFilters {
	filters: { key: string, order: string }[],
	search: string,
	dates: {
		from: string,
		to: string
	}
}
