import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { SessionCheckFailure, SessionCheckSuccess, SetSessionCookie } from 'src/app/auth/actions/auth.actions';


@Injectable()
export class InitSessionGuard {
  constructor(
    private readonly store: Store
  ) {}

  async canDeactivate(next: ActivatedRouteSnapshot): Promise<boolean> {

    try {
      await this.store.dispatch([new SetSessionCookie(), new SessionCheckSuccess()]);
    } catch (e) {
      await this.store.dispatch(new SessionCheckFailure());
    }
    return true;
  }
}
