import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ViewChild,
	Input,
	OnChanges,
	AfterViewInit,
	HostListener
} from '@angular/core';
import {NzCarouselComponent} from "ng-zorro-antd";
import {ICourseEventSuggestion, IInstructorSuggestion, ISuggestionItem} from "../../../state/suggestions.state";

@Component({
	selector: 'leap-suggestion-carousel',
	templateUrl: './suggestion-carousel.component.html',
	styleUrls: ['./suggestion-carousel.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionCarouselComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() type: string;
	@Input() suggestions: ISuggestionItem[] | ICourseEventSuggestion[] | IInstructorSuggestion[];
	@Input() moreLink: string;
	@Input() domain: string;
	@Input() fetching:boolean;
	private activeIndex = 1;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		const width = event.target.innerWidth;
		this.setAmountOfElements(width);
	}
	amountOfElements = 4;
	@ViewChild(NzCarouselComponent, {static: false}) myCarousel: NzCarouselComponent;

	constructor() {
	}

	ngOnInit() {
		const width = window.innerWidth;
		this.setAmountOfElements(width);
	}

	ngAfterViewInit() {
		this.myCarousel.goTo(this.suggestions.length > 4 ? 1 : 0);
	}

	ngOnChanges() {
	}

	switchPage() {
		this.activeIndex = this.activeIndex === 0 ? 1 : 0;
		this.myCarousel.goTo(this.activeIndex);
	}

	goToDetails(item) {
		const {url} = item;
		window.location.href = this.domain + url + '?leapest-force-login=true';
	}
	setAmountOfElements(width){
		if(width < 900){
			this.amountOfElements = 2;
		} else if(width > 900 && width < 1300){
			this.amountOfElements = 3;
		} else if(width > 1300 && width < 1550){
			this.amountOfElements = 4;
		} else if(width > 1550 && width < 1800){
			this.amountOfElements = 5;
		} else if(width > 1800 && width < 1950){
			this.amountOfElements = 6;
		} else if(width > 1950 && width < 2150){
			this.amountOfElements = 7;
		} else if(width > 2150) {
			this.amountOfElements = 8;
		}
	}
}
