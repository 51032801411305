import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from "@angular/router";
import { map, catchError } from 'rxjs/operators';

import { Employee } from 'src/app/snatch/models/employee.model';
import { AmberResponse, AmberError } from 'src/app/snatch/models/amber-response.model';
import { Profile } from 'src/app/common/common-profile/models/profile.model';
import { NotificationsState } from './notifications.state';
import { GlobalFiltersState } from './global-filters.state';
import { DictionariesState } from './dictionaries.state';
import { Forbidden, NotFound } from "../constants/error-messages.config";
import {
  GetProfile,
  GetProfileSuccess,
  GetProfileFailure,
  GetEmployee,
  GetTranslations, RedirectOnError, GetVmsFlag
} from "../actions/core.actions";
import { MiscService } from '../services/misc.service';

export class CoreStateModel {
  profile?: Partial<Profile>;
  currency?: string;
  translations: any;
  getProfilePending: boolean;
  getProfileSuccess: boolean;
  getProfileError: AmberError;
  vmsEnabled: boolean;
  integrationEnabled: boolean;
}

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    profile: undefined,
    currency: undefined,
    translations: {},

    getProfilePending: false,
    getProfileSuccess: false,
    getProfileError: undefined,
    vmsEnabled: false,
    integrationEnabled: false
  },
  children: [GlobalFiltersState, DictionariesState, NotificationsState]
})
export class CoreState {

  @Selector()
  static isPermittedToPublishToLxp(state: CoreStateModel): boolean {
    return state.profile
      && state.profile.employee
      && state.profile.employee.isAccountAdmin
      && state.profile.employee.account.lxpConfigured
      && state.profile.employee.account.buyer;
  }
  constructor(private miscService: MiscService, private router: Router) { }

  @Action(GetEmployee)
  getEmployee({ patchState, dispatch, getState }: StateContext<CoreStateModel>) {
    patchState({ getProfilePending: true, getProfileSuccess: false, getProfileError: undefined });
    return this.miscService.getEmployee().pipe(
      map(_ => _.data.employee),
      map((employee: Employee) => {
        return dispatch(new GetProfileSuccess(<Profile>{ employee }))
      }),
      catchError((httpError: HttpErrorResponse) => {
        const error = (<AmberResponse<any>>httpError.error).error;
        return dispatch(new GetProfileFailure(error))
      })
    );
  }

  @Action(GetProfile)
  getProfile({ dispatch }: StateContext<CoreStateModel>) {
    return dispatch(new GetEmployee());
  }

  @Action(GetTranslations)
  getTranslations({ patchState, dispatch, getState }: StateContext<CoreStateModel>) {
    patchState({
      ...getState(),
      translations: {}
    });

    this.miscService.getProductMarketplaceTranslations().subscribe((response: any) => {
      window['translations'] = response.data;
      patchState({
        ...getState(),
        translations: response.data
      });
    });
  }

  @Action(GetProfileSuccess)
  getProfileSuccess({ patchState, getState }: StateContext<CoreStateModel>, action: GetProfileSuccess) {
    patchState({
      ...getState(),
      getProfilePending: false,
      getProfileSuccess: true,
      getProfileError: undefined,
      profile: action.payload,
      currency: action.payload.employee && action.payload.employee.account ? action.payload.employee.account.currency : "EUR" //Default value EUR for itpx domain
    });

  }

  @Action(GetProfileFailure)
  getProfileFailure({ patchState, getState }: StateContext<any>, action: GetProfileFailure) {
    patchState({
      ...getState(),
      getProfilePending: false,
      getProfileSuccess: false,
      getProfileError: action.payload,
      profile: undefined
    });
  }

  @Action(RedirectOnError)
  redirectTo404({ patchState, getState }: StateContext<any>, action: RedirectOnError) {
    switch (action.errorKey) {
      case NotFound:
        this.router.navigate(['404']);
        break;
      case Forbidden:
        this.router.navigate(['403']);
        break;
    }
  }

  @Action(GetVmsFlag)
  getUserViewPermissions({ patchState, getState, dispatch }: StateContext<CoreStateModel>, action: GetVmsFlag) {
    patchState({ getProfilePending: true })
    return this.miscService.getUserViewPermissionSettings(action.domain).pipe(
      map(body => body['data']),
      map((data: any) => {
        let vmsEnabled = data.features && data.features.vmsEnabled;
        let integrationEnabled = data.features && data.features.integration.enabled
        patchState({
          ...getState(),
          getProfilePending: false,
          vmsEnabled: vmsEnabled,
          integrationEnabled: integrationEnabled
        });
        return dispatch([]);
      }),
      catchError((httpError: HttpErrorResponse) => {
        const error = (<AmberResponse<any>>httpError.error).error;
        return dispatch(new GetProfileFailure(error))
      })
    );
  }

}
