import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leap-error-unauthenticated',
  templateUrl: './error-unauthenticated.component.html',
  styleUrls: ['./error-unauthenticated.component.less']
})
export class ErrorUnauthenticatedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
