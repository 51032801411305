<svg *ngIf="active && direction === 1" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 320 512" style="enable-background:new 0 0 320 512;" xml:space="preserve">
<path class="path-default" d="M288,288H32c-28.4,0-42.8,34.5-22.6,54.6l128,128c12.5,12.5,32.8,12.5,45.3,0l128-128
	C330.7,322.5,316.4,288,288,288z M160,448L32,320h256L160,448z"/>
<path class="path-primary" d="M32,224h256c28.4,0,42.8-34.5,22.6-54.6l-128-128c-12.5-12.5-32.8-12.5-45.3,0l-128,128
	C-10.7,189.5,3.6,224,32,224z"/>
</svg>

<svg *ngIf="active && direction === -1" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 320 512" style="enable-background:new 0 0 320 512;" xml:space="preserve">
<path class="path-primary" d="M288,288H32c-28.4,0-42.8,34.5-22.6,54.6l128,128c12.5,12.5,32.8,12.5,45.3,0l128-128
	C330.7,322.5,316.4,288,288,288z"/>
<path class="path-default" d="M310.6,169.4l-128-128c-12.5-12.5-32.8-12.5-45.3,0l-128,128C-10.7,189.5,3.6,224,32,224h256
	C316.4,224,330.8,189.5,310.6,169.4z M32,192L160,64l128,128H32z"/>
</svg>

<svg *ngIf="!active || !direction" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 320 512" style="enable-background:new 0 0 320 512;" xml:space="preserve">
<path class="path-default"  d="M288,288H32c-28.4,0-42.8,34.5-22.6,54.6l128,128c12.5,12.5,32.8,12.5,45.3,0l128-128
	C330.7,322.5,316.4,288,288,288z M160,448L32,320h256L160,448z M32,224h256c28.4,0,42.8-34.5,22.6-54.6l-128-128
	c-12.5-12.5-32.8-12.5-45.3,0l-128,128C-10.7,189.5,3.6,224,32,224z M160,64l128,128H32L160,64z"/>
</svg>
