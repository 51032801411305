import { Pipe, PipeTransform } from '@angular/core';
import produce from 'immer';

@Pipe({
 name: 'orderBy',
    pure: true
})
export class OrderByPipe  implements PipeTransform {

    transform(array: any, field?: string, direction?: string): any[] {
        let isDescending = direction === 'desc';
        if (!Array.isArray(array)) {
            return;
        }
        return produce(array, (draft) => {
            draft.sort((a: any, b: any) => {
                let left = field && field.length ? a[field] : a;
                let right = field && field.length ? b[field] : b;
                if (left < right) {
                    return isDescending ? 1 : -1;
                } else if (left > right) {
                    return isDescending? -1 : 1;
                } else {
                    return 0;
                }
            });
        });
    }
}
