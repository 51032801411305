import * as tslib_1 from "tslib";
import { NzModalService, NzMessageService } from "ng-zorro-antd";
import { Observable, throwError } from "rxjs";
import { filter, map, switchMap, tap, catchError } from "rxjs/operators";
import { Select } from "@ngxs/store";
import { LxpPublishingService } from "./lxp-publishing.service";
import { PublishToLxpModalComponent } from "../components/publish-to-lxp-modal/publish-to-lxp-modal.component";
import { buildPublishBundleToLxpPayload, buildPublishToLxpPayload, } from "../utils/lxp-products.utils";
import * as i0 from "@angular/core";
import * as i1 from "ng-zorro-antd/modal";
import * as i2 from "./lxp-publishing.service";
import * as i3 from "ng-zorro-antd/message";
const ɵ0 = (state) => state.core.dictionaries.currencySignsMap;
export class PublishToLxpFormService {
    constructor(modalService, lxpPublishingService, messageService) {
        this.modalService = modalService;
        this.lxpPublishingService = lxpPublishingService;
        this.messageService = messageService;
        this.currencySignsMap$.subscribe((value) => {
            this.currencySignsMap = value;
        });
    }
    publishProductToLxp(product) {
        return new Observable((subscriber) => {
            this.openPublishToLxpModal1([product], subscriber);
        }).pipe(filter((res) => !!res), map((res) => buildPublishToLxpPayload(res, product)), switchMap((payload) => this.lxpPublishingService.publishToLXP([payload]).pipe(catchError((error) => {
            this.test.destroy();
            this.messageService.create("error", error.message, {
                nzDuration: 5000,
                nzPauseOnHover: true,
            });
            return throwError(error);
        }), tap((res) => {
            this.test.destroy();
            setTimeout((_) => this.modalService.success({
                nzTitle: `Congratulations!`,
                nzContent: `You have published ${product.name}${product.suffix ? " - " + product.suffix : ""} to your LXP. it can take some time for the publishing process`,
            }), 700);
        }))));
    }
    publishBundleToLxp(product) {
        return new Observable((subscriber) => {
            this.openPublishToLxpModal1([product], subscriber);
        }).pipe(filter((res) => !!res), map((res) => buildPublishBundleToLxpPayload(res, product)), switchMap((payload) => this.lxpPublishingService.publishBundleToLXP(payload).pipe(catchError((error) => {
            this.test.destroy();
            this.messageService.create("error", error.message, {
                nzDuration: 5000,
                nzPauseOnHover: true,
            });
            return throwError(error);
        }), tap((res) => {
            this.test.destroy();
            setTimeout((_) => this.modalService.success({
                nzTitle: `Congratulations!`,
                nzContent: `You have published ${product.name}${product.suffix ? " - " + product.suffix : ""} to your LXP. it can take some time for the publishing process`,
            }), 700);
        }))));
    }
    bulkPublishToLxp(products) {
        return new Observable((subscriber) => {
            this.openPublishToLxpModal1(products, subscriber);
        }).pipe(filter((res) => !!res), map((res) => products.map((p) => buildPublishToLxpPayload(res, p))), switchMap((payload) => this.lxpPublishingService.publishToLXP(payload).pipe(catchError((error) => {
            this.test.destroy();
            this.messageService.create("error", error.message, {
                nzDuration: 5000,
                nzPauseOnHover: true,
            });
            return throwError(error);
        }), tap((res) => {
            this.test.destroy();
            setTimeout((_) => {
                const productDisplayNames = products.map((p) => `${p.name}${p.suffix ? " - " + p.suffix : ""}`);
                this.modalService.success({
                    nzTitle: `Congratulations!`,
                    nzContent: `
                                <div>You have published the following courses to your LXP:</div>
                                <ul>
                                    ${productDisplayNames.map((n) => `<li>${n}</li>`)}
                                </ul>
                            `,
                });
            }, 700);
        }))));
    }
    editLxpProduct(product, formInitialValue, lxpProductId) {
        return new Observable((subscriber) => {
            this.openPublishToLxpModal1([product], subscriber, formInitialValue);
        }).pipe(filter((res) => !!res), map((res) => buildPublishToLxpPayload(res, product, lxpProductId)), switchMap((payload) => this.lxpPublishingService.editLxpProduct(payload).pipe(catchError((error) => {
            this.test.destroy();
            this.messageService.create("error", error.message, {
                nzDuration: 5000,
                nzPauseOnHover: true,
            });
            return throwError(error);
        }), tap((res) => {
            this.test.destroy();
            setTimeout((_) => this.modalService.success({
                nzTitle: `Congratulations!`,
                nzContent: `You have published ${product.name} to your LXP. it can take some time for the publishing process`,
            }), 700);
        }))));
    }
    openPublishToLxpModal1(products, subscriber, formInitialValue) {
        return (this.test = this.modalService.create({
            nzWidth: "660px",
            nzBodyStyle: {
                padding: "20px",
            },
            nzOkLoading: true,
            nzFooter: null,
            nzContent: PublishToLxpModalComponent,
            nzComponentParams: {
                products,
                formInitialValue,
                currencySignsMap: this.currencySignsMap
            },
            nzOnOk: (res) => {
                return new Promise((resolve, reject) => {
                    subscriber.next(res.resultData);
                }).catch(() => console.log("Oops errors!"));
            },
        }));
    }
}
PublishToLxpFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublishToLxpFormService_Factory() { return new PublishToLxpFormService(i0.ɵɵinject(i1.NzModalService), i0.ɵɵinject(i2.LxpPublishingService), i0.ɵɵinject(i3.NzMessageService)); }, token: PublishToLxpFormService, providedIn: "root" });
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], PublishToLxpFormService.prototype, "currencySignsMap$", void 0);
export { ɵ0 };
