import { Injectable } from "@angular/core";
import { NzModalService, NzMessageService, NzModalRef } from "ng-zorro-antd";
import { Observable, Subscriber, throwError } from "rxjs";
import { filter, map, switchMap, tap, catchError } from "rxjs/operators";
import { Select } from "@ngxs/store";

import { LxpPublishingService } from "./lxp-publishing.service";
import { PublishToLxpFormValueDto } from "../models/publish-to-lxp-form-dto.interface";
import { PublishToLxpModalComponent } from "../components/publish-to-lxp-modal/publish-to-lxp-modal.component";
import { ProductShort } from "../models/product-short.model";
import {
  PublishBundleToLxpPayload,
  PublishToLxpPayload,
} from "../models/publish-to-lxp-payload.interface";
import {
  buildPublishBundleToLxpPayload,
  buildPublishToLxpPayload,
} from "../utils/lxp-products.utils";
import { AuthTokenState } from "../../../auth/state/auth-token.state";

@Injectable({ providedIn: "root" })
export class PublishToLxpFormService {
  @Select((state: any) => state.core.dictionaries.currencySignsMap)
  currencySignsMap$: Observable<Map<string, string>>;

  currencySignsMap: Map<string, string>;
  test: NzModalRef;

  constructor(
    private modalService: NzModalService,
    private lxpPublishingService: LxpPublishingService,
    private messageService: NzMessageService
  ) {
    this.currencySignsMap$.subscribe((value: Map<string, string>) => {
      this.currencySignsMap = value;
    });
  }

  publishProductToLxp(product: ProductShort): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.openPublishToLxpModal1([product], subscriber);
    }).pipe(
      filter((res: PublishToLxpFormValueDto | false) => !!res),
      map((res: PublishToLxpFormValueDto) =>
        buildPublishToLxpPayload(res, product)
      ),
      switchMap((payload: PublishToLxpPayload) =>
        this.lxpPublishingService.publishToLXP([payload]).pipe(
          catchError((error) => {
            this.test.destroy();

            this.messageService.create("error", error.message, {
              nzDuration: 5000,
              nzPauseOnHover: true,
            });
            return throwError(error);
          }),
          tap((res: any) => {
            this.test.destroy();
            setTimeout(
              (_) =>
                this.modalService.success({
                  nzTitle: `Congratulations!`,
                  nzContent: `You have published ${product.name}${
                    product.suffix ? " - " + product.suffix : ""
                  } to your LXP. it can take some time for the publishing process`,
                }),
              700
            );
          })
        )
      )
    );
  }

  publishBundleToLxp(product: ProductShort): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.openPublishToLxpModal1([product], subscriber);
    }).pipe(
      filter((res: PublishToLxpFormValueDto | false) => !!res),
      map((res: PublishToLxpFormValueDto) =>
        buildPublishBundleToLxpPayload(res, product)
      ),
      switchMap((payload: PublishBundleToLxpPayload) =>
        this.lxpPublishingService.publishBundleToLXP(payload).pipe(
          catchError((error) => {
            this.test.destroy();

            this.messageService.create("error", error.message, {
              nzDuration: 5000,
              nzPauseOnHover: true,
            });
            return throwError(error);
          }),
          tap((res: any) => {
            this.test.destroy();
            setTimeout(
              (_) =>
                this.modalService.success({
                  nzTitle: `Congratulations!`,
                  nzContent: `You have published ${product.name}${
                    product.suffix ? " - " + product.suffix : ""
                  } to your LXP. it can take some time for the publishing process`,
                }),
              700
            );
          })
        )
      )
    );
  }

  bulkPublishToLxp(products: ProductShort[]): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.openPublishToLxpModal1(products, subscriber);
    }).pipe(
      filter((res: PublishToLxpFormValueDto | false) => !!res),
      map((res: PublishToLxpFormValueDto) =>
        products.map((p: ProductShort) => buildPublishToLxpPayload(res, p))
      ),
      switchMap((payload: PublishToLxpPayload[]) =>
        this.lxpPublishingService.publishToLXP(payload).pipe(
          catchError((error) => {
            this.test.destroy();
            this.messageService.create("error", error.message, {
              nzDuration: 5000,
              nzPauseOnHover: true,
            });
            return throwError(error);
          }),
          tap((res: any) => {
            this.test.destroy();

            setTimeout((_) => {
              const productDisplayNames: string[] = products.map(
                (p: ProductShort) =>
                  `${p.name}${p.suffix ? " - " + p.suffix : ""}`
              );
              this.modalService.success({
                nzTitle: `Congratulations!`,
                nzContent: `
                                <div>You have published the following courses to your LXP:</div>
                                <ul>
                                    ${productDisplayNames.map(
                                      (n: string) => `<li>${n}</li>`
                                    )}
                                </ul>
                            `,
              });
            }, 700);
          })
        )
      )
    );
  }

  editLxpProduct(
    product: ProductShort,
    formInitialValue: PublishToLxpFormValueDto,
    lxpProductId: string
  ): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.openPublishToLxpModal1([product], subscriber, formInitialValue);
    }).pipe(
      filter((res: PublishToLxpFormValueDto | false) => !!res),
      map((res: PublishToLxpFormValueDto) =>
        buildPublishToLxpPayload(res, product, lxpProductId)
      ),
      switchMap((payload: PublishToLxpPayload) =>
        this.lxpPublishingService.editLxpProduct(payload).pipe(
          catchError((error) => {
            this.test.destroy();
            this.messageService.create("error", error.message, {
              nzDuration: 5000,
              nzPauseOnHover: true,
            });
            return throwError(error);
          }),
          tap((res: any) => {
            this.test.destroy();

            setTimeout(
              (_) =>
                this.modalService.success({
                  nzTitle: `Congratulations!`,
                  nzContent: `You have published ${product.name} to your LXP. it can take some time for the publishing process`,
                }),
              700
            );
          })
        )
      )
    );
  }

  private openPublishToLxpModal1(
    products: ProductShort[],
    subscriber: Subscriber<any>,
    formInitialValue?: PublishToLxpFormValueDto
  ): NzModalRef<PublishToLxpModalComponent, PublishToLxpFormValueDto> {
    return (this.test = this.modalService.create<PublishToLxpModalComponent>({
      nzWidth: "660px",
      nzBodyStyle: {
        padding: "20px",
      },
      nzOkLoading: true,
      nzFooter: null,
      nzContent: PublishToLxpModalComponent,
      nzComponentParams: {
        products,
        formInitialValue,
        currencySignsMap: this.currencySignsMap
      },
      nzOnOk: (res: any) => {
        return new Promise((resolve, reject) => {
          subscriber.next(res.resultData);
        }).catch(() => console.log("Oops errors!"));
      },
    }));
  }
}
