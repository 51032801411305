import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilService} from "../../../../snatch/services/form-util.service";
import { Address } from 'src/app/snatch/models/address.model';
import {Subject} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {StateCodeName} from "../../../../core/state/dictionaries.state";

@Component({
  selector: 'leap-address-edit-modal',
  templateUrl: './address-edit-modal.component.html',
  styleUrls: ['./address-edit-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressEditModalComponent implements OnInit, OnDestroy {

  @Input() addMode: boolean;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() editAddress: Address = <Address>{};
  @Input() countriesMap: Map<string, string>;
  @Input() statesMap: Map<string, Array<StateCodeName>>;
  @Input() zipCodePatterns: any;

  @Input() success: boolean;
  @Input() pending: boolean;
  @Input() error: HttpErrorResponse;

  @Output() saveAddress: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  public form: FormGroup;
  public countryKeys: Array<string> = [];
  private ngUnsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private formUtil: FormUtilService,
    private chgRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.error = null;
    this.countryKeys = Array.from(this.countriesMap.keys())
      .sort((a, b) => {
        if (this.countriesMap.get(a) < this.countriesMap.get(b)) { return -1; }
        if (this.countriesMap.get(a) > this.countriesMap.get(b)) { return 1; }
        return 0;
      });

    this.form = this.fb.group({
      firstName: [this.editAddress.firstName, Validators.required],
      lastName: [this.editAddress.lastName, Validators.required],
      companyName: [this.editAddress.companyName],
      email: [this.editAddress.email, [Validators.required, Validators.email]],
      phoneNumber: [this.editAddress.phoneNumbers ? this.editAddress.phoneNumbers[0]: null, [Validators.required]],
      country: [this.editAddress.country, [Validators.required]],
      state: [this.editAddress.state],
      province: [this.editAddress.province],
      city: [this.editAddress.city, [Validators.required]],
      streetName: [this.editAddress.streetName, [Validators.required]],
      houseNumber: [this.editAddress.houseNumber],
      zipCode: [this.editAddress.zipCode, [Validators.required]],
      makeDefaultType: [!!this.editAddress.makeDefaultType],
    });
  }

  isFormItemRequired(formControlName: string) {
    return this.form.get(formControlName)
        && this.form.get(formControlName).dirty
        && this.form.get(formControlName).errors
        && this.form.get(formControlName).errors.required;
  }

  isFormItemInvalid(formControlName: string) {
    return !this.isFormItemRequired(formControlName)
        && this.form.get(formControlName)
        && this.form.get(formControlName).dirty
        && this.form.get(formControlName).invalid;
  }

  getStatesOfSelectedCountry() {
    return this.form.value.country &&
           this.statesMap.get(this.form.value.country) &&
           this.statesMap.get(this.form.value.country).length?
        this.statesMap.get(this.form.value.country).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        }) : [];
  }

  saveChanges() {
    this.formUtil.triggerValidation(this.form);

    if (this.form.valid) {
      this.saveAddress.emit(this.form.value);
    } else {
      this.chgRef.detectChanges();
    }
  }

  cancelChanges() {
    this.form.reset();
    this.cancel.emit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
