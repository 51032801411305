import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { HtmlBypassPipe } from "./pipes/html-bypass.pipe";
import { EnvironmentService, UploadService, ValidationService } from "./services";
import { SessionService } from "./services/session.service";
import { StorageService } from "./services/storage.service";
import { GroupGuard } from "./guards/group.guard";
import { NgxsModule } from "@ngxs/store";
import { HasGroupDirective } from "./directives/has-group.directive";
import { IsAuthDirective } from "./directives/is-auth.directive";
import { ReplacePipe } from "./pipes/replace.pipe";
import { FormGuidanceComponent } from "./components/form-guidance/form-guidance.component";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { DictionaryService } from "./services/dictionary.service";
import { TimezoneService } from "./services/timezone.service";
import { SearchableMultiselectComponent } from "./components/searchable-multiselect/searchable-multiselect.component";
import { FormControlLimitComponent } from "./components/form-control-limit/form-control-limit.component";
import { ControlErrorDirective } from "./directives/control-error.directive";
import { FormSubmitDirective } from "./directives/form-submit.directive";
import { ControlErrorComponent } from "./components/control-error/control-error.component";
import { ControlErrorContainerDirective } from "./directives/control-error-container.directive";
import { AmberErrorPipe } from "./pipes/amber-error.pipe";
import { ErrorAlertComponent } from "./components/alert-error/error-alert.component";
import { EllipsisPipe } from "./pipes/ellipsis.pipe";
import { MatchHeightDirective } from "./directives/match-height.directive";
import { LabelOptionalDirective } from "./directives/label-optional.directive";
import { LabelOptionalComponent } from "./components/label-optional/label-optional.component";
import { FilenamePipe } from "./pipes/filename.pipe";
import { TranslatePipe } from "./pipes/translate.pipe";
import { ShoppingCartGuard } from "./guards/shopping-cart.guard";
import { OrderByPipe } from "./pipes/order.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SanitizeHtmlPipe } from "./pipes/sanitize-html.pipe";
import { MediaFilterPipe } from "./pipes/media-filter.pipe";
import { YouTubePreviewPipe } from "./pipes/you-tube-preview.pipe";
import { MenuFilterPipe } from "./pipes/menu-filter.pipe";
import { ListItemSkeletonComponent } from "./components/list-item-skeleton/list-item-skeleton.component";
import { MultiTableComponent } from "./components/multi-table/multi-table.component";
import { MultiTableItemComponent } from "./components/multi-table-item/multi-table-item.component";
import { CommonUtilsModule } from "../common/common-utils/common-utils.module";
import { MultiTableSortBarComponent } from "./components/multi-table-sort-bar/multi-table-sort-bar.component";
import { ComponentDirective } from "./directives/component.directive";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { CurrencyPipe } from "./pipes/currency.pipe";
import { UrlFromBucketPipe } from './pipes/url-from-bucket.pipe';
import { SuggestionsModule } from "../suggestions/suggestions.module";
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { SideNavigationMenuComponent } from './components/side-navigation-menu/side-navigation-menu.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselSlideComponent } from './components/carousel/carousel-slide/carousel-slide.component';
import { SearchableFilterPanelComponent } from './components/searchable-filter-panel/searchable-filter-panel.component';
import { InitSessionGuard } from "./guards/init-session.guard";

@NgModule({
  imports: [NgxsModule.forFeature([])],
  declarations: []
})
export class RootSnatchModule {
}

const entryComponents = [
  ControlErrorComponent,
  LabelOptionalComponent,
];

const components = [
  ...entryComponents,
  FormControlLimitComponent,
  FormGuidanceComponent,
  SearchableMultiselectComponent,
  ErrorAlertComponent,
  ListItemSkeletonComponent,
  MultiTableComponent,
  MultiTableSortBarComponent,
  MultiTableItemComponent,
  MultiSelectComponent,
  SideNavigationMenuComponent,
  CarouselComponent,
  CarouselSlideComponent,
  SearchableFilterPanelComponent,
];

const directives = [
  HasGroupDirective,
  IsAuthDirective,
  ControlErrorDirective,
  FormSubmitDirective,
  ControlErrorContainerDirective,
  MatchHeightDirective,
  LabelOptionalDirective,
  ComponentDirective,
];

const pipes = [
  EllipsisPipe,
  HtmlBypassPipe,
  ReplacePipe,
  AmberErrorPipe,
  TranslatePipe,
  OrderByPipe,
  SanitizeHtmlPipe,
  SafeUrlPipe,
  UrlFromBucketPipe,
  FilenamePipe,
  MediaFilterPipe,
  YouTubePreviewPipe,
  MenuFilterPipe,
  CurrencyPipe,
];

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUtilsModule,
    SuggestionsModule,
    RouterModule,
  ],
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  exports: [
    ...components,
    ...directives,
    ...pipes,
  ],
  entryComponents,
})
export class SnatchModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootSnatchModule,
      providers: [
        EnvironmentService,
        UploadService,
        ValidationService,
        StorageService,
        SessionService,
        GroupGuard,
        ShoppingCartGuard,
        InitSessionGuard,
        DictionaryService,
        TimezoneService
      ]
    };
  }
}
