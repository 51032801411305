<div class="header">
  <h2>{{ isBulk ? "Bulk " : "" }}Publishing to LXP</h2>
  <p class="header-subtext">
    After publishing these materials will be visible to the users of your LXP.
  </p>
</div>

<div nz-row nzType="flex" nzJustify="center">
  <div class="stepper-container" nz-col [nzMd]="18">
    <nz-steps [nzCurrent]="step" nzSize="default" nzLabelPlacement="vertical">
      <nz-step nzTitle="Verify Items"></nz-step>
      <nz-step nzTitle="Publishing Options"></nz-step>
    </nz-steps>
  </div>
  <div class="content-container" [ngSwitch]="stepMap[step]">
    <ng-container *ngSwitchCase="'Verify Items'">
      <ng-container *ngTemplateOutlet="verifyItems"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Publishing Options'">
      <ng-container *ngTemplateOutlet="publishingOptions"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #verifyItems>
  <form [formGroup]="form">
    <h2 class="subheader-text">Verify Items</h2>
    <p>
      You are about to publish {{ products.length }} selected items to your LXP.
      Please specify channels and groups to which the items should be available.
    </p>
    <ng-container *ngIf="examProducts?.length">
      <p class="subheader-subtext" *ngIf="isBulk">
        Exams({{ examProducts.length }}):
      </p>
      <ng-container
        *ngFor="let product of examProducts"
        [ngTemplateOutlet]="productInfo"
        [ngTemplateOutletContext]="{ product: product }"
      >
      </ng-container>
    </ng-container>
    <ng-container *ngIf="nonExamProducts?.length">
      <p class="subheader-subtext" *ngIf="isBulk">
        Others({{ nonExamProducts.length }}):
      </p>
      <ng-container
        *ngFor="let product of nonExamProducts"
        [ngTemplateOutlet]="productInfo"
        [ngTemplateOutletContext]="{ product: product }"
      >
      </ng-container>
    </ng-container>

    <ng-template #productInfo let-product="product">
      <div class="product-info">
        <div class="product-info__sub-header">
          <div class="product-info__key">
            {{ productType ? productType : product.typeConfig?.configKey }}
          </div>
          <div class="product-info__header">
            {{ product.name | ellipsis: ["60"]
            }}{{ product.suffix ? " - " + product.suffix : "" }}
          </div>
          <div>
            <span class="product-info__key">Seller:</span>
            {{ product.seller?.name }}
          </div>
        </div>
        <div>
          <span class="product-info__key">Product ID:</span> {{ product.sku }}
        </div>
        <div *ngIf="!isMultipleDeliverableExam(product)">
          <span class="product-info__key">Delivery Formats:</span>
          {{ deliveryFormats[product.sku].join(", ") }}
        </div>
        <div>
          <span class="product-info__key"
            >{{
              (product?.productVariants)[0]?.pricing?.isPromotionPricingApplied
                ? "Promotional"
                : ""
            }}
            Price:</span
          >
          <span class="product-info__value">
            {{ currencySignsMap?.get(product.pricing?.currency) }}
            {{ product.pricing?.amount | number: "1.2-2" }}
          </span>
          <ng-container
            *ngIf="
              (product?.productVariants)[0]?.pricing?.isPromotionPricingApplied
            "
          >
            <span
              nz-tooltip
              nzTooltipPlacement="right"
              nzTooltipTitle="Valid upto: {{
                (product?.productVariants)[0]?.pricing?.promotionPricingDetail
                  ?.promotionPricingDiscountEndDate | date: 'dd MMM yyyy'
              }}"
              class="price-valid"
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                overflow="visible"
                preserveAspectRatio="none"
                viewBox="0 0 13 15"
                width="13"
                height="15"
              >
                <g transform="translate(0, 0)">
                  <g transform="translate(0, 0) rotate(0)">
                    <path
                      style="stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(247, 155, 66);"
                      d="M11.60714,1.875h-1.39286v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-5.57143v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-1.39286c-0.76897,0 -1.39286,0.62988 -1.39286,1.40625v10.3125c0,0.77637 0.62388,1.40625 1.39286,1.40625h10.21429c0.76897,0 1.39286,-0.62988 1.39286,-1.40625v-10.3125c0,-0.77637 -0.62388,-1.40625 -1.39286,-1.40625zM1.39286,2.8125h10.21429c0.25536,0 0.46429,0.21094 0.46429,0.46875v1.40625h-11.14286v-1.40625c0,-0.25781 0.20893,-0.46875 0.46429,-0.46875zM11.60714,14.0625h-10.21429c-0.25536,0 -0.46429,-0.21094 -0.46429,-0.46875v-7.96875h11.14286v7.96875c0,0.25781 -0.20893,0.46875 -0.46429,0.46875zM8.55156,11.25293c0.13638,0.1377 0.13638,0.36035 0,0.49805l-0.1654,0.16699c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-1.39286,-1.41211l-1.39576,1.40918c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-0.1654,-0.16699c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l1.39576,-1.40918l-1.39576,-1.40918c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l0.1654,-0.16699c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l1.39576,1.40918l1.39576,-1.40918c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l0.1654,0.16699c0.13638,0.1377 0.13638,0.36035 0,0.49805l-1.39866,1.41211z"
                      vector-effect="non-scaling-stroke"
                    />
                  </g>
                  <defs>
                    <path
                      id="path-1659940866239599"
                      d="M11.60714,1.875h-1.39286v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-5.57143v-1.52344c0,-0.19336 -0.1567,-0.35156 -0.34821,-0.35156h-0.23214c-0.19152,0 -0.34821,0.1582 -0.34821,0.35156v1.52344h-1.39286c-0.76897,0 -1.39286,0.62988 -1.39286,1.40625v10.3125c0,0.77637 0.62388,1.40625 1.39286,1.40625h10.21429c0.76897,0 1.39286,-0.62988 1.39286,-1.40625v-10.3125c0,-0.77637 -0.62388,-1.40625 -1.39286,-1.40625zM1.39286,2.8125h10.21429c0.25536,0 0.46429,0.21094 0.46429,0.46875v1.40625h-11.14286v-1.40625c0,-0.25781 0.20893,-0.46875 0.46429,-0.46875zM11.60714,14.0625h-10.21429c-0.25536,0 -0.46429,-0.21094 -0.46429,-0.46875v-7.96875h11.14286v7.96875c0,0.25781 -0.20893,0.46875 -0.46429,0.46875zM8.55156,11.25293c0.13638,0.1377 0.13638,0.36035 0,0.49805l-0.1654,0.16699c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-1.39286,-1.41211l-1.39576,1.40918c-0.13638,0.1377 -0.35692,0.1377 -0.4933,0l-0.1654,-0.16699c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l1.39576,-1.40918l-1.39576,-1.40918c-0.13638,-0.1377 -0.13638,-0.36035 0,-0.49805l0.1654,-0.16699c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l1.39576,1.40918l1.39576,-1.40918c0.13638,-0.1377 0.35692,-0.1377 0.4933,0l0.1654,0.16699c0.13638,0.1377 0.13638,0.36035 0,0.49805l-1.39866,1.41211z"
                      vector-effect="non-scaling-stroke"
                    />
                  </defs>
                </g>
              </svg>
            </span>
          </ng-container>
        </div>
        <div>
          <span class="product-info__key">Languages:</span>
          {{ languages[product.sku].join(", ") }}
        </div>

        <ng-container
          *ngIf="isMultipleDeliverableExam(product)"
          [ngTemplateOutlet]="deliveryFormat"
          [ngTemplateOutletContext]="{ product: product }"
        ></ng-container>
      </div>
    </ng-template>

    <ng-template #deliveryFormat let-product="product">
      <div class="horizontal-divider"></div>

      <nz-form-label>Delivery Format</nz-form-label>
      <leap-form-guidance
        >Please select the delivery format you wish to make
        available.</leap-form-guidance
      >

      <nz-form-item>
        <ng-container [formGroup]="getDeliveryFormatGroup(product.sku)">
          <nz-radio-group formControlName="restrictedDeliveryFormat">
            <label
              *ngFor="
                let deliveryFormat of getDeliveryFormatGroup(product.sku).get(
                  'deliveryFormats'
                ).value
              "
              nz-radio
              [nzValue]="deliveryFormat.key"
            >
              <span>{{ deliveryFormat.value }}</span>
            </label>
          </nz-radio-group>
        </ng-container>
      </nz-form-item>
    </ng-template>

    <div class="buttons-section">
      <button type="button" nz-button nzType="default" (click)="cancel()">
        Cancel
      </button>
      <button type="button" nz-button nzType="primary" (click)="next()">
        Continue
      </button>
    </div>
  </form>
</ng-template>

<ng-template #publishingOptions>
  <form [formGroup]="form">
    <nz-form-item *ngIf="products[0]?.bundle; else normalProduct">
      <nz-form-label>Dedicated Channel *</nz-form-label>
      <leap-form-guidance
        >Please select a unique channel on your LXP that will showcase all the
        products included in the this bundle</leap-form-guidance
      >
      <nz-form-control>
        <nz-select
          nzShowSearch
          nzServerSearch
          nzShowArrow="true"
          nzPlaceHolder="Select or Search for a dedicated channel"
          formControlName="channels"
          (nzOnSearch)="onSearch($event)"
        >
          <nz-option
            *ngFor="
              let option of (channels$ | async).map(
                simpleOptionToMultiselectOption
              )
            "
            [nzLabel]="option.label"
            [nzValue]="option.value"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <ng-template #normalProduct>
      <nz-form-item>
        <nz-form-label>LXP Channels</nz-form-label>
        <leap-form-guidance
          >Please select channels of your LXP to publish this product
          to.</leap-form-guidance
        >
        <nz-form-control>
          <leap-multi-select
            formControlName="channels"
            [options]="(channels$ | async).map(simpleOptionToMultiselectOption)"
            [showSearch]="true"
            (onSearch)="onSearch($event)"
          >
          </leap-multi-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>LXP Groups</nz-form-label>
        <leap-form-guidance
          >Please select groups of your LXP to publish this product
          to.</leap-form-guidance
        >
        <nz-form-control>
          <leap-multi-select
            formControlName="groups"
            [options]="(groups$ | async).map(simpleOptionToMultiselectOption)"
          >
          </leap-multi-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>
          Transaction Type
          <span
            class="interactive-text"
            nz-tooltip
            nzTooltipTitle="Consumption Vouchers - Assign voucher to learners to consume these products.\nLearner pay-per-use - In the end of the month pay for all consumed products by your learners."
            nzTooltipPlacement="bottomRight"
          >
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              overflow="visible"
              preserveAspectRatio="none"
              viewBox="0 0 514 514"
              width="12"
              height="12"
            >
              <g transform="translate(1, 1)">
                <path
                  d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
                  vector-effect="non-scaling-stroke"
                  style="fill: currentColor;"
                />
              </g>
            </svg>
          </span>
        </nz-form-label>
        <leap-form-guidance>
          How should the item be billed to you when a learner accesses the
          content?
        </leap-form-guidance>
        <nz-form-control>
          <nz-select formControlName="transactionType">
            <nz-option
              *ngFor="let item of transactionTypes$ | async"
              [nzValue]="item"
              [nzLabel]="item?.value"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <ng-container
        *ngIf="
          form.get('transactionType').value?.value ===
          'Monthly Consumption Invoice'
        "
      >
        <nz-form-item>
          <nz-form-label>Approval Required</nz-form-label>
          <leap-form-guidance>
            Does it require an approval before a learner can access the content?
          </leap-form-guidance>
          <nz-radio-group formControlName="approvalRequired">
            <label nz-radio [nzValue]="true">Yes</label>
            <label nz-radio [nzValue]="false">No</label>
          </nz-radio-group>
        </nz-form-item>

        <ng-container *ngIf="form.get('approvalRequired').value">
          <nz-form-item>
            <nz-form-label>Approval Rule</nz-form-label>
            <leap-form-guidance
              >Please select an approval rule for this
              product.</leap-form-guidance
            >
            <nz-form-control>
              <nz-select formControlName="approvalRule">
                <nz-option
                  *ngFor="let item of approvalRules$ | async"
                  [nzValue]="item"
                  [nzLabel]="item.name"
                ></nz-option>
              </nz-select>
              <nz-form-explain>
                <div class="right">
                  <a
                    class="input-explain-link"
                    href="{{ saharaUrl }}/organization/approvals/approved-rules"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Create Approval Rule
                  </a>
                </div>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>

          <div
            *ngIf="form.get('approvalRule').value as approvalRule"
            class="selected-option-details"
          >
            <div>{{ approvalRule.name }}</div>
            <div class="selected-option-details__row">
              <span class="selected-option-details__key">Rule Type:</span>
              {{ approvalRule?.type?.configValue }}
            </div>
            <div class="selected-option-details__row">
              <span class="selected-option-details__key">Approval Group:</span>
              {{ approvalRule?.approvalGroup?.groupName }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
    <div class="buttons-section">
      <button type="button" nz-button nzType="default" (click)="back()">
        Back
      </button>
      <button
        type="button"
        nz-button
        nzType="primary"
        (click)="submit()"
        [disabled]="this.form.invalid"
        [nzLoading]="isLoading"
      >
        Publish To LXP
      </button>
    </div>
  </form>
</ng-template>
