import { Component, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
import { EnvironmentService } from 'src/app/snatch/services';
import { Adyen3DSecureCCData } from 'src/app/common/common-checkout/models/payment.model';

@Component({
  selector: 'leap-adyen',
  templateUrl: './adyen.component.html',
})
export class AdyenComponent implements AfterViewInit, OnChanges {

  @ViewChild('dropinContainer', { static: true }) dropinRef: ElementRef;
  @Input() gatewayPaymentMethods: any;
  @Input() gatewayActions: any;
  @Output() onSubmitCCDetails: EventEmitter<Adyen3DSecureCCData> = new EventEmitter();

  dropin;

  constructor(private environment: EnvironmentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gatewayActions && changes.gatewayActions.currentValue) {
      this.dropin.handleAction(changes.gatewayActions.currentValue);
    }
  }

  ngAfterViewInit(): void {
    let configuration = {
      openFirstPaymentMethod: true,
      paymentMethods: this.gatewayPaymentMethods.paymentMethods, // The `/paymentMethods` response from the server.
      clientKey: this.environment.adyenClientKey,
      environment: (this.environment.envName === 'prod' || this.environment.envName === 'prod-euwest1') ? 'live' : 'test',
      onSubmit: (state, dropin) => {
        const payload = { browserInfo: state.data.browserInfo, ...state.data.paymentMethod };
        delete payload.type;
        delete payload.brand;
        this.onSubmitCCDetails.emit(payload);
          // makePayment(state.data)
          //   .then(response => {
          //     if (response.action) {
          //       // Drop-in handles the action object from the /payments response
          //       dropin.handleAction(response.action);
          //     } else {
          //       // Your function to show the final result to the shopper
          //       showFinalResult(response);
          //     }
          //   })
          //   .catch(error => {
          //     throw Error(error);
          //   });
        },
      onAdditionalDetails: (state, dropin) => {
        console.log('.')
        // Your function calling your server to make a `/payments/details` request
        // makeDetailsCall(state.data)
        //   .then(response => {
        //     if (response.action) {
        //       // Drop-in handles the action object from the /payments response
        //       dropin.handleAction(response.action);
        //     } else {
        //       // Your function to show the final result to the shopper
        //       showFinalResult(response);
        //     }
        //   })
        //   .catch(error => {
        //     throw Error(error);
        //   });
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: true,
          name: 'Credit or debit card',
          billingAddressRequired: false,
        },
      },
    }
    const checkout = new AdyenCheckout(configuration);  
    this.dropin = checkout.create('dropin').mount('#dropin-container');
  }
}
