import * as i0 from "@angular/core";
export class FormUtilService {
    constructor() { }
    triggerValidation(form, onlySelf = false) {
        for (const i in form.controls) {
            if (form.controls.hasOwnProperty(i)) {
                const control = form.controls[i];
                control.markAsDirty({ onlySelf });
                control.updateValueAndValidity({ onlySelf });
            }
        }
        return form.valid;
    }
}
FormUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormUtilService_Factory() { return new FormUtilService(); }, token: FormUtilService, providedIn: "root" });
