import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
} from "@angular/core";
import { Select, Store } from "@ngxs/store";
import {
  combineLatest,
  merge,
  Observable,
  Subject,
  BehaviorSubject,
} from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  bufferCount,
  debounceTime,
  map,
  shareReplay,
  startWith,
  takeUntil,
  take,
  switchMap,
  scan,
} from "rxjs/operators";

import {
  ChangePage,
  ClearProducts,
  FetchFavoriteSuppliers,
  FetchMySuppliers,
  FetchProducts,
  RestoreProductsState,
  ToggleSort,
} from "../../../../products/actions/products.actions";
import { ILabeledItem } from "../../../../snatch/models/labeled-item.model";
import { AuthTokenState } from "../../../../auth/state/auth-token.state";
import { DictionaryItem } from "../../../../snatch/models/dictionary.model";
import { AddLineItemToShoppingCart } from "../../../../shopping-cart/actions/shopping-cart.actions";
import {
  GetSellerProducts,
  SellerProductChangePage,
  SellerProductsClearProducts,
  SellerProductsRestoreProductsState,
  SellerProductsToggleSort,
} from "../../../../seller-page/actions/seller-details.actions";
import {
  ISearchParams,
  ICountAggregate,
} from "../../../../snatch/models/products-search-params.model";
import { IPageable } from "../../../../snatch/models/page.model";
import { Sort } from "../../../../snatch/models/sort.model";
import {
  AmberError,
  AmberResponse,
} from "../../../../snatch/models/amber-response.model";
import { ISearchMetadata } from "../../../../products/state/products.state";
import { ProductShort } from "../../models/product-short.model";
import { SupplierProductChangePage } from "../../../../my-suppliers/actions/suppliers.actions";
import { PublishToLxpFormValueDto } from "../../models/publish-to-lxp-form-dto.interface";
import { PublishToLxpFormService } from "../../services/publish-to-lxp-form.service";
import { LxpPublishingService } from "../../services/lxp-publishing.service";
import { LxpProductDetails } from "../../models/lxp-product.interface";
import { CoreState } from "src/app/core/state/core.state";

interface ListFilters {
  deliveryFormats: Array<ILabeledItem>;
  productTypes: Array<ILabeledItem>;
  languages: Array<ILabeledItem>;
  level: Array<ILabeledItem>;
  category: Array<ILabeledItem>;
  sellers: Array<ILabeledItem>;
  topic: ILabeledItem;
  subtopic: ILabeledItem;
  courseDeliveryFormats: Array<ILabeledItem>;
  lxpProductStatus: Array<ILabeledItem>;
  catalogs: Array<ILabeledItem>;
}

@Component({
  selector: "leap-product-feed",
  templateUrl: "./product-feed.component.html",
  styleUrls: ["./product-feed.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFeedComponent implements OnInit, OnDestroy {
  @Input() isSellerProductListing: boolean;
  @Input() isSupplierProductListing: boolean;
  @Input() domain;

  @Select((state: any) => state.shoppingCart.addLineItemSku)
  addLineItemSku$: Observable<string>;
  @Select((state: any) => state.shoppingCart.addLineItemPending)
  addLineItemPending$: Observable<boolean>;

  @Select((state: any) => state.products.searchMetadata.languages)
  languagesMap$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.level)
  levelMap$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.category)
  categoryMapForFilter$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.deliveryFormats)
  deliveryFormatsMap$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.certificateTypes)
  certificateTypesMap$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.productTypes)
  productTypesMap$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.sellers)
  sellersMap$: Observable<Array<ICountAggregate>>;
  @Select((state: any) => state.products.searchMetadata.courseDeliveryFormats)
  courseDeliveryFormatsMap$: Observable<Array<ICountAggregate>>;

  @Select((state: any) => state.core.dictionaries.subTopicsMap)
  subTopicsMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.topicsMap)
  topicsMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.lxpProductStatusMap)
  lxpProductStatusMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.catalogsMap)
  catalogsMap$: Observable<Map<string, string>>;
  @Select(AuthTokenState.isLogged)
  isLogged$: Observable<boolean>;
  @Select(CoreState.isPermittedToPublishToLxp)
  isPermittedToPublishToLxp$: Observable<boolean>;
  @Select((state: any) => state.core.dictionaries.currencySignsMap)
  currencySignsMap$: Observable<Map<string, string>>;

  products$: Observable<Array<ProductShort>>;
  odysseySellers$: Observable<Array<any>>;
  searchMetadata$: Observable<ISearchMetadata>;
  fetchProductsPending$: Observable<boolean>;
  fetchProductsSuccess$: Observable<boolean>;
  fetchProductsError$: Observable<AmberError>;
  sort$: Observable<Sort>;
  pagination$: Observable<IPageable>;
  searchForm$: Observable<{ model: ISearchParams }>;
  categoriesMap$: Observable<Map<string, string>>;
  selectedFilters$: Observable<ListFilters>;
  selectedProductsIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  pageSelectedProducts$: Observable<ProductShort[]>;
  totalSelectedProducts$: Observable<ProductShort[]>;

  sortFields;
  drawerVisible = false;
  ngxsFormPath: string;
  shownProducts: ProductShort[] = [];
  formGroup: FormGroup = this.fb.group({
    topic: [null],
    subtopic: [null],
    filter: [null],
    deliveryFormats: [[]],
    productTypes: [[]],
    languages: [[]],
    level: [[]],
    category: [[]],
    sellers: [[]],
    hideRestricted: [null],
    courseDeliveryFormats: [[]],
    lxpProductStatus: [[]],
    catalogs: [[]],
  });

  // Use for takeUntil to unsubscribe onDestroy
  private ngUnsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private lxpPublishingService: LxpPublishingService,
    private publishToLxpFormService: PublishToLxpFormService
  ) {}

  ngOnInit() {
    if (this.isSellerProductListing) {
      this.ngxsFormPath = "sellerDetails.searchForm";
      this.products$ = this.store.select(
        (state) => state.sellerDetails.allProductSeller
      );
      this.categoriesMap$ = this.store.select(
        (state) => state.sellerDetails.categoriesMap
      );
      this.searchForm$ = this.store.select(
        (state) => state.sellerDetails.searchForm
      );
      this.searchMetadata$ = this.store.select(
        (state) => state.sellerDetails.searchMetadata
      );
      this.fetchProductsPending$ = this.store.select(
        (state) => state.sellerDetails.fetchProductsPending
      );
      this.fetchProductsSuccess$ = this.store.select(
        (state) => state.sellerDetails.fetchProductsSuccess
      );
      this.fetchProductsError$ = this.store.select(
        (state) => state.sellerDetails.fetchProductsError
      );
      this.sort$ = this.store.select((state) => state.sellerDetails.sort);
      this.pagination$ = this.store.select(
        (state) => state.sellerDetails.pagination
      );
    } else if (this.isSupplierProductListing) {
      this.ngxsFormPath = "suppliers.selectedSupplier.searchForm";
      this.products$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.products
      );
      this.categoriesMap$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.categoriesMap
      );
      this.searchForm$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.searchForm
      );
      this.searchMetadata$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.searchMetadata
      );
      this.fetchProductsPending$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.fetchProductsPending
      );
      this.fetchProductsSuccess$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.fetchProductsSuccess
      );
      this.fetchProductsError$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.fetchProductsError
      );
      this.sort$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.sort
      );
      this.pagination$ = this.store.select(
        (state) => state.suppliers.selectedSupplier.pagination
      );
    } else {
      this.ngxsFormPath = "products.searchForm";
      this.products$ = this.store.select((state) => state.products.products);
      this.odysseySellers$ = this.store.select(
        (state) => state.products.customSuppliers
      );
      this.categoriesMap$ = this.store.select(
        (state) => state.products.categoriesMap
      );
      this.searchForm$ = this.store.select(
        (state) => state.products.searchForm
      );
      this.searchMetadata$ = this.store.select(
        (state) => state.products.searchMetadata
      );
      this.fetchProductsPending$ = this.store.select(
        (state) => state.products.fetchProductsPending
      );
      this.fetchProductsSuccess$ = this.store.select(
        (state) => state.products.fetchProductsSuccess
      );
      this.fetchProductsError$ = this.store.select(
        (state) => state.products.fetchProductsError
      );
      this.sort$ = this.store.select((state) => state.products.sort);
      this.pagination$ = this.store.select(
        (state) => state.products.pagination
      );
    }

    merge(this.sort$, this.pagination$, this.searchForm$)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.fetchProducts();
      });

    this.constructSortFields();

    this.products$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (products) =>
          (this.shownProducts = [
            ...new Set([...this.shownProducts, ...products]),
          ])
      );

    this.pageSelectedProducts$ = this.selectedProductsIds$.pipe(
      map((selectedIds) =>
        this.filterProductsByIds(this.shownProducts, selectedIds)
      )
    );

    // @ts-ignore
    this.totalSelectedProducts$ = combineLatest([
      this.pageSelectedProducts$.pipe(
        startWith([]),
        scan((oldP, newP) => {
          const previousAndCurrent = oldP.concat(newP);
          return [
            ...new Map(
              previousAndCurrent.map((item) => [item.sku, item])
            ).values(),
          ];
        }, [])
      ),
      // @ts-ignore
      this.selectedProductsIds$,
    ]).pipe(
      map(([products, selectedIds]) =>
        products.filter((p) => selectedIds.includes(p.id))
      ),
      shareReplay(1)
    );

    this.formGroup.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_) => {
        if (this.isSellerProductListing) {
          this.store.dispatch(new SellerProductChangePage(1));
        } else if (this.isSellerProductListing) {
          this.store.dispatch(new SupplierProductChangePage(1));
        } else {
          this.store.dispatch(new ChangePage(1));
        }
      });
    if (this.isSellerProductListing) {
      this.store.dispatch(new SellerProductsRestoreProductsState());
    } else {
      this.store.dispatch(new RestoreProductsState());
    }
  }

  private mapFilters(params: ISearchParams, dictionaries: any): ListFilters {
    const {
      languagesMap,
      levelMap,
      deliveryFormatsMap,
      productTypesMap,
      sellersMap,
      categoriesMap,
      topicsMap,
      courseDeliveryFormatsMap,
      subTopicsMap,
      lxpProductStatusMap,
      catalogsMap,
    } = dictionaries;
    if (!Object.values(dictionaries).every((e) => !!e)) {
      return {} as ListFilters;
    } else {
      return {
        languages: params.languages.map((key) => ({
          id: key,
          value: languagesMap.find((el) => el.id === key).displayValue,
        })) as Array<ILabeledItem>,
        level: params.level.map((key) => ({
          id: key,
          value: levelMap.find((el) => el.id === key).displayValue,
        })) as Array<ILabeledItem>,
        deliveryFormats: params.deliveryFormats.map((key) => ({
          id: key,
          value: deliveryFormatsMap.find((el) => el.id === key).displayValue,
        })) as Array<ILabeledItem>,
        productTypes: params.productTypes.map((key) => ({
          id: key,
          value: productTypesMap.find((el) => el.id === key).displayValue,
        })) as Array<ILabeledItem>,
        category: params.category.map((key) => ({
          id: key,
          value: categoriesMap.find((el) => el.id === key).displayValue,
        })) as Array<ILabeledItem>,
        sellers:
          params.sellers &&
          (params.sellers.map((key) => ({
            id: key,
            value: sellersMap.find((el) => el.id === key).displayValue,
          })) as Array<ILabeledItem>),
        topic: params.topic
          ? { id: params.topic, value: topicsMap.get(params.topic) }
          : undefined,
        subtopic: params.subtopic
          ? { id: params.subtopic, value: subTopicsMap.get(params.subtopic) }
          : undefined,
        courseDeliveryFormats: params.courseDeliveryFormats.map((key) => ({
          id: key,
          value: courseDeliveryFormatsMap.find((el) => el.id === key)
            .displayValue,
        })) as Array<ILabeledItem>,
        lxpProductStatus:
          params.lxpProductStatus &&
          (params.lxpProductStatus.map((key) => ({
            id: key,
            value: lxpProductStatusMap.get(key),
          })) as Array<ILabeledItem>),
        catalogs:
          params.lxpProductStatus &&
          (params.catalogs.map((key) => ({
            id: key,
            value: catalogsMap.get(key),
          })) as Array<ILabeledItem>),
      };
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.isSellerProductListing) {
      this.store.dispatch(new SellerProductsClearProducts(this.domain));
    } else {
      this.store.dispatch(new ClearProducts());
    }
  }

  constructSortFields() {
    let fields = [
      {
        value: "name",
        displayValue: "A-Z",
      },
      {
        value: "best",
        displayValue: "Relevance",
      },
    ];

    const isLogged: any = this.store.selectSnapshot(
      AuthTokenState.isLogged
    );
    if (isLogged)
      fields.splice(1, 0, { value: "price", displayValue: "Price" });

    this.sortFields = fields;
  }

  private static getItemById(
    collection: Array<DictionaryItem>,
    id: string
  ): ILabeledItem {
    return { id, value: collection.find((l) => l.key === id).value };
  }

  trackById(index: string, item: any) {
    return item.id;
  }

  openDrawer() {
    this.drawerVisible = true;
  }

  closeDrawer() {
    this.drawerVisible = false;
  }

  addToCart(sku: string) {
    this.store.dispatch(new AddLineItemToShoppingCart(sku, 1));
  }

  toggleSort(key: string) {
    if (this.isSellerProductListing) {
      this.store.dispatch(new SellerProductsToggleSort(key));
    } else {
      this.store.dispatch(new ToggleSort(key));
    }
  }

  changePage(page: any) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.isSellerProductListing) {
      this.store.dispatch(new SellerProductChangePage(page));
    } else {
      this.store.dispatch(new ChangePage(page));
    }
  }

  patchForm(change: Partial<ISearchParams>) {
    this.formGroup.patchValue(change);
  }

  clearForm() {
    this.formGroup.reset({
      deliveryFormats: [],
      productTypes: [],
      languages: [],
      level: [],
      sellers: [],
      category: [],
      hideRestricted: null,
      courseDeliveryFormats: [],
      lxpProductStatus: [],
    });
  }

  favoriteSupplierSelection(selectionType) {
    if (selectionType.showFavorites) {
      this.store.dispatch(new FetchFavoriteSuppliers());
    }
    if (selectionType.showMySuppliers) {
      this.store.dispatch(new FetchMySuppliers());
    }
  }

  filterProductsByIds(products: ProductShort[], ids: string[]): ProductShort[] {
    return products.filter((p: ProductShort) => ids.includes(p.id));
  }

  onSelectProduct(productId: string, check: boolean) {
    const newValue = check
      ? [...this.selectedProductsIds$.value, productId]
      : this.selectedProductsIds$.value.filter((i: string) => i !== productId);
    this.selectedProductsIds$.next(newValue);
  }

  clearSelectedProducts() {
    this.selectedProductsIds$.next([]);
  }

  bukPublishToLxp() {
    this.totalSelectedProducts$
      .pipe(take(1))
      .subscribe((products: ProductShort[]) => {
        this.publishToLxpFormService
          .bulkPublishToLxp(products)
          .subscribe(() => {
            this.selectedProductsIds$.next([]);
            this.fetchProducts();
          });
      });
  }

  isProductSelected(productId: string): boolean {
    return this.selectedProductsIds$.value.includes(productId);
  }

  editPublishedToLxpProduct(product: ProductShort) {
    this.lxpPublishingService
      .getLxpProductDetails(product.sku)
      .pipe(
        switchMap((res: AmberResponse<LxpProductDetails>) => {
          const lxpProductDetails: PublishToLxpFormValueDto = {
            channels: res.data.channels,
            groups: res.data.groups,
            transactionType: {
              key: res.data.transactionType.id,
              value: res.data.transactionType.configValue,
            },
            approvalRequired: res.data.approval,
            approvalRule: res.data.approvalPolicy,
            restrictedDeliveryFormats: res.data.restrictedDeliveryFormat && [
              {
                sku: product.sku,
                restrictedDeliveryFormat: res.data.restrictedDeliveryFormat,
              },
            ],
          };

          return this.publishToLxpFormService.editLxpProduct(
            product,
            lxpProductDetails,
            res.data.id
          );
        })
      )
      .subscribe(() => {
        this.selectedProductsIds$.next([]);
        this.fetchProducts();
      });
  }

  publishProductToLxp(product: ProductShort) {
    if (
      product.typeConfig &&
      product.typeConfig.configKey === "bundle-product"
    ) {
      this.publishToLxpFormService.publishBundleToLxp(product).subscribe(() => {
        this.selectedProductsIds$.next([]);
        this.fetchProducts();
      });

      return;
    }

    this.publishToLxpFormService.publishProductToLxp(product).subscribe(() => {
      this.selectedProductsIds$.next([]);

      this.fetchProducts();
    });
  }

  private fetchProducts() {
    if (this.isSellerProductListing) {
      this.store.dispatch(new GetSellerProducts(this.domain));
    } else if (this.isSupplierProductListing) {
    } else {
      this.store.dispatch(new FetchProducts());
    }

    this.products$.subscribe(
      (_) =>
        (this.selectedFilters$ = combineLatest(
          this.searchForm$,
          this.languagesMap$,
          this.levelMap$,
          this.deliveryFormatsMap$,
          this.productTypesMap$,
          this.sellersMap$,
          this.categoryMapForFilter$,
          this.topicsMap$,
          this.courseDeliveryFormatsMap$,
          this.subTopicsMap$,
          this.lxpProductStatusMap$,
          this.catalogsMap$
        ).pipe(
          map(
            ([
              { model },
              languagesMap,
              levelMap,
              deliveryFormatsMap,
              productTypesMap,
              sellersMap,
              categoriesMap,
              topicsMap,
              courseDeliveryFormatsMap,
              subTopicsMap,
              lxpProductStatusMap,
              catalogsMap,
            ]) => {
              return this.mapFilters(model, {
                languagesMap,
                levelMap,
                deliveryFormatsMap,
                productTypesMap,
                sellersMap,
                categoriesMap,
                topicsMap,
                courseDeliveryFormatsMap,
                subTopicsMap,
                lxpProductStatusMap,
                catalogsMap,
              });
            }
          ),
          takeUntil(this.ngUnsubscribe),
          shareReplay()
        ))
    );
  }
}
