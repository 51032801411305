<main class="main">
  <leap-loading-bar></leap-loading-bar>
  <leap-navbar #navBar></leap-navbar>
  <nz-content class="content content-height-100">
      <router-outlet></router-outlet>
      <leap-shopping-cart-drawer></leap-shopping-cart-drawer>
  </nz-content>
  <leap-footer [instructorMarketUrl]="getInstructorServiceUrl">

  </leap-footer>
</main>
