import * as tslib_1 from "tslib";
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/auth/state/auth-token.state';
const ɵ0 = (state) => state.session.state;
export class IsAuthDirective {
    constructor(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.isHidden = true;
        this.authenticated = true;
        this.auth = true;
    }
    ngOnInit() {
        this.state$.subscribe(_ => {
            this.authenticated = (_ === AuthState.LOGGED);
            this.updateView();
        });
    }
    set leapIsAuth(val) {
        this.auth = val;
        this.updateView();
    }
    updateView() {
        if (this.isAuth()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        }
        else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }
    isAuth() {
        return this.auth === this.authenticated;
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], IsAuthDirective.prototype, "state$", void 0);
export { ɵ0 };
