import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable()
export class SessionService {

  constructor(private storageService: StorageService) { }

  getToken() {
    const storage = this.storageService.get('okta-token-storage')
    return storage && storage.accessToken && storage.accessToken.accessToken;
  }

  decodeToken(token) {
    const payload = token ? JSON.parse(atob(token.split('.')[1])) : null;
    return payload;
  };

  getLoggedInUserDetails() {
    return this.decodeToken(this.getToken());
  }
}
