import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList, OnChanges
} from "@angular/core";
import { ISearchMetadata, ISearchParams } from "../../../../products/state/products.state";
import { ILabeledItem } from "../../../../snatch/models/labeled-item.model";
import { ProductFilterPillComponent } from "./product-filter-pill/product-filter-pill.component";

@Component({
  selector: 'leap-product-applied-filters',
  templateUrl: './product-applied-filters.component.html',
  styleUrls: ['./product-applied-filters.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductAppliedFiltersComponent implements OnInit, OnChanges {

  @Input()
  searchMetadata: ISearchMetadata;
  @Input()
  searchForm: ISearchParams;
  @Input()
  filters: { [key in keyof ISearchParams]: Array<ILabeledItem> };

  @Output()
  filterPatched = new EventEmitter<Partial<ISearchParams>>();
  @Output()
  filtersRemoved = new EventEmitter<void>();

  @ViewChildren(ProductFilterPillComponent) filterPills: QueryList<ProductFilterPillComponent>;

  constructor() {}

  ngOnInit() {
  }
  ngOnChanges(){
  }
  removeFilter(filter: keyof ISearchParams) {
    this.filterPatched.emit({ [filter]: undefined });
  }

  removeArrayItem(filter: keyof ISearchParams, item: string) {
    const array = this.searchForm[filter];
    if (array instanceof Array) {
      const pos = array.findIndex(i => i === item);
      if (pos > -1) {
        const newArray = array.slice();
        newArray.splice(pos, 1);
        this.filterPatched.emit({ [filter]: newArray });
      }
    }
  }

  clearFilters() {
    this.filtersRemoved.emit();
  }
}

