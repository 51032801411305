import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EnvironmentService } from 'src/app/snatch/services';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ChangeTypeFilter } from '../../state/inbox.state';


@Component({
  selector: 'leap-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InboxComponent implements OnInit, OnDestroy {


  @Select((state: any) => state.inbox.typeFilter)
  typeFilter$: Observable<Map<string, string>>;

  @Select((state: any) => state.inbox.selectedConversation)
  conversation$: Observable<any>;

  myEmail: string;

  myDomain: string;

  pending: boolean;


  constructor(
    private environment: EnvironmentService,
    private store: Store,
  ) { }

  ngOnInit() { 
    const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
    this.myEmail = userDetails.username;
    this.myDomain = userDetails.domain;
  }
  ngOnDestroy() { }

  onChangeTypeFilter(type: string) {
    this.store.dispatch(new ChangeTypeFilter(type));
  }

}
