/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-guidance.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./form-guidance.component";
var styles_FormGuidanceComponent = [i0.styles];
var RenderType_FormGuidanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormGuidanceComponent, data: {} });
export { RenderType_FormGuidanceComponent as RenderType_FormGuidanceComponent };
export function View_FormGuidanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_FormGuidanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-form-guidance", [], null, null, null, View_FormGuidanceComponent_0, RenderType_FormGuidanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.FormGuidanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormGuidanceComponentNgFactory = i1.ɵccf("leap-form-guidance", i2.FormGuidanceComponent, View_FormGuidanceComponent_Host_0, {}, {}, ["*"]);
export { FormGuidanceComponentNgFactory as FormGuidanceComponentNgFactory };
