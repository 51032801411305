<div class="sort-container">
  <h2 *ngIf="title">{{ title }}</h2>
  <div class="sort-wrapper">
    <button *ngFor="let field of sortFields" class="sort-btn" (click)="toggleSortFn(field.value)" nz-button nzType="default">
      <div class="btn-wrapper">
        <span class="sort-name">{{ field.displayValue }}</span>
        <leap-sort-caret [active]="sort?.key === field.value" [direction]="sort?.order"></leap-sort-caret>
      </div>
    </button>
  </div>
</div>
