import { Injectable } from '@angular/core';

import { IBreadcrumb } from '../models/breadcrumb.interface';
import { QueryParametersCacheService } from './query-parameters-cache.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsBuildingService {

    constructor(
        private queryParametersCacheService: QueryParametersCacheService,
    ) { }

    buildBreadcrumbsWithQueryParams(breadcrumbs: IBreadcrumb[]): IBreadcrumb[] {
        const queryParamsCache = this.queryParametersCacheService.cacheCurrentValue;
        return breadcrumbs.map((b: IBreadcrumb) =>
            ({
                ...b,
                queryParams: b.path && b.withQueryParams && (b.path in queryParamsCache)
                    ? queryParamsCache[b.path]
                    : null
            })
        );
    }
}