import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../snatch/services';
import { DomainConfiguration } from './actions/auth.actions';
import { Store } from '@ngxs/store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../snatch/services/environment.service";
import * as i3 from "@ngxs/store";
export class DomainConfigurationService {
    constructor(http, environment, store) {
        this.http = http;
        this.environment = environment;
        this.store = store;
    }
    getDomainConfiguration() {
        if (this.environment.isProdin || this.environment.isEtisalat) {
            this.getDomainConfig()
                .subscribe((res) => {
                this.store.dispatch(new DomainConfiguration(res.data));
            });
        }
        else {
            this.store.dispatch(new DomainConfiguration(null));
            return;
        }
    }
    getDomainConfig() {
        const url = `${this.environment.amberUrl}/domain/details/${this.environment.isEtisalat ? 'etisalat' : 'fsp'}`;
        return this.http.get(url);
    }
}
DomainConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainConfigurationService_Factory() { return new DomainConfigurationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.EnvironmentService), i0.ɵɵinject(i3.Store)); }, token: DomainConfigurationService, providedIn: "root" });
