/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/menu";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "@angular/router";
import * as i5 from "./filter-menu.component";
var styles_FilterMenuComponent = [i0.styles];
var RenderType_FilterMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterMenuComponent, data: {} });
export { RenderType_FilterMenuComponent as RenderType_FilterMenuComponent };
export function View_FilterMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ul", [["class", "vertical-menu"], ["nz-menu", ""], ["style", "border: none; width: 100%; padding-top: 50px;"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.NzMenuService, i2.NzMenuService, []), i1.ɵprd(1024, null, i3.NzMenuBaseService, i2.NzMenuServiceFactory, [[3, i3.NzDropdownHigherOrderServiceToken], i2.NzMenuService]), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(4, 1785856, null, 2, i2.NzMenuDirective, [i1.ElementRef, i3.NzMenuBaseService, i3.NzUpdateHostClassService], { nzMode: [0, "nzMode"] }, null), i1.ɵqud(603979776, 1, { listOfNzMenuItemDirective: 1 }), i1.ɵqud(603979776, 2, { listOfNzSubMenuComponent: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "group-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Messages"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "li", [["nz-menu-item", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).clickMenuItem($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.changeTypeFilter.emit("dm") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(11, 1785856, [[1, 4]], 2, i2.NzMenuItemDirective, [i3.NzUpdateHostClassService, i3.NzMenuBaseService, [2, i2.NzSubmenuService], i1.Renderer2, i1.ElementRef, [2, i4.RouterLink], [2, i4.RouterLinkWithHref], [2, i4.Router]], { nzSelected: [0, "nzSelected"] }, null), i1.ɵqud(603979776, 3, { listOfRouterLink: 1 }), i1.ɵqud(603979776, 4, { listOfRouterLinkWithHref: 1 }), (_l()(), i1.ɵted(-1, null, ["Direct Messages"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "inline"; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.typeFilter === "dm"); _ck(_v, 11, 0, currVal_1); }, null); }
export function View_FilterMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-filter-menu", [], null, null, null, View_FilterMenuComponent_0, RenderType_FilterMenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.FilterMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterMenuComponentNgFactory = i1.ɵccf("leap-filter-menu", i5.FilterMenuComponent, View_FilterMenuComponent_Host_0, { typeFilter: "typeFilter" }, { changeTypeFilter: "changeTypeFilter" }, []);
export { FilterMenuComponentNgFactory as FilterMenuComponentNgFactory };
