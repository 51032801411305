
<section class="top-fixed-panel" *ngIf="(selectedProductsIds$ | async).length">
    <div>
        <span>{{ (selectedProductsIds$ | async).length }} product(s) selected</span>
        <a nz-button nzType="link" nzDanger (click)="clearSelectedProducts()">Clear all</a>
    </div>
    <button nz-button nzType="primary" nzSize="small" (click)="bukPublishToLxp()">
        Publish Products to LXP
    </button>
</section>
<nz-layout class="layout">
    <nz-sider
        [nzWidth]="250"
        [nzBreakpoint]="'lg'"
        [nzCollapsedWidth]="0"
        class="sider-height-100"
        style="background:#fff;">
        <leap-product-filter-menu
            [form]="formGroup"
            [searchMetadata]="searchMetadata$ | async"
            [odysseySellers]="odysseySellers$ | async"
            [searchForm]="searchForm$ | async"
            [isPermittedToPublishToLxp]="isPermittedToPublishToLxp$ | async"
            [isAuthorized]="isLogged$ | async"
            (filterPatched)="patchForm($event)"
            (favoriteSupplierSelection)="favoriteSupplierSelection($event)">
        </leap-product-filter-menu>
    </nz-sider>
    <nz-content>
        <div class="top-toolbar">
            <div nz-row [nzGutter]="10">
                <div nz-col [nzLg]="12" [nzMd]="14" style="display: flex; margin-bottom: 5px;">
                    <div nz-row style="display: flex; width: 100%;">
                        <div nz-col [nzLg]="0">
                            <button nz-button nzSize="large" style="margin-right: 10px;" (click)="openDrawer()">
                                <span class="filters-btn-content-wrapper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.2"
                                        overflow="visible"
                                        preserveAspectRatio="none"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        height="18"
                                    >
                                        <g>
                                            <path
                                                xmlns:default="http://www.w3.org/2000/svg"
                                                d="M10,18h4v-2h-4V18z M3,6v2h18V6H3z M6,13h12v-2H6V13z"
                                                style="fill: rgb(156, 164, 166);"
                                                vector-effect="non-scaling-stroke"
                                            />
                                        </g>
                                    </svg>
                                    <span>Filters</span>
                                </span>
                            </button>
                        </div>
                        <leap-hide-restricted-checkbox
                            *ngIf="isLogged$ | async"
                            style="margin-right: 10px;"
                            [form]="formGroup"
                        ></leap-hide-restricted-checkbox>
                        <leap-sort-bar
                            style="flex-grow: 1;"
                            [sort]="sort$ | async"
                            [sortFields]="sortFields"
                            (toggleSort)="toggleSort($event)"
                        ></leap-sort-bar>
                    </div>
                </div>
                <div nz-col [nzLg]="12" [nzMd]="10" class="search-bar-container" style="margin-bottom: 5px;">
                    <leap-search-bar
                        [form]="formGroup"
                        [ngxsFormPath]="ngxsFormPath"
                        [placeholder]="'Search within these results'"
                    ></leap-search-bar>
                </div>
            </div>
        </div>
        <div class="applied-filters-container">
            <leap-product-applied-filters
                *ngIf="selectedFilters$ | async as filters"
                [searchForm]="(searchForm$ | async)?.model"
                [searchMetadata]="searchMetadata$ | async"
                [filters]="filters"
                (filterPatched)="patchForm($event)"
                (filtersRemoved)="clearForm()"
            >
            </leap-product-applied-filters>
        </div>
        <div nz-row [nzGutter]="10" class="products-container">
            <div
                nz-col
                [nzSm]="12"
                [nzMd]="12"
                [nzLg]="8"
                [nzXl]="6"
                [nzXXl]="6"
                *ngFor="let product of products$ | async as products; trackBy: trackById"
                class="product-wrapper"
            >
                <leap-product-card
                    [id]="product.id"
                    [sku]="product.sku"
                    [name]="product.name"
                    [suffix]="product.suffix"
                    [type]="product?.typeConfig?.configValue"
                    [isPromotionPricingApplied] = "product?.pricing?.isPromotionPricingApplied"
                    [actualAmount]="product?.pricing?.actualAmount"
                    [promotionPricingDiscountEndDate] = "product?.pricing?.promotionPricingDetail?.promotionPricingDiscountEndDate"
                    [price]="product.pricing?.amount"
                    [currency]="product.pricing?.currency"
                    [shortDescription]="product.shortDescription"
                    [variants]="product.productVariants"
                    [isBundleType]="product?.typeConfig?.configKey === 'bundle-product'"
                    [bundle]="product?.bundle"

                    [addLineItemSku]="addLineItemSku$ | async"
                    [addLineItemPending]="addLineItemPending$ | async"
                    [searchedDeliveryFormats]="(searchForm$ | async)?.model.deliveryFormats"
                    [searchedLanguageTypes]="(searchForm$ | async)?.model.languages"

                    [currencySignsMap]="currencySignsMap$ | async"
                    [sellerImageUrl]="product.seller.logo"
                    [sellerName]="product.seller.displayName"
                    [sellerDomain]="product.seller.name"
                    [hasAccess]="product.restriction?.hasAccess"
                    [isNotContractedSupplier]="product.restriction?.isNotContractedSupplier"
                    [restricted]="product.restriction?.restricted"
                    [isLogged]="isLogged$ | async"
                    [isPermittedToPublishToLxp]="isPermittedToPublishToLxp$ | async"
                    [enabledForLxp]="product.enabledForLxp"
                    [publishedToLxp]="product.publishedToLxp"
                    [checked]="isProductSelected(product.id)"
                    (addToCart)="addToCart($event)"
                    (publishToLxp)="publishProductToLxp(filterProductsByIds(products, [$event])[0])"
                    (editLxpProduct)="editPublishedToLxpProduct(filterProductsByIds(products, [$event])[0])"
                    (selectCard)="onSelectProduct(product.id, $event)"
                >
                </leap-product-card>
            </div>
            <div class="no-results-container" style="width: 100%; display: flex; justify-content: center;">
                <ng-container
                    *ngIf="
                        !(products$ | async)?.length &&
                        !(fetchProductsPending$ | async) &&
                        ((fetchProductsSuccess$ | async) || (fetchProductsError$ | async))
                    "
                >
                    <leap-product-no-results
                        [searchForm$]="searchForm$"
                        [filters$]="selectedFilters$"
                    ></leap-product-no-results>
                </ng-container>
            </div>
            <ng-container *ngIf="fetchProductsPending$ | async">
                <ng-container *ngTemplateOutlet="skeletonsTemplate"></ng-container>
            </ng-container>
        </div>
        <leap-suggestions [term]="formGroup.get('filter').value"></leap-suggestions>
        <div class="pagination-wrapper">
            <nz-pagination
                *ngIf="(products$ | async)?.length > 0"
                nzShowQuickJumper
                [nzHideOnSinglePage]="true"
                [nzPageIndex]="(pagination$ | async)?.page"
                [nzTotal]="(searchMetadata$ | async)?.count"
                [nzPageSize]="12"
                (nzPageIndexChange)="changePage($event)"
            ></nz-pagination>
        </div>
    </nz-content>
</nz-layout>

<ng-template #skeletonsTemplate>
    <div
        *ngFor="let product of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
        nz-col
        [nzSm]="24"
        [nzMd]="12"
        [nzLg]="8"
        [nzXl]="6"
        [nzXXl]="6"
        class="product-wrapper"
    >
        <leap-product-card-skeleton></leap-product-card-skeleton>
    </div>
</ng-template>

<nz-drawer
    [nzClosable]="true"
    [nzVisible]="drawerVisible"
    [nzPlacement]="'left'"
    [nzWidth]="'100%'"
    [nzHeight]="'100%'"
    (nzOnClose)="closeDrawer()"
>
    <leap-product-filter-menu
        [form]="formGroup"
        [searchMetadata]="searchMetadata$ | async"
        [searchForm]="searchForm$ | async"
        [isPermittedToPublishToLxp]="isPermittedToPublishToLxp$ | async"
        [isAuthorized]="isLogged$ | async"
        (favoriteSupplierSelection)="favoriteSupplierSelection($event)"
    >
    </leap-product-filter-menu>
</nz-drawer>
