/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./products.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/common-product/containers/product-feed/product-feed.component.ngfactory";
import * as i3 from "../../../common/common-product/containers/product-feed/product-feed.component";
import * as i4 from "@angular/forms";
import * as i5 from "@ngxs/store";
import * as i6 from "@angular/router";
import * as i7 from "../../../common/common-product/services/lxp-publishing.service";
import * as i8 from "../../../common/common-product/services/publish-to-lxp-form.service";
import * as i9 from "./products.component";
var styles_ProductsComponent = [i0.styles];
var RenderType_ProductsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductsComponent, data: {} });
export { RenderType_ProductsComponent as RenderType_ProductsComponent };
export function View_ProductsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-feed", [], null, null, null, i2.View_ProductFeedComponent_0, i2.RenderType_ProductFeedComponent)), i1.ɵdid(1, 245760, null, 0, i3.ProductFeedComponent, [i4.FormBuilder, i5.Store, i6.Router, i7.LxpPublishingService, i8.PublishToLxpFormService], { isSellerProductListing: [0, "isSellerProductListing"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProductsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-products", [], null, null, null, View_ProductsComponent_0, RenderType_ProductsComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProductsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductsComponentNgFactory = i1.ɵccf("leap-products", i9.ProductsComponent, View_ProductsComponent_Host_0, {}, {}, []);
export { ProductsComponentNgFactory as ProductsComponentNgFactory };
