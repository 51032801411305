import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: '[leap-list-item-skeleton]',
  templateUrl: './list-item-skeleton.component.html',
  styleUrls: ['./list-item-skeleton.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemSkeletonComponent implements OnInit {
  @Input() columns:Array<any> = [];
  constructor() { }

  ngOnInit() {
  }

}
