import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { SendMessage, FetchOlderMessages, MarkReadMessage, CheckDMConversation, CreateDMConversation } from '../../state/inbox.state';
import { InboxMessage, InboxConversation } from '../models/inbox-message.model';
import { filter } from 'rxjs/operators';
import { ScrollContainerDirective } from '../../directives/scroll-container.directive';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'leap-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationComponent implements OnInit, OnDestroy {
  
  @Input() pending: boolean;
  @Input() conversationId: string;
  
  @Output()
  actionClick = new EventEmitter<any>();

  @Select((state: any) => state.inbox.selectedConversation)
  conversation$: Observable<InboxConversation>;

  @Select((state: any) => state.inbox.messages)
  messages$: Observable<InboxMessage[]>;

  @Select((state: any) => state.inbox.newMessageInSelectedConversation)
  newMessage$: Observable<boolean>;

  @Select((state: any) => state.inbox.scrollToBottom)
  scrollToBottom$: Observable<boolean>;

  @Select((state: any) => state.inbox.fetchingOlderMessages)
  fetchingOlderMessages$: Observable<boolean>;

  @Select((state: any) => state.core.dictionaries.currencySignsMap)
  currencySignsMap$: Observable<Map<string, string>>;

  @ViewChild('conversationContainer', { static: true })
  conversationContainerRef: ElementRef;

  @ViewChild('conversationContainer', { static: true})
  conversationContainerDirective: ScrollContainerDirective;

  isNearTop: boolean;
  scrolledToTop: Subject<void> = new Subject<void>();

  myEmail: string;

  myDomain: string;
  
  constructor(
    private store: Store
  ) { }

  ngOnInit() {

    const userDetails = this.store.selectSnapshot(state => state.session.userDetails);
    this.myEmail = userDetails.username;
    this.myDomain = userDetails.domain;

    this.newMessage$
      .pipe(
        filter(_ => !!_),
        untilDestroyed(this)
      )
      .subscribe(_ => 
        setTimeout(_ => this.conversationContainerRef.nativeElement.scrollTop = this.conversationContainerRef.nativeElement.scrollHeight, 200))

    this.scrollToBottom$
      .pipe(
        filter(_ => !!_),
        untilDestroyed(this)
      )
      .subscribe(_ => {
        setTimeout(_ => this.conversationContainerRef.nativeElement.scrollTop = this.conversationContainerRef.nativeElement.scrollHeight, 100)
      })

    this.messages$.subscribe(_ => {
      console.log(_ && _.length);
    })
  }

  ngOnDestroy() { }

  getRecipientDisplayName(conversation: any) {
    return conversation.recipient && conversation.recipient.displayName;
  }

  getRecipientLogoUrl(conversation: any) {
    return conversation.recipient && conversation.recipient.logoUrl;
  }


  sendMessage(message: string) {
    if (this.conversationId === 'new-dm') {
      this.store.dispatch(new CreateDMConversation(message));
    } else {
      this.store.dispatch(new SendMessage(message));
    }
  }

  onScrollDown() {
    this.store.dispatch(new MarkReadMessage());
  }

  onScrollUp() {
    const messages = this.store.selectSnapshot((state: any) => state.inbox.messages);
    if (messages && messages.length) this.store.dispatch(new FetchOlderMessages())
  }

  createDMConversation(domain: string) {
    this.store.dispatch(new CheckDMConversation(domain));
  }

  getSenderDisplayName(senderDomain: string, accounts: any[]) {
    return accounts.find(_ => _.name === senderDomain).displayName;
  }

  getSenderLogoUrl(senderDomain: string, accounts: any[]) {
    return accounts.find(_ => _.name === senderDomain).logoUrl;
  }

  trackById(index, item: InboxMessage) {
    return item.id;
  }
}
