import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'leap-conversation-tile',
  templateUrl: './conversation-tile.component.html',
  styleUrls: ['./conversation-tile.component.less']
})
export class ConversationTileComponent implements OnInit {

  @Input() status: string;
  @Input() isActive: boolean;

  @Input() recipient: any;
  @Input() timestamp: string;
  @Input() lastMessage: string;
  @Input() imageUrl: string;
  @Input()
  set unreadCount(value: number) {
    this._unreadCount = value;
    this.cdr.markForCheck();
  }

  @Input() lastMessageSenderDomain: string;

  @Input() amIBuyer: boolean;

  @Input() myDomain: string;

  _unreadCount: number;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

}
