import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { SHOPPING_CART } from 'src/app/core/graphql/shipping-cart.fragment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EnvironmentService } from "../../snatch/services";
import { SessionService } from "../../snatch/services/session.service";
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "../../snatch/services/environment.service";
import * as i3 from "../../snatch/services/session.service";
import * as i4 from "@angular/common/http";
const GET_SHOPPING_CART_DETAILS = gql `
  query {
    getMyBasketDetails {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const ADD_LINE_ITEM_TO_SHOPPING_CART = gql `
  mutation addBasketItem($input: BasketAddItem!) {
    addBasketItem(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const EDIT_LINE_ITEM_FROM_SHOPPING_CART = gql `
  mutation editBasketItem($input: BasketEditItem!) {
    editBasketItem(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const REMOVE_LINE_ITEM_FROM_SHOPPING_CART = gql `
  mutation removeBasketItem($input: BasketRemoveItem!) {
    removeBasketItem(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const EDIT_SHIPPING_METHOD = gql `
  mutation editShippingMethod($input: BasketShippingMethodEdit!) {
    editShippingMethod(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const ADD_PROMOTION_CODE = gql `
  mutation addPromoCode($input: BasketPromoCode!) {
    addPromoCode(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const REMOVE_PROMOTION_CODE = gql `
  mutation removePromoCode($input: BasketPromoCode!) {
    removePromoCode(input: $input) {
      ...basketViewFragment
    }
  }
  ${SHOPPING_CART}
`;
const GET_PROMOTION_CODES = gql `
query promotionCodes($input: PromotionCodeListQuery!) {
  promotionCodes(input: $input) {
    code,
    discountPercentage,
    unlimitedUsage,
    unitLimit,
    validTill
  }
}`;
const UPDATE_PURCHASE_ORDER = gql `
    mutation editBasketItemPurchaseOrder($input: BasketPurchaseOrderItem!) {
        editBasketItemPurchaseOrder(input: $input) {
            ...basketViewFragment
        }
    }
    ${SHOPPING_CART}
`;
export class ShoppingCartService {
    constructor(apollo, environmentService, sessionService, httpClient) {
        this.apollo = apollo;
        this.environmentService = environmentService;
        this.sessionService = sessionService;
        this.httpClient = httpClient;
    }
    getShoppingCartDetails() {
        return this.apollo
            .watchQuery({
            query: GET_SHOPPING_CART_DETAILS
        })
            .valueChanges;
    }
    addLineItem(sku, quantity = 1) {
        return this.apollo.mutate({
            mutation: ADD_LINE_ITEM_TO_SHOPPING_CART,
            variables: {
                input: { sku, quantity }
            }
        });
    }
    editLineItem(id, quantity) {
        return this.apollo.mutate({
            mutation: EDIT_LINE_ITEM_FROM_SHOPPING_CART,
            variables: {
                input: { id, quantity }
            }
        });
    }
    removeLineItem(id) {
        return this.apollo.mutate({
            mutation: REMOVE_LINE_ITEM_FROM_SHOPPING_CART,
            variables: {
                input: { id }
            }
        });
    }
    editShippingMethod(shippingGroupId, shippingMethodId) {
        return this.apollo.mutate({
            mutation: EDIT_SHIPPING_METHOD,
            variables: {
                input: { shippingGroupId, shippingMethodId }
            }
        });
    }
    fetchPromotionCodes() {
        return this.apollo
            .watchQuery({
            query: GET_PROMOTION_CODES,
            variables: { input: { available: true } }
        })
            .valueChanges;
    }
    addPromotionCode(promotionCode) {
        return this.apollo.mutate({
            mutation: ADD_PROMOTION_CODE,
            variables: { input: { promoCode: promotionCode } }
        });
    }
    removePromotionCode(promotionCode) {
        return this.apollo.mutate({
            mutation: REMOVE_PROMOTION_CODE,
            variables: { input: { promoCode: promotionCode } }
        });
    }
    updatePurchaseOrderOnBasketItem(id, purchaseOrderIds) {
        return this.apollo.mutate({
            mutation: UPDATE_PURCHASE_ORDER,
            variables: { input: {
                    id: id,
                    purchaseOrderIds: purchaseOrderIds
                }
            }
        });
    }
    fetchAvailablePurchaseOrders(sellerIds) {
        const url = this.environmentService.amberUrl +
            '/odyssey/orders/purchase/listBySellerAccountIds';
        const params = new HttpParams();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.sessionService.getToken()}`
        });
        return this.httpClient.post(url, sellerIds, { params: params, headers: headers });
    }
}
ShoppingCartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShoppingCartService_Factory() { return new ShoppingCartService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.EnvironmentService), i0.ɵɵinject(i3.SessionService), i0.ɵɵinject(i4.HttpClient)); }, token: ShoppingCartService, providedIn: "root" });
