import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/auth/state/auth-token.state';

@Directive({
  selector: '[leapIsAuth]'
})
export class IsAuthDirective {
  @Select((state: any) => state.session.state)
  state$: Observable<AuthState>;

  private isHidden = true;
  private authenticated = true;
  private auth = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    this.state$.subscribe(_ => {
      this.authenticated = (_ === AuthState.LOGGED)
      this.updateView();
    });
  }

  @Input()
  set leapIsAuth(val) {
    this.auth = val;
    this.updateView();
  }

  private updateView() {
    if (this.isAuth()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private isAuth() {
    return this.auth === this.authenticated;
  }

}
