<div class="address-edit-modal-container">
    <form nz-form [formGroup]="form">
        <div nz-row>
            <nz-divider nzText="DETAILS" nzOrientation="left"></nz-divider>
            <div nz-col [nzMd]="24">
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="First name field label in address form|@@checkout.addressform.firstName.label">First Name</label>
                            <nz-form-control [nzXs]="24">
                                <input nz-input formControlName="firstName" placeholder="John">

                                <nz-form-explain *ngIf="isFormItemRequired('firstName')">
                                    <span i18n="First name field required in address form|@@checkout.addressform.firstName.required">First name is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="Last name field label in address form|@@checkout.addressform.lastName.label">Last Name</label>
                            <nz-form-control [nzXs]="24">
                                <input nz-input formControlName="lastName" placeholder="Doe">
                                <nz-form-explain *ngIf="isFormItemRequired('lastName')">
                                    <span i18n="Last name field required in address form|@@checkout.addressform.lastName.required">Last name is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label>
                                <span i18n="Company name field label in address form|@@checkout.addressform.companyname.label">Company Name </span>
                                <span i18n="Optional field label for company name|@@checkout.addressform.companyname.optional"><i>Optional</i></span>
                            </label>
                            <nz-form-control [nzXs]="24">
                                <input nz-input formControlName="companyName" >

                                <nz-form-explain *ngIf="isFormItemRequired('companyName')">
                                    <span i18n="Company name field required in address form|@@checkout.addressform.companyName.required">Company name is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12"></div>
                </div>
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="Email field label in address form|@@checkout.addressform.email.label">Email</label>
                            <nz-form-control [nzXs]="24">
                                <input nz-input formControlName="email" placeholder="example@email.com">

                                <nz-form-explain *ngIf="isFormItemRequired('email')">
                                    <span i18n="Email field required in address form|@@checkout.addressform.email.required">Email is required!</span>
                                </nz-form-explain>
                                <nz-form-explain *ngIf="isFormItemInvalid('email')">
                                    <span i18n="Email field not valid in address form|@@checkout.addressform.email.invalid">Email is not valid!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="Phone field label in address form|@@checkout.addressform.phone.label">Phone</label>
                            <nz-form-control [nzXs]="24">
                                <input type="text" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$" nz-input formControlName="phoneNumber" placeholder="+XXX XXXXXXXXX">

                                <nz-form-explain *ngIf="isFormItemRequired('phoneNumber')">
                                    <span i18n="Phone number required in address form|@@checkout.addressform.phone.required">Phone number is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <nz-divider nzText="SHIPPING ADDRESS" nzOrientation="left"></nz-divider>
            <div nz-col [nzMd]="24">
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="Country field label in address form|@@checkout.addressform.country.label">Country</label>
                            <nz-form-control [nzXs]="24">
                                <nz-select nzShowSearch formControlName="country">
                                    <nz-option *ngFor="let key of countryKeys" [nzValue]="key" [nzLabel]="countriesMap?.get(key)"></nz-option>
                                </nz-select>

                                <nz-form-explain *ngIf="isFormItemRequired('country')">
                                    <span i18n="Country is required in address form|@@checkout.addressform.country.required">Country is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12">

                    </div>
                </div>
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label>State</label>
                            <nz-form-control>
                                <nz-select style="min-width: 100%;" nzShowSearch nzPlaceholder="Choose State" formControlName="state">
                                    <nz-option *ngFor="let state of getStatesOfSelectedCountry()"
                                               [nzValue]="state.code" [nzLabel]="state.name">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label>
                                <span>Province </span>
                                <span><i>Optional</i></span>
                            </label>
                            <nz-form-control>
                                <input type="text" formControlName="province" nz-input>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="City field label in address form|@@checkout.addressform.city.label">City</label>
                            <nz-form-control [nzXs]="24">
                                <input nz-input formControlName="city" >

                                <nz-form-explain *ngIf="isFormItemRequired('city')">
                                    <span i18n="City is required in address form|@@checkout.addressform.city.required">City is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="Zip code name field label in address form|@@checkout.addressform.zipCode.label">Zip Code</label>
                            <nz-form-control [nzXs]="24">
                                <input style="width: 50%" required [pattern]="zipCodePatterns[editAddress.country]" nz-input formControlName="zipCode" name="zipCode" id="zipCode" >

                                <nz-form-explain *ngIf="isFormItemRequired('zipCode')">
                                    <span i18n="Zip code is required in address form|@@checkout.addressform.zipCode.required">Zip code is required!</span>
                                </nz-form-explain>
                                <nz-form-explain *ngIf="isFormItemInvalid('zipCode')">
                                    <span i18n="Zip code is invalid in address form|@@checkout.addressform.zipCode.invalid">Zip code is invalid!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row [nzGutter]="18">
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="Street name field label in address form|@@checkout.addressform.streetName.label">Street Name</label>
                            <nz-form-control [nzXs]="24">
                                <input required nz-input formControlName="streetName" >

                                <nz-form-explain *ngIf="isFormItemRequired('streetName')">
                                    <span i18n="Street name is required in address form|@@checkout.addressform.streetName.required">Street name is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzMd]="12">
                        <nz-form-item>
                            <label i18n="House number field label in address form|@@checkout.addressform.houseNumber.label">House Number</label>
                            <nz-form-control [nzXs]="24">
                                <input style="width: 50%"  required nz-input formControlName="houseNumber" >

                                <nz-form-explain *ngIf="isFormItemRequired('houseNumber')">
                                    <span i18n="House number is required in address form|@@checkout.addressform.houseNumber.required">House number is required!</span>
                                </nz-form-explain>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>
        <div nz-row>
            <nz-form-item>
                <div class="messages-default">
                    <label i18n="Default address|@@checkout.addressform.defaultAddress.text">Default Address</label><br>
                    <label class="this-address-can-be-changed" i18n="The default address will be automatically selected. This can be changed at any time.|@@checkout.addressform.defaultAddress.thisAddressCanBeChangedAutomatically">
                        The default address will be automatically selected. This can be changed at any time.
                    </label>
                </div>
                <label nz-checkbox formControlName="makeDefaultType" style="margin-top: 10px;">
                    <span i18n="Setup as default address text|@@checkout.addressform.defaultAddress.check">Set up as the Default Address</span>
                </label>
            </nz-form-item>
            <nz-form-item>
                <leap-error-alert [source]="error?.message"></leap-error-alert>
            </nz-form-item>
        </div>
        <div class="address-edit-modal-footer">
            <button nz-button nzType="default" (click)="cancelChanges()">
                <span i18n="Cancel button text in address form|@@checkout.addressform.cancel.button.text">Cancel</span>
            </button>
            <button nz-button nzType="primary" [nzLoading]="pending" [disabled]="pending" (click)="saveChanges()">
                <span *ngIf="editAddress.id" i18n="Save changes button text in address form|@@checkout.addressform.saveChanges.button.text">Save Changes</span>
                <span *ngIf="!editAddress.id" i18n="Add address button text in address form|@@checkout.addressform.addAddress.button.text">{{ addMode ? 'Add' : 'Save' }} Address</span>
            </button>
        </div>
    </form>
</div>


