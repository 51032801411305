<div
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzTrigger="click"
  class="bell-container"
  [nzVisible]="dropdownPanelVisible$ | async"
  (nzVisibleChange)="showDropdownPanel($event)"
  [nzClickHide]="false"
>
  <div class="new-messages-notification" *ngIf="notReadNotificationsExist$ | async"></div>

  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 450 514"
    xml:space="preserve"
    y="0px"
    x="0px"
    id="Layer_1_1557842065902"
    width="24"
    height="28"
  >
    <g transform="translate(1, 1)">
      <path
        d="M224,480c-17.7,0-32-14.4-32-32h-32c0,35.3,28.7,64,64,64s64-28.7,64-64h-32C256,465.6,241.7,480,224,480z M433.4,334.8  c-28-26.6-49.3-54.5-49.3-148.9c0-79.6-63.4-144.5-144-152.4V16c0-8.8-7.2-16-16-16s-16,7.2-16,16v17.6  c-80.7,7.9-144,72.8-144,152.3c0,94.4-21.4,122.3-49.3,148.9C0.6,348.1-3.8,368.2,3.4,386c7.3,18.2,24.8,30,44.6,30h352  c19.8,0,37.4-11.8,44.6-30C451.8,368.2,447.4,348.1,433.4,334.8z M400,384H48c-14.2,0-21.3-16.5-11.3-26  C71.5,324.8,96,287.6,96,185.9C96,118.5,153.2,64,224,64c70.8,0,128,54.5,128,121.9c0,101.4,24.2,138.7,59.3,172.1  C421.4,367.6,414.2,384,400,384z"
        vector-effect="non-scaling-stroke"
        style="fill: rgb(142, 143, 166);"
      />
    </g>
  </svg>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div nz-menu class="dropdown-panel" (click)="showDropdownPanel(false)">
    <div class="dropdown-panel__header">
      <div>NOTIFICATIONS</div>
      <div>
        <a class="interactive-text" routerLink="/notifications/settings">Settings</a>
      </div>
    </div>
    <ul class="notifications-list" *ngIf="(recentNotArchivedNotifications$ | async).length">
      <ng-container *ngFor="let notification of recentNotArchivedNotifications$ | async; let i = index">
        <li class="list-item">
          <a routerLink="/notifications/listing" [queryParams]="{ notificationId: notification.id }">
            <div class="list-item__date">{{ notification.createdAt | date: 'd MMM, yyyy h:mm' }}</div>
            <div class="list-item__title" [class.list-item__title--bold]="notification.isNew">
              {{ notification.title }}
            </div>
            <!-- <div class="list-item__text">{{ notification.text }}</div> -->
            <div class="list-item__text">
              <a>View more</a>
            </div>
          </a>
        </li>
        <hr *ngIf="i != (recentNotArchivedNotifications$ | async).length - 1" />
      </ng-container>
    </ul>
    <div *ngIf="!(recentNotArchivedNotifications$ | async).length" class="empty-container">
      <nz-empty nzNotFoundContent="No notifications"></nz-empty>
    </div>
    <div class="dropdown-panel__footer">
      <a routerLink="/notifications/listing" class="interactive-text">View All</a>
    </div>
  </div>
</nz-dropdown-menu>
