<div class="search-container" *ngIf="showSearchInput">
  <input nz-input type="text" (input)="filterChanged($event.target.value)" class="search-input" />
  <svg xmlns="http://www.w3.org/2000/svg" version="1.2" overflow="visible" preserveAspectRatio="none"
       viewBox="0 0 24 24"
       class="search-btn">
    <g>
      <path xmlns:default="http://www.w3.org/2000/svg"
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            style="fill: rgb(156, 164, 166);" vector-effect="non-scaling-stroke"/>
    </g>
  </svg>
</div>
<div class="list-container" [class.expanded]="expanded">
  <div>

    <ng-container *ngIf="enableRadioSelection">
      <div><span *ngIf="hasActiveFilter && shownItems.length === 0">No results for '{{filter}}'</span></div>
      <nz-radio-group [(ngModel)]="radioSelectionModel" (ngModelChange)="selectionChanged($event)">
        <div *ngFor="let item of shownItems; trackBy: trackById" class="control-container">
          <label nz-radio [ngStyle]="nzRadioSelectionStyles" [nzValue]="item.id">
            <span class="display-value"><span class="token-wrapper" *ngFor="let token of item.tokens" [class.bolded]="token.highlight">{{token.token}}</span></span>
<!--            <span class="option-count" *ngIf="item.count !== null">({{item.count}})</span>-->
          </label>
        </div>
      </nz-radio-group>
    </ng-container>


    <nz-checkbox-wrapper *ngIf="!enableRadioSelection" (nzOnChange)="selectionChanged($event)">
      <span *ngIf="hasActiveFilter && shownItems.length === 0">No results for '{{filter}}'</span>
      <div *ngFor="let item of shownItems; trackBy: trackById" class="control-container">
        <label nz-checkbox [class.label-disabled]="item.count === 0 && !isSelected(item.id)"
               [nzDisabled]="item.count === 0 && !isSelected(item.id)"
               [nzValue]="item.id"
               [nzChecked]="isSelected(item.id)">
          <span class="display-value">
            <span class="token-wrapper" *ngFor="let token of item.tokens" [class.bolded]="token.highlight">{{token.token}}</span>
          </span>
          <span class="option-count">({{item.count}})</span>
        </label>
      </div>
    </nz-checkbox-wrapper>
  </div>
  <div *ngIf="!expanded && (items?.length - shownItems?.length > 0) && (shownItems?.length >= maxCollapsedCount)" class="expand-container" (click)="expand()">
    <span>Show {{numberOfSearchResults - maxCollapsedCount}} more...</span>
  </div>
</div>
<div *ngIf="expanded" class="collapse-container" (click)="collapse()">Show less...</div>
