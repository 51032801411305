<form [formGroup]="form" (ngSubmit)="submitFn()">
  <nz-form-item>
    <nz-form-control>
        <textarea
            nz-input
            formControlName="text"
            [nzAutosize]="{ minRows: 1, maxRows: 4 }"
            placeholder="Send message to {{ recipient }}"></textarea>
        <button nz-button [nzSize]="'small'" [nzLoading]="pending" nzType="primary" (click)="sendMessage()">Send</button>
    </nz-form-control>
  </nz-form-item>
</form>