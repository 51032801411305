import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  detectTimezone(timezones: Array<{key: string, offset: number}>): string | null {
    const offset = new Date().getTimezoneOffset();
    const match = timezones.find(t => t.offset === offset);
    return match ? match.key : null;
  }
}
