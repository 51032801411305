import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProductShort } from "../../models/product-short.model";
import { NzCarouselComponent } from "ng-zorro-antd";
import { Review } from "../../../../product-details/components/reviews/model/review.model";
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'leap-products-carousel-section',
  templateUrl: './products-carousel-section.component.html',
  styleUrls: ['./products-carousel-section.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsCarouselSectionComponent implements OnInit {

  @Input() title : string;
  @Input() caption : string;
  @Input() bgColor : string;
  @Input() products : Array<ProductShort>;
  @Output() onProductClick : EventEmitter<ProductShort> = new EventEmitter();
  @Output() seeMoreClick : EventEmitter<ProductShort> = new EventEmitter();

  @ViewChild('carouselComponent', { static: true }) carouselComponent : NzCarouselComponent;

  public averageRatingCache: any = {};
  public thumbnailUrlCache: any = {};

  isSmScreen;
  isMdScreen;
  isLgScreen;
  isXlScreen;

  smArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  smItems = [0];

  mdArray = [0, 1, 2, 3, 4, 5]; 
  mdItems = [0, 1];

  lgArray = [0, 1, 2, 3];
  lgItems = [0, 1, 2];

  xlArray = [0, 1, 2];
  xlItems = [0, 1, 2, 3];

  effect = 'scrollx';

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.isSmScreen = this.breakpointObserver.isMatched(['(max-width: 768px)']);
    this.isMdScreen = this.breakpointObserver.isMatched(['(min-width: 769px) and (max-width: 992px)']);
    this.isLgScreen = this.breakpointObserver.isMatched(['(min-width: 993px) and (max-width: 1200px)']);
    this.isXlScreen = this.breakpointObserver.isMatched('(min-width: 1201px)');
  }

  getAverageRating(product: ProductShort) {
    const sku = product.sku;

    if (this.averageRatingCache.hasOwnProperty(sku)) {
      return this.averageRatingCache[sku];
    }
    this.averageRatingCache[sku] = this.buildProductRatingFromReviews(product);
    return this.averageRatingCache[sku];
  }

  buildProductRatingFromReviews(product: ProductShort) {
    let reviews: Review[] = product.reviews;
    let count = 0, total = reviews.map((review: Review) => {
      return count + review.rating;
    })[0] || 0;
    return Math.ceil(count / reviews.length);
  }

  getProductThumbNail(product: ProductShort) {
    if (this.thumbnailUrlCache.hasOwnProperty(product.sku)) {
      return this.thumbnailUrlCache[product.sku];
    }
    const defaultVariant = product.productVariants.filter(_ => _ != null && !!_.defaultVariant)[0];
    const selectedVariant = defaultVariant? defaultVariant: (product.productVariants.length? product.productVariants[0] : null);
    const variantRenders = selectedVariant && selectedVariant.renders.length && selectedVariant.renders[0];
    this.thumbnailUrlCache[product.sku] = variantRenders && variantRenders.mediumImageUri;
    return this.thumbnailUrlCache[product.sku];

  }

  gotoProductDetails(product: ProductShort) {
    this.onProductClick.emit(product);
  }

  gotoPreviousPage() {
    this.carouselComponent.pre();
  }

  isSeeMoreAvailable() {
    return this.seeMoreClick.observers.length > 0;
  }

  seeMoreClickFn() {
    this.seeMoreClick.emit();
  }

  gototNextPage() {
    this.carouselComponent.next();
  }
}
