import { ElementRef } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { TranslateService } from 'src/app/core/services/translate.service';
import * as i0 from "@angular/core";
import * as i1 from "./environment.service";
export class UtilsService {
    constructor(environment) {
        this.environment = environment;
    }
    buildSphinxUrl(bucket, key) {
        return `https://downloads.mkpqa.com/proxy/service/jwt/${bucket}/${key}`;
    }
    extractVideoIdFromUrl(url) {
        // let regex: any = '/^https?://(?:www\.)?youtu(?:\.be|be\.com)/(?:\S+/)?(?:[^\s/]*(?:\?|&)vi?=)?([^#?&]+)/'
        const regex = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
        const parsed = url.match(regex);
        return parsed ? parsed[7] : null;
    }
    getFileUrl(key, bucket) {
        const result = this.environment.sphinxUrl + `/proxy/system/jwt/${bucket}/${key}`;
        return result;
    }
    convertObjectToDictionary(obj) {
        const result = [];
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                result.push({ key: prop, value: obj[prop] });
            }
        }
        return result;
    }
    formatLineItems(items) {
        return items
            .sort((a, b) => {
            if (a.createdAt < b.createdAt)
                return 1;
            else if (a.createdAt > b.createdAt)
                return -1;
            else
                return 0;
        });
    }
    scrollModalTop(modalRef) {
        modalRef['elementRef'].nativeElement.children[0].children[1].scrollTo({ top: 0, behavior: 'smooth' });
    }
    scrollToElementID(elementId) {
        const element = document.querySelector('#' + elementId);
        const top = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
            top,
            behavior: 'smooth'
        });
    }
    formatGraphqlError(graphqlError) {
        let message = graphqlError.message.replace('GraphQL error: ', '');
        if (message.indexOf('Network error') === 0)
            message = 'network.error';
        return { message: TranslateService.translateErrorMessage(message), errorKey: message };
    }
}
UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.EnvironmentService)); }, token: UtilsService, providedIn: "root" });
