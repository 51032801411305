/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-card-image.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../product-card-image-skeleton/product-card-image-skeleton.component.ngfactory";
import * as i3 from "../product-card-image-skeleton/product-card-image-skeleton.component";
import * as i4 from "@angular/common";
import * as i5 from "./product-card-image.component";
var styles_ProductCardImageComponent = [i0.styles];
var RenderType_ProductCardImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductCardImageComponent, data: {} });
export { RenderType_ProductCardImageComponent as RenderType_ProductCardImageComponent };
function View_ProductCardImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-card-image-skeleton", [], null, null, null, i2.View_ProductCardImageSkeletonComponent_0, i2.RenderType_ProductCardImageSkeletonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProductCardImageSkeletonComponent, [], { size: [0, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProductCardImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "img", [["alt", "Product Image"], ["class", "product-image"]], [[8, "src", 4]], [[null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = ((_co.imageLoading = false) !== false);
        ad = (pd_0 && ad);
    } if (("error" === en)) {
        _co.imageLoading = false;
        var pd_1 = ((_co.brokenImage = true) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "product-image"; var currVal_2 = _co.size; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_ProductCardImageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "image-placeholder"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickFn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, [" Image Placeholder "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "image-placeholder"; var currVal_1 = _co.size; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ProductCardImageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "image-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductCardImageComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickFn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductCardImageComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["imagePlaceholder", 2]], null, 0, null, View_ProductCardImageComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "image-container"; var currVal_1 = _co.size; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.imageLoading; _ck(_v, 4, 0, currVal_2); var currVal_4 = (_co.imageUrl && !_co.brokenImage); var currVal_5 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.imageLoading; _ck(_v, 5, 0, currVal_3); }); }
export function View_ProductCardImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-card-image", [], null, null, null, View_ProductCardImageComponent_0, RenderType_ProductCardImageComponent)), i1.ɵdid(1, 638976, null, 0, i5.ProductCardImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductCardImageComponentNgFactory = i1.ɵccf("leap-product-card-image", i5.ProductCardImageComponent, View_ProductCardImageComponent_Host_0, { size: "size", imageUrl: "imageUrl" }, { onClick: "onClick" }, []);
export { ProductCardImageComponentNgFactory as ProductCardImageComponentNgFactory };
