import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './containers/products/products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NgxsModule } from '@ngxs/store';
import { ProductsState } from './state/products.state';
import { SnatchModule } from '../snatch/snatch.module';
import { CommonUtilsModule } from '../common/common-utils/common-utils.module';
import { ProductsService } from './services/products.service';
import { UrlParamBuilderService } from './services/url-param-builder.service';
import { UrlStorageService } from './services/url-storage.service';
import { CommonProductModule } from '../common/common-product/common-product.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ProductsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    NgxsModule.forFeature([ProductsState]),
    SnatchModule,
    CommonUtilsModule,
    CommonProductModule,
    RouterModule
  ],
  providers: [ProductsService, UrlParamBuilderService, UrlStorageService]
})
export class ProductsModule { }
