import { Pipe, PipeTransform } from '@angular/core';
import {EnvironmentService} from "src/app/snatch/services";


@Pipe({
  name: 'urlFromBucket'
})
export class UrlFromBucketPipe implements PipeTransform {
  constructor( private environment:EnvironmentService){}
  transform(value: unknown, bucketDetails: { s3Key: string, s3Bucket: string }): unknown {
   if (!bucketDetails) return '';
    const { s3Key, s3Bucket } = bucketDetails;
    return s3Key && s3Bucket ? this.getFileUrl(s3Key, s3Bucket) : undefined;
  }
	getFileUrl(key: string, bucket: string): string {
		const result = this.environment.sphinxUrl + `/proxy/system/jwt/${bucket}/${key}`;
		return result;
	}
}
