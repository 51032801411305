import { QueryParametersCacheService } from './query-parameters-cache.service';
import * as i0 from "@angular/core";
import * as i1 from "./query-parameters-cache.service";
export class BreadcrumbsBuildingService {
    constructor(queryParametersCacheService) {
        this.queryParametersCacheService = queryParametersCacheService;
    }
    buildBreadcrumbsWithQueryParams(breadcrumbs) {
        const queryParamsCache = this.queryParametersCacheService.cacheCurrentValue;
        return breadcrumbs.map((b) => (Object.assign({}, b, { queryParams: b.path && b.withQueryParams && (b.path in queryParamsCache)
                ? queryParamsCache[b.path]
                : null })));
    }
}
BreadcrumbsBuildingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreadcrumbsBuildingService_Factory() { return new BreadcrumbsBuildingService(i0.ɵɵinject(i1.QueryParametersCacheService)); }, token: BreadcrumbsBuildingService, providedIn: "root" });
