import { Injectable } from '@angular/core';
import { EnvironmentService } from '../../snatch/services';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ISearchParams, ISearchMetadata } from '../state/products.state';
import { AmberResponse } from '../../snatch/models/amber-response.model';
import {Observable } from 'rxjs';
import { Sort } from 'src/app/snatch/models/sort.model';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { ProductShort } from 'src/app/common/common-product/models/product-short.model';
import { IPageable } from 'src/app/snatch/models/page.model';
import { ProductListingQuery } from 'src/app/common/common-product/models/product-listing-query.model';

export interface IProductListResponse {
  results: Array<ProductShort>;
  metadata: ISearchMetadata;
}

const LIST_CATEGORIES = gql`
  query listCategories($input: CategoryListQuery!) {
    results: listCategories(input: $input) {
      code,
      name
    }
  }
`

const LIST_PRODUCTS = gql`
    query listProducts($listing: ProductListingQuery!) {
      results: listProducts(listing: $listing) {
        products {
          id,
          name,
          sku,
          typeConfig {
              configValue
              configKey
          }
          levelConfig {
              configValue
              configKey
          },
          courseDeliveryFormatsConfig {
              configValue
              configKey
          },
          shortDescription,
          pricing {
            amount,
            currency,
            actualAmount,
            isPromotionPricingApplied,
            promotionPricingDetail {
              promotionPricingDiscount,
              promotionPricingPercentage,
              promotionPricingDiscountEndDate
            }
          },
          suffix,
          render {
            mediumImageUri
          },
          seller {
            displayName,
            name,
            logo
          }
          bundle {
            sku
            name
               description
               licensePricing {
                   licenseType
                   duration
                   durationType
                   sku
                   currency
                   price
                   licenseTypeValue
               }
            }
          productVariants {
            id,
            sku,
            name,
            bundleLicensePricingSKU,
            language,
            deliveryFormat,
            certificateType,
            defaultVariant,
            renders {
              mediumImageUri
            },
            languageConfig {
                configValue
                configKey
            },
            deliveryFormatConfig {
                configValue
                configKey
            },
            certificateTypeConfig {
                configValue
                configKey
            }
            language,
            deliveryFormat,
            certificateType
          },
          restriction {
            restricted,
            hasAccess,
            isNotContractedSupplier,
            accreditationSegments {
              code,
              name
            }
          },
          publishedToLxp,
          enabledForLxp
        },
        metadata {
          totalCount,
          minPrice,
          maxPrice,
          aggregations {
            key,
            items {
              key,
              count,
              displayValue
            }
          }
        }
      }
    }
`;

const LIST_MY_SELLERS = gql`
  query listMySellerAccounts {
    results: listMySellerAccounts {
      id
      name
      accountId
      currency
      displayName
      logo
      buyer
      seller
      instructor
      sellerProfile {
        aboutInfo
        supportEmail
      }
      website
      preferred
    }
  }
`;



@Injectable()
export class ProductsService {
  constructor(
    private apollo: Apollo,
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) {}


  list(filters: ISearchParams = {}, sort: Sort = {}, pageable?: IPageable): Observable<any> {
    let sortObject: { sortField: string, sortDir: number };

    sortObject = {
      sortField: sort.key || 'best',
      sortDir: sort.order || -1
    }

    let listing: ProductListingQuery = {
      ...pageable as { page: number, limit: number },
      ...filters,
      ...sortObject
    }

    listing.page = listing.page - 1;

    return this.apollo
      .watchQuery({
        query: LIST_PRODUCTS,
        variables:  { listing }
      })
      .valueChanges;
  }

  listCategories(code: string, isCatalog?: boolean): Observable<any> {

    const input: { subtopic?: string, catalog?: string } =
        isCatalog ? { catalog: code } : { subtopic: code };

    return this.apollo
      .watchQuery({
        query: LIST_CATEGORIES,
        variables:  { input }
      })
      .valueChanges;
  }

  fetchFavoriteSuppliers() {
    const url = this.environmentService.amberUrl + '/odyssey/supplier-management/mysuppliers/list?preferred=true&limit=-1'
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.get<AmberResponse<any>>(url, { params: params, headers: headers });
  }

  fetchMySuppliers() {
    return this.apollo
        .watchQuery({
          query: LIST_MY_SELLERS,
        })
        .valueChanges;
  }
}
