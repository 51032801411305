import { Injectable } from '@angular/core';
import { IPageable, ISearchParams } from '../state/inbox.state';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Sort } from '../../snatch/models/sort.model';
import { UrlParamBuilderService } from "./url-param-builder.service";

@Injectable()
export class UrlStorageService {
  // private lastParams;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private urlParamBuilderService: UrlParamBuilderService
  ) {}

  save(filters: ISearchParams = {}, sort: Sort = {}, pageable?: IPageable) {
    let params = this.urlParamBuilderService.constructParams(filters, sort, pageable);
    // Skip page && limit if same as defaults
    if (pageable) {
      if (pageable.page === this.urlParamBuilderService.DEFAULT_PAGE) {
        params = params.delete('page');
      }
      if (pageable.limit === this.urlParamBuilderService.DEFAULT_LIMIT) {
        params = params.delete('limit');
      }
    }
    const paramsToPersist = {};
    params.keys().forEach(p => (paramsToPersist[p] = params.getAll(p)));
    const yOffset = window.scrollY;
    this.router
      .navigate([], { relativeTo: this.route, queryParams: paramsToPersist, replaceUrl: true })
      .then(() => window.scrollTo(0, yOffset));
  }

  restore(): { filters: ISearchParams; sort: Sort; pageable: IPageable } {
    const lastParams = new TypedParamMap<ISearchParams & IPageable>(this.route.snapshot.queryParamMap);
    const pageable = {
      page: parseInt(lastParams.get('page'), 10),
      limit: parseInt(lastParams.get('limit'), 10)
    };

    const filters: ISearchParams = {
      filter: lastParams.get('filter'),
      topics: lastParams.getAll('topics')
    };

    let sort: Sort = {};
    const sortParamKey = lastParams.paramMap.keys.find(k => k.startsWith('$'));
    if (sortParamKey) {
      sort = { key: sortParamKey.substr(1), order: parseInt(lastParams.paramMap.get(sortParamKey), 10) };
    }

    return { filters, sort, pageable };
  }
}

export class TypedParamMap<T> {
  constructor(public readonly paramMap: ParamMap) {}

  get(name: keyof T): string {
    return this.paramMap.get(name as string);
  }

  getAll(name: keyof T): string[] {
    return this.paramMap.getAll(name as string);
  }
}
