/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sort-caret.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sort-caret.component";
var styles_SortCaretComponent = [i0.styles];
var RenderType_SortCaretComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SortCaretComponent, data: {} });
export { RenderType_SortCaretComponent as RenderType_SortCaretComponent };
function View_SortCaretComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["id", "Layer_1"], ["style", "enable-background:new 0 0 320 512;"], ["version", "1.1"], ["viewBox", "0 0 320 512"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["class", "path-default"], ["d", "M288,288H32c-28.4,0-42.8,34.5-22.6,54.6l128,128c12.5,12.5,32.8,12.5,45.3,0l128-128\n\tC330.7,322.5,316.4,288,288,288z M160,448L32,320h256L160,448z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["class", "path-primary"], ["d", "M32,224h256c28.4,0,42.8-34.5,22.6-54.6l-128-128c-12.5-12.5-32.8-12.5-45.3,0l-128,128\n\tC-10.7,189.5,3.6,224,32,224z"]], null, null, null, null, null))], null, null); }
function View_SortCaretComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["id", "Layer_1"], ["style", "enable-background:new 0 0 320 512;"], ["version", "1.1"], ["viewBox", "0 0 320 512"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["class", "path-primary"], ["d", "M288,288H32c-28.4,0-42.8,34.5-22.6,54.6l128,128c12.5,12.5,32.8,12.5,45.3,0l128-128\n\tC330.7,322.5,316.4,288,288,288z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["class", "path-default"], ["d", "M310.6,169.4l-128-128c-12.5-12.5-32.8-12.5-45.3,0l-128,128C-10.7,189.5,3.6,224,32,224h256\n\tC316.4,224,330.8,189.5,310.6,169.4z M32,192L160,64l128,128H32z"]], null, null, null, null, null))], null, null); }
function View_SortCaretComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["id", "Layer_1"], ["style", "enable-background:new 0 0 320 512;"], ["version", "1.1"], ["viewBox", "0 0 320 512"], ["x", "0px"], ["xmlns", "http://www.w3.org/2000/svg"], ["y", "0px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["class", "path-default"], ["d", "M288,288H32c-28.4,0-42.8,34.5-22.6,54.6l128,128c12.5,12.5,32.8,12.5,45.3,0l128-128\n\tC330.7,322.5,316.4,288,288,288z M160,448L32,320h256L160,448z M32,224h256c28.4,0,42.8-34.5,22.6-54.6l-128-128\n\tc-12.5-12.5-32.8-12.5-45.3,0l-128,128C-10.7,189.5,3.6,224,32,224z M160,64l128,128H32L160,64z"]], null, null, null, null, null))], null, null); }
export function View_SortCaretComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortCaretComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortCaretComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortCaretComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active && (_co.direction === 1)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.active && (_co.direction === (0 - 1))); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.active || !_co.direction); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SortCaretComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-sort-caret", [], null, null, null, View_SortCaretComponent_0, RenderType_SortCaretComponent)), i1.ɵdid(1, 638976, null, 0, i3.SortCaretComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SortCaretComponentNgFactory = i1.ɵccf("leap-sort-caret", i3.SortCaretComponent, View_SortCaretComponent_Host_0, { direction: "direction", active: "active" }, {}, []);
export { SortCaretComponentNgFactory as SortCaretComponentNgFactory };
