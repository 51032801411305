import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import { EnvironmentService } from 'src/app/snatch/services';
import { Select, Store } from '@ngxs/store';
import { AuthTokenState } from 'src/app/auth/state/auth-token.state';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'leap-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  @Select(AuthTokenState.isLogged)
  isLogged$: Observable<boolean>;
  @Select((state: any) => state.session.groups)
  groups$: Observable<string[]>;
  @Select((state: any) => state.domain.domainConfiguration)
  domainConfiguration$: Observable<any>;

  @Input() instructorMarketUrl: string;


  year: number;
  baseUrl: string;
  saharaUrl: string;
  footerLogo:string;

  constructor(
    private environment: EnvironmentService,
    private store: Store,
    private router: Router) { }

  ngOnInit() {
    this.domainConfiguration$.subscribe(data =>{
      if(data && data.logo){
        this.footerLogo = `${this.environment.s3Url}/${this.environment.cdnBucket}${data.logo.light.url}`
      }else {
        this.footerLogo = `${this.environment.s3Url}/${this.environment.cdnBucket}/images/ed-marketplace-logo-white.png`;
      }
    })
    this.year = new Date().getFullYear();
    this.instructorMarketUrl = `${this.environment.instructorMarketUrl}`;
    this.saharaUrl = `${this.environment.saharaUrl}`;
  }

  login() {
    this.router.navigate(['login'])
  }

}
