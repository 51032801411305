import {Injectable} from '@angular/core';
import {Sort} from '../../snatch/models/sort.model';
import {ISearchParams} from '../state/products.state';
import {HttpParams} from '@angular/common/http';
import {IPageable} from 'src/app/snatch/models/page.model';

@Injectable()
export class UrlParamBuilderService {
  constructor() {}

  readonly DEFAULT_PAGE = 1;
  readonly DEFAULT_LIMIT = 12;

  constructParams(filters: ISearchParams = {}, sort: Sort = {}, pageable?: IPageable): HttpParams {
    let params = new HttpParams();
    for (const key of Object.keys(filters)) {
      if (!filters[key]) {
        continue;
      }
      if (filters[key] instanceof Array) {
        filters[key].forEach(value => {
          params = params.append(key, value);
        });
      } else {
        params = params.set(key, filters[key]);
      }
    }

    const { key, order = 1 } = sort;
    if (key != null) {
      params = params.set(`$${key}`, order.toString());
    }

    if (pageable) {
      if (pageable.page) {
        params = params.set('page', pageable.page.toString());
      }
      if (pageable.limit) {
        params = params.set('limit', pageable.limit.toString());
      }
    }
    
    return params;
  }
}
