import * as tslib_1 from "tslib";
import { State, Action, Selector, Store } from '@ngxs/store';
import produce from 'immer';
import { Navigate } from '@ngxs/router-plugin';
import { HedwigService } from 'src/app/core/services/hedwig.service';
export class ChangeTypeFilter {
    constructor(type) {
        this.type = type;
    }
}
ChangeTypeFilter.type = '[Inbox] Change Type Filter';
export class CreateDMConversation {
    constructor(message) {
        this.message = message;
    }
}
CreateDMConversation.type = '[Inbox] Create DM Conversation';
export class CheckDMConversation {
    constructor(domain) {
        this.domain = domain;
    }
}
CheckDMConversation.type = '[Inbox] Check DM Conversation';
export class FetchMessages {
    constructor(channelId) {
        this.channelId = channelId;
    }
}
FetchMessages.type = '[Inbox] Fetch Messages';
export class FetchOlderMessages {
    constructor() { }
}
FetchOlderMessages.type = '[Inbox] Fetch Older Messages';
export class SendMessage {
    constructor(message) {
        this.message = message;
    }
}
SendMessage.type = '[Inbox] Send Message';
export class NewMessage {
    constructor(message) {
        this.message = message;
    }
}
NewMessage.type = '[Inbox] New Message';
export class MarkReadMessage {
    constructor() { }
}
MarkReadMessage.type = '[Inbox] Mark Read Message';
export class SetConversations {
    constructor(conversations) {
        this.conversations = conversations;
    }
}
SetConversations.type = '[Inbox] Set Conversations';
export class MarkAsSeen {
}
MarkAsSeen.type = '[Inbox] Mark Conversations as Seen';
export class FetchConversations {
    constructor() { }
}
FetchConversations.type = '[Inbox] Fetch Conversations';
export class NewConversation {
    constructor(conversation) {
        this.conversation = conversation;
    }
}
NewConversation.type = '[Inbox] New Conversation';
export class UpdateConversation {
    constructor(conversation) {
        this.conversation = conversation;
    }
}
UpdateConversation.type = '[Inbox] Update Conversation';
export class SelectConversation {
    constructor(conversationId) {
        this.conversationId = conversationId;
    }
}
SelectConversation.type = '[Inbox] Select Conversation';
export class SelectLatestConversation {
    constructor() { }
}
SelectLatestConversation.type = '[Inbox] Select Latest Conversation';
export class RedirectToInbox {
    constructor() { }
}
RedirectToInbox.type = '[Inbox] Redirect To Inbox';
export class ClearInboxState {
    constructor() { }
}
ClearInboxState.type = '[Inbox] Clear Inbox State';
let InboxState = class InboxState {
    constructor(hedwigService, store) {
        this.hedwigService = hedwigService;
        this.store = store;
    }
    ngxsOnInit({ patchState }) {
        const userDetails = this.store.selectSnapshot((state) => state.session.userDetails);
        patchState({ myDomain: userDetails ? userDetails.domain : null });
    }
    static filteredConversations(state) {
        const filter = (state.searchForm.model.term || '').toLocaleLowerCase();
        let conversations = state.conversations;
        switch ('dm') {
            case 'dm':
                conversations = conversations.filter(conversation => conversation.channel.type === 'dm');
            // tslint:disable-next-line:no-switch-case-fall-through
            default:
                break;
        }
        const filteredConversations = conversations.filter(conversation => {
            return conversation.channel.name.toLocaleLowerCase().indexOf(filter) >= 0;
        });
        return filteredConversations;
    }
    redirectToInbox(ctx) {
        setTimeout(_ => {
            const state = ctx.getState();
            const latestConversation = state.conversations[0];
            ctx.patchState({
                selectedConversationId: latestConversation.channelId,
                selectedConversation: latestConversation,
                typeFilter: 'dm'
            });
            ctx.dispatch([new Navigate(['inbox'])]);
        }, 1000);
    }
    fetchMessages(ctx, { channelId }) {
        ctx.patchState({ scrollToBottom: false });
        const state = ctx.getState();
        const lastMessageId = (state.selectedConversation.channel && state.selectedConversation.channel.lastMessage) ? state.selectedConversation.channel.lastMessage.id : 1;
        return this.hedwigService.fetchMessages(channelId, lastMessageId, 10)
            .subscribe(resp => {
            ctx.patchState({ messages: resp.result.reverse(), scrollToBottom: true });
            ctx.dispatch(new MarkReadMessage());
        });
    }
    fetchOlderMessages(ctx) {
        ctx.patchState({ fetchingOlderMessages: true });
        const state = ctx.getState();
        if (!state.messages || !state.messages.length)
            return;
        const lastMessageId = state.messages[0].id;
        return this.hedwigService.fetchMessages(state.selectedConversationId, lastMessageId, 10)
            .subscribe(resp => {
            const messages = produce(state.messages, messages => {
                return resp.result.filter(_ => _.id !== lastMessageId).reverse().concat(messages);
            });
            ctx.patchState({ messages: messages, fetchingOlderMessages: false });
        });
    }
    changeTypeFilter(ctx, { type }) {
        ctx.patchState({ typeFilter: 'dm', conversations: ctx.getState().conversations });
    }
    selectConversation(ctx, { conversationId }) {
        const selectedConversation = ctx.getState().conversations.find(_ => _.channelId === conversationId);
        ctx.patchState({ selectedConversationId: conversationId, selectedConversation, messages: [] });
        ctx.dispatch([new FetchMessages(conversationId)]);
    }
    sendMessage(ctx, { message }) {
        ctx.patchState({ newMessageInSelectedConversation: false });
        const state = ctx.getState();
        this.hedwigService.sendMessage(state.selectedConversationId, message)
            .subscribe(_ => {
            const messages = produce(state.messages, messages => {
                messages.push(_);
            });
            ctx.patchState({ messages, newMessageInSelectedConversation: true });
        });
    }
    newMessage(ctx, { message }) {
        ctx.patchState({ newMessageInSelectedConversation: false, scrollToBottom: false });
        const state = ctx.getState();
        let unseenMessages = true;
        if (message['channel'].id === state.selectedConversationId) {
            const messages = produce(state.messages, messages => {
                messages.push(message);
            });
            unseenMessages = false;
            ctx.patchState({ messages, newMessageInSelectedConversation: true, scrollToBottom: true });
            ctx.dispatch([new MarkReadMessage()]);
        }
        // if (!message.sender || (message.sender.domain !== this.store.selectSnapshot(state => state.session.userDetails.domain)))
        ctx.patchState({ latestReceivedMessage: message.id, unseenMessages });
        ctx.dispatch([new FetchConversations()]);
    }
    markReadMessage(ctx, {}) {
        const state = ctx.getState();
        const messageId = state.messages.length ? state.messages[state.messages.length - 1].id : 1;
        const messageTimestamp = state.messages.length ? state.messages[state.messages.length - 1].timestamp : 1;
        this.hedwigService.markReadMessage(state.selectedConversationId, messageId)
            .subscribe(_ => { });
        const conversations = produce(state.conversations, conversations => {
            let conversation = conversations.find(_ => _.channelId === state.selectedConversationId);
            conversation.unreadCount = 0;
        });
        const latestSeenMessage = state.latestSeenMessageTimestamp > messageTimestamp ? state.latestSeenMessage : messageId;
        const latestSeenMessageTimestamp = state.latestSeenMessageTimestamp > messageTimestamp ? state.latestSeenMessageTimestamp : messageTimestamp;
        ctx.patchState({ conversations, latestSeenMessage, latestSeenMessageTimestamp, unseenMessages: false });
    }
    setConversations(ctx, { conversations }) {
        const filteredConversations = conversations.filter(conversation => conversation.channel.type === 'dm');
        const lastConversation = filteredConversations[filteredConversations.length - 1];
        const latestSeenMessage = lastConversation.lastReadMessageId;
        const latestReceivedMessage = lastConversation.channel.lastMessage ? lastConversation.channel.lastMessage.id : 0;
        const latestReceivedMessageTimestamp = lastConversation.channel.lastMessage ? lastConversation.channel.lastMessage.timestamp : 0;
        const unseenMessages = !!lastConversation.unreadCount;
        ctx.patchState({ conversations: this.formatConversations(conversations), latestSeenMessage, latestReceivedMessage, latestReceivedMessageTimestamp, unseenMessages });
    }
    newConversation(ctx, { conversation }) {
        const state = ctx.getState();
        const conversations = produce(state.conversations, conversations => {
            conversations.unshift(conversation);
            return conversations;
        });
        ctx.patchState({ conversations });
        if (conversation.channel.lastMessage.sender !== this.store.selectSnapshot(state => state.session.userDetails.username))
            ctx.patchState({
                latestReceivedMessage: conversation.channel.lastMessage.id,
                latestReceivedMessageTimestamp: conversation.channel.lastMessage.timestamp,
                unseenMessages: true
            });
        ctx.dispatch([]);
    }
    updateConversation(ctx, { conversation }) {
        const state = ctx.getState();
        let updatedIndex = -1;
        const conversations = produce(state.conversations, conversations => {
            updatedIndex = conversations.findIndex(_ => _.channel.id === conversation.channelId);
            if (updatedIndex >= 0) {
                let updateConversation = conversation;
                if (state.selectedConversation.channel.id === conversation.channelId)
                    updateConversation = Object.assign({}, updateConversation, { unreadCount: 0 });
                conversations[updatedIndex] = updateConversation;
            }
            return conversations;
        });
        const patchObject = { conversations };
        if (updatedIndex >= 0 && (state.selectedConversation.channel.id === conversations[updatedIndex].channelId)) {
            patchObject.selectedConversation = patchObject.conversations[updatedIndex];
        }
        ctx.patchState(patchObject);
        ctx.dispatch([]);
    }
    selectLatestConversation(ctx) {
        const state = ctx.getState();
        const conversations = (state.conversations && state.conversations.filter(conversation => conversation.channel.type === 'dm'));
        const latestConversationId = state.selectedConversationId ? state.selectedConversationId : conversations.length ? conversations[0].channelId : undefined;
        if (latestConversationId)
            ctx.dispatch([new SelectConversation(latestConversationId)]);
    }
    fetchConversations(ctx) {
        const state = ctx.getState();
        // ctx.patchState({
        //     conversations: []
        // });
        return this.hedwigService.fetchChannels()
            .subscribe(conversations => {
            ctx.patchState({ conversations: this.formatConversations(conversations) });
        });
    }
    markAsSeen(ctx) {
        const state = ctx.getState();
        ctx.patchState({
            latestSeenMessage: state.latestReceivedMessage,
            latestSeenMessageTimestamp: state.latestReceivedMessageTimestamp,
            unseenMessages: false
        });
    }
    createDMConversation(ctx, { message }) {
        this.hedwigService.createDMChannel(ctx.getState().selectedConversation.recipient.name)
            .subscribe((conversation) => {
            ctx.patchState({
                selectedConversationId: conversation.channelId,
                selectedConversation: conversation,
                typeFilter: 'dm',
                messages: []
            });
            ctx.dispatch([new SendMessage(message), new FetchConversations(), new FetchMessages(conversation.channelId)]);
        });
    }
    checkDMConversation(ctx, { domain }) {
        this.hedwigService.checkDMChannel(domain)
            .subscribe((conversation) => {
            const formattedConversation = conversation;
            if (!conversation.channelId) {
                formattedConversation.channelId = 'new-dm';
                formattedConversation.channel = {
                    accounts: [{ name: ctx.getState().myDomain }, formattedConversation.recipient],
                    closed: false,
                    createdAt: null,
                    updatedAt: null,
                    context: {},
                    id: 'new-dm',
                    key: `__hedwig-dm-${ctx.getState().myDomain}-${formattedConversation.recipient.name}`,
                    lastMessage: undefined,
                    name: 'Direct Chat',
                    status: 'active',
                    type: 'dm'
                };
            }
            const conversations = produce(ctx.getState().conversations, (draft) => {
                draft.unshift(formattedConversation);
            });
            ctx.patchState({
                selectedConversationId: formattedConversation.channelId,
                selectedConversation: formattedConversation,
                typeFilter: 'dm',
                conversations,
                messages: []
            });
            const actions = [];
            if (conversation.channelId)
                actions.push(new FetchMessages(conversation.channelId));
            actions.push(new Navigate(['inbox']));
            ctx.dispatch(actions);
        });
    }
    clearInboxState(ctx) {
        const conversations = produce(ctx.getState().conversations, (draft) => {
            return draft.filter(_ => _.channelId !== 'new-dm');
        });
        ctx.patchState({
            selectedConversation: undefined,
            selectedConversationId: undefined,
            typeFilter: undefined,
            conversations,
            searchForm: { model: { term: undefined } }
        });
    }
    formatConversations(conversations) {
        return conversations.sort((a, b) => {
            return (a.channel && b.channel && (a.channel.updatedAt > b.channel.updatedAt)) ? -1
                : ((a.channel && b.channel && (a.channel.updatedAt < b.channel.updatedAt)) ? 1 : 0);
        });
    }
};
tslib_1.__decorate([
    Action(RedirectToInbox),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "redirectToInbox", null);
tslib_1.__decorate([
    Action(FetchMessages),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchMessages]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "fetchMessages", null);
tslib_1.__decorate([
    Action(FetchOlderMessages),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "fetchOlderMessages", null);
tslib_1.__decorate([
    Action(ChangeTypeFilter),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeTypeFilter]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "changeTypeFilter", null);
tslib_1.__decorate([
    Action(SelectConversation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectConversation]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "selectConversation", null);
tslib_1.__decorate([
    Action(SendMessage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SendMessage]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "sendMessage", null);
tslib_1.__decorate([
    Action(NewMessage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, NewMessage]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "newMessage", null);
tslib_1.__decorate([
    Action(MarkReadMessage),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, MarkReadMessage]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "markReadMessage", null);
tslib_1.__decorate([
    Action(SetConversations),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetConversations]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "setConversations", null);
tslib_1.__decorate([
    Action(NewConversation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, NewConversation]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "newConversation", null);
tslib_1.__decorate([
    Action(UpdateConversation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdateConversation]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "updateConversation", null);
tslib_1.__decorate([
    Action(SelectLatestConversation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "selectLatestConversation", null);
tslib_1.__decorate([
    Action(FetchConversations),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "fetchConversations", null);
tslib_1.__decorate([
    Action(MarkAsSeen),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "markAsSeen", null);
tslib_1.__decorate([
    Action(CreateDMConversation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CreateDMConversation]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "createDMConversation", null);
tslib_1.__decorate([
    Action(CheckDMConversation),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, CheckDMConversation]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "checkDMConversation", null);
tslib_1.__decorate([
    Action(ClearInboxState),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState.prototype, "clearInboxState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], InboxState, "filteredConversations", null);
InboxState = tslib_1.__decorate([
    State({
        name: 'inbox',
        defaults: {
            typeFilter: 'dm',
            myDomain: undefined,
            selectedConversationId: undefined,
            selectedConversation: undefined,
            newMessageInSelectedConversation: false,
            conversations: [],
            messages: [],
            scrollToBottom: false,
            fetchingOlderMessages: false,
            searchForm: { model: { term: undefined } },
            latestSeenMessage: undefined,
            latestSeenMessageTimestamp: undefined,
            latestReceivedMessage: undefined,
            latestReceivedMessageTimestamp: undefined,
            unseenMessages: false
        }
    }),
    tslib_1.__metadata("design:paramtypes", [HedwigService,
        Store])
], InboxState);
export { InboxState };
