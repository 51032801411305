import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LineItem } from '../../models/line-item.model';
import { ShippingGroup } from '../../models/shipping-group.model';
import { Address } from 'src/app/snatch/models/address.model';
import {Observable} from "rxjs";

@Component({
  selector: 'leap-shopping-cart-review-section',
  templateUrl: './shopping-cart-review-section.component.html',
  styleUrls: ['./shopping-cart-review-section.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartReviewSectionComponent implements OnInit {

  @Input() calculating$: Observable<boolean>;
  @Input() shippingBatches: ShippingGroup[];
  @Input() currency: string;
  @Input() defaultShippingAddress: Address;

  @Input() languagesMap: Map<string, string>;
  @Input() deliveryFormatsMap: Map<string, string>;
  @Input() certificateTypesMap: Map<string, string>;
  @Input() productTypesMap: Map<string, string>;
  @Input() currencySignsMap: Map<string, string>;
  @Input() countriesMap: Map<string, string>;

  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() quantityChange: EventEmitter<any> = new EventEmitter();
  @Output() changeMethod: EventEmitter<{ shippingGroupId: string, shippingMethodId: string }> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  removeLineItem(lineItem: LineItem) {
    this.remove.emit(lineItem);
  }

  quantityChangeLineItem(lineItem: LineItem) {
    this.quantityChange.emit(lineItem);
  }

  onMethodChange(shippingMethodId: string, shippingGroupId: string) {
    this.changeMethod.emit({ shippingGroupId, shippingMethodId })
  }
}
