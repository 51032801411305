import { State, StateContext, Action, Store } from '@ngxs/store';
import {DoGlobalSearch, ClearGlobalSearch, SetContext} from '../actions/global-filters.actions';
import { MiscService } from '../services/misc.service';
import { debounceTime, map, catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AmberResponse } from 'src/app/snatch/models/amber-response.model';
import { ProductVariantShort } from 'src/app/common/common-product/models/product-short.model';

export class GlobalFiltersStateModel {
  searchForm: { model: { term: string, type: string } };
	searchHits: { courses: SearchHit[]; instructors: SearchHit[]; products:GenericSearchHit[], topics: GenericSearchHit[], sellers: GenericSearchHit[]};
  lastQuery: string;
  globalSearchPending: boolean;
	context: string
}

export interface GenericSearchHit {
	key: string;
	value: string;
	isExactMatch: boolean | null;
	metadata: any;
}

export interface ProductHit {
  highlight: string;
  product: { sku: string, name: string, productVariants: ProductVariantShort[] }
}
export interface SearchHit {
	highlight: string;
	course: {
		id: string,
		code: string;
		name: string,
		hits: [],
		solarCourse: {
			format: {
				configValue: string
			}
		}
	}
	instructor: { id: string,
		displayName: string,
		accountDisplayName: string,
		image: {
			s3Key: string,
			s3Bucket: string
		},
		hits: [] }
	  product: {
	  sku: string, name: string, productVariants: ProductVariantShort[] }
	  attributes:any;
}
const defaults: GlobalFiltersStateModel = {
  searchForm: {
    model: {
      term: undefined,
	    type: 'products'
    }
  },
	searchHits: undefined,
  lastQuery: undefined,
	context:'products',
  globalSearchPending: false
}
@State<GlobalFiltersStateModel>({
  name: 'globalFilters',
  defaults
})
export class GlobalFiltersState {
  constructor(private miscService: MiscService, private store: Store) {}

  @Action(DoGlobalSearch)
  doGlobalSearch({ patchState, dispatch, getState }: StateContext<GlobalFiltersStateModel>, action: DoGlobalSearch) {
    let termExists = getState().searchForm.model && getState().searchForm.model.term;
    if (!termExists) {
	    patchState({ searchHits: undefined});
	    return undefined;
    }
	  patchState({ lastQuery: getState().searchForm.model.term, globalSearchPending: true });

    switch (action.context) {
      case 'products':
	      return this.miscService.doGlobalSearch(getState().searchForm.model.term)
	      .pipe(
	      catchError((httpError: HttpErrorResponse) => {
		      patchState({ globalSearchPending: false });
		      return dispatch([])
	      }),
	      map(response => response.data.results),
	      tap((results: any) => {
		      patchState({ searchHits: {...getState().searchHits,products:results.products, sellers: results.sellers, topics: results.subtopics}, globalSearchPending: false })
		      return dispatch([]);
	      })
	      );
      case 'courses':
	      return this.miscService.doGlobalSearchCourses(getState().searchForm.model.term)
	      .pipe(
	      catchError((httpError: HttpErrorResponse) => {
		      patchState({ globalSearchPending: false })
		      return dispatch([])
	      }),
	      map(response => response.data.results),
	      tap((results: any) => {
		      patchState({ searchHits: {...getState().searchHits,courses:results.courses}, globalSearchPending: false })
		      return dispatch([]);
	      })
	      );
      case 'instructors':
	      return this.miscService.doGlobalSearchInstructors(getState().searchForm.model.term)
	      .pipe(
	      catchError((httpError: HttpErrorResponse) => {
		      patchState({ globalSearchPending: false })
		      return dispatch([])
	      }),
	      map(response => response.data.results),
	      tap((results: any) => {
		      patchState({ searchHits: {...getState().searchHits,instructors:results.instructors}, globalSearchPending: false })
		      return dispatch([]);
	      })
	      );
      default:
	      return this.miscService.doGlobalSearch(getState().searchForm.model.term)
	      .pipe(
	      catchError((httpError: HttpErrorResponse) => {
		      patchState({ globalSearchPending: false })
		      return dispatch([])
	      }),
	      map(response => response.data.results),
	      tap((results: any) => {
		      patchState({ searchHits: {...getState().searchHits,products:results.productHits}, globalSearchPending: false })
		      return dispatch([]);
	      })
	      );
    }
  }

  @Action(ClearGlobalSearch)
  clearGlobalSearch({ patchState, dispatch, getState }: StateContext<GlobalFiltersStateModel>, action: ClearGlobalSearch) {
    patchState(defaults)
  }

	@Action(SetContext)
	SetContext({patchState, dispatch, getState}: StateContext<GlobalFiltersStateModel>, action: SetContext) {
		patchState({context: action.context})
	}


}
