<div nz-row nzType="flex" *ngFor="let data of shippingMethods">
  <span class="no-padding-right" style="width: 16px;">
    <nz-radio-group [(ngModel)]="selectedMethod" (ngModelChange)="onChangeMethod($event)">
      <label nz-radio [nzValue]="data.id"></label>
    </nz-radio-group>
  </span>
  <span class="title">{{ data.name }}</span>
  <span class="price">{{ currencySignsMap?.get(currency) }} {{ data.totalAmount | number : '1.2-2' }}</span>
  <span class="deliveryTime">(in {{ data.shipsWithin }}-{{ data.shipsWithin + data.arrivesWithin }} days)</span>
</div>
