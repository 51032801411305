import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { DictionaryItem } from '../models/dictionary.model';
import { AmberResponse } from '../models/amber-response.model';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getBulk(types: Array<string>): Observable<AmberResponse<BulkConfiguration>> {
    const url = `${this.envService.amberUrl}/virgo/configuration/bulk/read`;
    const params = types.reduce((p, type) => p.append('types', type), new HttpParams());
    return this.http.get<AmberResponse<BulkConfiguration>>(url, { params });
  }

}

export interface BulkConfiguration {
  [key: string]: Array<DictionaryItem>;
}
