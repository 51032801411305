import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'menuFilter'
})
export class MenuFilterPipe implements PipeTransform {

  transform(items: any[], filter: IMenuFilter): any {
    if (!items || !filter) {
      return items;
    }
    if ('parentTopic' in filter && 'parentCatalog' in filter) {
      return items.filter(i => i.parentTopic === filter.parentTopic && i.parentCatalog === filter.parentCatalog)
    }

    if ('parentCatalog' in filter) {
      return items.filter(i => i.parentCatalog === filter.parentCatalog)
    }


    return items;

  }

}

interface IMenuFilter {
  parentCatalog?: string;
  parentTopic?: string;
}
