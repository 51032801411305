import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { EnvironmentService } from "src/app/snatch/services";
import { AmberResponse } from "src/app/snatch/models/amber-response.model";
import { SimpleOption } from "../models/simple-option.interface";
import { ApprovalPolicy } from "../models/approval-policy.interface";
import {
  PublishBundleToLxpPayload,
  PublishToLxpPayload,
} from "../models/publish-to-lxp-payload.interface";
import { LxpProductDetails } from "../models/lxp-product.interface";

@Injectable({ providedIn: "root" })
export class LxpPublishingService {
  constructor(
    private environment: EnvironmentService,
    private http: HttpClient
  ) {}

  getChannels(
    channelName: string = null
  ): Observable<AmberResponse<SimpleOption[]>> {
    const url = `${this.environment.amberUrl}/agora/lxp-product/channels`;

    let params = new HttpParams();
    if (channelName) {
      params = params.set("filter", channelName);
    }

    return this.http.get<AmberResponse<any>>(url, { params: params });
  }

  getGroups(): Observable<AmberResponse<SimpleOption[]>> {
    return this.http.get<AmberResponse<SimpleOption[]>>(
      `${this.environment.amberUrl}/agora/lxp-product/groups`
    );
  }

  getTransactionTypes(): Observable<AmberResponse<SimpleOption[]>> {
    return this.http.get<AmberResponse<SimpleOption[]>>(
      `${this.environment.amberUrl}/agora/configuration/dict/filtered/transaction-type`
    );
  }

  getApprovalRules(
    ruleType: string
  ): Observable<AmberResponse<ApprovalPolicy[]>> {
    return this.http.get<AmberResponse<ApprovalPolicy[]>>(
      `${this.environment.amberUrl}/agora/buyer-approvals/approvalpolicy/list`,
      { params: { filter: ruleType } }
    );
  }

  publishToLXP(payload: PublishToLxpPayload[]): Observable<AmberResponse<any>> {
    return this.http.post<AmberResponse<any>>(
      `${this.environment.amberUrl}/agora/lxp-product/publish/bulkbysku`,
      payload
    );
  }

  publishBundleToLXP(
    payload: PublishBundleToLxpPayload
  ): Observable<AmberResponse<any>> {
    return this.http.post<AmberResponse<any>>(
      `${this.environment.amberUrl}/agora/lxp-bundle/publish`,
      payload
    );
  }

  editLxpProduct(payload: PublishToLxpPayload): Observable<AmberResponse<any>> {
    return this.http.post<AmberResponse<any>>(
      `${this.environment.amberUrl}/agora/lxp-product/update/by-sku`,
      payload
    );
  }

  getLxpProductDetails(
    masterSku: string
  ): Observable<AmberResponse<LxpProductDetails>> {
    return this.http.get<AmberResponse<any>>(
      `${this.environment.amberUrl}/agora/lxp-product/partner/sku/details`,
      {
        params: {
          sku: masterSku,
        },
      }
    );
  }
}
