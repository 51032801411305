import * as tslib_1 from "tslib";
import { State, Selector, Action } from '@ngxs/store';
import { ListenForSessionChange, NavigateToLogout, SessionCheckFailure, SessionCheckSuccess, SetSessionCookie } from '../actions/auth.actions';
import { GetEmployee } from 'src/app/core/actions/core.actions';
import { SessionService } from 'src/app/snatch/services/session.service';
import { StorageService } from 'src/app/snatch/services/storage.service';
import { EnvironmentService } from 'src/app/snatch/services';
import { OktaAuth } from '@okta/okta-auth-js';
import * as oktaHelpers from 'src/app/snatch/utils/okta-helpers';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
export class AuthStateModel {
}
export var AuthState;
(function (AuthState) {
    AuthState[AuthState["GUEST"] = 0] = "GUEST";
    AuthState[AuthState["LOGGED"] = 1] = "LOGGED";
    AuthState[AuthState["PROMPTED_FOR_RELOG"] = 2] = "PROMPTED_FOR_RELOG";
})(AuthState || (AuthState = {}));
let AuthTokenState = class AuthTokenState {
    constructor(sessionService, storageService, environment, _authClient) {
        this.sessionService = sessionService;
        this.storageService = storageService;
        this.environment = environment;
        this._authClient = _authClient;
        this.intervalSessionCheckInMs = 5000;
    }
    static isLogged({ state }) {
        return Boolean(state === AuthState.LOGGED || state === AuthState.PROMPTED_FOR_RELOG);
    }
    listenForSessionChange({ getState, dispatch }, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            timer(0, this.intervalSessionCheckInMs).pipe(map(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const skipRoute = window.location.pathname.startsWith('/login/callback');
                const oktaIsAuthenticated = yield this._authClient.isAuthenticated();
                const activeCookieSession = oktaHelpers.checkForActiveSession();
                if (!skipRoute) {
                    if (!oktaIsAuthenticated) {
                        // If cookie is present, redirect to okta
                        // If cookie is not present, but okta tokens exist, sign out
                        if (activeCookieSession) {
                            yield oktaHelpers.oktaSignInWithRedirect(this._authClient);
                        }
                        else if (getState().state === 1) { // In case the session is still set to 1, Navigate to Logout
                            dispatch([new NavigateToLogout()]);
                        }
                    }
                    else {
                        yield oktaHelpers.checkForSessionMismatch(this._authClient, this.storageService, this.sessionService);
                    }
                }
                if (oktaIsAuthenticated && !activeCookieSession) {
                    dispatch([new NavigateToLogout()]);
                }
                return;
            })))
                .subscribe();
        });
    }
    sessionCheckSuccess({ dispatch, patchState }, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const oktaUser = yield this.sessionService.getLoggedInUserDetails();
            patchState({
                state: AuthState.LOGGED,
                userDetails: {
                    username: oktaUser['mkpEmail'],
                    domain: oktaUser['mkpDomain'],
                    groups: oktaUser['mkpGroups']
                },
                groups: oktaUser['mkpGroups']
            });
            dispatch(new GetEmployee());
        });
    }
    setSessionCookie({}) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const oktaTokenStorage = this.storageService.get('okta-token-storage');
            const accessTokenData = oktaTokenStorage && oktaTokenStorage['accessToken'];
            const refreshToken = oktaTokenStorage && oktaTokenStorage['refreshToken'];
            const claims = accessTokenData && accessTokenData['claims'];
            const accessToken = accessTokenData && accessTokenData['accessToken'];
            const authTime = this.sessionService.decodeToken(accessToken).auth_time;
            const cookiePayload = {
                username: claims.mkpEmail,
                domain: claims.mkpDomain,
                authAt: authTime,
                expiresAt: refreshToken.expiresAt,
                initiator: window.location.origin
            };
            const cookieDomain = this.environment.getHost;
            this.setCookie("mkp-session", JSON.stringify(cookiePayload), cookieDomain, refreshToken.expiresAt * 1000);
        });
    }
    sessionCheckFailure({ dispatch, patchState, setState }, action) {
        setState({ state: AuthState.GUEST, groups: [] });
    }
    setCookie(cname, cvalue, domain, expiresAt) {
        const d = new Date();
        d.setTime(expiresAt);
        let expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/;domain=.${domain}`;
    }
    navigateToLogout(ctx) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cookieDomain = this.environment.getHost;
            document.cookie = `mkp-session=;expires=-999999;path=/;domain=.${cookieDomain}`;
            const baseUrl = new URL(window.location.href);
            const postLogoutRedirectUri = `${baseUrl.origin}/logout`;
            yield this._authClient.signOut({ postLogoutRedirectUri });
        });
    }
    checkIfLoggedOutFromAnotherApp(dispatch) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isAuthenticated = yield this._authClient.isAuthenticated();
            const activeSession = oktaHelpers.checkForActiveSession();
            if (isAuthenticated && !activeSession) {
                dispatch([new NavigateToLogout()]);
            }
        });
    }
    checkForSessionMismatch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield oktaHelpers.checkForSessionMismatch(this._authClient, this.storageService, this.sessionService);
        });
    }
};
tslib_1.__decorate([
    Action(ListenForSessionChange),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ListenForSessionChange]),
    tslib_1.__metadata("design:returntype", Promise)
], AuthTokenState.prototype, "listenForSessionChange", null);
tslib_1.__decorate([
    Action(SessionCheckSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SessionCheckSuccess]),
    tslib_1.__metadata("design:returntype", Promise)
], AuthTokenState.prototype, "sessionCheckSuccess", null);
tslib_1.__decorate([
    Action(SetSessionCookie),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Promise)
], AuthTokenState.prototype, "setSessionCookie", null);
tslib_1.__decorate([
    Action(SessionCheckFailure),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SessionCheckFailure]),
    tslib_1.__metadata("design:returntype", void 0)
], AuthTokenState.prototype, "sessionCheckFailure", null);
tslib_1.__decorate([
    Action(NavigateToLogout),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Promise)
], AuthTokenState.prototype, "navigateToLogout", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [AuthStateModel]),
    tslib_1.__metadata("design:returntype", Boolean)
], AuthTokenState, "isLogged", null);
AuthTokenState = tslib_1.__decorate([
    State({
        name: 'session',
        defaults: {
            state: null,
            userDetails: undefined,
            groups: []
        }
    }),
    tslib_1.__metadata("design:paramtypes", [SessionService,
        StorageService,
        EnvironmentService,
        OktaAuth])
], AuthTokenState);
export { AuthTokenState };
