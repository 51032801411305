import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { DeferredResource } from '../../services/amber-rest.service';
import { AmberErrorPipe } from '../../pipes/amber-error.pipe';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'leap-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorAlertComponent implements OnInit {

  showError = false;
  message: string;

  errorPipe = new AmberErrorPipe();

  @Input()
  set source(value: DeferredResource<any> | HttpErrorResponse | string) {
    if (value instanceof DeferredResource) {
      if (value.error) {
        this.showError = true;
        this.message = this.errorPipe.transform(value.error);
      } else {
        this.showError = false;
        this.message = null;
      }
    } else if (value instanceof HttpErrorResponse) {
      this.showError = true;
      this.message = this.errorPipe.transform(value);
    } else if (typeof value === 'string') {
      this.showError = true;
      this.message = value;
    } else {
      this.showError = false;
      this.message = null;
    }

    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }
}
