import { AfterContentInit, Component, ContentChild, Input, OnInit } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'leap-form-control-limit',
  templateUrl: './form-control-limit.component.html',
  styleUrls: ['./form-control-limit.component.less']
})
export class FormControlLimitComponent implements OnInit, AfterContentInit {
  @Input()
  maxLength = 120;
  length = 0;

  @ContentChild(FormControlName, /* TODO: add static flag */ { static: true })
  textarea: FormControlName;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.textarea) {
      this.textarea.control.valueChanges.pipe(startWith(this.textarea.control.value)).subscribe(v => {
        this.length = v ? v.length : 0;
      });
    } else {
      console.warn('leap-form-control-limit expects a child with a formControlName');
    }
  }

  get remainingLength(): number {
    return Math.max(0, this.maxLength - this.length);
  }
}
