import {ISupplier} from 'src/app/common/common-supplier/models/my-suppliers.model';

export class ToggleSort {
	static readonly type = '[Suppliers List] Toggle Sort';
	constructor(public readonly key: string) {}
}
export class FetchMySuppliers {
	static readonly type = '[Suppliers List] Fetch all suppliers from Odyssey';

	constructor(public readonly order: { filters: { key: string, order: string }[], search: string, dates: { from: string, to: string } },public sort:boolean) {
	}
}
export class FetchMySuppliersSuccess {
	static readonly type = '[Suppliers List] Fetch success';
	constructor(public payload: { suppliers: ISupplier[], count: number }) {}
}
export class FetchMySupplierDetails{
	static readonly type = '[Supplier Details] Getting supplier details';
	constructor(public readonly id: string, public readonly domain: string) {}
}
export class FetchSupplierOrderList {
	static readonly type = '[Supplier Details] Getting supplier order list';
	constructor(public order: { filters: { key: string, order: string }[], search: string, dates: { from: string, to: string } },public sort:boolean) {}
}
export class GetSupplierProducts {
	static readonly type = '[Supplier Details] Getting supplier products';
	constructor(public domain: string) {}
}
export class ClearSelectedSupplier {
	static readonly type = '[Supplier Details] Clear Selected Supplier';
	constructor() {}
}
export class SupplierProductChangePage {
	static readonly type = '[Supplier Details] Change Page product list';
	constructor(public readonly payload: number) {}
}

export class ChangeSupplierPage {
	static readonly type = '[Supplier List] Change Page suppliers list';
	constructor(public readonly payload: number,public order: { filters: { key: string, order: string }[], search: string, dates: { from: string, to: string } }) {}
}
export class ChangeSupplierOrdersPage {
	static readonly type = '[Supplier List] Change Page suppliers list orders';
	constructor(public readonly payload: number,public  order: { filters: { key: string, order: string }[], search: string, dates: { from: string, to: string } }) {}
}
export class ChangeSupplierContractsPage {
	static readonly type = '[Supplier List] Change Page suppliers list contracts';
	constructor(public readonly payload: number,public order: { filters: { key: string, order: string }[], search: string, dates: { from: string, to: string } }) {}
}
export class FetchSupplierContractsList {
	static readonly type = '[Supplier Details] Getting supplier contracts list';
	constructor(public order: { filters: { key: string, order: string }[], search: string, dates: { from: string, to: string } },public sort:boolean) {}
}

export class RecordTransaction {
	static readonly type = '[Supplier Details] Record Transaction';
	constructor(public payload: any) {}
}

export class FetchSelectedSupplierPOs {
	static readonly type = '[Supplier Details] Fetch Selected Supplier POs';
	constructor() {}
}

export class FetchVenues {
	static readonly type = '[Supplier Details] Fetch Venues';
	constructor() {}
}

export class FetchCustomers {
	static readonly type = '[Supplier Details] Fetch Customers';
	constructor(public domain: string) {}
}
