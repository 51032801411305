<div class="row" [ngClass]="orientation">
    <div class="message-container">
        <div class="top-container">
            <div style="display: flex;">
                <div style="display: flex;">
                    <ng-container *ngIf="imageUrl; else topImagePlaceholder">
                        <div class="image-container">
                            <img style="margin-top: -6px;" class="image" alt="Image"
                                [src]="imageUrl">
                        </div>
                    </ng-container>
                    <ng-template #topImagePlaceholder>
                        <div style="margin-top: 1px;" class="image-placeholder"></div>
                    </ng-template>
                    <h5>{{ sender }}</h5>
                </div>
            </div>
            <span class="timestamp">{{ timestamp | date: 'dd/MM/yyyy hh:mm a' }}</span>
        </div>
        <div class="content-container">
            <ng-content select="[message]"></ng-content>
            <ng-content select="[actions]"></ng-content>
            <ng-content select="[bottom]"></ng-content>
        </div>
    </div>
    <ng-container *ngIf="imageUrl; else imagePlaceholder">
        <div class="image-container big" [ngClass]="orientation">
            <img class="image big" alt="Image"
                [src]="imageUrl">
        </div>
    </ng-container>
    <ng-template #imagePlaceholder>
        <div class="image-placeholder big" [ngClass]="orientation"></div>
    </ng-template>
</div>