import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { RouterStateModel } from '@ngxs/router-plugin';
import { BehaviorSubject, Observable } from 'rxjs';
import { omit } from 'lodash';

import { queryParamStringToObject } from '../utils/query-params.utils';

export interface ParamsCache {
    [path: string]: Params;
}

@Injectable({ providedIn: 'root' })
export class QueryParametersCacheService {

    queryParamsCache$: Observable<ParamsCache>;

    get cacheCurrentValue(): ParamsCache {
        return this._queryParamsCache$.value;
    }

    private _queryParamsCache$: BehaviorSubject<ParamsCache> = new BehaviorSubject({});

    constructor(
        private store: Store,
    ) {
        this.queryParamsCache$ = this._queryParamsCache$.asObservable();
    }

    init() {

        this.store.select((state) => state.router)
            .subscribe((route: RouterStateModel<RouterStateSnapshot>) => {
                if (!route.state) { return; }

                const [path, queryString] = route.state.url.split('?');
                const queryParams: Params = queryParamStringToObject(queryString);
                const previousValue: ParamsCache = this._queryParamsCache$.value;

                if (path in previousValue) {
                    if (queryString) {
                        this.setPath(path, queryParams);
                    } else {
                        this.removePath(path);
                    }
                } else {
                    if (queryString) {
                        this.setPath(path, queryParams);
                    }
                }
            });
    }

    private setPath(path: string, queryParams: Params = {}) {
        const previousValue: ParamsCache = this._queryParamsCache$.value;
        this._queryParamsCache$.next({
            ...previousValue,
            [path]: queryParams,
        });
    }

    private removePath(path: string) {
        const previousValue: ParamsCache = this._queryParamsCache$.value;
        const newValue = omit(previousValue, path);
        this._queryParamsCache$.next(newValue);
    }
}

