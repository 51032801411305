<div style="max-width: 510px;">
  <div class="icon-container">
    <i nz-icon type="stop"></i>
  </div>
  <p style="margin-bottom: 20px;">Sorry, we couldn't find any results matching {{ appliedFilters }}</p>
  <h4 style="margin-bottom: 0">Search Tips:</h4>
  <ul style="padding-left: 20px;">
    <li>Check your spelling and try again</li>
    <li>Try using different, yet still related terms</li>
    <li>Try using more general terms</li>
    <li>Try adjusting your filters for a more simple and broader search</li>
  </ul>
</div>