import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CloseShoppingCartDrawer, QuantityChangeLineItemInShoppingCart, RemoveLineItemFromShoppingCart } from '../../actions/shopping-cart.actions';
import { LineItem } from '../../models/line-item.model';
import {ActivatedRoute, Router} from '@angular/router';
import { combineLatest, Observable, merge } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AmberError } from 'src/app/snatch/models/amber-response.model';
import {GoToDetails} from "../../../checkout/actions/checkout.actions";

@Component({
  selector: 'leap-shopping-cart-drawer',
  templateUrl: './shopping-cart-drawer.component.html',
  styleUrls: ['./shopping-cart-drawer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartDrawerComponent implements OnInit {

  @Select(state => state.shoppingCart.drawerVisible)
  drawerVisible$: Observable<boolean>;

  @Select(state => state.shoppingCart.lineItems)
  lineItems$: Observable<LineItem[]>;

  @Select(state => state.shoppingCart.grossTotal)
  grossTotal$: Observable<number>;
  @Select(state => state.shoppingCart.taxTotal)
  taxTotal$: Observable<number>;
  @Select(state => state.shoppingCart.discountTotal)
  discountTotal$: Observable<number>;

  @Select(state => state.shoppingCart.currency)
  currency$: Observable<number>;

  @Select((state: any) => state.core.dictionaries.languagesMap)
  languagesMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.deliveryFormatsMap)
  deliveryFormatsMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.certificateTypesMap)
  certificateTypesMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.productTypesMap)
  productTypesMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.currencySignsMap)
  currencySignsMap$: Observable<Map<string, string>>;

  @Select((state: any) => state.shoppingCart.addLineItemPending)
  addLineItemPending$: Observable<boolean>;
  @Select((state: any) => state.shoppingCart.addLineItemError)
  addLineItemError$: Observable<AmberError>;
  @Select((state: any) => state.shoppingCart.removeLineItemPending)
  removeLineItemPending$: Observable<boolean>;
  @Select((state: any) => state.shoppingCart.removeLineItemError)
  removeLineItemError$: Observable<AmberError>;
  @Select((state: any) => state.shoppingCart.quantityChangeLineItemPending)
  quantityChangeLineItemPending$: Observable<boolean>;
  @Select((state: any) => state.shoppingCart.quantityChangeLineItemError)
  quantityChangeLineItemError$: Observable<AmberError>;
  @Select((state: any) => state.shoppingCart.addPromotionCodePending)
  addPromotionCodePending$: Observable<boolean>;
  @Select((state: any) => state.shoppingCart.addPromotionCodeError)
  addPromotionCodeError$: Observable<AmberError>;
  @Select((state: any) => state.shoppingCart.removePromotionCodePending)
  removePromotionCodePending$: Observable<boolean>;
  @Select((state: any) => state.shoppingCart.removePromotionCodeError)
  removePromotionCodeError$: Observable<AmberError>;

  calculating$: Observable<boolean>;
  error$: Observable<AmberError>;

  constructor(private store: Store, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.calculating$ = combineLatest(
      this.addLineItemPending$,
      this.removeLineItemPending$,
      this.quantityChangeLineItemPending$,
      this.addPromotionCodePending$,
      this.removePromotionCodePending$
    )
    .pipe(map(([a, b, c, d, e]: any) => a || b || c || d || e))

    this.error$ = merge(
      this.addLineItemError$,
      this.removeLineItemError$,
      this.quantityChangeLineItemError$,
      this.addPromotionCodeError$,
      this.removePromotionCodeError$
    ).pipe(map(_ => {
      return _;
    }))
  }

  closeShoppingCartDrawer() {
    this.store.dispatch(new CloseShoppingCartDrawer())
  }

  removeLineItem(lineItem: LineItem, index: number) {
    this.store.dispatch(new RemoveLineItemFromShoppingCart(lineItem));
  }

  quantityChangeLineItem(lineItem: LineItem, index: number) {
    this.store.dispatch(new QuantityChangeLineItemInShoppingCart(lineItem));
  }

  trackByFn(index, item) {
    return item.id
  }

  viewCart() {
    this.closeShoppingCartDrawer();
    this.router.navigate(['shopping-cart']);
  }

  proceedToCheckout() {
    this.closeShoppingCartDrawer();
    this.router.navigate(['checkout']).then(_ => {
      this.store.dispatch(new GoToDetails());
    });
  }

  goBackToProducts() {
    this.closeShoppingCartDrawer();
    this.router.navigate(['products']);
  }

}
