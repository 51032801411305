import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'leap-hide-restricted-checkbox',
  templateUrl: './hide-restricted-checkbox.component.html',
  styleUrls: ['./hide-restricted-checkbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HideRestrictedCheckboxComponent implements OnInit {

  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
