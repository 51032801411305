import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { omit } from 'lodash';
import { queryParamStringToObject } from '../utils/query-params.utils';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class QueryParametersCacheService {
    constructor(store) {
        this.store = store;
        this._queryParamsCache$ = new BehaviorSubject({});
        this.queryParamsCache$ = this._queryParamsCache$.asObservable();
    }
    get cacheCurrentValue() {
        return this._queryParamsCache$.value;
    }
    init() {
        this.store.select((state) => state.router)
            .subscribe((route) => {
            if (!route.state) {
                return;
            }
            const [path, queryString] = route.state.url.split('?');
            const queryParams = queryParamStringToObject(queryString);
            const previousValue = this._queryParamsCache$.value;
            if (path in previousValue) {
                if (queryString) {
                    this.setPath(path, queryParams);
                }
                else {
                    this.removePath(path);
                }
            }
            else {
                if (queryString) {
                    this.setPath(path, queryParams);
                }
            }
        });
    }
    setPath(path, queryParams = {}) {
        const previousValue = this._queryParamsCache$.value;
        this._queryParamsCache$.next(Object.assign({}, previousValue, { [path]: queryParams }));
    }
    removePath(path) {
        const previousValue = this._queryParamsCache$.value;
        const newValue = omit(previousValue, path);
        this._queryParamsCache$.next(newValue);
    }
}
QueryParametersCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryParametersCacheService_Factory() { return new QueryParametersCacheService(i0.ɵɵinject(i1.Store)); }, token: QueryParametersCacheService, providedIn: "root" });
