import { Component, OnInit, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "[leap-multi-table-item]",
  templateUrl: "./multi-table-item.component.html",
  styleUrls: ["./multi-table-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiTableItemComponent implements OnInit {
  @Input() displayingColumns: string[];
  @Input() item: any;

  constructor() {
  }

  ngOnInit() {
  }

}
