import gql from 'graphql-tag';

export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on Address {
    id,
    isCompany,
    streetName,
    city,
    zipCode,
    houseNumber,
    country,
    state,
    province,
    email,
    firstName,
    lastName,
    companyName,
    phoneNumbers
    makeDefaultType
  }
`;
