var NotificationsState_1;
import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { FetchNotifications, NewNotification, SetNotifications, ChangeNotificationsStatus } from '../actions/notifications.actions';
import { HedwigService } from '../services/hedwig.service';
const defaults = {
    notifications: [],
};
let NotificationsState = NotificationsState_1 = class NotificationsState {
    constructor(hedwigService) {
        this.hedwigService = hedwigService;
    }
    static archivedNotifications(state) {
        return state.notifications.filter((n) => n.isArchived);
    }
    static notArchivedNotifications(state) {
        return state.notifications.filter((n) => !n.isArchived);
    }
    static notReadNotifications(_, notArchivedNotifications) {
        return notArchivedNotifications.filter((n) => n.isNew);
    }
    fetchNotifications({ patchState }) {
        this.hedwigService.fetchNotifications(1, 0, null).subscribe((res) => {
            patchState({ notifications: res.data });
        });
    }
    changeNotificationsStatus({ patchState, getState }, action) {
        this.hedwigService.changeNotificationStatus(action.payload).subscribe(() => {
            const prevState = getState();
            const notifications = prevState.notifications.map((n) => {
                return action.payload.notificationsIds.includes(n.id)
                    ? Object.assign({}, n, { isNew: action.payload.isNew !== undefined ? action.payload.isNew : n.isNew, isArchived: action.payload.isArchived !== undefined ? action.payload.isArchived : n.isArchived }) : n;
            });
            patchState({ notifications });
        });
    }
    newNotificationGotten({ patchState, getState }, action) {
        const prevState = getState();
        patchState({ notifications: [action.payload, ...prevState.notifications], });
    }
    setNotifications({ patchState }, action) {
        patchState({ notifications: action.payload });
    }
};
tslib_1.__decorate([
    Action(FetchNotifications),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], NotificationsState.prototype, "fetchNotifications", null);
tslib_1.__decorate([
    Action(ChangeNotificationsStatus),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ChangeNotificationsStatus]),
    tslib_1.__metadata("design:returntype", void 0)
], NotificationsState.prototype, "changeNotificationsStatus", null);
tslib_1.__decorate([
    Action(NewNotification),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, NewNotification]),
    tslib_1.__metadata("design:returntype", void 0)
], NotificationsState.prototype, "newNotificationGotten", null);
tslib_1.__decorate([
    Action(SetNotifications),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SetNotifications]),
    tslib_1.__metadata("design:returntype", void 0)
], NotificationsState.prototype, "setNotifications", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Array)
], NotificationsState, "archivedNotifications", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Array)
], NotificationsState, "notArchivedNotifications", null);
tslib_1.__decorate([
    Selector([NotificationsState_1.notArchivedNotifications]),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Array]),
    tslib_1.__metadata("design:returntype", Array)
], NotificationsState, "notReadNotifications", null);
NotificationsState = NotificationsState_1 = tslib_1.__decorate([
    State({
        name: 'notifications',
        defaults,
    }),
    tslib_1.__metadata("design:paramtypes", [HedwigService])
], NotificationsState);
export { NotificationsState };
