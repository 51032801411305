<div class="address-delete-modal-container">
    <div nz-row>
        <div nz-col [nzMd]="24">
            If you delete this address, you will need to provide a new one.<br>
            This action cannot be undone.<br>
            How do you wish to proceed?
        </div>
        <div nz-col [nzMd]="24" class="address-delete-error">
            <leap-error-alert [source]="error?.message"></leap-error-alert>
        </div>
    </div>
    <div class="address-delete-modal-footer">
        <div nz-row>
            <button nz-button nzType="default" (click)="closeModal()" style="margin-right: 15px;">Cancel</button>
            <button nz-button nzType="danger" [nzLoading]="pending" [disabled]="pending" (click)="deleteAddressFn()">Delete</button>
        </div>
    </div>
</div>


