import { Component, OnInit, ChangeDetectionStrategy, Input } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { ISearchParams } from "../../../../products/state/products.state";
import { DictionaryItem } from "../../../../snatch/models/dictionary.model";

@Component({
  selector: 'leap-product-no-results',
  templateUrl: './product-no-results.component.html',
  styleUrls: ['./product-no-results.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductNoResultsComponent implements OnInit {

  @Input()
  searchForm$: Observable<ISearchParams>;
  @Input()
  filters$: Observable<{ [key in keyof ISearchParams]: Array<DictionaryItem> }>;
  appliedFilters: string;
  constructor() { }

  ngOnInit() {
    combineLatest(this.searchForm$, this.filters$)
      .subscribe(
        ([searchFormObj, filters]) => {
          let searchForm = searchFormObj['model'];
          let result = '';

          if(searchForm.filter) result += `\"${searchForm.filter}\" + `;
          this.appliedFilters = result.length ? result.substring(0, result.length - 3) : result;
        }
      )
  }

}
