import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'leap-product-card-short',
  templateUrl: './product-card-short.component.html',
  styleUrls: ['./product-card-short.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardShortComponent implements OnInit {

  @Input() title: string;
  @Input() thumbnailUrl: string;
  @Input() rating: number;
  @Input() sellerName: string;
  @Input() sellerLogo: string;

  constructor() { }

  ngOnInit() {
  }

}
