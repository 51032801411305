import { NotificationDto, CnangeNotificationsStatusPayload } from '../models/hedwig-models';

export class NewNotification {
  static readonly type = '[Notifications] New Notification Gotten';
  constructor(public payload: NotificationDto) { }
}

export class FetchNotifications {
  static readonly type = '[Notifications] Fetch Notifications';
  constructor() { }
}

export class ChangeNotificationsStatus {
  static readonly type = '[Notifications] Change Notifications Status';
  constructor(public payload: CnangeNotificationsStatusPayload) { }
}

export class SetNotifications {
  static readonly type = '[Notifications] Set Notifications';
  constructor(public payload: NotificationDto[]) { }
}