import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LineItem } from '../../models/line-item.model';
import {
  RemoveLineItemFromShoppingCart,
  QuantityChangeLineItemInShoppingCart,
  AddPromotionCode,
  RemovePromotionCode,
  FetchPromotionCodes,
  EditShippingMethod,
} from '../../actions/shopping-cart.actions';
import { PromotionCode } from 'src/app/common/common-shopping-cart/models/promotion-code.model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShippingGroup } from '../../models/shipping-group.model';
import { Address } from 'cluster';
import { AmberError } from "../../../snatch/models/amber-response.model";
import { Router } from "@angular/router";
import { BREADCRUMBS_CONFIGURATION } from 'src/app/core/configurations/breadcrumbs.config';
import { GoToDetails } from "../../../checkout/actions/checkout.actions";
import { IBreadcrumb } from 'src/app/core/models/breadcrumb.interface';
import { BreadcrumbsBuildingService } from 'src/app/core/services/breadcrumbs-building.service';

@Component({
  selector: 'leap-shopping-cart-page',
  templateUrl: './shopping-cart-page.component.html',
  styleUrls: ['./shopping-cart-page.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartPageComponent implements OnInit {

  @Select(state => state.shoppingCart.shippingBatches)
  shippingBatches$: Observable<ShippingGroup[]>;

  @Select(state => state.shoppingCart.defaultShippingAddress)
  defaultShippingAddress$: Observable<Address[]>;

  @Select((state: any) => state.shoppingCart.appliedPromotionCodes)
  appliedPromotionCodes$: Observable<PromotionCode[]>;
  @Select((state: any) => state.shoppingCart.invalidPromotionCodes)
  invalidPromotionCodes$: Observable<PromotionCode[]>;
  @Select((state: any) => state.shoppingCart.promotionCodes)
  promotionCodes$: Observable<PromotionCode[]>;

  @Select(state => state.shoppingCart.editShippingMethodPending)
  editShippingMethodPending$: Observable<boolean>;
  @Select(state => state.shoppingCart.addPromotionCodePending)
  addPromotionCodePending$: Observable<boolean>;
  @Select(state => state.shoppingCart.addPromotionCodeError)
  addPromotionCodeError$: Observable<AmberError>;
  @Select(state => state.shoppingCart.removePromotionCodePending)
  removePromotionCodePending$: Observable<boolean>;
  @Select(state => state.shoppingCart.removePromotionCodeError)
  removePromotionCodeError$: Observable<AmberError>;


  @Select(state => state.shoppingCart.quantityChangeLineItemPending)
  quantityChangeLineItemPending$: Observable<boolean>;

  @Select(state => state.shoppingCart.netTotal)
  netTotal$: Observable<number>;
  @Select(state => state.shoppingCart.grossTotal)
  grossTotal$: Observable<number>;
  @Select(state => state.shoppingCart.taxTotal)
  taxTotal$: Observable<number>;
  @Select(state => state.shoppingCart.surchargeTotal)
  surchargeTotal$: Observable<number>;
  @Select(state => state.shoppingCart.shippingTotal)
  shippingTotal$: Observable<number>;
  @Select(state => state.shoppingCart.actualNetTotal)
  actualNetTotal$: Observable<number>;
  @Select(state => state.shoppingCart.promotionPricingDiscountTotal)
  promotionPricingDiscountTotal$: Observable<number>;
  @Select(state => state.shoppingCart.currency)
  currency$: Observable<number>;

  @Select((state: any) => state.core.dictionaries.languagesMap)
  languagesMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.deliveryFormatsMap)
  deliveryFormatsMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.certificateTypesMap)
  certificateTypesMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.productTypesMap)
  productTypesMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.currencySignsMap)
  currencySignsMap$: Observable<Map<string, string>>;
  @Select((state: any) => state.core.dictionaries.countriesMap)
  countriesMap$: Observable<Map<string, string>>;

  calculating$: Observable<boolean>;

  breadcrumbs: IBreadcrumb[] = this.breadcrumbsBuildingService.buildBreadcrumbsWithQueryParams(
    [BREADCRUMBS_CONFIGURATION.Products, BREADCRUMBS_CONFIGURATION.ShoppingCart]
  );

  constructor(
    private store: Store,
    private router: Router,
    private breadcrumbsBuildingService: BreadcrumbsBuildingService,
  ) { }

  ngOnInit() {
    this.store.dispatch(new FetchPromotionCodes());

    this.calculating$ = combineLatest(
      this.quantityChangeLineItemPending$,
      this.editShippingMethodPending$,
      this.addPromotionCodePending$,
      this.removePromotionCodePending$)
      .pipe(
        map(([a, b, c]: any) => (a || b || c))
      )
  }

  removeLineItem(lineItem: LineItem) {
    this.store.dispatch(new RemoveLineItemFromShoppingCart(lineItem));
  }

  quantityChangeLineItem(lineItem: LineItem) {
    this.store.dispatch(new QuantityChangeLineItemInShoppingCart(lineItem));
  }

  changeMethod(payload: { shippingGroupId: string, shippingMethodId: string }) {
    this.store.dispatch(new EditShippingMethod(payload.shippingGroupId, payload.shippingMethodId))
  }

  addPromotionCode(promotionCode: string) {
    this.store.dispatch(new AddPromotionCode(promotionCode));
  }

  removePromotionCode(promotionCode: string) {
    this.store.dispatch(new RemovePromotionCode(promotionCode));
  }

  proceedToCheckout() {
    // this.router.navigate(['checkout']);
    this.router.navigate(['checkout']).then(_ => {
      this.store.dispatch(new GoToDetails());
    });
  }

  goBackToProducts() {
    this.router.navigate(['products']);
  }
}
