import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EnvironmentService } from 'src/app/snatch/services';
import { Select, Store } from '@ngxs/store';
import { AuthTokenState } from 'src/app/auth/state/auth-token.state';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
const ɵ0 = (state) => state.session.groups, ɵ1 = (state) => state.domain.domainConfiguration;
export class FooterComponent {
    constructor(environment, store, router) {
        this.environment = environment;
        this.store = store;
        this.router = router;
    }
    ngOnInit() {
        this.domainConfiguration$.subscribe(data => {
            if (data && data.logo) {
                this.footerLogo = `${this.environment.s3Url}/${this.environment.cdnBucket}${data.logo.light.url}`;
            }
            else {
                this.footerLogo = `${this.environment.s3Url}/${this.environment.cdnBucket}/images/ed-marketplace-logo-white.png`;
            }
        });
        this.year = new Date().getFullYear();
        this.instructorMarketUrl = `${this.environment.instructorMarketUrl}`;
        this.saharaUrl = `${this.environment.saharaUrl}`;
    }
    login() {
        this.router.navigate(['login']);
    }
}
tslib_1.__decorate([
    Select(AuthTokenState.isLogged),
    tslib_1.__metadata("design:type", Observable)
], FooterComponent.prototype, "isLogged$", void 0);
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], FooterComponent.prototype, "groups$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], FooterComponent.prototype, "domainConfiguration$", void 0);
export { ɵ0, ɵ1 };
