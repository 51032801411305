import * as tslib_1 from "tslib";
import { Action, State, Store } from '@ngxs/store';
import { OpenShoppingCartDrawer, CloseShoppingCartDrawer, QuantityChangeLineItemInShoppingCart, RemoveLineItemFromShoppingCart, AddLineItemToShoppingCart, GetShoppingCartDetails, EmptyCartAfterPayment, FetchPromotionCodes, RemovePromotionCode, AddPromotionCode, AddPromotionCodeFailure, AddPromotionCodeSuccess, RemovePromotionCodeSuccess, RemovePromotionCodeFailure, EditShippingMethod, CleanErrors, CleanShippingBatches, UpdatePurchaseOrderIdOnItem, FetchAvailablePurchaseOrders, AddReferenceId } from "../actions/shopping-cart.actions";
import { ShoppingCartService } from '../services/shopping-cart.service';
import { map, tap, catchError, filter } from 'rxjs/operators';
import { UtilsService } from 'src/app/snatch/services/utils.service';
import { AuthTokenState } from 'src/app/auth/state/auth-token.state';
import { HelpersService } from 'src/app/core/services/helpers.service';
export class ShoppingCartStateModel {
}
const defaults = {
    drawerVisible: false,
    shippingGroups: [],
    shippingBatches: [],
    lineItems: [],
    netTotal: 0,
    grossTotal: 0,
    taxTotal: 0,
    discountTotal: 0,
    surchargeTotal: 0,
    shippingTotal: 0,
    currency: undefined,
    defaultShippingAddress: undefined,
    appliedPromotionCodes: [],
    invalidPromotionCodes: [],
    promotionCodes: [],
    referenceId: undefined,
    addLineItemSku: undefined,
    addLineItemPending: false,
    addLineItemSuccess: false,
    addLineItemError: undefined,
    removeLineItemPending: false,
    removeLineItemSuccess: false,
    removeLineItemError: undefined,
    quantityChangeLineItemPending: false,
    quantityChangeLineItemSuccess: false,
    quantityChangeLineItemError: undefined,
    editShippingMethodPending: false,
    editShippingMethodSuccess: false,
    editShippingMethodError: undefined,
    addPromotionCodePending: false,
    addPromotionCodeSuccess: false,
    addPromotionCodeError: undefined,
    removePromotionCodePending: false,
    removePromotionCodeSuccess: false,
    removePromotionCodeError: undefined,
    availablePurchaseOrdersPerSeller: [],
    availablePurchaseOrdersPerSellerPending: false,
    availablePurchaseOrdersPerSellerSuccess: false,
    availablePurchaseOrdersPerSellerError: undefined,
    updatePurchaseOrdersPerItem: undefined,
    updatePurchaseOrdersPerItemPending: false,
    updatePurchaseOrdersPerItemSuccess: false,
    updatePurchaseOrdersPerItemFailed: false,
    updatePurchaseOrdersPerItemError: undefined
};
let ShoppingCartState = class ShoppingCartState {
    constructor(service, utilsService, store, helpers) {
        this.service = service;
        this.utilsService = utilsService;
        this.store = store;
        this.helpers = helpers;
    }
    ngxsOnInit({ dispatch }) {
        this.store.select(AuthTokenState.isLogged)
            .pipe(filter(_ => !!_))
            .subscribe(_ => {
            dispatch(new GetShoppingCartDetails());
        });
    }
    openShoppingCartDrawer({ patchState, dispatch }) {
        patchState({ drawerVisible: true });
        dispatch(new CleanErrors());
    }
    closeShoppingCartDrawer({ patchState }) {
        patchState({ drawerVisible: false });
    }
    updateCart(patchState, data) {
        patchState(this.helpers.buildShoppingCart(data));
    }
    getShoppingCartDetails({ patchState, dispatch }, action) {
        return this.service.getShoppingCartDetails()
            .pipe(map((response) => response.data.getMyBasketDetails), filter(data => !!data), tap((data) => {
            this.updateCart(patchState, data);
            return dispatch([]);
        }), catchError((graphqlError) => {
            const error = this.utilsService.formatGraphqlError(graphqlError);
            return dispatch([]);
        }));
    }
    addLineItem({ getState, patchState, dispatch }, action) {
        let itemAlreadyInCart = getState().lineItems.find(_ => _.productVariantSnapshot.sku === action.sku);
        if (itemAlreadyInCart) {
            itemAlreadyInCart.quantity = action.quantity;
            return dispatch([new QuantityChangeLineItemInShoppingCart(itemAlreadyInCart), new OpenShoppingCartDrawer()]);
        }
        dispatch(new CleanErrors());
        patchState({
            addLineItemSku: action.sku,
            addLineItemPending: true,
            addLineItemSuccess: false,
            addLineItemError: undefined,
        });
        return this.service.addLineItem(action.sku, action.quantity)
            .pipe(map((response) => response.data.addBasketItem), tap((data) => {
            this.updateCart(patchState, data);
            patchState({
                addLineItemSku: undefined,
                addLineItemPending: false,
                addLineItemSuccess: true,
                addLineItemError: undefined,
            });
            return dispatch([new OpenShoppingCartDrawer()]);
        }), catchError((graphqlError) => {
            patchState({
                addLineItemSku: undefined,
                addLineItemPending: false,
                addLineItemSuccess: false,
                addLineItemError: this.utilsService.formatGraphqlError(graphqlError)
            });
            return dispatch([]);
        }));
    }
    quantityChangeLineItem({ patchState, dispatch }, action) {
        dispatch(new CleanErrors());
        patchState({
            quantityChangeLineItemPending: true,
            quantityChangeLineItemSuccess: false,
            quantityChangeLineItemError: undefined,
        });
        return this.service.editLineItem(action.lineItem.id, action.lineItem.quantity)
            .pipe(map((response) => response.data.editBasketItem), tap((data) => {
            this.updateCart(patchState, data);
            patchState({
                quantityChangeLineItemPending: false,
                quantityChangeLineItemSuccess: true,
                quantityChangeLineItemError: undefined,
            });
            return dispatch([]);
        }), catchError((graphqlError) => {
            patchState({
                quantityChangeLineItemPending: false,
                quantityChangeLineItemSuccess: false,
                quantityChangeLineItemError: this.utilsService.formatGraphqlError(graphqlError),
            });
            return dispatch([]);
        }));
    }
    removeLineItem({ patchState, dispatch }, action) {
        dispatch(new CleanErrors());
        patchState({
            removeLineItemPending: true,
            removeLineItemSuccess: false,
            removeLineItemError: undefined,
        });
        return this.service.removeLineItem(action.lineItem.id)
            .pipe(map((response) => response.data.removeBasketItem), tap((data) => {
            this.updateCart(patchState, data);
            patchState({
                removeLineItemPending: false,
                removeLineItemSuccess: true,
                removeLineItemError: undefined,
            });
            return dispatch([]);
        }), catchError((graphqlError) => {
            patchState({
                removeLineItemPending: false,
                removeLineItemSuccess: false,
                removeLineItemError: this.utilsService.formatGraphqlError(graphqlError)
            });
            return dispatch([]);
        }));
    }
    editShippingMethod({ patchState, dispatch }, action) {
        dispatch(new CleanErrors());
        patchState({
            editShippingMethodPending: true,
            editShippingMethodSuccess: false,
            editShippingMethodError: undefined,
        });
        return this.service.editShippingMethod(action.shippingGroupId, action.shippingMethodId)
            .pipe(map((response) => response.data.editShippingMethod), tap((data) => {
            this.updateCart(patchState, data);
            patchState({
                editShippingMethodPending: false,
                editShippingMethodSuccess: true,
                editShippingMethodError: undefined,
            });
            return dispatch([]);
        }), catchError((graphqlError) => {
            patchState({
                editShippingMethodPending: false,
                editShippingMethodSuccess: false,
                editShippingMethodError: this.utilsService.formatGraphqlError(graphqlError)
            });
            return dispatch([]);
        }));
    }
    addPromotionCode({ patchState, dispatch }, action) {
        dispatch(new CleanErrors());
        patchState({ addPromotionCodePending: true, addPromotionCodeSuccess: false, addPromotionCodeError: undefined });
        return this.service.addPromotionCode(action.promotionCode)
            .pipe(map((response) => response.data.addPromoCode), tap((data) => {
            this.updateCart(patchState, data);
            patchState({ addPromotionCodePending: false, addPromotionCodeSuccess: true, addPromotionCodeError: undefined });
            return dispatch(new AddPromotionCodeSuccess());
        }), catchError((graphqlError) => {
            patchState({
                addPromotionCodePending: false,
                addPromotionCodeSuccess: false,
                addPromotionCodeError: this.utilsService.formatGraphqlError(graphqlError)
            });
            return dispatch(new AddPromotionCodeFailure());
        }));
    }
    removePromotionCode({ patchState, dispatch }, action) {
        dispatch(new CleanErrors());
        patchState({ removePromotionCodePending: true, removePromotionCodeSuccess: false, removePromotionCodeError: undefined });
        return this.service.removePromotionCode(action.promotionCode)
            .pipe(map((response) => response.data.removePromoCode), tap((data) => {
            this.updateCart(patchState, data);
            patchState({ removePromotionCodePending: false, removePromotionCodeSuccess: true, removePromotionCodeError: undefined });
            return dispatch(new RemovePromotionCodeSuccess());
        }), catchError((graphqlError) => {
            patchState({
                removePromotionCodePending: false,
                removePromotionCodeSuccess: false,
                removePromotionCodeError: this.utilsService.formatGraphqlError(graphqlError)
            });
            return dispatch(new RemovePromotionCodeFailure());
        }));
    }
    emptyCartAfterPayment({ patchState }) {
        patchState(defaults);
    }
    fetchPromotionCodes({ patchState }) {
        return this.service.fetchPromotionCodes()
            .pipe(map(response => response.data.promotionCodes), tap((promotionCodes) => {
            patchState({ promotionCodes });
        }));
    }
    cleanErrors({ patchState }) {
        patchState({
            addLineItemError: undefined,
            removeLineItemError: undefined,
            editShippingMethodError: undefined,
            quantityChangeLineItemError: undefined,
            addPromotionCodeError: undefined,
            removePromotionCodeError: undefined
        });
    }
    cleanShoppingBatches({ patchState }) {
        patchState({
            shippingBatches: [],
            shippingGroups: []
        });
    }
    updatePurchaseOrderIdOnItem({ patchState, dispatch, getState }, action) {
        patchState({
            updatePurchaseOrdersPerItemPending: true
        });
        return this.service.updatePurchaseOrderOnBasketItem(action.id, action.purchaseOrderIds)
            .pipe(map((response) => response.data.editBasketItemPurchaseOrder), tap((data) => {
            this.updateCart(patchState, data);
            patchState({
                updatePurchaseOrdersPerItemPending: false,
                updatePurchaseOrdersPerItemSuccess: true
            });
            return dispatch([]);
        }), catchError((httpError) => {
            patchState({
                updatePurchaseOrdersPerItemPending: false,
                updatePurchaseOrdersPerItemSuccess: false,
                updatePurchaseOrdersPerItemFailed: true,
                updatePurchaseOrdersPerItemError: httpError
            });
            return dispatch([]);
        }));
    }
    fetchAvailablePurchaseOrders({ patchState, dispatch, getState }, action) {
        patchState({
            availablePurchaseOrdersPerSellerPending: true,
            availablePurchaseOrdersPerSellerSuccess: false,
            availablePurchaseOrdersPerSellerError: undefined
        });
        return this.service.fetchAvailablePurchaseOrders(action.sellerIds).pipe(map((response) => response.data), map((response) => {
            patchState({
                availablePurchaseOrdersPerSeller: response,
                availablePurchaseOrdersPerSellerPending: false,
                availablePurchaseOrdersPerSellerSuccess: true,
                availablePurchaseOrdersPerSellerError: undefined
            });
            return dispatch([]);
        }), catchError((httpError) => {
            const error = httpError.error.error;
            patchState({
                availablePurchaseOrdersPerSeller: [],
                availablePurchaseOrdersPerSellerPending: false,
                availablePurchaseOrdersPerSellerSuccess: false,
                availablePurchaseOrdersPerSellerError: error
            });
            return dispatch([]);
        }));
    }
    addReferenceId({ patchState }, action) {
        patchState({ referenceId: action.referenceId });
    }
};
tslib_1.__decorate([
    Action(OpenShoppingCartDrawer),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "openShoppingCartDrawer", null);
tslib_1.__decorate([
    Action(CloseShoppingCartDrawer),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "closeShoppingCartDrawer", null);
tslib_1.__decorate([
    Action(GetShoppingCartDetails),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddLineItemToShoppingCart]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "getShoppingCartDetails", null);
tslib_1.__decorate([
    Action(AddLineItemToShoppingCart),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddLineItemToShoppingCart]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "addLineItem", null);
tslib_1.__decorate([
    Action(QuantityChangeLineItemInShoppingCart),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RemoveLineItemFromShoppingCart]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "quantityChangeLineItem", null);
tslib_1.__decorate([
    Action(RemoveLineItemFromShoppingCart),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RemoveLineItemFromShoppingCart]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "removeLineItem", null);
tslib_1.__decorate([
    Action(EditShippingMethod),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, EditShippingMethod]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "editShippingMethod", null);
tslib_1.__decorate([
    Action(AddPromotionCode),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddPromotionCode]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "addPromotionCode", null);
tslib_1.__decorate([
    Action(RemovePromotionCode),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, RemovePromotionCode]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "removePromotionCode", null);
tslib_1.__decorate([
    Action(EmptyCartAfterPayment),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "emptyCartAfterPayment", null);
tslib_1.__decorate([
    Action(FetchPromotionCodes),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "fetchPromotionCodes", null);
tslib_1.__decorate([
    Action(CleanErrors),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "cleanErrors", null);
tslib_1.__decorate([
    Action(CleanShippingBatches),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "cleanShoppingBatches", null);
tslib_1.__decorate([
    Action(UpdatePurchaseOrderIdOnItem),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, UpdatePurchaseOrderIdOnItem]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "updatePurchaseOrderIdOnItem", null);
tslib_1.__decorate([
    Action(FetchAvailablePurchaseOrders),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, FetchAvailablePurchaseOrders]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "fetchAvailablePurchaseOrders", null);
tslib_1.__decorate([
    Action(AddReferenceId),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, AddReferenceId]),
    tslib_1.__metadata("design:returntype", void 0)
], ShoppingCartState.prototype, "addReferenceId", null);
ShoppingCartState = tslib_1.__decorate([
    State({
        name: 'shoppingCart',
        defaults
    }),
    tslib_1.__metadata("design:paramtypes", [ShoppingCartService,
        UtilsService,
        Store,
        HelpersService])
], ShoppingCartState);
export { ShoppingCartState };
