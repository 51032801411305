<nz-select
  [nzShowSearch]="showSearch"
  [nzAllowClear]="allowClear"
  [nzPlaceHolder]="placeholder"
  [nzDisabled]="disabled"
  [formControl]="control"
  (nzOnSearch)="onSearchEvent($event)"
>
  <nz-option
    *ngFor="let option of availableOptions"
    [nzLabel]="option.label"
    [nzValue]="option.value"
    [nzDisabled]="option.disabled"
  ></nz-option>
</nz-select>

<div class="selected-options-container">
  <div *ngFor="let item of selectedOptions" class="selected-option">
    <span class="selected-option__label">
      {{ item.label }}
    </span>
    &nbsp;
    <svg
      (click)="unselectOption(item)"
      class="unselect-icon"
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 514 514"
      xml:space="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1588239402326"
      width="16"
      height="16"
    >
      <g transform="translate(1, 1)">
        <path
          d="M256,8C119,8,8,119,8,256s111,248,248,248s248-111,248-248S393,8,256,8z M256,472c-118.7,0-216-96.1-216-216  c0-118.7,96.1-216,216-216c118.7,0,216,96.1,216,216C472,374.7,375.9,472,256,472z M350.8,186.7L281.5,256l69.3,69.3  c4.7,4.7,4.7,12.3,0,17l-8.5,8.5c-4.7,4.7-12.3,4.7-17,0L256,281.5l-69.3,69.3c-4.7,4.7-12.3,4.7-17,0l-8.5-8.5  c-4.7-4.7-4.7-12.3,0-17l69.3-69.3l-69.3-69.3c-4.7-4.7-4.7-12.3,0-17l8.5-8.5c4.7-4.7,12.3-4.7,17,0l69.3,69.3l69.3-69.3  c4.7-4.7,12.3-4.7,17,0l8.5,8.5C355.4,174.4,355.4,182,350.8,186.7L350.8,186.7z"
          vector-effect="non-scaling-stroke"
          style="fill: currentColor;"
        />
      </g>
    </svg>
  </div>
</div>
