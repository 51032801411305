import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Sort } from 'src/app/snatch/models/sort.model';

@Component({
  selector: 'leap-sort-bar',
  templateUrl: './sort-bar.component.html',
  styleUrls: ['./sort-bar.component.less']
})
export class SortBarComponent implements OnInit {
  @Input() title?: string;
  @Input() sortFields: { value: string, displayValue: string }[];
  @Input() sort?: Sort;
  @Output() toggleSort = new EventEmitter<string>();

  constructor(private store: Store) {}

  ngOnInit() {}

  toggleSortFn(key: string) {
    this.toggleSort.emit(key);
  }
}
