<div class="line-item-container" [formGroup]="form">
  <div class="left-container">
    <div class="line-item-image">
      <leap-product-card-image class="image-item" [size]="'small'" [imageUrl]="bundle?.bundleThumbnailUrl || imageUrl" (onClick)="goToDetailsFn()"></leap-product-card-image>
    </div>
    <div class="line-item-details-container">
      <h5 class="line-item-title" (click)="goToDetailsFn()">{{ bundle?.name || name | ellipsis:[25] }}</h5>
      <p class="line-item-details" *ngIf="!bundle else bundleDetails">
        <span>{{ language }}</span>
        <span> / {{ deliveryFormat }}</span>
        <span *ngIf="certificateType"> / {{ certificateType }}</span>
      </p>

      <ng-template #bundleDetails>
        <div class="line-item-details">
          <div>{{ language }}</div>
          <div>Bundle / {{ bundle.productsCount }} Products</div>
          <div>{{ bundle.licenseValue }} {{bundle.durationTypeValue ? '/' : ''}} {{ bundle.duration }} {{ bundle.durationTypeValue }}</div>
        </div>
      </ng-template>
    </div>
    <div class="line-item-quantity-input">
      <input style="width: 64px;" type="number" nz-input formControlName="quantity" min="1" />
    </div>
  </div>
  <div class="right-container">
    <div class="line-item-price">
      <p>{{ currencySignsMap?.get(currency) }} {{ netTotalWithSurcharges | number : '1.2-2' }}</p>
    </div>
    <div class="remove-product">
      <svg (click)="onRemove()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" xml:space="preserve" y="0px" x="0px" id="Layer_1_1553784554570" width="16" height="20"><g transform="translate(1, 1)"><style type="text/css">
        .st0_1553784554570{fill:#394042;}
      </style><path d="M336,64l-33.6-44.8C293.3,7.1,279.1,0,264,0h-80c-15.1,0-29.3,7.1-38.4,19.2L112,64H24C10.7,64,0,74.7,0,88v2  c0,3.3,2.7,6,6,6h26v368c0,26.5,21.5,48,48,48h288c26.5,0,48-21.5,48-48V96h26c3.3,0,6-2.7,6-6v-2c0-13.3-10.7-24-24-24H336z   M184,32h80c5,0,9.8,2.4,12.8,6.4L296,64H152l19.2-25.6C174.2,34.4,179,32,184,32z M384,464c0,8.8-7.2,16-16,16H80  c-8.8,0-16-7.2-16-16V96h320V464z M208,420V156c0-6.6,5.4-12,12-12h8c6.6,0,12,5.4,12,12v264c0,6.6-5.4,12-12,12h-8  C213.4,432,208,426.6,208,420z M128,420V156c0-6.6,5.4-12,12-12h8c6.6,0,12,5.4,12,12v264c0,6.6-5.4,12-12,12h-8  C133.4,432,128,426.6,128,420z M288,420V156c0-6.6,5.4-12,12-12h8c6.6,0,12,5.4,12,12v264c0,6.6-5.4,12-12,12h-8  C293.4,432,288,426.6,288,420z" class="st0_1553784554570" vector-effect="non-scaling-stroke" style="fill: rgb(142, 143, 166);"/></g></svg>
    </div>
  </div>
</div>
