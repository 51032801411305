<div class="image-container" [ngClass]="size">
  <leap-product-card-image-skeleton [size]="size" *ngIf="imageLoading"></leap-product-card-image-skeleton>
  <div [hidden]="imageLoading" (click)="onClickFn()">
    <ng-container *ngIf="imageUrl && !brokenImage; else imagePlaceholder">
        <img class="product-image" [ngClass]="size" alt="Product Image"
          [src]="imageUrl"
          (load)="imageLoading = false"
          (error)="imageLoading = false; brokenImage = true">
    </ng-container>
    <ng-template #imagePlaceholder>
      <div class="image-placeholder" [ngClass]="size" (click)="onClickFn()"> Image Placeholder </div>
    </ng-template>
  </div>
</div>