<nz-card>
  <ng-container *ngIf="!freeze && promotionCodes">
    <form>
      <nz-form-item>
        <nz-form-label>Promotion Code</nz-form-label>
        <nz-form-control>
          <div class="input-container">
            <input nz-input
              nzSize="large"
              [nzAutocomplete]="auto"
              [(ngModel)]="promotionCode"
              [ngModelOptions]="{ standalone: true }"
              placeHolder="XXXX XXXX XXXX XXXX">
            <nz-autocomplete [nzOverlayStyle]="{ 'width': '33.5vw'}" #auto >
              <nz-auto-optgroup *ngIf="promotionCodes.length" [nzLabel]="promotionCodeGroupLabelTemplate">
                  <nz-auto-option *ngFor="let item of promotionCodes" [nzLabel]="item.code" [nzValue]="item.code">
                    <ng-container *ngTemplateOutlet="promotionCodeOption; context: { item: item }"></ng-container>
                  </nz-auto-option>
              </nz-auto-optgroup>
            </nz-autocomplete>
            <button nzSize="large" nz-button nzType="default" (click)="addPromotionCodeFn()">Add</button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </form>
    <leap-error-alert [source]="addPromotionCodeError?.message"></leap-error-alert>
    <hr>
  </ng-container>
  <nz-spin [nzSpinning]="calculating$ | async" [nzIndicator]="loadingIndicatorTemplate">
    <h2>{{ recordedTransaction ? 'Transaction' : 'Order' }} Summary <span class="payment-status" *ngIf="paymentStatus && paymentStatus.configKey === 'payment-paid'">{{ paymentStatus.configValue }}</span></h2>
    <nz-table
        [nzData]="[1, 2]"
        [nzShowPagination]="false">
        <tbody *ngIf="!recordedTransaction">
          <tr>
              <td class="label" i18n="@@order-summary.labels.subtotal">Subtotal</td>
              <td class="value">{{ currencySignsMap?.get(currency) }} {{ (actualNetTotal ? actualNetTotal : subtotalAmount) | number:'1.2-2' }}</td>
          </tr>
          <tr>
              <td class="label" i18n="@@order-summary.labels.surcharges">Surcharges</td>
              <td class="value">{{ currencySignsMap?.get(currency) }} {{ surchargeAmount || 0 | number:'1.2-2' }}</td>
          </tr>
          <tr>
              <td class="label" i18n="Label for shipping costs@@order-summary.labels.shipping">Shipping</td>
              <td class="value">{{ currencySignsMap?.get(currency) }} {{ shippingAmount | number:'1.2-2' }}</td>
          </tr>
          <tr>
              <td class="label" i18n="@@order-summary.labels.taxes">Taxes</td>
              <td class="value align-to-top">{{ currencySignsMap?.get(currency) }} {{ feeAmount | number:'1.2-2' }}</td>
          </tr>
          <tr *ngIf="promotionPricingDiscountTotal">
              <td class="label">Tiers Promotion</td>
              <td class="value align-to-top">-{{ currencySignsMap?.get(currency) }} {{ promotionPricingDiscountTotal | number:'1.2-2' }}</td>
          </tr>
          <!-- <tr>
              <td class="label">
                <span>International Handling Fee</span>
                <span class="description">(will be calculated at checkout)</span>
              </td>
              <td class="value align-to-top">{{ currencySignsMap?.get(currency) }} {{ feeAmount | number:'1.2-2' }}</td>
          </tr> -->
          <tr *ngFor="let promotionCode of appliedPromotionCodes" leap-promotion-code-row
            [freeze]="freeze"
            [promotionCode]="promotionCode"
            [currencySignsMap]="currencySignsMap"
            [currency]="currency"
            (remove)="removePromotionCodeFn($event)"></tr>
          <tr *ngFor="let promotionCode of invalidPromotionCodes" leap-promotion-code-row-invalid
            [freeze]="freeze"
            [promotionCode]="promotionCode"
            (remove)="removePromotionCodeFn($event)"></tr>
          <tr>
          <tr>
            <td><hr></td>
            <td><hr></td>
          </tr>
          <tr>
            <td class="label total">Estimated Total</td>
            <td class="value total">{{ currencySignsMap?.get(currency) }} {{ totalAmount | number:'1.2-2' }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="recordedTransaction">
          <tr>
            <td class="label total">Total</td>
            <td class="value total">{{ currencySignsMap?.get(currency) }} {{ totalAmount | number:'1.2-2' }}</td>
          </tr>
        </tbody>
    </nz-table>
    <leap-error-alert [source]="removePromotionCodeError?.message"></leap-error-alert>
  </nz-spin>
</nz-card>

<ng-template #promotionCodeGroupLabelTemplate>
  <div nz-row style="padding-top: 10px; border-bottom: 1px solid #cfd0dd;" class="promotion-label">
    <div nz-col nzLg="10" nzMd="6" style="padding-left: 5px; color: #26273b">Code</div>
    <div nz-col nzLg="6" nzMd="5" style="color: #26273b">Discount %</div>
    <div nz-col nzLg="8" nzMd="7" style="text-align: right; color: #26273b;">Availability</div>
  </div>
</ng-template>

<ng-template #promotionCodeOption let-item="item">
  <div nz-row style="padding-top: 8px; padding-bottom: 8px;">
    <div nz-col nzLg="10" nzMd="7" class="item-code">{{ item.code }}</div>
    <div nz-col nzLg="4" nzMd="4" class="discount-percentage">{{ item.discountPercentage * 100 }}% off</div>
    <div nz-col nzLg="10" nzMd="7" class="valid-till" style="color: #26273b;"> {{ item.unlimitedUsage ? 'Unlimited' : item.unitLimit === 1 ? item.unitLimit + ' item' : item.unitLimit + ' items' }} until {{ item.validTill | date:'dd/MM/yyyy' }}</div>
  </div>
</ng-template>

<ng-template #loadingIndicatorTemplate>
  <i nz-icon nzType="loading" style="font-size: 24px;"></i>
</ng-template>
