import { Injectable } from '@angular/core';
import { LineItem, LineItemListItem } from 'src/app/shopping-cart/models/line-item.model';
import { ShippingGroup } from 'src/app/shopping-cart/models/shipping-group.model';
import { ShoppingCart } from 'src/app/shopping-cart/models/shopping-cart.model';
import { PromotionCode } from 'src/app/common/common-shopping-cart/models/promotion-code.model';
import { UtilsService } from 'src/app/snatch/services/utils.service';

@Injectable()
export class HelpersService {

    constructor(private utilsService: UtilsService) { }

    buildShippingBatches(lineItems: LineItem[], shippingGroups: ShippingGroup[]): ShippingGroup[] {
      const resultShippingGroups = shippingGroups.filter(_ => !_['orphan']);
      const groupedIds = resultShippingGroups
        .map(_ => _.basketItemIds)
        .reduce((acc, val) => acc.concat(val), [])
        .filter(_ => !!_);

      resultShippingGroups.forEach(_ => {
        const groupedLineItems = [];

        if (!_.basketItemIds) return;

        _.basketItemIds.forEach(itemId => {
          const lineItem = lineItems.find(lineItem => lineItem.id === itemId);

          if (lineItem) groupedLineItems.push(lineItem)
        })

        const shippingMethods = _.shippingMethods.sort((a, b) => {
          return a.totalAmount - b.totalAmount;
        })

        _.shippingMethods = shippingMethods;
        _['lineItems'] = groupedLineItems;
      })

      const orphanLineItems = [];
      lineItems.forEach(lineItem => {

        if (!groupedIds || !groupedIds.find(_ => _ === lineItem.id)) orphanLineItems.push(lineItem)
      })
      if (orphanLineItems.length) {
        (resultShippingGroups as any[]).push({ orphan: true, lineItems: orphanLineItems })
      }
      return resultShippingGroups;
    }

    buildShoppingCart(data: ShoppingCart) {
      const items: LineItem[] = data.items;
      const netTotal = data.netTotal;
      const grossTotal = data.grossTotal;
      const taxTotal = data.taxTotal;
      const discountTotal = data.discountTotal;
      const surchargeTotal = data.surchargeTotal;
      const shippingTotal = data.shippingTotal;
      const promotionPricingDiscountTotal = data.promotionPricingDiscountTotal;
      const actualNetTotal = data.actualNetTotal;
      const currency = data.currency;
      const defaultShippingAddress = data.defaultShippingAddress;

      const shippingGroups = data.shippingGroups;
      const shippingBatches = this.buildShippingBatches(items, shippingGroups);

      const appliedPromotionCodes: PromotionCode[] = data.appliedPromoCodes;
      const invalidPromotionCodes: PromotionCode[] = data.invalidPromoCodes;

      // Aggregate the Promotion Code -> SKUs for the tooltip on the applied Promotion Codes
      appliedPromotionCodes.map((promotionCode: PromotionCode) => {
        const aggregation = [];
        items
          .forEach((lineItem: any) => {
            const listItemWithPromotionCodeApplied = (lineItem.listItems as LineItemListItem[])
              .find((listItem: LineItemListItem) => !!listItem.promoCodes.find((_: string) => _ === promotionCode.code));

            if (listItemWithPromotionCodeApplied)
              aggregation.push({
                productName: lineItem.productVariantSnapshot.name,
                sku: lineItem.productVariantSnapshot.sku,
                ...listItemWithPromotionCodeApplied
              })
          })

          if (aggregation.length)
            promotionCode.aggregation = aggregation;
      })

      return {
        lineItems: this.utilsService.formatLineItems(items),
        shippingGroups,
        shippingBatches,
        netTotal,
        grossTotal,
        taxTotal,
        discountTotal,
        surchargeTotal,
        shippingTotal,
        promotionPricingDiscountTotal,
        actualNetTotal,
        currency,
        appliedPromotionCodes,
        invalidPromotionCodes,
        defaultShippingAddress
      }
    }
}
