import { ComponentFactoryResolver, Directive, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { LabelOptionalComponent } from '../components/label-optional/label-optional.component';

@Directive({
  selector: '[leapLabelOptional]'
})
export class LabelOptionalDirective implements OnInit {
  constructor(private resolver: ComponentFactoryResolver, private vcr: ViewContainerRef,private renderer: Renderer2) {}

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(LabelOptionalComponent);
    this.vcr.createComponent(factory);
  }
}
