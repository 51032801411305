import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leap-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.less']
})
export class ProductsComponent implements OnInit {

  ngOnInit() {}

}