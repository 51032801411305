/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-no-results.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "./product-no-results.component";
var styles_ProductNoResultsComponent = [i0.styles];
var RenderType_ProductNoResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductNoResultsComponent, data: {} });
export { RenderType_ProductNoResultsComponent as RenderType_ProductNoResultsComponent };
export function View_ProductNoResultsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["style", "max-width: 510px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["nz-icon", ""], ["type", "stop"]], null, null, null, null, null)), i1.ɵdid(3, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["style", "margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["Sorry, we couldn't find any results matching ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["style", "margin-bottom: 0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search Tips:"])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "ul", [["style", "padding-left: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Check your spelling and try again"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Try using different, yet still related terms"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Try using more general terms"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Try adjusting your filters for a more simple and broader search"]))], function (_ck, _v) { var currVal_0 = "stop"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.appliedFilters; _ck(_v, 5, 0, currVal_1); }); }
export function View_ProductNoResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "leap-product-no-results", [], null, null, null, View_ProductNoResultsComponent_0, RenderType_ProductNoResultsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ProductNoResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductNoResultsComponentNgFactory = i1.ɵccf("leap-product-no-results", i4.ProductNoResultsComponent, View_ProductNoResultsComponent_Host_0, { searchForm$: "searchForm$", filters$: "filters$" }, {}, []);
export { ProductNoResultsComponentNgFactory as ProductNoResultsComponentNgFactory };
