import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Select } from '@ngxs/store';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
const ɵ0 = (state) => state.core.globalFilters.searchHits;
export class NavbarFiltersComponent {
    constructor(fb) {
        this.fb = fb;
        this.topicSelect = new EventEmitter();
        this.filterProducts = new EventEmitter();
        this.filterProduct = new EventEmitter();
        this.filterSeller = new EventEmitter();
        this.filterSubtopic = new EventEmitter();
        this.doGlobalSearch = new EventEmitter();
        this.searchForm = this.fb.group({
            term: [null],
            type: ['products']
        });
    }
    ngOnInit() {
        this.searchForm.controls.term.valueChanges
            .pipe(debounceTime(500))
            .subscribe(_ => {
            const type = this.searchForm.get('type').value;
            this.doGlobalSearch.emit({ context: type });
        });
    }
    filterProductsFn(filter) {
        this.filterProducts.emit(filter);
    }
    filterProductFn(term) {
        this.filterProduct.emit(term);
    }
    filterSellerFn(term) {
        this.filterSeller.emit(term);
    }
    filterSubtopicFn(term) {
        this.filterSubtopic.emit(term);
    }
    onTypeSelected(type) {
        this.searchForm.patchValue({ type });
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], NavbarFiltersComponent.prototype, "searchHits$", void 0);
export { ɵ0 };
