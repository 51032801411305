import { Injectable } from '@angular/core';
import { filter, finalize, take, tap } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import { CallbackResultModel } from '../models/callback-result.model';

@Injectable({
  providedIn: 'root'
})
export class WindowOpenerService {
  openWindowForResult(
    url: string,
    state: CallbackResultModel,
    queryParams: { [key: string]: string }
  ): Observable<any> {
    const clone = {
      ...queryParams,
      state: btoa(CallbackResultModel.toJSON(state))
    };

    const urlParams = Object.keys(clone).reduce((arr, key) => {
      arr.push(`${key}=${clone[key]}`);
      return arr;
    }, []);

    const fullUrl = url + '?' + urlParams.join('&');
    const popup = window.open(fullUrl);
    // TODO: timeout and check for ad blocker
    window.focus();

    return fromEvent(window, 'message').pipe(
      filter((e: MessageEvent) => e.origin === window.location.origin),
      filter((e: MessageEvent) => e.data.event === state.event),
      take(1),
      tap((event: MessageEvent) => console.log('EVENT', event.data)),
      finalize(() => popup.close())
    );
  }
}
