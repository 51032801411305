import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Store} from "@ngxs/store";
import {FetchSuggestions} from "../../../suggestions/actions/suggestion.actions";

@Component({
  selector: 'leap-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionsComponent implements OnInit {
  @Input() term: string;
  constructor() { }

  ngOnInit() {

  }

}
