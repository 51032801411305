import { HttpClient, HttpParams } from "@angular/common/http";
import { EnvironmentService } from "src/app/snatch/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../snatch/services/environment.service";
import * as i2 from "@angular/common/http";
export class LxpPublishingService {
    constructor(environment, http) {
        this.environment = environment;
        this.http = http;
    }
    getChannels(channelName = null) {
        const url = `${this.environment.amberUrl}/agora/lxp-product/channels`;
        let params = new HttpParams();
        if (channelName) {
            params = params.set("filter", channelName);
        }
        return this.http.get(url, { params: params });
    }
    getGroups() {
        return this.http.get(`${this.environment.amberUrl}/agora/lxp-product/groups`);
    }
    getTransactionTypes() {
        return this.http.get(`${this.environment.amberUrl}/agora/configuration/dict/filtered/transaction-type`);
    }
    getApprovalRules(ruleType) {
        return this.http.get(`${this.environment.amberUrl}/agora/buyer-approvals/approvalpolicy/list`, { params: { filter: ruleType } });
    }
    publishToLXP(payload) {
        return this.http.post(`${this.environment.amberUrl}/agora/lxp-product/publish/bulkbysku`, payload);
    }
    publishBundleToLXP(payload) {
        return this.http.post(`${this.environment.amberUrl}/agora/lxp-bundle/publish`, payload);
    }
    editLxpProduct(payload) {
        return this.http.post(`${this.environment.amberUrl}/agora/lxp-product/update/by-sku`, payload);
    }
    getLxpProductDetails(masterSku) {
        return this.http.get(`${this.environment.amberUrl}/agora/lxp-product/partner/sku/details`, {
            params: {
                sku: masterSku,
            },
        });
    }
}
LxpPublishingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LxpPublishingService_Factory() { return new LxpPublishingService(i0.ɵɵinject(i1.EnvironmentService), i0.ɵɵinject(i2.HttpClient)); }, token: LxpPublishingService, providedIn: "root" });
