import * as tslib_1 from "tslib";
import { SessionCheckFailure, SessionCheckSuccess } from './actions/auth.actions';
import { SessionService } from '../snatch/services/session.service';
import * as i0 from "@angular/core";
import * as i1 from "../snatch/services/session.service";
export class AuthService {
    constructor(sessionService) {
        this.sessionService = sessionService;
    }
    initSession() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = this.sessionService.getToken();
            const isAuth = !!token;
            return isAuth ? new SessionCheckSuccess() : new SessionCheckFailure();
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.SessionService)); }, token: AuthService, providedIn: "root" });
