import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsState } from 'src/app/core/state/notifications.state';
export class NotificationsComponent {
    constructor() {
        this.dropdownPanelVisible$ = new BehaviorSubject(false);
    }
    ngOnInit() {
        this.notReadNotificationsExist$ = this.notReadNotifications$.pipe(map((n) => n.length > 0));
        this.recentNotArchivedNotifications$ = this.notArchivedNotifications$.pipe(map((n) => n.slice(0, 3)));
    }
    showDropdownPanel(value) {
        this.dropdownPanelVisible$.next(value);
    }
}
tslib_1.__decorate([
    Select(NotificationsState.notReadNotifications),
    tslib_1.__metadata("design:type", Observable)
], NotificationsComponent.prototype, "notReadNotifications$", void 0);
tslib_1.__decorate([
    Select(NotificationsState.notArchivedNotifications),
    tslib_1.__metadata("design:type", Observable)
], NotificationsComponent.prototype, "notArchivedNotifications$", void 0);
